import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './style';

const NotFound = ({ classes }) => (
  <div className={classes.container}>
    <h1 className={classes.header}>NOT FOUND</h1>
    <p className={classes.text}>Please try a better URL</p>
  </div>
);

NotFound.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

NotFound.defaultProps = {
  classes: '',
};

export default withStyles(styles)(NotFound);
