const regIBAN = /^([A-Z]{2})(\d{2})(\d{18})$/;
const reqWhiteSpace = /\s+/g;

const isValidIBAN = (iban) => {
  const code = iban.match(regIBAN);

  if (!code) {
    return null;
  }
  const digits = (code[3] + code[1] + code[2]).replace(
    /[A-Z]/g,
    letter => letter.charCodeAt(0) - 55,
  );

  let checksum = digits.slice(0, 2);
  let fragment;
  for (let offset = 2; offset < digits.length; offset += 7) {
    fragment = String(checksum) + digits.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum === 1;
};

const sanitizeIBAN = iban => iban.toUpperCase().replace(reqWhiteSpace, '');

export { isValidIBAN, sanitizeIBAN };
