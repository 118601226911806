import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  withStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import InputText from '../../components/InputText';
import Button from '../../components/Button';
import SwitchInput from '../../components/SwitchInput';
import SelectField from '../../components/SelectField';
import styles from './style';
import { COMPANY } from '../../actions/offer';

class ModalSendOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: null,
      lastName: null,
      companyName: null,
      selectTitle: null,
      email: null,
      postalCode: this.props.offer.postalCode,
      password: null,
      registration: false,
      street: null,
      houseNr: null,
      city: null,
    };
    this.sendOffer = this.sendOffer.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.offer.postalCode !== this.props.offer.postalCode) {
      this.setState({
        postalCode: this.props.offer.postalCode,
      });
    }
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
  }

  async sendOffer(event) {
    event.preventDefault();

    const {
      selectTitle, companyName, firstName, lastName, email,
      password, registration, street, postalCode, houseNr, city,
    } = this.state;

    const name = selectTitle === COMPANY ? { companyName } : { firstName, lastName };

    let userData = {
      uuid: this.props.offer.offerId,
      email,
      ...name,
      title: selectTitle,
      secretWord: password,
    };
    if (registration) {
      userData = {
        ...userData,
        street,
        postalCode,
        houseNr,
        city,
      };
    }
    await this.props.setCustomerLogin(userData);

    if (!registration) {
      this.props.toggleSendOfferModal();
    }
  }

  render() {
    const {
      classes,
      structure,
      evbNumber,
      currentStep,
      toggleSendOfferModal,
      openSendOfferModal,
      inputFields,
    } = this.props;
    const {
      selectTitle,
      registration,
      street,
      houseNr,
      city,
      companyName,
      firstName,
      lastName,
      postalCode,
      email,
      password,
    } = this.state;
    return (
      <Fragment>
        {structure && (
          <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openSendOfferModal}
            onClose={toggleSendOfferModal}
            maxWidth="lg"
          >
            <DialogTitle align="center">{structure.emailDialog.title}</DialogTitle>
            <form onSubmit={this.sendOffer}>
              {evbNumber === '' && (
                <Fragment>
                  <DialogContent>
                    <Grid
                      container
                      justify="center"
                      alignContent="center"
                      spacing={0}
                      className={classes.dialogContentTitleContainer}
                    >
                      <Typography variant="h4" align="center">
                        {structure.emailDialog.text}
                      </Typography>
                    </Grid>
                    <Grid container justify="center" alignContent="center" spacing={0}>
                      <Grid item sm={10} xs={12}>
                        <Grid container justify="flex-start" alignContent="center" spacing={2}>
                          <Grid item sm={4} xs={12}>
                            <SelectField
                              {...inputFields.selectTitle}
                              onChange={this.onValueChange}
                              defaultValue={selectTitle}
                              setRequired
                            />
                          </Grid>
                          <Grid item sm={8} xs={12} />
                          {this.state.selectTitle === COMPANY && (
                            <Grid item xs={12}>
                              <InputText
                                {...inputFields.companyName}
                                onChange={this.onValueChange}
                                defaultValue={companyName}
                                disableAutocomplete
                              />
                            </Grid>
                          )}

                          {this.state.selectTitle !== COMPANY && (
                            <Fragment>
                              <Grid item sm={6} xs={12}>
                                <InputText
                                  {...inputFields.firstName}
                                  onChange={this.onValueChange}
                                  defaultValue={firstName}
                                  disableAutocomplete
                                />
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                <InputText
                                  {...inputFields.lastName}
                                  onChange={this.onValueChange}
                                  defaultValue={lastName}
                                  disableAutocomplete
                                />
                              </Grid>
                            </Fragment>
                          )}
                          <Grid item xs={12}>
                            <InputText
                              {...inputFields.email}
                              onChange={this.onValueChange}
                              defaultValue={email}
                              disableAutocomplete
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <InputText
                              {...inputFields.password}
                              {...structure.emailDialog.fields.password}
                              onChange={this.onValueChange}
                              defaultValue={password}
                              disableAutocomplete
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SwitchInput
                              {...inputFields.registration}
                              onChange={this.onValueChange}
                              defaultValue={registration}
                            />
                          </Grid>
                          {!registration && (
                            <Grid item xs={12}>
                              <Typography component="p" align="center">
                                {structure.emailDialog.fields.text}
                              </Typography>
                            </Grid>
                          )}
                          {registration && (
                            <Grid item xs={12}>
                              <Grid
                                container
                                justify="flex-start"
                                alignContent="center"
                                spacing={2}
                              >
                                <Grid item sm={6} xs={12}>
                                  <InputText
                                    {...inputFields.street}
                                    onChange={this.onValueChange}
                                    defaultValue={street}
                                    disableAutocomplete
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputText
                                    {...inputFields.houseNr}
                                    onChange={this.onValueChange}
                                    defaultValue={houseNr}
                                    disableAutocomplete
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputText
                                    {...inputFields.postalCode}
                                    onChange={this.onValueChange}
                                    defaultValue={postalCode}
                                    readOnly={!!postalCode && currentStep !== 'step1'}
                                    disableAutocomplete
                                  />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <InputText
                                    {...inputFields.city}
                                    onChange={this.onValueChange}
                                    defaultValue={city}
                                    disableAutocomplete
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Grid
                      container
                      justify="space-around"
                      alignContent="center"
                      spacing={0}
                      className={classes.dialogActionsContainer}
                    >
                      <Button
                        {...this.props.structure.emailDialog.buttons.cancel}
                        onClick={toggleSendOfferModal}
                      />
                      <Button {...this.props.structure.emailDialog.buttons.send} />
                    </Grid>
                  </DialogActions>
                </Fragment>
              )}
              {this.props.evbNumber !== '' && (
                <Fragment>
                  <DialogContent>
                    <Grid container justify="center" alignContent="center" spacing={0}>
                      <Typography variant="h4" align="center">
                        {structure.evbDialog.successText}
                      </Typography>
                    </Grid>
                    <Grid container justify="center" alignContent="center" spacing={0}>
                      <Grid item xs={12}>
                        <Typography variant="h4" align="center">
                          {structure.evbDialog.yourEvb}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h3" align="center">
                          {structure.evbDialog.evb} {evbNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Grid
                      container
                      justify="space-around"
                      alignContent="center"
                      spacing={0}
                      className={classes.dialogActionsContainer}
                    >
                      <Button
                        {...structure.evbDialog.buttons.close}
                        onClick={toggleSendOfferModal}
                      />
                    </Grid>
                  </DialogActions>
                </Fragment>
              )}
            </form>
          </Dialog>
        )}
      </Fragment>
    );
  }
}

ModalSendOffer.propTypes = {
  offer: PropTypes.objectOf(PropTypes.any).isRequired,
  structure: PropTypes.objectOf(PropTypes.any).isRequired,
  inputFields: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleSendOfferModal: PropTypes.func.isRequired,
  openSendOfferModal: PropTypes.bool.isRequired,
  setCustomerLogin: PropTypes.func.isRequired,
  evbNumber: PropTypes.string,
  currentStep: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};

ModalSendOffer.defaultProps = {
  classes: '',
  evbNumber: '',
};

export default withStyles(styles)(ModalSendOffer);
