import types from '../actions/types';

const initialState = {
  firstName: '',
  lastName: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_PERSONAL_DETAILS: {
      const { supervisor } = payload;
      const { id: supervisorId, firstName = '', lastName = '' } =
        supervisor || {};
      const supervisorFullName =
        firstName.length === 0 && lastName.length === 0
          ? 'N/A'
          : `${lastName}, ${firstName}`;
      return {
        ...state,
        ...{ supervisor, ...payload },
        ...{ supervisorId },
        ...{ supervisorFullName },
      };
    }
    default:
      return {
        ...state,
      };
  }
};
