import React, { Fragment, Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles, Tabs, Tab, Typography } from '@material-ui/core';
import cx from 'classnames';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import styles from './style';
import VehicleModelSearch from '../VehicleModelSearch';
import VehicleNumberSearch from '../VehicleNumberSearch';
import { clearCarModelList } from '../../actions/vehicleSearch';
import { clearVehicleNumberSearch } from '../../actions/vehicleNumberSearch';
import WehicleSearchModel from '../../models/wehicleSearch';
import TabContainer from '../../components/TabContainer';
import history from '../../history';
import { isBmw } from '../../utils/theme';
import { CHOOSE_VEHICLE_TYPE } from '../../utils/redirects';
import { vehicleTypeKeys } from '../../reducers/settings';

class VehicleSearch extends Component {
  state = {
    value: 0,
  };

  componentDidMount() {
    this.props.clearCarModelList();
    this.props.clearVehicleNumberSearch();
  }

  componentDidUpdate() {
    this.handleRedirect();
  }

  handleRedirect = () => {
    // if flow is BMW and vehicleType is non set push to choose page
    if (isBmw() && this.props.vehicleType === vehicleTypeKeys.NON_SET) {
      this.moveToChooseVehicleType();
    }
  }

  moveToChooseVehicleType = () => {
    const queryParams = this.props.location.search;
    const url = queryParams ? `${CHOOSE_VEHICLE_TYPE}${queryParams}` : CHOOSE_VEHICLE_TYPE;
    history.push(url);
  }

  handleChange = (event, value) => {
    this.props.clearCarModelList();
    this.props.clearVehicleNumberSearch();
    this.setState({ value });
  }

  handleChangeIndex = (index) => {
    this.props.clearCarModelList();
    this.props.clearVehicleNumberSearch();
    this.setState({ value: index });
  }

  render() {
    const { structure, classes, theme } = this.props;
    const { value } = this.state;

    const SearchTabs = () => (
      <Tabs
        value={value}
        onChange={this.handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label={structure.buttons.vehicleNumber.label} />
        <Tab label={structure.buttons.vehicleModel.label} />
      </Tabs>);

    return (
      <Fragment>
        {structure && (
        <Fragment>
          <Typography align="center" variant="h1" className={classes.title}>
            {structure.header.mainText}
          </Typography>

          {isBmw() ?
            <div className={cx(classes.wrapper, classes.centeredContainer)}>
              <div className={classes.spaceSide}>
                <button
                  className={classes.backButton}
                  onClick={() => { this.moveToChooseVehicleType(); }}
                >
                  <KeyboardArrowLeftIcon styles={styles.arrowIcon} />{structure.buttons.back.label}
                </button>
              </div>
              <div className={classes.spaceMid}> { SearchTabs() }</div>
              <div className={classes.spaceSide} />
            </div>
            :
            <>{ SearchTabs() }</>
        }

          <Typography component="div" className={classes.hr} />
          <Typography component="div" className={classes.wrapper}>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={this.handleChangeIndex}
              slideClassName={classes.slide}
            >
              <TabContainer dir={theme.direction}>
                <VehicleNumberSearch />
              </TabContainer>
              <TabContainer dir={theme.direction}>
                <VehicleModelSearch />
              </TabContainer>
            </SwipeableViews>
          </Typography>
        </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  structure: state.projectStructure.vehicleSearch,
  callCenterDialog: state.projectStructure.callCenterDialog,
  inputFields: state.projectFields,
  vehicleType: state.settings.vehicleType,
});

const mapDispatchToProps = dispatch => ({
  clearCarModelList: () => dispatch(clearCarModelList()),
  clearVehicleNumberSearch: () => dispatch(clearVehicleNumberSearch()),
});

VehicleSearch.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  clearCarModelList: PropTypes.func.isRequired,
  clearVehicleNumberSearch: PropTypes.func.isRequired,
  structure: WehicleSearchModel,
  theme: PropTypes.objectOf(Object),
  vehicleType: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

VehicleSearch.defaultProps = {
  classes: '',
  structure: undefined,
  theme: '',
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(VehicleSearch);
