const styles = theme => ({
  container: theme.containers.container,
  paper: theme.containers.paper,
  divider: {
    margin: `${theme.units.spacing * 2}px 0`,
  },
  title: {
    ...theme.containers.title,
    paddingTop: 0,
  },
});

export default styles;
