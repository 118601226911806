import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, withStyles } from '@material-ui/core';
import Button from '../Button';
import ButtonLink from '../ButtonLink';
import FooterPrice from '../../containers/FooterPrice';
import styles from './style';

const Footer = ({ classes, buttonLink, buttonSubmit }) => (
  <Paper className={`${classes.paper} ${classes.hoveringPaper}  ${classes.customInput}`}>
    <Grid container justify="space-around" alignContent="center">
      <Grid item xs={4}>
        <FooterPrice />
      </Grid>
      <Grid item xs={4} align="center" className={classes.backButtonContainer}>
        <ButtonLink {...buttonLink.props} onClick={buttonLink.onClick} disabled={buttonLink.disabled} />
      </Grid>
      <Grid item xs={4} align="center" className={classes.nextButtonContainer}>
        <Button {...buttonSubmit.props} onClick={buttonSubmit.onClick} disabled={buttonSubmit.disabled} />
      </Grid>
    </Grid>
  </Paper>
);

export default withStyles(styles)(Footer);

Footer.propTypes = {
  message: PropTypes.string,
  error: PropTypes.bool,
  buttonLink: PropTypes.object,
  buttonSubmit: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string),
};

Footer.defaultProps = {
  classes: '',
  message: '',
  buttonLink: {},
  buttonSubmit: {},
  error: false,
};
