import React, { Fragment } from 'react';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { clearOffer } from '../../actions/offer';
import { clearCarModelList } from '../../actions/vehicleSearch';
import { clearVehicleNumberSearch } from '../../actions/vehicleNumberSearch';
import { setEvbOfferTab } from '../../actions/evbList';
import CompletedOffes from './CompletedOffers';
import UncompletedOffers from './UncompletedOffers';
import TabContainer from '../../components/TabContainer';
import fetchSalesChannels from '../../helpers/network/fetchSalesChannels';
import EvbOffersType from '../../models/evbOffers';
import history from '../../history';
import styles from './style';

export const displayClientName = offer =>
  (offer.customerIsCompany
    ? offer.customerCompanyName || ''
    : `${offer.customerLastName || ''} ${offer.customerFirstName || ''}`);

class EvbList extends React.Component {
  state = {
    salesChannels: {},
  };

  async componentDidMount() {
    this.props.clearOffer();
    this.props.clearCarModelList();
    this.props.clearVehicleNumberSearch();

    try {
      await this.loadSalesChannels();
    } catch {
      // do nothing
    }
  }

  handleOpenOffer = (offer) => {
    history.push(`insuranceoffer/${offer.id}`);
  }

  handleChange = (_, nextTab) => {
    this.props.setEvbOfferTab(nextTab);
  };

  loadSalesChannels = async () => {
    const salesChannels = await fetchSalesChannels();
    this.setState({ salesChannels });
  }

 displaySalesChannel = ({ saleChannel }) => this.state.salesChannels[saleChannel] || saleChannel;

 render() {
   const {
     classes, theme, structure, tab,
   } = this.props;
   return (
     <Fragment>
       {structure && (
       <Fragment>
         <Paper>
           <Typography variant="h2" className={classes.title}>
             {structure.header.mainText}
           </Typography>
           <Tabs
             value={tab}
             onChange={this.handleChange}
             indicatorColor="primary"
             textColor="primary"
             centered
           >
             <Tab label={
               <span className={classes.label}>{structure.header.completedOffers}</span>}
             />
             <Tab label={<span className={classes.label}>{structure.header.openOffers}</span>} />
           </Tabs>
         </Paper>
         <SwipeableViews
           axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
           index={tab}
           onChangeIndex={this.handleChangeIndex}
           slideClassName={classes.slide}
         >
           <TabContainer dir={theme.direction}>
             <CompletedOffes
               displaySalesChannel={offer => this.displaySalesChannel(offer)}
               handleOpenOffer={offer => this.handleOpenOffer(offer)}
             />
           </TabContainer>
           <TabContainer dir={theme.direction}>
             <UncompletedOffers
               displaySalesChannel={offer => this.displaySalesChannel(offer)}
               handleOpenOffer={offer => this.handleOpenOffer(offer)}
             />
           </TabContainer>
         </SwipeableViews>
       </Fragment>
        )}
     </Fragment>
   );
 }
}

const mapStateToProps = state => ({
  structure: state.projectStructure.evbList,
  tab: state.evbList.evbOfferTab,
});

const mapDispatchToProps = dispatch => ({
  clearOffer: () => dispatch(clearOffer()),
  clearCarModelList: () => dispatch(clearCarModelList()),
  clearVehicleNumberSearch: () => dispatch(clearVehicleNumberSearch()),
  setEvbOfferTab: tab => dispatch(setEvbOfferTab(tab)),
});

EvbList.propTypes = {
  classes: PropTypes.objectOf(Object).isRequired,
  theme: PropTypes.objectOf(Object),
  structure: PropTypes.objectOf(PropTypes.any),
  tab: PropTypes.number,
  clearOffer: PropTypes.func.isRequired,
  clearCarModelList: PropTypes.func.isRequired,
  clearVehicleNumberSearch: PropTypes.func.isRequired,
  setEvbOfferTab: PropTypes.func.isRequired,
};

EvbList.defaultProps = {
  theme: {},
  structure: undefined,
  tab: EvbOffersType.Completed,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EvbList);
