import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { resetUserPassword } from '../../actions/authentication';
import { clearMessage } from '../../actions/notificationCenter';
import styles from './style';
import InputText from '../../components/InputText';
import Button from '../../components/Button';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      email: this.props.passwordResetEmail
        ? this.props.passwordResetEmail
        : '',
      message: '',
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.isStructureReady = this.isStructureReady.bind(this);
    this.inputFieldsRepeater = this.inputFieldsRepeater.bind(this);
  }

  componentWillUnmount() {
    this.props.clearMessage();
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
  }

  submitHandler(event) {
    event.preventDefault();
    this.props.resetUserPassword(this.state.email);
  }

  isStructureReady() {
    return this.props.structure !== undefined;
  }

  inputFieldsRepeater(fields) {
    const mapItem = [];
    fields.map(key =>
      mapItem.push(<InputText
        {...this.props.inputFields[key]}
        {...this.props.structure.fields[key]}
        defaultValue={this.state[key]}
        onChange={this.onValueChange}
        key={`inputfields-${key}`}
      />));
    return mapItem;
  }

  render() {
    const isPasswordResetEmail = !!this.props.passwordResetEmail;
    return (
      <Typography component="div" className={this.classes.wrapper}>
        {this.isStructureReady() && (
          <Grid container justify="center" alignContent="center" spacing={0}>
            <Grid item xs={12} md={8}>
              <Typography align={isPasswordResetEmail ? 'align' : 'center'} variant={isPasswordResetEmail ? 'h3' : 'h1'} className={this.classes.title}>
                {isPasswordResetEmail
                  ? this.props.structure.header.secondaryText
                  : this.props.structure.header.mainText }
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <form onSubmit={event => this.submitHandler(event)} className={this.classes.form}>
                {this.inputFieldsRepeater(this.props.structure.fieldsOrder)}
                <Button {...this.props.structure.buttons.submit} myWidth="" />
              </form>
            </Grid>
          </Grid>
        )}
      </Typography>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.resetPassword,
  inputFields: state.projectFields,
  passwordResetEmail: state.auth.passwordResetEmail,
});

const mapDispatchToProps = dispatch => ({
  resetUserPassword: email => dispatch(resetUserPassword(email)),
  clearMessage: () => dispatch(clearMessage()),
});

ResetPassword.propTypes = {
  message: PropTypes.string,
  error: PropTypes.bool,
  passwordResetEmail: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  resetUserPassword: PropTypes.func,
  clearMessage: PropTypes.func,
};

ResetPassword.defaultProps = {
  classes: '',
  message: '',
  passwordResetEmail: null,
  error: false,
  resetUserPassword: () => {},
  clearMessage: () => {},
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ResetPassword);
