const marginSwitcher = ({ marginTop }) => marginTop;

const bullet = ({ valid }) => {
  if (valid === true) return 'url("/assets/images/checkbox-blank.svg")';
  return 'url("/assets/images/checkbox-blank.svg")';
};

const styles = theme => ({
  input: theme.inputs.inputText,
  inputUppercase: {
    ...theme.inputs.inputText,
    textTransform: 'uppercase',
  },
  inputContainer: {
    position: 'relative',
    marginTop: marginSwitcher,
  },
  inputButton: {
    width: '40px',
    height: '40px',
    position: 'absolute',
    backgroundColor: theme.palette.accent1Color,
    display: 'block',
    bottom: '0',
    right: '0',
    borderRadius: '0px 4px 4px 0px',
    cursor: 'pointer',
  },
  icons: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  helperText: {
    fontSize: '12px',
    fontStyle: 'italic',
    marginTop: theme.spacing(1),
  },
  ruleCheck: {
    ...theme.fonts.paragraph,
    display: 'flex',
    padding: '8px 0px 0px 0px',
    '&::before': {
      content: '""',
      background: 'no-repeat left center',
      width: '30px',
      height: '30px',
      display: 'inline-block',
      backgroundImage: bullet,
    },
  },
});

export default styles;
