import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Grid } from '@material-ui/core';
import CustomTextField, { formikTextFieldProps } from './CustomTextField';
import { QUESTION_GROUP, CLIENT_TYPE } from '../../../constants/';
import { getText, getQuestions, getMandatoryFields, getFieldsWithValidationRules, validateValuesByRuleNames } from '../ContactForm';
import TitleLabel from './TitleLabel';
import DescriptionLabel from './DescriptionLabel';
import useFormSubmitTrigger from './useFormSubmitTrigger';
import useFormErrorRender from './useFormErrorRender';
import useFormOnChange from './useFormOnChange';
import { sanitizeIBAN } from '../../../utils/iban';

const isCaseForShowMessage = values => (values.FirstName || values.LastName || values.DateOfBirth);

const currentFormQuestionGroup = QUESTION_GROUP.IBAN;

const IbanFormPhysical = (props) => {
  const {
    schema,
    submitTrigger,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    onChange,
    onErrorMarked,
    isSubmitting
  } = props;

  const fields = getQuestions(schema.questions, currentFormQuestionGroup);
  const formRef = useFormSubmitTrigger(submitTrigger);
  useFormOnChange(onChange, values, currentFormQuestionGroup);
  useFormErrorRender(onErrorMarked, isSubmitting, errors);

  const formikProps = {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    validationErrorMessages: schema.validationErrorMessages,
  };

  const handleCustomSubmit = (event) => {
    const ibanFieldName = fields.IBAN.fieldName;
    values[ibanFieldName] = sanitizeIBAN(values[ibanFieldName]);
    onChange(currentFormQuestionGroup, values);
    handleSubmit(event);
  };

  return (
    <form autoComplete="off" ref={formRef} onSubmit={handleCustomSubmit}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <TitleLabel>
            {getText(schema, currentFormQuestionGroup, 'text_1')}
          </TitleLabel>
        </Grid>


        <Grid item xs={12}>
          <DescriptionLabel>
            {getText(schema, currentFormQuestionGroup, 'text_2_1')}
            {getText(schema, currentFormQuestionGroup, 'text_2_2_1')}
            <a
              href={`${process.env.PUBLIC_URL}/assets/pdfs/Standard-SEPA-Mandat.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >{getText(schema, currentFormQuestionGroup, 'text_2_2_2')}
            </a>
            {getText(schema, currentFormQuestionGroup, 'text_2_2_3')}
            <br />
            {getText(schema, currentFormQuestionGroup, 'text_2_3')}
          </DescriptionLabel>
        </Grid>


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.IBAN, formikProps)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.Bank, formikProps)}
          />
        </Grid>


        <Grid item xs={12}>
          <DescriptionLabel>
            {getText(schema, currentFormQuestionGroup, 'text_3')}
          </DescriptionLabel>
        </Grid>


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.LastName, formikProps)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.FirstName, formikProps)}
          />
        </Grid>


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.DateOfBirth, formikProps)}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} />

        {isCaseForShowMessage(values) ?
          <Grid item xs={12}>
            <DescriptionLabel blue>
              {getText(schema, currentFormQuestionGroup, 'text_4')}
            </DescriptionLabel>
          </Grid> : null}

      </Grid>
    </form>
  );
};

IbanFormPhysical.propTypes = {
  schema: PropTypes.object.isRequired,
  submitTrigger: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withFormik({
  handleSubmit: (_, { setSubmitting, props }) => {
    props.onSuccessfulFormSubmit(currentFormQuestionGroup);
    setSubmitting(false);
  },
  mapPropsToValues: ({ formValues }) => ({
    IBAN: formValues.IBAN.IBAN || '',
    Bank: formValues.IBAN.Bank || '',
    LastName: formValues.IBAN.LastName || '',
    FirstName: formValues.IBAN.FirstName || '',
    DateOfBirth: formValues.IBAN.DateOfBirth,
  }),
  validate: (values, { schema, ...props }) => {
    const errors = {};

    getFieldsWithValidationRules(
      schema.questions,
      currentFormQuestionGroup,
      CLIENT_TYPE.PHYSICAL_PERSON,
      true,
    ).forEach(validateValuesByRuleNames(values, errors));

    const mandatoryFields = getMandatoryFields(
      schema.questions,
      currentFormQuestionGroup,
      CLIENT_TYPE.PHYSICAL_PERSON,
    );

    mandatoryFields.forEach((key) => {
      if (!values[key]) {
        errors[key] = 'Required';
      }
    });

    if (Object.keys(errors).length > 0) {
      props.onValidationError(currentFormQuestionGroup);
    }

    return errors;
  },
})(IbanFormPhysical);
