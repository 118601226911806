import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Typography, withStyles } from '@material-ui/core';
import { compose } from 'recompose';

import { getCurrentProjectName } from '../../utils/theme';
import styles from './style';

const CustomSwitch = ({
  name, onChange, defaultValue, labelLink, labelText, classes, readOnly, onHandleConfirmationModal,
}) => (
  <Typography component="div" className={classes.root}>
    <Typography component="div" className={classes.switchContainer}>
      <Switch
        checked={defaultValue}
        onChange={evn => onChange(evn.target.checked, name)}
        disabled={readOnly}
        classes={{
          switchBase: classes.colorSwitchBase,
          checked: classes.colorChecked,
          track: classes.colorBar,
        }}
      />
    </Typography>
    <Typography component="div" className={classes.labelContainer}>
      <Typography component="span" className={classes.link} onClick={onHandleConfirmationModal}>
        {labelLink[getCurrentProjectName()]}
      </Typography>
      {labelText}
    </Typography>
  </Typography>
);

CustomSwitch.propTypes = {
  defaultValue: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  labelText: PropTypes.string,
  labelLink: PropTypes.string,
  onHandleConfirmationModal: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  readOnly: PropTypes.bool,
};

CustomSwitch.defaultProps = {
  defaultValue: false,
  name: '',
  onChange: () => {},
  labelText: '',
  labelLink: '',
  classes: '',
  readOnly: false,
};

export default compose(withStyles(styles))(CustomSwitch);

