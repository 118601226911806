const backgroundImage = '/assets/images/bmw/choose-default-image.png';

const coverBackground = {
  '-webkit-background-size': 'cover',
  '-moz-background-size': 'cover',
  '-o-background-size': 'cover',
  backgroundSize: 'cover',
};

const styles = theme => ({
  container: {
    height: 'calc(100vh - 80px)',
    background: `url(${backgroundImage}) no-repeat center center fixed`,
    ...coverBackground,
  },
  button: {
    width: '37%',
    height: '78%',
    fontSize: '30px',
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: 'unset',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.15)',
    },
    '@media (max-width: 800px)': {
      fontSize: '20px',
    },
  },
});

export default styles;
