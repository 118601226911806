import types from '../actions/types';

const initialState = {
  showSpinner: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SPINNER:
      return { ...state, showSpinner: action.payload };
    default:
      return state;
  }
};
