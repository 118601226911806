import red from '@material-ui/core/colors/red';

const styles = theme => ({
  textField: {
    marginBottom: theme.spacing.unit * 3,
  },
  formControl: {
    width: '100%',
  },

  inputBaseRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
    '&:focus': {
      borderColor: '#A5ABB6',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      outline: 'unset',
    },
  },

  inputBaseError: {
    '& input': {
      borderColor: red[900],
    },
  },
  inputLabel: {
    fontSize: '1.125rem',
    fontWeight: 'bolder',
    color: theme.palette.textColor,
    paddingLeft: theme.spacing.unit,
    lineHeight: '24px',
  },
  inputLabelFocused: {
    color: `${theme.palette.darkBlue} !important`,
  },

});

export default styles;
