import { bindActionCreators } from 'redux';
import request from '../../requestAgent';
import store from '../../store';
import handleErrors from '../../actions/errorHandling';

const validateAddress = async (address) => {
  try {
    const endpoint = '/api/request-manager/checkAddress';
    const { body } = await request.post(endpoint).send(address);
    return body;
  } catch (err) {
    const boundActions = bindActionCreators({ handleErrors }, store.dispatch);
    boundActions.handleErrors(err);
    throw new Error('unable to validate address');
  }
};

export default validateAddress;
