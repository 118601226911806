import { isRequired } from './require';
import { maxLength } from './max-length';
import { minLength } from './min-length';
import { greaterThan } from './greater-than';
import { greaterThanEqual } from './greater-than-equal';
import { lessThanEqual } from './less-than-equal';

export const HSN_NUMBER_VALIDATION = [isRequired, maxLength(4), minLength(4)];
export const TSN_NUMBER_VALIDATION = [isRequired, maxLength(3), minLength(3)];
export const FINANCING_VALIDATION = [isRequired];
export const STATE_VALIDATION = [isRequired];
export const MILAGE_VALIDATION = [greaterThanEqual(0), lessThanEqual(20)];
export const MILAGE_VALIDATION_WITH_DATE = [greaterThan(0), lessThanEqual(999)];

export const checkValidations = (validations, field) => {
  const map = validations.map((m) => {
    const value = m(field);
    return !value;
  });
  return !map.includes(false);
};
