import request from '../requestAgent';
import types from './types';
import handleErrors from './errorHandling';
import { openSnackbar, SnackbarKind } from './snackbar';

const setSupervisorsAddUser = payload => ({
  type: types.SET_SUPERVISORS_ADD_USER,
  payload,
});

const setSupervisorsEditUser = payload => ({
  type: types.SET_SUPERVISORS_EDIT_USER,
  payload,
});

const getSupervisors = (option, dealership = '', role = '') => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user/supervisors';
    const res = await request.post(endpoint).send({ dealership, role });
    dispatch(option === 'add' ? setSupervisorsAddUser(res.body) : setSupervisorsEditUser(res.body));
  } catch (err) {
    err && err.response
      ? dispatch(openSnackbar({
        message: err.response.body.message,
        kind: SnackbarKind.ERROR,
      }))
      : dispatch(openSnackbar({
        message: 'Error without err object. Implement proper error handling!',
        kind: SnackbarKind.ERROR,
      }));
    dispatch(handleErrors(err.response.statusCode));
  }
};

export default getSupervisors;
