const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 3,
  },
  switchContainer: {
    display: 'inline-block',
    cursor: 'pointer',
    width: '62px',
  },
  colorSwitchBase: {
    '&$colorChecked': {
      color: theme.palette.primary1Color,
      '& + $colorBar': {
        backgroundColor: theme.palette.primary1Color,
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  labelContainer: {
    width: 'calc(100% - 63px)',
    position: 'relative',
    height: 'auto',
    display: 'inline-block',
    paddingLeft: theme.spacing.unit,
    verticalAlign: 'middle',
  },
  link: {
    display: 'inline-block',
    color: theme.palette.primary1Color,
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginRight: theme.spacing.unit / 2,
  },
  switch: theme.typography.h4,
});

export default styles;
