import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 180px)',
    ...theme.containers.loginBackground,
    backgroundSize: 'cover',
  },
  wrapper: theme.containers.wrapper,
  title: {
    color: theme.palette.common.white,
    lineHeight: '60px',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(28),
    padding: '120px 0 64px 0',
  },
  submitButton: {
    width: '450px',
    height: '40px',
    fontSize: '16px',
    color: theme.palette.common.white,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: 'unset',
    marginTop: '32px',
    textTransform: 'none',
    '@media (max-width: 500px)': {
      width: '80vw',
    },
  },
  radio: {
    '&$checked': {
      color: theme.palette.common.white,
    },
  },
  checked: {},
  radioLabel: {
    height: '24px',
    color: theme.palette.common.white,
    fontFamily: theme.fonts.fontFamily,
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '24px',
  },
}));

const ContactFormStep1 = ({
  schema, onNextButtonClick, clientType, setSelectedClientType,
}) => {
  const classes = useStyles();

  const [physicalPersonLabel] = useState(schema.clientType.physicalPerson);
  const [legalPersonLabel] = useState(schema.clientType.legalPerson);

  const handleChange = (event) => {
    setSelectedClientType(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography align="left" variant="h1" className={classes.title}>
          {schema.step1.title}
        </Typography>

        <FormControl component="fieldset">
          <RadioGroup row aria-label="position" name="position" value={clientType} onChange={handleChange}>
            <FormControlLabel
              value={physicalPersonLabel}
              control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
              label={<p className={classes.radioLabel}> {physicalPersonLabel} </p>}
            />
            <FormControlLabel
              value={legalPersonLabel}
              control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
              label={<p className={classes.radioLabel}> {legalPersonLabel} </p>}
            />
          </RadioGroup>
        </FormControl>
        <Button
          variant="outlined"
          className={classes.submitButton}
          onClick={onNextButtonClick}
        > {schema.step1.labelButton}
        </Button>
      </div>
    </div>
  );
};

ContactFormStep1.propTypes = {
  schema: PropTypes.object.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  clientType: PropTypes.string.isRequired,
  setSelectedClientType: PropTypes.func.isRequired,
};

export default ContactFormStep1;
