import isEmpty from 'lodash/isEmpty';
import { reset } from 'redux-form';
import types from './types';
import { openSnackbar, SnackbarKind } from './snackbar';
import handleErrors from './errorHandling';
import { carModelList } from './vehicleSearch';
import { getFormattedData } from './vehicleModelSearch';
import { findEndpoint, methodName } from '../helpers/endpoints/vehicles';
import sendRequestSearchVehicleNumber from '../helpers/network/vehicles';
import { vehicleTypeKeys } from '../reducers/settings';

export const setVehicleNumberSearch = car => ({
  type: types.SET_VEHICLE_NUMBER_SEARCH,
  payload: car,
});

export const setClearVehicleNumberSearch = () => ({
  type: types.CLEAR_VEHICLE_NUMBER_SEARCH,
});

export const VEHICLE_NUMBER_SEARCH_FIND_CAR_FORM = 'vehicleNumberSearchFindCarForm';
export const VEHICLE_NUMBER_SEARCH_CAR_DETAILS_FORM = 'vehicleNumberSearchCarDetailsForm';

export const clearVehicleNumberSearch = () => (dispatch) => {
  dispatch(setClearVehicleNumberSearch());
  dispatch(reset(VEHICLE_NUMBER_SEARCH_FIND_CAR_FORM));
};

export const searchVehicleNumber = data => async (dispatch, getState) => {
  try {
    const { vehicleType } = getState().settings;
    if (vehicleType === vehicleTypeKeys.NON_SET) return;
    const url = findEndpoint(vehicleType, methodName.SEARCH_VEHICLE_NUMBER);
    const { hsnNumber, tsnNumber } = data;

    const { body } = await sendRequestSearchVehicleNumber(vehicleType, url, hsnNumber, tsnNumber);

    if (isEmpty(body)) {
      dispatch(openSnackbar({
        message: 'Dieses Fahrzeug konnte nicht gefunden werden',
        kind: SnackbarKind.WARNING,
      }));
      dispatch(clearVehicleNumberSearch());
    } else {
      const vehicles = getFormattedData(vehicleType, [body], 'companyName');
      dispatch(setVehicleNumberSearch(vehicles[0]));
      dispatch(carModelList(vehicles));
    }
  } catch (err) {
    dispatch(handleErrors(err.response.body));
  }
};
