const styles = theme => ({
  container: theme.containers.container,
  gridContainer: {
    width: '100%',
    paddingBottom: theme.spacing.unit * 4,
    fontFamily: theme.fontFamily,
  },
  avbDelivered: {
    paddingTop: theme.spacing.unit * 4,
  },
  sendDocuments: {
    paddingTop: theme.spacing.unit * 2,
  },
  paper: {
    marginBottom: '30px',
    overflow: 'hidden',
  },
  title: theme.fonts.headline_2,
  questionLabel: theme.fonts.headline_3,
  paperBox: {
    padding: theme.spacing.unit * 3,
    textAlign: 'left',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing.unit,
    borderRadius: '0px 4px 4px 4px',
    position: 'relative',
  },
  paperTag: {
    width: '200px',
    height: '30px',
    display: 'block',
    backgroundColor: 'grey',
    borderRadius: '4px 4px 0px 0px',
    position: 'absolute',
    left: '-200px',
    top: '-30px',
    transform: 'rotate(-90deg)',
    transformOrigin: 'right bottom 0',
    lineHeight: '30px',
    color: 'white',
    textAlign: 'center',
  },
  fieldContent: {
    color: 'black',
    fontSize: '16px',
    marginTop: '10px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    overflow: 'hidden',
  },
  fieldTitle: {
    fontSize: '12px',
  },
  buttonContainer: {
    overflow: 'hidden',
    width: '100%',
  },
  buttonLeft: {
    float: 'left',
  },
  buttonRight: {
    float: 'right',
  },
  '@global': {
    '.submitButton': {
      'margin-top': '0',
    },
    '.separator': {
      display: 'block',
      height: '70px',
    },
    '.hoveringPaper': {
      position: 'fixed',
      bottom: '0px',
      left: '0',
      width: '100%',
      'margin-bottom': '0',
      'z-index': '1000',
      'padding-bottom': '25px',
    },
    '.hoveringPaper .totalPrice': {
      'font-size': '32px',
      'font-style': 'normal',
      'font-weight': '650',
      'margin-top': '25px',
      display: 'inline-block',
    },
  },
});

export default styles;
