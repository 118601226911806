const styles = ({ spacing }) => ({
  form: {
    padding: spacing.unit,
    marginTop: spacing.unit * 5,
    paddingBottom: spacing.unit * 7,
  },
  stateContainer: {
    marginBottom: spacing.unit,
  },
  milageContainer: {
    width: '200px',
    marginBottom: spacing.unit,
    marginRight: spacing.unit * 5,
    '& label': {
      lineHeight: '24px',
      position: 'relative',
      width: '130%',
    },
    '& > div > div': {
      marginTop: 0,
    },
  },
  registrationContainer: {
    width: '190px',
    marginBottom: spacing.unit,
    '& > div': {
      maxWidth: '100%',
    },
  },
  financingContainer: {
    marginTop: spacing.unit * 5,
    marginBottom: spacing.unit * 9,
  },
});

export default styles;
