import red from '@material-ui/core/colors/red';

const styles = theme => ({
  textField: {
    marginBottom: theme.spacing.unit * 3,
  },
  formControl: {
    width: '100%',
  },
  inputBaseRoot: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
    '&:focus': {
      borderColor: '#A5ABB6',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      outline: 'unset',
    },
  },
  numberFormat: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #A5ABB6',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#A5ABB6',
      boxShadow: 'none',
      outline: 'unset',
    },
    '&::placeholder': {
      color: '#A5ABB6',
      fontWeight: 300,
    },
  },
  invalidNumberFormat: {
    borderColor: red[900],
  },
  inputLabel: {
    fontSize: '1.125rem',
    fontWeight: 'bolder',
    color: theme.palette.textColor,
    paddingLeft: theme.spacing.unit,
    lineHeight: '24px',
  },
  inputLabelFocused: {
    color: `${theme.palette.darkBlue} !important`,
  },
  formHelperTextRoot: {
    fontSize: '0.7em',
  },
});

export default styles;
