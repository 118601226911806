import { TextField, withStyles } from '@material-ui/core';
import { getTodayString } from '../../../utils/date';

const styles = theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.containers.contactForm.primaryColor,
      },
    },
  },
});

const stylesMultilineLabel = () => ({
  root: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -10px) scale(0.75)',
    },
  },
});

const CustomTextField = withStyles(styles)(TextField);

export const CustomTextMultilineLabelField = withStyles(stylesMultilineLabel)(CustomTextField);

export default CustomTextField;

const requiredErrorMessage = 'erforderlich';

const buildMaxForInputProps = (max) => {
  switch (max) {
    case 'today':
      return getTodayString();
    default:
      return max;
  }
};

export const formikTextFieldProps = ({
  fieldName, label, errorMessage, mandatory, max = undefined,
}, {
  values, handleChange, handleBlur, errors, touched, validationErrorMessages = {},
}) => {
  let helperText = '';

  const value = typeof values[fieldName] !== 'undefined'
    ? values[fieldName]
    : '';

  const isError = errors[fieldName];
  const isTouched = touched[fieldName];

  if (value && isError) {
    helperText = validationErrorMessages[isError] || '';
  }

  if (mandatory && !isTouched && !value) {
    helperText = requiredErrorMessage;
  }

  if (mandatory && isTouched && !value) {
    helperText = errorMessage || '';
  }
  const inputProps = { onBlur: handleBlur, lang: 'de' };
  const maxProp = buildMaxForInputProps(max);
  if (maxProp) {
    inputProps.max = maxProp;
  }

  return {
    name: fieldName,
    value,
    onChange: handleChange,
    label,
    error: isTouched && Boolean(isError),
    helperText,
    inputProps,
    variant: 'outlined',
    type: 'text',
  };
};
