import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Grid } from '@material-ui/core';
import CustomTextField, { formikTextFieldProps, CustomTextMultilineLabelField } from './CustomTextField';
import { QUESTION_GROUP, CLIENT_TYPE } from '../../../constants/';
import { getText, getQuestions, getMandatoryFields } from '../ContactForm';
import TitleLabel from './TitleLabel';
import DescriptionLabel from './DescriptionLabel';
import useFormSubmitTrigger from './useFormSubmitTrigger';
import useFormErrorRender from './useFormErrorRender';
import useFormOnChange from './useFormOnChange';

const currentFormQuestionGroup = QUESTION_GROUP.PREVIOUS_INSURER;

const PreviousInsurerPhysicalForm = (props) => {
  const {
    schema,
    submitTrigger,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    onChange,
    onErrorMarked,
    isSubmitting
  } = props;

  const fields = getQuestions(schema.questions, currentFormQuestionGroup);
  const formRef = useFormSubmitTrigger(submitTrigger);
  useFormOnChange(onChange, values, currentFormQuestionGroup);
  useFormErrorRender(onErrorMarked, isSubmitting, errors);

  const formikProps = {
    values, handleChange, handleBlur, errors, touched,
  };

  return (
    <form autoComplete="off" ref={formRef} onSubmit={handleSubmit}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <TitleLabel>
            {getText(schema, currentFormQuestionGroup, 'text_1')}
          </TitleLabel>
        </Grid>


        <Grid item xs={12}>
          <DescriptionLabel>
            {getText(schema, currentFormQuestionGroup, 'text_2')}
          </DescriptionLabel>
        </Grid>


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.NameAndOffice, formikProps)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextMultilineLabelField
            {...formikTextFieldProps(fields.InsuranceNumber, formikProps)}
          />
        </Grid>


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.VehicleNumber, formikProps)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.OfficialMark, formikProps)}
          />
        </Grid>


        <Grid item xs={12}>
          <DescriptionLabel>
            {getText(schema, currentFormQuestionGroup, 'text_3')}
          </DescriptionLabel>
        </Grid>


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.FirstName, formikProps)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.LastName, formikProps)}
          />
        </Grid>


      </Grid>
    </form>
  );
};

PreviousInsurerPhysicalForm.propTypes = {
  schema: PropTypes.object.isRequired,
  submitTrigger: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withFormik({
  handleSubmit: (_, { setSubmitting, props }) => {
    props.onSuccessfulFormSubmit(currentFormQuestionGroup);
    setSubmitting(false);
  },
  mapPropsToValues: ({ formValues }) => ({
    NameAndOffice: formValues.PREVIOUS_INSURER.NameAndOffice,
    InsuranceNumber: formValues.PREVIOUS_INSURER.InsuranceNumber,
    VehicleNumber: formValues.PREVIOUS_INSURER.VehicleNumber,
    OfficialMark: formValues.PREVIOUS_INSURER.OfficialMark,
    FirstName: formValues.PREVIOUS_INSURER.FirstName,
    LastName: formValues.PREVIOUS_INSURER.LastName,
  }),
  validate: (values, { schema, ...props }) => {
    const errors = {};

    const mandatoryFields = getMandatoryFields(
      schema.questions,
      currentFormQuestionGroup,
      CLIENT_TYPE.PHYSICAL_PERSON,
    );

    mandatoryFields.forEach((key) => {
      if (!values[key]) {
        errors[key] = 'Required';
      }
    });

    if (Object.keys(errors).length > 0) {
      props.onValidationError(currentFormQuestionGroup);
    }

    return errors;
  },
})(PreviousInsurerPhysicalForm);
