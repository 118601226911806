const styles = theme => ({
  paper: {
    marginBottom: '30px',
    overflow: 'hidden',
  },
  title: {
    ...theme.fonts.headline_5,
    paddingTop: `${theme.units.spacing * 10}px`,
    paddingBottom: `${theme.units.spacing * 2}px`,
  },
  question2: {
    marginBottom: `${theme.units.spacing * 7}px`,
  },
  sliderContainer: {
    margin: 'auto',
  },
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    sliderContainer: {
      marginTop: '13px',
    },
  },
  questionLabel: theme.fonts.headline_3,
  number: {
    padding: '5px',
    background: theme.palette.primary1Color,
    display: 'inline-block',
    color: '#fff',
    'margin-right': '5px',
  },
  price: {
    color: theme.palette.primary1Color,
    fontFamily: theme.fontFamily,
  },
  mileage: {
    textAlign: 'center',
    marginTop: '0px',
    fontFamily: theme.fontFamily,
  },
  unlimited: {
    textAlign: 'center',
    marginTop: '6px',
    fontFamily: theme.fontFamily,
  },
  specialPriceContainer: {
    position: 'relative',
    border: `1px solid ${theme.palette.darkBlue}`,
    borderRadius: '5px',
    opacity: '0.5',
    padding: `${theme.units.spacing * 2}px 0px !important`,
    minHeight: '65px',
  },
  specialPriceInfo: {
    fontSize: '12px',
    fontWeight: 600,
    position: 'absolute',
    bottom: 0,
    right: theme.units.spacing,
    letterSpacing: 0,
    lineHeight: '17px',
    color: theme.palette.primary1Color,
  },
});

export default styles;
