import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  preview: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '98px',
    width: '272px',
    margin: '5px',
    borderRadius: '9px',
    backgroundColor: theme.containers.contactForm.whiteColor,
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  },
  fileName: {
    fontFamily: theme.fonts.fontFamily,
    color: theme.palette.textColor,
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '0 12px',
  },
  closeButton: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    backgroundColor: theme.containers.contactForm.whiteColor,
    '&:hover': {
      backgroundColor: theme.containers.contactForm.whiteColor,
    },
    padding: '0',
  },
}));

const Preview = ({
  file,
  onRemove,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.preview}>
      <span className={classes.fileName}>{file.name}</span>
      <IconButton
        color="primary"
        aria-label="preview uploaded file"
        component="span"
        className={classes.closeButton}
        onClick={onRemove}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

Preview.propTypes = {
  file: PropTypes.objectOf(PropTypes.string),
  onRemove: PropTypes.func,
};

Preview.defaultProps = {
  file: {
    name: '',
  },
  onRemove: () => {},
};

export default Preview;
