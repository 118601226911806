import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, Hidden } from '@material-ui/core';

import ContactSupport from '@material-ui/icons/ContactSupport';
import EvStation from '@material-ui/icons/EvStation';
import styles from './style';
import UserMenu from '../UserMenu';
import NewOfferButton from './NewOfferButton';
import history from '../../history';
import { getLabel, getLogo } from '../../utils/theme';
import { isCallCenterUser } from '../Authorization/index';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.history = history;
  }

  render() {
    const {
      classes, structure, userLogged
    } = this.props;

    if (!structure) return null;

    const electricBonusLink = `https://${structure.buttons.electricBonus.link}`

    return (
      <header>
        {structure && (
          <div className={classes.navbar}>
            <img src={getLogo()} alt={getLabel()} className={classes.logo} />
            <UserMenu />
            {userLogged && (
              <span className={classes.link}>
                <Hidden smDown>
                  <label
                    className={classes.linkText}
                  >
                    <a className={classes.linkLabel}
                      href={electricBonusLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {structure.buttons.electricBonus.label}
                    </a>
                  </label>
                </Hidden>
                <Hidden mdUp>
                  <a className={classes.linkLabel}
                    href={electricBonusLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EvStation
                      fontSize="large"
                      className={classes.userIcon}
                      aria-controls="electricBonus"
                    />
                  </a>
                </Hidden>
                <span className={classes.line} />
              </span>
            )}
            <span className={classes.link}>
              <Hidden smDown>
                <label
                  className={classes.linkText}
                  onClick={() => this.history.push(structure.buttons.support.link)}
                >
                  {structure.buttons.support.label}
                </label>
              </Hidden>
              <Hidden mdUp>
                <ContactSupport
                  fontSize="large"
                  className={classes.userIcon}
                  onClick={() => this.history.push(structure.buttons.support.link)}
                  aria-haspopup="true"
                  aria-controls="buttonSupport"
                />
              </Hidden>
              <span className={classes.line} />
            </span>
            {!isCallCenterUser() ? <NewOfferButton /> : null }

          </div>
        )}
      </header>
    );
  }
}

const mapStateToProps = state => ({
  structure: state.projectStructure.navbar,
  userLogged: state.auth.loggedInUser
});

Navbar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  structure: PropTypes.objectOf(Object),
  userLogged: PropTypes.string,
};

Navbar.defaultProps = {
  classes: '',
  structure: undefined,
  userLogged: null,
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    null,
  ),
)(Navbar);
