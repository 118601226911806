import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Dialog, DialogContent, DialogContentText, IconButton, Typography } from '@material-ui/core';

import Pdf from '@material-ui/icons/PictureAsPdf';
import CloseIcon from '@material-ui/icons/Close';
import styles from './style';
import { getCurrentProjectName } from '../../utils/theme';

const DialogWithDocuments = (props) => {
  const {
    isOpen,
    onHandleConfirmationModal,
    structure,
    classes,
    paymentType,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={onHandleConfirmationModal}
      aria-labelledby="dialog-conformation"
      className={classes.container}
    >
      <IconButton aria-label="Close" className={classes.closeButton} onClick={onHandleConfirmationModal}>
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.container}>
        <DialogContentText>
          {structure.links[getCurrentProjectName()][paymentType].map(el => (
            <Typography component="span" key={el.text} className={classes.link}>
              <span>
                <Pdf className={classes.icon} />
              </span>
              <a href={el.link} target="_blank" rel="noopener noreferrer">{el.text}</a>
            </Typography>
          ))}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};


DialogWithDocuments.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  onHandleConfirmationModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  structure: PropTypes.objectOf(PropTypes.any).isRequired,
  paymentType: PropTypes.oneOf(['bmwBank', 'other']),
};

DialogWithDocuments.defaultProps = {
  classes: '',
  isOpen: false,
  paymentType: 'other',
};

export default withStyles(styles)(DialogWithDocuments);

