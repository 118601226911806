import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, RadioGroup, FormControlLabel, FormControl, Radio } from '@material-ui/core';
import { QUESTION_GROUP } from '../../../../constants';
import { getText, getQuestions } from '../../ContactForm';
import TitleLabel from '../TitleLabel';
import DescriptionLabel from '../DescriptionLabel';
import FilePanel from './FilePanel/FilePanel';
import Alert from '../Alert/Alert';

const currentFormQuestionGroup = QUESTION_GROUP.DOCUMENT_UPLOAD;
const DOCUMENTS = 'documents';

const DocumentUpload = ({
  schema,
  onChange: onChangeFormValues,
  formValues,
}) => {
  const fields = getQuestions(schema.questions, currentFormQuestionGroup);

  const [radioValue, setRadioValue] = useState(fields.CompletedWithSEPA.label);

  useEffect(() => {
    const presentDocumentTypeRadioResponse = formValues.DOCUMENT_UPLOAD.DocumentTypeRadioResponse;
    if (presentDocumentTypeRadioResponse) {
      setRadioValue(presentDocumentTypeRadioResponse);
    } else {
      onChangeFormValues(
        currentFormQuestionGroup,
        { DocumentTypeRadioResponse: radioValue },
      );
    }

    // eslint-disable-next-line
  }, []);

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setRadioValue(value);
    onChangeFormValues(
      currentFormQuestionGroup,
      { DocumentTypeRadioResponse: value },
    );
  };

  const handleChangeDocumentUploadFormValues = (values) => {
    onChangeFormValues(DOCUMENTS, values);
  };

  return (
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <TitleLabel>
          {getText(schema, currentFormQuestionGroup, 'text_1')}
        </TitleLabel>
      </Grid>


      <Grid item xs={12}>
        <DescriptionLabel>
          {getText(schema, currentFormQuestionGroup, 'text_2')}
        </DescriptionLabel>
      </Grid>


      <Grid item xs={12}>
        <FormControl component="fieldset">
          <RadioGroup
            name="radio"
            value={radioValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={fields.CompletedWithSEPA.label}
              control={<Radio color="primary" />}
              label={fields.CompletedWithSEPA.label}
            />
            <FormControlLabel
              value={fields.TransferNoClaimsBonuses.label}
              control={<Radio color="primary" />}
              label={fields.TransferNoClaimsBonuses.label}
            />
            <FormControlLabel
              value={fields.DriverLicenseAsPartOfSpecialClassification.label}
              control={<Radio color="primary" />}
              label={fields.DriverLicenseAsPartOfSpecialClassification.label}
            />
          </RadioGroup>
        </FormControl>
      </Grid>


      <Grid item xs={12}>
        <Alert
          outlined
          hideButton
          style={{ justifyContent: 'flex-start' }}
          label={getText(schema, currentFormQuestionGroup, 'text_alert')}
        />
      </Grid>


      <Grid item xs={12}>
        <TitleLabel>
          {getText(schema, currentFormQuestionGroup, 'text_3')}
        </TitleLabel>
      </Grid>


      <Grid item xs={12}>
        <DescriptionLabel>
          {getText(schema, currentFormQuestionGroup, 'text_4')}
        </DescriptionLabel>
      </Grid>


      <FilePanel
        uploadInputLabel={getText(schema, currentFormQuestionGroup, 'text_upload_button')}
        onChangeDocumentUploadFormValues={handleChangeDocumentUploadFormValues}
      />


    </Grid>
  );
};

DocumentUpload.propTypes = {
  schema: PropTypes.object,
  onChange: PropTypes.func,
  formValues: PropTypes.object,
};

DocumentUpload.defaultProps = {
  schema: {},
  onChange: () => {},
  formValues: {},
};

export default DocumentUpload;
