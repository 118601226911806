import { volvo, imagesObject as volvoObject, organization as volvoOrganization } from '../styles/volvo.style';
import { nrw, imagesObject as nrwObject, organization as nrwOrganization } from '../styles/nrw.style';
import { bmw, imagesObject as bmwObject, organization as bmwOrganization } from '../styles/bmw.style';
import { hyundai, imagesObject as hyundaiObject, organization as hyundaiOrganization } from '../styles/hyundai.style';
import { emp, imagesObject as empObject, organization as empOrganization } from '../styles/emp.style';

const HOST_ENDS_WITH = '.cloudfirst.digital';

// TODO: configuration should be outside of application code
// TODO: Refactor this part when we switch to new deployment
const empUrls = [
  'ergo-mobility-protect.de',
  'www.ergo-mobility-protect.de',
  'ergo-mobility-protect.mobility-insurance.de',
];

const nrwUrls = [
  'localhost:8090',
  'emil-frey-autoversicherung.mobility-insurance.de',
  'emil-frey-autoversicherung.de',
  'www.emil-frey-autoversicherung.de',
  'nrw-garage-autoversicherung.de',
  'www.nrw-garage-autoversicherung.de',
  'staging-nrw-garage.aws-0211.cloudfirst.digital',
  'master-nrw-garage.aws-0211.cloudfirst.digital',
  'test-nrw-garage.aws-0211.cloudfirst.digital',
  'nrwgarage-autoversicherung.ems-prod-canary.mobility-insurance.de',
  'nrwgarage-autoversicherung.mobility-insurance.de',
];

const bmwUrls = [
  'fahrfreude-versicherung.ems-prod-canary.mobility-insurance.de',
  'fahrfreude-versicherung.mobility-insurance.de',
  'ems-fahrfreude-versicherung.de',
];

const hyundaiUrls = [
  'hyundai.ems-prod-canary.mobility-insurance.de',
  'hyundai.mobility-insurance.de',
  'hyundai-protect-autoversicherung.de',
  'www.hyundai-protect-autoversicherung.de',
];

const brands = {
  VOLVO: 'volvo',
  NRW_GARAGE: 'nrw',
  BMW: 'bmw',
  HYUNDAI: 'hyundai',
  EMP: 'emp',
};

const EMIL_FREY_PREFIX = 'emil-frey';

const brandOptions = {
  THEME: 'theme',
  LOGO: 'logo',
  LABEL: 'label',
  BLUE_LOGO: 'blueLogo',
  ORGANIZATION: 'organization',
};

const BrandDetails = {
  [brands.VOLVO]: {
    ...volvoObject,
    [brandOptions.THEME]: volvo,
    [brandOptions.ORGANIZATION]: volvoOrganization,
  },
  [brands.NRW_GARAGE]: {
    ...nrwObject,
    [brandOptions.THEME]: nrw,
    [brandOptions.ORGANIZATION]: nrwOrganization,
  },
  [brands.BMW]: {
    ...bmwObject,
    [brandOptions.THEME]: bmw,
    [brandOptions.ORGANIZATION]: bmwOrganization,
  },
  // TODO: change after adding real Hyundai themes
  [brands.HYUNDAI]: {
    ...hyundaiObject,
    [brandOptions.THEME]: hyundai,
    [brandOptions.ORGANIZATION]: hyundaiOrganization,
  },
  [brands.EMP]: {
    ...empObject,
    [brandOptions.THEME]: emp,
    [brandOptions.ORGANIZATION]: empOrganization,
  },
};

export const getCurrentProjectName = () => {
  const { host } = window.location;

  if (nrwUrls.includes(host)) {
    return brands.NRW_GARAGE;
  }
  if (bmwUrls.includes(host)) {
    return brands.BMW;
  }
  if (hyundaiUrls.includes(host)) {
    return brands.HYUNDAI;
  }
  if (empUrls.includes(host)) {
    return brands.EMP;
  }

  if ([brands.NRW_GARAGE, EMIL_FREY_PREFIX].some(value => host.includes(value))
    && host.endsWith(HOST_ENDS_WITH)) {
    return brands.NRW_GARAGE;
  }
  if (host.includes(brands.VOLVO) && host.endsWith(HOST_ENDS_WITH)) {
    return brands.VOLVO;
  }
  if (host.includes(brands.BMW) && host.endsWith(HOST_ENDS_WITH)) {
    return brands.BMW;
  }
  if (host.includes(brands.HYUNDAI) && host.endsWith(HOST_ENDS_WITH)) {
    return brands.HYUNDAI;
  }
  if (host.includes(brands.EMP) && host.endsWith(HOST_ENDS_WITH)) {
    return brands.EMP;
  }
  return brands.VOLVO;
};

const getProperInfo = details => BrandDetails[getCurrentProjectName()][details];

export const isBmw = () => getCurrentProjectName() === brands.BMW;

export const isNRW = () => getCurrentProjectName() === brands.NRW_GARAGE;

export const isHyundai = () => getCurrentProjectName() === brands.HYUNDAI;

export const isEmp = () => getCurrentProjectName() === brands.EMP;

export const getTheme = () => getProperInfo(brandOptions.THEME);

export const getOrganization = () => getProperInfo(brandOptions.ORGANIZATION);

export const getLabel = () => getProperInfo(brandOptions.LABEL);

export const getLogo = () => getProperInfo(brandOptions.LOGO);

export const getBlueLogo = () => getProperInfo(brandOptions.BLUE_LOGO);
