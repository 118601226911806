import React, { Component, Fragment } from 'react';
import { Element, scroller } from 'react-scroll';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  Typography,
  Radio,
  withStyles } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';

import moment from 'moment';
import { createOffer } from '../../actions/offer';
import { carModelList } from '../../actions/vehicleSearch';
import { getBrands, searchVehicles, clearVehicleModelForm } from '../../actions/vehicleModelSearch';
import InputText from '../../components/InputText';
import RadioInput from '../../components/RadioInput';
import DatePickerInput from '../../components/DatePickerInput';
import SelectField from '../../components/SelectField';
import Button from '../../components/Button';
import ButtonIcon from '../../components/ButtonIcon';
import NumberFormat from '../../components/NumberFormat';
import VehicleModel from '../../models/vehicle';
import PersonalDetailsModel from '../../models/personalDetails';
import NotificationModel from '../../models/notification';
import * as Sorting from '../../utils/sorting';
import styles from './style';
import { isMotorcycleSelected } from '../../reducers/settings';
import { isBmw, isEmp } from '../../utils/theme';
import { isCallCenterUser } from '../Authorization';
import { SPECIAL_PRICE_1_ID_VALUE, SPECIAL_PRICE_2_ID_VALUE } from '../../constants/vehicle';
import {
  MILAGE_VALIDATION,
  MILAGE_VALIDATION_WITH_DATE,
  checkValidations,
} from '../../validators/validators';
import { getQueryParamsFromLWLRedirect } from '../../utils/lwlQueryParams';

class VehicleModelSearch extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      searched: false,
      order: 'asc',
      orderBy: 'strengthKw',
      brand: '',
      model: '',
      fuel: '',
      power: '',
      state: '',
      milage: 0,
      financing: '',
      isElectric: false,
      fuelFilter: [],
      powerFilter: [],
      selectedModel: '',
      registrationDate: null,
    };

    this.submitVehicleModel = this.submitVehicleModel.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onCarSelect = this.onCarSelect.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.setFuelFilter = this.setFuelFilter.bind(this);
    this.setPowerFilter = this.setPowerFilter.bind(this);
    this.setBrands = this.setBrands.bind(this);
    this.renderInformation = this.renderInformation.bind(this);
  }

  componentDidMount() {
    this.props.getBrands();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.carModelList.length > 0) {
      this.setFuelFilter(nextProps.carModelList);
      this.setPowerFilter(nextProps.carModelList);
      this.renderData(nextProps.carModelList);
    } else if (nextProps.clearSearchForm) {
      this.resetState();
      document.getElementById('searchVehicleModelForm').reset();
      this.props.clearVehicleModelForm(false);
    } else {
      return this.renderEmptyUser();
    }
  }

  onCarSelect(item) {
    this.setState({ selectedModel: item });
    setTimeout(() => {
      scroller.scrollTo('moreOptions', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }, 0);
  }

  onKeyDown(event) {
    event.keyCode === 13 && this.submitVehicleModel(event);
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
  }

  setFuelFilter(cars) {
    let uniqueFuels = [];

    let fuelFilter = [];
    uniqueFuels = [...new Set(cars.map(item => item.fuel))];
    fuelFilter = [...this.props.structure.filter.fuel];
    uniqueFuels.forEach((key) => {
      fuelFilter.push({ key, value: key });
    });
    this.setState({ fuelFilter: [...fuelFilter] });
  }

  setPowerFilter(cars) {
    const rangePower = this.props.structure.filter.power;
    const filteredRangePower = rangePower.filter((power) => {
      const powerValues = power.value.split('-');
      const car = cars.find(c => c.strengthKw >= powerValues[0] && c.strengthKw <= powerValues[1]);
      return car;
    });
    filteredRangePower.unshift(rangePower[0]);
    this.setState({ powerFilter: [...filteredRangePower] });
  }

  setBrands(brands) {
    const newBrands = [{ key: this.props.structure.fields.brand.placeholder, value: '' }];
    if (brands.length > 0) {
      return newBrands.concat(brands);
    }
    return newBrands;
  }

  financingStructureByBrand = () => {
    const financingStructure = this.props.structure.fields.financing;

    if (isCallCenterUser() && isEmp()) {
      return financingStructure;
    } else if (isBmw()) {
      const bmwNotAllowedOptions = [SPECIAL_PRICE_1_ID_VALUE];
      financingStructure.options = financingStructure.options.filter(item => !bmwNotAllowedOptions.includes(item.value));
      return financingStructure
    }
    const notAllowedOptions = [SPECIAL_PRICE_1_ID_VALUE, SPECIAL_PRICE_2_ID_VALUE];
    financingStructure.options = financingStructure.options.filter(item => !notAllowedOptions.includes(item.value));
    return financingStructure;
  }

  isMilageValid = (milage) => {
    const { state } = this.state;
    const registrationDateValue = this.props.structure.fields.state.options[1].value;

    if (milage === undefined) {
      this.setState({ milage: 0 });
    }

    return (state === registrationDateValue)
      ? checkValidations(MILAGE_VALIDATION_WITH_DATE, milage)
      : checkValidations(MILAGE_VALIDATION, milage);
  }

  isValidForm() {
    const {
      selectedModel,
      financing,
      state,
      milage,
    } = this.state;
    return selectedModel && financing && state && this.isMilageValid(milage);
  }

  createSortHandler = property => (event) => {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  submitVehicleModel(event) {
    event.preventDefault();
    if (this.state.brand !== '' && this.state.model !== '') {
      this.setState({
        selectedModel: '',
        fuel: '',
        power: '',
        financing: '',
        searched: true,
      });
      this.props.searchVehicles(this.state.brand, this.state.model);
    }
  }

  submitHandler(event) {
    event.preventDefault();
    if (this.state.selectedModel) {
      this.props.setCarModelList(this.state.selectedModel);
      const adaptedMilage = this.state.milage * 1000;
      const paramsFromLwlRedirect = getQueryParamsFromLWLRedirect();
      this.props.createOffer(
        this.props.personalDetails.employeeNumber,
        this.state.selectedModel.producerId,
        this.state.selectedModel.vehTypeId,
        this.state.state === 'true',
        this.state.financing,
        this.state.registrationDate,
        this.state.selectedModel.fuel.includes('Elektro') ||
        this.state.selectedModel.fuel.includes('Hybr'),
        adaptedMilage,
        paramsFromLwlRedirect,
      );
    }
  }

  resetState() {
    return this.setState({
      model: '',
      brand: '',
      selectedModel: '',
      fuel: '',
      power: '',
      financing: '',
      searched: false,
    });
  }

  renderTable(cars) {
    const { structure, classes } = this.props;
    const { orderBy, order, selectedModel } = this.state;
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{structure.copy.model}</TableCell>
            <TableCell>
              <Tooltip title="Sort" enterDelay={300}>
                <TableSortLabel
                  active={orderBy === 'strengthKw'}
                  direction={order}
                  onClick={this.createSortHandler('strengthKw')}
                >
                  {structure.copy.power}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
            <TableCell>
              {structure.copy.hsn} / {structure.copy.tsn}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Sorting.stableSort(cars, Sorting.getSorting(order, orderBy)).map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Radio
                  checked={
                    `${selectedModel.producerId}-${selectedModel.vehTypeId}` ===
                    `${row.producerId}-${row.vehTypeId}`
                  }
                  onChange={() => this.onCarSelect(row)}
                  value={`${row.producerId}-${row.vehTypeId}`}
                  name="carSelector"
                  aria-label={`${row.producerId}-${row.vehTypeId}`}
                  classes={{
                    root: classes.carRadio,
                    checked: classes.carRadioChecked,
                  }}
                />
              </TableCell>
              <TableCell>
                {row.companyName} {row.salesDesignation}
              </TableCell>
              <TableCell>
                {this.renderStrengthCellContent(row)}
              </TableCell>
              <TableCell>
                {row.producerId} / {row.vehTypeId}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  renderStrengthCellContent(vehicle) {
    const carContent = `${vehicle.strengthHp} PS ${vehicle.strengthKw} KW`;
    const motorcycleContent = `${vehicle.strengthKw} KW`;
    return this.props.isMotorcycleSelected ?
      motorcycleContent : carContent;
  }

  renderData(cars) {
    if (cars.length > 0) {
      const { power, fuel } = this.state;
      const powerFilter = power.split('-');
      let filteredCars = cars;
      filteredCars = filteredCars.filter((car) => {
        const carPower = car.strengthKw ? car.strengthKw.toString() : '';
        if (
          (fuel === '' || fuel === car.fuel) &&
          (power === '' ||
            (parseInt(carPower) >= parseInt(powerFilter[0]) &&
              parseInt(carPower) <= parseInt(powerFilter[1])))
        ) {
          return car;
        }
        return false;
      });
      if (
        (filteredCars.length > 0 && power && fuel) ||
        (filteredCars.length > 0 && filteredCars.length <= 10)
      ) {
        return this.renderTable(filteredCars);
      }
      return this.renderInformation(filteredCars.length);
    } else if (this.state.searched) {
      return this.renderEmptyUser();
    }
    return false;
  }

  renderInformation(totalVehicles) {
    return (
      <Typography variant="h4" align="center" className={this.props.classes.info}>
        <HelpOutline className={this.props.classes.infoIcon} />
        Zu Ihren Angaben wurden {totalVehicles} Fahrzeuge gefunden.
        Bitte machen Sie genauere Angaben.
      </Typography>
    );
  }

  renderEmptyUser() {
    return (
      <Typography variant="h4" align="center" className={this.props.classes.info}>
        {this.props.structure.copy.noCarFound}
      </Typography>
    );
  }

  render() {
    const {
      model,
      brand,
      selectedModel,
      fuel,
      fuelFilter,
      power,
      powerFilter,
      state,
      registrationDate,
      financing,
    } = this.state;
    const {
      carModelList, classes, structure, inputFields, brands,
    } = this.props;

    return (
      <Fragment>
        {structure && (
          <form id="searchVehicleModelForm" onSubmit={event => this.submitHandler(event)} className={classes.form}>
            <Grid container justify="center" alignContent="center" spacing={0}>
              <Grid item container xs={12} spacing={2}>
                <Grid item sm={5} xs={12}>
                  <SelectField
                    {...inputFields.brand}
                    {...structure.fields.brand}
                    defaultValue={brand}
                    onChange={this.onValueChange}
                    options={this.setBrands(brands)}
                  />
                </Grid>
                <Grid item sm={5} xs={12}>
                  <InputText
                    {...inputFields.model}
                    {...structure.fields.model}
                    defaultValue={model}
                    onChange={this.onValueChange}
                    onKeyDown={event => this.onKeyDown(event)}
                  />
                </Grid>
                <Grid item xs={12} sm={2} className={classes.buttonContainer}>
                  <ButtonIcon
                    version="combined"
                    type="button"
                    onClick={event => this.submitVehicleModel(event)}
                    disabled={!brand || !model}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                {carModelList.length > 0 && (
                  <Fragment>
                    <Grid item sm={5} xs={12}>
                      <SelectField
                        {...inputFields.fuel}
                        {...structure.fields.fuel}
                        defaultValue={fuel}
                        onChange={this.onValueChange}
                        options={fuelFilter}
                      />
                    </Grid>
                    <Grid item sm={5} xs={12}>
                      <SelectField
                        {...inputFields.power}
                        {...structure.fields.power}
                        defaultValue={power}
                        onChange={this.onValueChange}
                        options={powerFilter}
                      />
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
            <Grid container justify="center" alignContent="center" spacing={2}>
              <Grid item sm={12}>
                {this.renderData(carModelList)}
              </Grid>
            </Grid>
            <Element name="moreOptions" className={classes.moreOptions}>
              {carModelList.length > 0 && selectedModel && (
                <Fragment>
                  <Grid container spacing={2} className={classes.radios}>
                    <Grid item xs={12} md={5}>
                      <RadioInput
                        {...inputFields.state}
                        {...structure.fields.state}
                        defaultValue={state}
                        onChange={this.onValueChange}
                        customRadioGroupClass={classes.radioGroup}
                      />
                    </Grid>
                    <Grid container item xs={12} md={7}>
                      {state && (
                        <div className={classes.milageContainer}>
                          <NumberFormat
                            {...structure.fields.milage}
                            meta={{
                              error: !this.isMilageValid(this.state.milage),
                            }}
                            input={{
                              value: this.state.milage,
                              onChange: (value) => {
                                this.onValueChange(value, structure.fields.milage.name);
                              },
                            }}
                            helperText={structure.fields.milage.description}
                          />
                        </div>
                      )}
                      {state === structure.fields.state.options[1].value && (
                        <div className={classes.registrationContainer}>
                          <DatePickerInput
                            {...structure.fields.registrationDate}
                            maxDate={moment()}
                            defaulValue={registrationDate}
                            onChange={this.onValueChange}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.radios}>
                    <Grid item sm={8} xs={12}>
                      <RadioInput
                        {...inputFields.financing}
                        {...this.financingStructureByBrand()}
                        defaultValue={financing}
                        onChange={this.onValueChange}
                        customRadioGroupClass={classes.radioGroup}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Element>
            <Grid container justify="center" alignContent="center" spacing={2}>
              <Button
                disabled={!this.isValidForm()}
                {...structure.buttons.submit}
                myWidth="170px"
              />
            </Grid>
          </form>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  notificationCenter: state.notificationCenter,
  carModelList: state.carModelList,
  inputFields: state.projectFields,
  structure: state.projectStructure.vehicleModelSearch,
  personalDetails: state.personalDetails,
  brands: state.vehicleModelSearch.brands,
  clearSearchForm: state.vehicleModelSearch.clearSearchForm,
  isMotorcycleSelected: isMotorcycleSelected(state.settings),
});

const mapDispatchToProps = dispatch => ({
  createOffer: (
    primaryEmployeeNumber,
    hsn,
    tsn,
    preOwned,
    paymentType,
    registrationDate,
    isElectric,
    milage,
    paramsFromLwlRedirect,
  ) =>
    dispatch(createOffer(
      primaryEmployeeNumber,
      hsn,
      tsn,
      preOwned,
      paymentType,
      registrationDate,
      isElectric,
      milage,
      paramsFromLwlRedirect,
    )),
  setCarModelList: car => dispatch(carModelList([car])),
  searchVehicles: (brand, model) => dispatch(searchVehicles(brand, model)),
  getBrands: () => dispatch(getBrands()),
  clearVehicleModelForm: clearForm => dispatch(clearVehicleModelForm(clearForm)),
});

VehicleModelSearch.propTypes = {
  message: PropTypes.string,
  createOffer: PropTypes.func,
  searchVehicles: PropTypes.func,
  searchBrands: PropTypes.func,
  setCarModelList: PropTypes.func,
  error: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  structure: PropTypes.objectOf(Object),
  inputFields: PropTypes.objectOf(Object),
  carModelList: PropTypes.arrayOf(VehicleModel),
  notificationCenter: NotificationModel,
  personalDetails: PersonalDetailsModel,
  brands: PropTypes.arrayOf(Object),
  clearSearchForm: PropTypes.bool,
  getBrands: PropTypes.func.isRequired,
  isMotorcycleSelected: PropTypes.bool.isRequired,
};

VehicleModelSearch.defaultProps = {
  classes: '',
  createOffer: () => {},
  searchVehicles: () => {},
  searchBrands: () => {},
  setCarModelList: () => {},
  message: '',
  error: false,
  structure: undefined,
  inputFields: undefined,
  notificationCenter: {
    message: '',
    error: false,
  },
  carModelList: [],
  personalDetails: undefined,
  brands: [],
  clearSearchForm: false,
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(VehicleModelSearch);
