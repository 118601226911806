import React from 'react';
import RiskTypes from './riskTypes';
import { DEFAULT_PREVIOUS_INSURANCE_NUMBER, COMPANY } from '../actions/offer';
import { isMotorcycleSelected } from '../reducers/settings';
import store from '../store';

const getPreviousInsuranceNumber = currentNumber =>
  (currentNumber && currentNumber !== DEFAULT_PREVIOUS_INSURANCE_NUMBER ? currentNumber : '-');

const getStrenghtRow = vehicle => (isMotorcycleSelected(store.getState().settings) ? `${vehicle.strengthKw} KW` : `${vehicle.strengthHp} PS (${vehicle.strengthKw} KW)`);

const convertTooLongStringToSplitedArray = (str) => {
  let output = ['-'];
  if (str.length > 30) {
    output = [];
    const splitted = str.split(' ');
    let temp = '';
    // eslint-disable-next-line no-restricted-syntax
    for (const word of splitted) {
      temp = `${temp}${word} `;
      if (temp.length > 30) {
        output.push(temp.slice(0, -1));
        temp = '';
      }
    }
    if (temp) {
      output.push(temp.slice(0, -1));
    }
  } else if (str.length) {
    output = [str];
  }
  return output;
};

const mapOfferOverview = (classes, structure, offerInfo, carModelList, price) => {
  const frequenceAndUnit = offerInfo.contractPaymentInterval ?
    `€ ${structure.structure.insuranceDetails.paymentInterval[offerInfo.contractPaymentInterval].toLowerCase()}` :
    '';
  const companyOrPersonDifferingOwnerName =
    offerInfo.differingOwnerTitle === COMPANY
      ? `${offerInfo.differingOwnerCompanyName || ''}`
      : `${offerInfo.differingOwnerFirstName || ''} ${offerInfo.differingOwnerLastName || ''}`;

  const getDifferingOwner = () =>
    (offerInfo.contractDifferingOwner ? (
      <div>
        <div className={classes.fieldContent}>
          {`${offerInfo.differingOwnerTitle || ''} ${companyOrPersonDifferingOwnerName}`}
        </div>
        <div className={classes.fieldContent}>
          {`${offerInfo.differingOwnerStreet || ''} ${offerInfo.differingOwnerHouseNr || ''}`}
        </div>
        <div className={classes.fieldContent}>
          {`${offerInfo.differingOwnerPostalCode || ''} ${offerInfo.differingOwnerCity || ''}`}
        </div>
      </div>
    ) : (
      <div className={classes.fieldContent}>-</div>
    ));

  const getExtraProtection = () => {
    const contractOfferValuesRoadsideAssistance = offerInfo.contractOfferValuesRoadsideAssistance
      ? structure.structure.insuranceDetails.extraProtectionOptions
        .contractOfferValuesRoadsideAssistance
      : '';
    const contractOfferValuesMobility = offerInfo.contractOfferValuesMobility
      ? structure.structure.insuranceDetails.extraProtectionOptions.contractOfferValuesMobility
      : '';
    const contractOfferValuesElectroPlus = offerInfo.contractOfferValuesElectroPlus
      ? structure.structure.insuranceDetails.extraProtectionOptions.contractOfferValuesElectroPlus
      : '';
    const contractOfferValuesDiscountProtection = offerInfo.contractOfferValuesDiscountProtection
      ? structure.structure.insuranceDetails.extraProtectionOptions
        .contractOfferValuesDiscountProtection
      : '';

    const contractOfferValuesDriveProtection = offerInfo.contractOfferValuesDriveProtection
      ? structure.structure.insuranceDetails.extraProtectionOptions
        .contractOfferValuesDriveProtection
      : '';

    const contractOfferValuesDriveGAP = offerInfo.contractOfferValuesDriveGAP
      ? structure.structure.insuranceDetails.extraProtectionOptions
        .contractOfferValuesDriveGAP
      : '';

    const contractOfferValuesCoverageAbroad = offerInfo.contractOfferValuesCoverageAbroad
      ? structure.structure.insuranceDetails.extraProtectionOptions
        .contractOfferValuesCoverageAbroad
      : '';

    if (
      contractOfferValuesRoadsideAssistance ||
      contractOfferValuesMobility ||
      contractOfferValuesElectroPlus ||
      contractOfferValuesDiscountProtection ||
      contractOfferValuesDriveProtection ||
      contractOfferValuesDriveGAP ||
      contractOfferValuesCoverageAbroad
    ) {
      return (
        <div>
          { contractOfferValuesRoadsideAssistance && (
            <div className={classes.fieldContent}>{contractOfferValuesRoadsideAssistance}</div>
          )}
          { contractOfferValuesMobility && (
            <div className={classes.fieldContent}>{contractOfferValuesMobility}</div>
          )}
          { contractOfferValuesElectroPlus && (
            <div className={classes.fieldContent}>{contractOfferValuesElectroPlus}</div>
          )}
          { contractOfferValuesDiscountProtection && (
            <div className={classes.fieldContent}>{contractOfferValuesDiscountProtection}</div>
          )}
          { contractOfferValuesDriveProtection && (
            <div className={classes.fieldContent}>{contractOfferValuesDriveProtection}</div>
          )}
          { contractOfferValuesDriveGAP && (
            <div className={classes.fieldContent}>{contractOfferValuesDriveGAP}</div>
          )}
          { contractOfferValuesCoverageAbroad && (
            <div className={classes.fieldContent}>{contractOfferValuesCoverageAbroad}</div>
          )}
        </div>
      );
    }
    return <div className={classes.fieldContent}>-</div>;
  };

  const getDate = date => (
    new Date(date).toLocaleDateString('de', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  );

  const getVehicleUser = () => {
    switch (offerInfo.contractVehicleUserConditions) {
      case 2:
        return getDate(offerInfo.contractVehicleUsersSpouseBirthdate);
      case 3:
        return offerInfo.contractVehicleUsersYoungestDriverAge;
      default:
        return '';
    }
  };

  const getRequestDate = () => {
    if (offerInfo.statusSentAt) {
      return getDate(offerInfo.statusSentAt);
    }
    if (offerInfo.priceFetchedA) {
      getDate(offerInfo.priceFetchedAt);
    }
    return getDate(offerInfo.createdAt);
  };

  const getYearlyMileageInKM = () => {
    const { yearlyMileageInKM } = structure.structure.insuranceDetails;
    const isSpecial = offerInfo.isSpecialPrice || offerInfo.tariff === 'special';

    let yearlyMileageInKMIndex = offerInfo.contractYearlyMileageInKM;
    if (isSpecial
      && (!Number.isInteger(yearlyMileageInKMIndex)
        || yearlyMileageInKMIndex < 0
        || yearlyMileageInKMIndex >= yearlyMileageInKM.car.length)) {
          yearlyMileageInKMIndex = 8;
      }
    return isMotorcycleSelected(store.getState().settings)
      ? yearlyMileageInKM.motorcycle[
        isSpecial ? 4 : offerInfo.contractYearlyMileageInKM
      ]
      : yearlyMileageInKM.car[yearlyMileageInKMIndex];
  };

  const companyOrPersonName = offerInfo.customerIsCompany
    ? `${offerInfo.customerTitle} ${offerInfo.customerCompanyName}`
    : `${offerInfo.customerTitle} ${offerInfo.customerFirstName} ${offerInfo.customerLastName}`;

  const riskTypeData =
    offerInfo.coverage !== RiskTypes.PARTIALLY_COMPREHENSIVE_COVERAGE &&
    offerInfo.coverage !== RiskTypes.LIABILITY
      ? [
        `${structure.structure.personalData.sfClassLiability} ${
          offerInfo.contractSfClassLiability
        }`,
        `${structure.structure.personalData.sfClassVollkasko} ${
          offerInfo.contractSfClassVollkasko
        }`,
      ]
      : [
        `${structure.structure.personalData.sfClassLiability} ${
          offerInfo.contractSfClassLiability
        }`,
      ];

  const insuranceProtection = offerInfo.coverage
    ? structure.structure.insuranceDetails.coverage[offerInfo.coverage][offerInfo.tariff]
    : structure.structure.insuranceDetails.coverage[offerInfo.riskType][offerInfo.tariff];

  const data = {
    personalData: {
      buttonStructure: structure.buttons.backPersonal,
      backStep: 'step4',
      leftItems: [
        {
          paperTag: structure.structure.personalData.title.toUpperCase(),
          fieldTitle: structure.structure.personalData.address.toUpperCase(),
          fieldContent: [
            companyOrPersonName,
            `${offerInfo.customerStreet || ''} ${offerInfo.customerHouseNr || ''}`,
            `${offerInfo.customerPostalCode || ''} ${offerInfo.customerCity || ''}`,
          ],
        },
        {
          fieldTitle: structure.structure.personalData.phone.toUpperCase(),
          fieldContent: [offerInfo.customerPhone],
        },
        {
          fieldTitle: structure.structure.personalData.email.toUpperCase(),
          fieldContent: [offerInfo.customerEmail || '-'],
        },
        offerInfo.customerIsCompany ?
          {
            fieldTitle: '',
            fieldContent: [''],
          } :
          {
            fieldTitle: structure.structure.personalData.birthDate.toUpperCase(),
            fieldContent: [getDate(offerInfo.customerBirthday)],
          },
        {
          fieldTitle: structure.structure.personalData.bankDetails.toUpperCase(),
          fieldContent: [offerInfo.customerBankIBAN, offerInfo.customerBankName],
        },
      ],
      rightItems: [
        {
          fieldTitle: structure.structure.personalData.sf.toUpperCase(),
          fieldContent: riskTypeData,
        },
        {
          fieldTitle: structure.structure.personalData.previousInsurer.toUpperCase(),
          fieldContent: convertTooLongStringToSplitedArray(offerInfo.customerPreviousInsurerName),
        },
        {
          fieldTitle: structure.structure.personalData.insuranceNumber.toUpperCase(),
          fieldContent: [getPreviousInsuranceNumber(offerInfo.previousInsuranceNumber)],
        },
        {
          fieldTitle: structure.structure.personalData.differingOwner.toUpperCase(),
          fieldContent: [getDifferingOwner()],
        },
        offerInfo.contractVehicleUserConditions ? {
          fieldTitle: structure.structure.insuranceDetails.vehicleUserConditionsLabel[
            offerInfo.contractVehicleUserConditions
          ],
          fieldContent: [getVehicleUser() || ''],
        } : {
          fieldTitle: '',
          fieldContent: [''],
        },
      ],
    },

    insuranceDetails: {
      buttonStructure: structure.buttons.backInsurance,
      backStep: 'step3',
      leftItems: [
        {
          paperTag: structure.structure.insuranceDetails.title.toUpperCase(),
          fieldTitle: structure.structure.insuranceDetails.protection.toUpperCase(),
          fieldContent: [insuranceProtection],
        },
        {
          fieldTitle: structure.structure.insuranceDetails.users.toUpperCase(),
          fieldContent: [
            structure.structure.insuranceDetails.vehicleUserConditions[
              offerInfo.contractVehicleUserConditions
            ],
            structure.structure.insuranceDetails.typeOfUsage[offerInfo.contractVehicleTypeOfUsage],
          ],
        },
        {
          fieldTitle: structure.structure.insuranceDetails.yearlyMileage.toUpperCase(),
          fieldContent: [
            getYearlyMileageInKM(),
          ],
        },
        {
          fieldTitle: structure.structure.insuranceDetails.deductible.toUpperCase(),
          fieldContent: [
            offerInfo.riskType !== 'liability'
              ? structure.structure.insuranceDetails.contractDeductibleType[
                offerInfo.contractDeductibleType
              ].vk
              : '',
            offerInfo.riskType !== 'liability'
              ? structure.structure.insuranceDetails.contractDeductibleType[
                offerInfo.contractDeductibleType
              ].tk
              : '',
            offerInfo.riskType === 'liability' && '-',
          ],
        },
        price ? {
          fieldTitle: structure.structure.insuranceDetails.price.toUpperCase(),
          fieldContent: [`${price.toLocaleString('de-DE', { minimumFractionDigits: 2 })} ${frequenceAndUnit}`],
        } : {
          fieldTitle: '',
          fieldContent: [''],
        },
        offerInfo.vehiclePreOwned ? {
          fieldTitle: structure.structure.insuranceDetails.firstRegistration.toUpperCase(),
          fieldContent: [getDate(offerInfo.vehicleRegistrationDate)],
        } : {
          fieldTitle: '',
          fieldContent: [''],
        },
        {
          fieldTitle: structure.structure.insuranceDetails.typeOfFinancing.toUpperCase(),
          fieldContent: [structure.structure.insuranceDetails.financingConditions[
            offerInfo.vehiclePaymentType
          ].toUpperCase()],
        },
        {
          fieldTitle: structure.structure.insuranceDetails.requestDate.toUpperCase(),
          fieldContent: [getRequestDate()],
        },
        {
          fieldTitle: structure.structure.insuranceDetails.dateOfInsurance.toUpperCase(),
          fieldContent: [getDate(offerInfo.contractStartDate) || '-'],
        },
      ],
      rightItems: [
        {
          fieldTitle: structure.structure.insuranceDetails.vehicle.toUpperCase(),
          fieldContent: [
            carModelList.length > 0 ? carModelList[0].companyName : '',
            carModelList.length > 0 ? carModelList[0].salesDesignation : '',
            carModelList.length > 0 ? carModelList[0].fuel : '',
            carModelList.length > 0 ? getStrenghtRow(carModelList[0]) : '',
            carModelList.length > 0 ? `${carModelList[0].producerId} / ${carModelList[0].vehTypeId}` : '',
            offerInfo.vehiclePreOwned
              ? structure.structure.insuranceDetails.vehiclePreOwned.usedCar
              : structure.structure.insuranceDetails.vehiclePreOwned.newCar,
            `${offerInfo.vehicleMileage} ${structure.structure.insuranceDetails.vehicleMileage}`,
            offerInfo.vehicleLicensePlateNumber ? offerInfo.vehicleLicensePlateNumber : '',
            offerInfo.vehicleIdentificationNumber ? offerInfo.vehicleIdentificationNumber : '',
          ],
        },
        {
          fieldTitle: structure.structure.insuranceDetails.extraProtection.toUpperCase(),
          fieldContent: [getExtraProtection()],
        },
        {
          fieldTitle: structure.structure.insuranceDetails.payment.toUpperCase(),
          fieldContent: [
            structure.structure.insuranceDetails.paymentInterval[offerInfo.contractPaymentInterval],
          ],
        },
        {
          fieldTitle: structure.structure.insuranceDetails.offerNumber.toUpperCase(),
          fieldContent: [offerInfo.code || '-'],
        },
        {
          fieldTitle: structure.structure.insuranceDetails.evbNumber.toUpperCase(),
          fieldContent: [offerInfo.vehicleEvbNumber || '-'],
        },
      ],
    },
  };

  return [data.personalData, data.insuranceDetails];
};

export default mapOfferOverview;
