import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { QUESTION_GROUP } from '../../../constants';
import { getText, getQuestions } from '../ContactForm';
import TitleLabel from './TitleLabel';
import DescriptionLabel from './DescriptionLabel';

const currentFormQuestionGroup = QUESTION_GROUP.DOCUMENT_REQUEST;

const DocumentRequestForm = (props) => {
  const {
    schema,
    onChange,
    formValues,
  } = props;

  const fields = getQuestions(schema.questions, currentFormQuestionGroup);

  const getLabelByName = name => (name === fields.NeedDuplicateCheckboxResponse.fieldName
    ? fields.NeedDuplicate.label : fields.NeedCard.label);

  const handleChange = (e) => {
    const eventTargetName = e.target.name;
    const currentQuestionValues = { ...formValues[currentFormQuestionGroup] };

    if (currentQuestionValues[eventTargetName]) {
      delete currentQuestionValues[eventTargetName];
    } else {
      currentQuestionValues[eventTargetName] = getLabelByName(eventTargetName);
    }

    onChange(currentFormQuestionGroup, { ...currentQuestionValues });
  };

  return (
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <TitleLabel>
          {getText(schema, currentFormQuestionGroup, 'text_1')}
        </TitleLabel>
      </Grid>

      <Grid item xs={12}>
        <DescriptionLabel>
          {getText(schema, currentFormQuestionGroup, 'text_2')}
        </DescriptionLabel>
      </Grid>

      <Grid item xs={12}>
        <FormControl component="fieldset" >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={!!formValues[currentFormQuestionGroup].NeedDuplicateCheckboxResponse}
                onChange={handleChange}
                name={fields.NeedDuplicateCheckboxResponse.fieldName}
                color="primary"
              />}
              label={fields.NeedDuplicate.label}
            />
            <FormControlLabel
              control={<Checkbox
                checked={!!formValues[currentFormQuestionGroup].NeedCardCheckboxResponse}
                onChange={handleChange}
                name={fields.NeedCardCheckboxResponse.fieldName}
                color="primary"
              />}
              label={fields.NeedCard.label}
            />
          </FormGroup>
        </FormControl>
      </Grid>

    </Grid>
  );
};

DocumentRequestForm.propTypes = {
  schema: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DocumentRequestForm;
