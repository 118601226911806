const styles = theme => ({
  logo: {
    ...theme.components.printOfferLogo,
    marginTop: theme.spacing.unit * 3,
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      marginTop: theme.spacing.unit,
    },
  },
  title: {
    margin: theme.spacing.unit,
    fontSize: theme.typography.pxToRem(20),
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  sectionTitle: {
    ...theme.fonts.headline_3_print,
    color: theme.palette.primary1Color,
    paddingLeft: 0,
    paddingTop: 5,
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      fontSize: '7pt',
    },
  },
  sectionSubTitle: {
    ...theme.fonts.headline_3_print,
    padding: 0,
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      fontSize: '7pt',
    },
  },
  itemContainer: {
    width: '100%',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      width: '60vw',
    },
  },
  '@media print': {
    dialogContent: {
      overflow: 'hidden',
      margin: '0px 20px !important',
      padding: '0 !important',
    },
    itemContainer: {
      minWidth: '100vw',
    },
    fieldContent: {
      lineHeight: '1.4 !important',
      fontSize: '9pt !important',
    },
  },
  // for Internet Explorer 10 and 11
  '@media print and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    dialogContent: {
      margin: '0px 5px !important',
    },
    fieldContent: {
      fontSize: '7pt !important',
    },
  },
});

export default styles;
