import request from '../requestAgent';
import types from './types';
import handleErrors from './errorHandling';

const setRolesAddUser = payload => ({
  type: types.SET_ROLES_ADD_USER,
  payload,
});

const setRolesEditUser = payload => ({
  type: types.SET_ROLES_EDIT_USER,
  payload,
});

const getRoles = option => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user/roles';
    const res = await request.get(endpoint);
    dispatch(option === 'add' ? setRolesAddUser(res.body) : setRolesEditUser(res.body));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export default getRoles;
