import types from './types';
import { vehicleTypeKeys } from '../reducers/settings';

export const setMotorcycleAsVehicleType = () => async (dispatch) => {
  dispatch({
    type: types.SET_VEHICLE_TYPE,
    payload: vehicleTypeKeys.MOTORCYCLE,
  });
};

export const setCarAsVehicleType = () => async (dispatch) => {
  dispatch({
    type: types.SET_VEHICLE_TYPE,
    payload: vehicleTypeKeys.CAR,
  });
};

export const setNonSetAsVehicleType = () => async (dispatch) => {
  dispatch({
    type: types.SET_VEHICLE_TYPE,
    payload: vehicleTypeKeys.NON_SET,
  });
};

export const setCorrectedZipCode = zipCode => async (dispatch) => {
  dispatch({
    type: types.SET_CORRECTED_ZIP_CODE,
    payload: zipCode,
  });
};
