import PropTypes from 'prop-types';

const header = PropTypes.shape({
  mainText: PropTypes.string,
});

const wehicleModel = PropTypes.shape({
  label: PropTypes.string,
});

const wehicleNumber = PropTypes.shape({
  label: PropTypes.string,
});

const buttons = PropTypes.shape({
  wehicleModel,
  wehicleNumber,
});

const WehicleSearchModel = PropTypes.shape({
  header,
  buttons,
});

export default WehicleSearchModel;
