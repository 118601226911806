const styles = theme => ({
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    ...theme.inputs.inputText,
    ...{
      marginLeft: '10px',
      // width: 'unset',
    },
  },
});

export default styles;
