import React, { useEffect, useState, useRef, useReducer } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { isEqual } from 'lodash';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ButtonsPanel from './Components/ButtonsPanel';
import YearlyMileageForm from './Components/YearlyMileageForm';
import AddressForm from './Components/AddressForm';
import IbanFormPhysical from './Components/IbanFormPhysical';
import IbanFormLegal from './Components/IbanFormLegal';
import DriversGroupFormPhysical from './Components/DriversGroupFormPhysical';
import DriversGroupFormLegal from './Components/DriversGroupFormLegal';
import NameFormPhysical from './Components/NameFormPhysical';
import NameFormLegal from './Components/NameFormLegal';
import ContractScopeForm from './Components/ContractScopeForm';
import VehicleSoldFormPhysical from './Components/VehicleSoldFormPhysical';
import VehicleSoldFormLegal from './Components/VehicleSoldFormLegal';
import DocumentRequestForm from './Components/DocumentRequestForm';
import DocumentUpload from './Components/DocumentUpload/DocumentUpload';
import PreviousInsurerPhysicalForm from './Components/PreviousInsurerPhysicalForm';
import PreviousInsurerLegalForm from './Components/PreviousInsurerLegalForm';
import { QUESTION_GROUP, CLIENT_TYPE } from '../../constants/';
import { NEXT_BUTTON_ACTION, SUB_FORM_ERROR, AFTER_NEXT_BUTTON_WITH_ERROR_ACTION,
  SHOW_ERROR_DIALOG, HIDE_ERROR_DIALOG, SHOW_SUCCESS_DIALOG, SET_SEND_BUTTON_DISABLED,
  SET_SEND_BUTTON_HIDDEN, initialState, contactFormStep4Reducer }
  from './contactFormStep4Reducer'
import request from '../../requestAgent';
import SenderDialog from './Components/SenderDialog';
import { getCurrentProjectName } from '../../utils/theme';

const useStyles = makeStyles(theme => ({
  wrapper: {
    ...theme.containers.contactForm.wrapper,
    paddingTop: '64px',
  },
  buttons: theme.containers.contactForm.buttons,
}));

const isValid = (selectedContactReasons, successfulSubmissions) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(selectedContactReasons)) {
    if (selectedContactReasons[key] && !successfulSubmissions[key]) {
      return false;
    }
  }
  return true;
};

const isDocumentsInFormValues = formValues =>
  (formValues.documents && formValues.documents.length > 0);

const sendForm = async (formValues, recaptchaToken) => {
  const params = { ...formValues, recaptchaToken };

  if (isDocumentsInFormValues(formValues)) {
    params.documents = formValues.documents.map(({ location }) => location);
    params[QUESTION_GROUP.DOCUMENT_UPLOAD].FileNamesList = formValues.documents.map(({ name }) => name).join(', ');
  }

  let success = false;
  try {
    const res = await request
      .post('/api/customer-contact-services/intelligent-contact')
      .send(params);
    if (res.status === 200) {
      success = true;
    }
  } catch {
    // do nothing
  }
  return success;
};

const isOnlyFormsWithoutDataToSendSelected = (selectedContactReasons, clientType) => {
  const base = {
    ADDRESS: false,
    CONTRACT_SCOPE: false,
    DOCUMENT_REQUEST: false,
    DRIVERS_GROUP: false,
    IBAN: false,
    IDENTIFICATION: false,
    NAME: false,
    VEHICLE_SOLD: false,
    YEARLY_MILEAGE: false,
    DOCUMENT_UPLOAD: false,
    PREVIOUS_INSURER: false,
  };

  const onlyContractScopeSelected = { ...base, ...{ CONTRACT_SCOPE: true } };
  if (isEqual(selectedContactReasons, onlyContractScopeSelected)) {
    return true;
  }

  if (clientType === CLIENT_TYPE.LEGAL_PERSON) {
    const bothContractScopeAndDriversGroupSelected = {
      ...base,
      ...{
        CONTRACT_SCOPE: true,
        DRIVERS_GROUP: true,
      },
    };
    if (isEqual(selectedContactReasons, bothContractScopeAndDriversGroupSelected)) {
      return true;
    }

    const onlyDriversGroupSelected = { ...base, ...{ DRIVERS_GROUP: true } };
    if (isEqual(selectedContactReasons, onlyDriversGroupSelected)) {
      return true;
    }
  }

  return false;
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const ContactFormStep4 = ({
  schema,
  onBackButtonClick,
  onFormChange,
  clientType,
  formValues,
  selectedContactReasons,
  setCurrentStep,
}) => {
  const classes = useStyles();

  const [successfulSubmissions, setSuccessfulSubmissions] = useState({});
  const prevSuccessfulSubmissions = usePrevious(successfulSubmissions);
  const [{ submitTrigger, nextButtonClicked, subFormErrorReady, showErrorDialog,
    showSuccessDialog, isSendButtonHidden, isSendButtonDisabled }, dispatch]
    = useReducer(contactFormStep4Reducer, initialState);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    // custom behaviour for forms without any data to send
    const shouldSendButtonBeHidden = isOnlyFormsWithoutDataToSendSelected(
      selectedContactReasons,
      clientType,
    );

    dispatch({ type: SET_SEND_BUTTON_HIDDEN, data: shouldSendButtonBeHidden });
  }, [selectedContactReasons, clientType]);

  useEffect(() => {
    // custom behaviour for non documents uploaded case
    if (selectedContactReasons[QUESTION_GROUP.DOCUMENT_UPLOAD]) {
      dispatch({ type: SET_SEND_BUTTON_DISABLED, data: !isDocumentsInFormValues(formValues) });
    }
    // eslint-disable-next-line
  }, [formValues.documents]);

  const send = async () => {
    const canSent = isValid(selectedContactReasons, successfulSubmissions);
    if (!canSent) return;

    let recaptchaToken = '';
    if (executeRecaptcha) {
      recaptchaToken = await executeRecaptcha('intelligentContact');
    }
    const isSuccess = await sendForm(formValues, recaptchaToken);
    if (isSuccess) {
      dispatch({type: SHOW_SUCCESS_DIALOG});
    } else {
      dispatch({type: SHOW_ERROR_DIALOG});
    }
  };

  useEffect(() => {
    /*
    TODO:
    Refactor programmatic trigger based solution to for example:
    Create only one main Formik form here(ContactFormStep4), remove formik and html form from all chilren components,
    export validation methods from children components and conditionally apply them to main formik here.
    This will allow to replace all successfulSubmissions submitTrigger logic with build-in Formik methods.
    */

    if (!isEqual(prevSuccessfulSubmissions, successfulSubmissions)) {
      send();
    }
    // eslint-disable-next-line
  }, [successfulSubmissions]);

  useEffect(() => {
    if (nextButtonClicked && subFormErrorReady) {
      const elements = document.getElementsByClassName('Mui-error');
      if (elements && elements.length > 0) {
        const el = elements[0];
        el.scrollIntoView({ behavior: 'smooth' });
      }
      dispatch({ type: AFTER_NEXT_BUTTON_WITH_ERROR_ACTION });
    }
  }, [nextButtonClicked, subFormErrorReady]);

  const handleSuccessfulFormSubmit = (questionGroup) => {
    setSuccessfulSubmissions(prevState => ({ ...prevState, ...{ [questionGroup]: true } }));
  };

  const handleOnValidationError = (questionGroup) => {
    setSuccessfulSubmissions(prevState => ({ ...prevState, ...{ [questionGroup]: false } }));
  };

  const validateQuestionsBuildedWithoutFormik = () => {
    if (selectedContactReasons[QUESTION_GROUP.DOCUMENT_UPLOAD]) {
      if (isDocumentsInFormValues(formValues)) {
        handleSuccessfulFormSubmit(QUESTION_GROUP.DOCUMENT_UPLOAD);
      } else {
        handleOnValidationError(QUESTION_GROUP.DOCUMENT_UPLOAD);
      }
    }

    if (selectedContactReasons[QUESTION_GROUP.DOCUMENT_REQUEST]) {
      const documentRequestValues = formValues[QUESTION_GROUP.DOCUMENT_REQUEST];
      if (documentRequestValues.NeedDuplicateCheckboxResponse ||
        documentRequestValues.NeedCardCheckboxResponse) {
        handleSuccessfulFormSubmit(QUESTION_GROUP.DOCUMENT_REQUEST);
      } else {
        handleOnValidationError(QUESTION_GROUP.DOCUMENT_REQUEST);
      }
    }
  };

  const handleNextButtonClick = () => {
    dispatch({ type: NEXT_BUTTON_ACTION });

    validateQuestionsBuildedWithoutFormik();

    // handle forms without any data to send
    if (clientType === CLIENT_TYPE.LEGAL_PERSON) {
      setSuccessfulSubmissions(prevState => ({
        ...prevState,
        ...{ [QUESTION_GROUP.DRIVERS_GROUP]: true },
        ...{ [QUESTION_GROUP.CONTRACT_SCOPE]: true },
      }));
    } else {
      handleSuccessfulFormSubmit(QUESTION_GROUP.CONTRACT_SCOPE);
    }
  };

  const getPhoneNumberByOEM = () => {
    const oemPhoneNumber = schema.oem[getCurrentProjectName()].phone;
    return oemPhoneNumber || '';
  };

  const commonProps = {
    submitTrigger,
    schema,
    formValues,
    onChange: onFormChange,
    onSuccessfulFormSubmit: handleSuccessfulFormSubmit,
    onValidationError: handleOnValidationError,
    getPhoneNumberByOEM,
    onErrorMarked: () => dispatch({ type: SUB_FORM_ERROR })
  };

  const handleSuccessSentDialogLeftButtonClick = () => setCurrentStep(2);
  const handleSuccessSentDialogRightButtonClick = () => setCurrentStep(1);
  const handleErrorSentDialogLeftButtonClick = () => {
    setSuccessfulSubmissions({});
    dispatch({ type: HIDE_ERROR_DIALOG });
  };
  const handleErrorSentDialogRightButtonClick = () => send();

  return (
    <div className={classes.wrapper}>
      {showSuccessDialog ? (
        <SenderDialog
          successIcon
          title={schema.sentDialog.success.title}
          subtitle={schema.sentDialog.success.subtitle}
          labelLeftButton={schema.sentDialog.success.leftButton}
          labelRightButton={schema.sentDialog.success.rightButton}
          onLeftButtonClick={handleSuccessSentDialogLeftButtonClick}
          onRightButtonClick={handleSuccessSentDialogRightButtonClick}
        />
      ) : null}

      {showErrorDialog ? (
        <SenderDialog
          failIcon
          title={schema.sentDialog.fail.title}
          subtitle={schema.sentDialog.fail.subtitle}
          labelLeftButton={schema.sentDialog.fail.leftButton}
          labelRightButton={schema.sentDialog.fail.rightButton}
          onLeftButtonClick={handleErrorSentDialogLeftButtonClick}
          onRightButtonClick={handleErrorSentDialogRightButtonClick}
        />
      ) : null}

      {
        {
          [CLIENT_TYPE.PHYSICAL_PERSON]: (
            <>
              {selectedContactReasons[QUESTION_GROUP.PREVIOUS_INSURER] ? (
                <PreviousInsurerPhysicalForm {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.ADDRESS] ? (
                <AddressForm {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.YEARLY_MILEAGE] ? (
                <YearlyMileageForm {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.DRIVERS_GROUP] ? (
                <DriversGroupFormPhysical {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.IBAN] ? (
                <IbanFormPhysical {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.NAME] ? (
                <NameFormPhysical {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.VEHICLE_SOLD] ? (
                <VehicleSoldFormPhysical {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.CONTRACT_SCOPE] ? (
                <ContractScopeForm {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.DOCUMENT_REQUEST] ? (
                <DocumentRequestForm {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.DOCUMENT_UPLOAD] ? (
                <DocumentUpload
                  schema={schema}
                  onChange={onFormChange}
                  formValues={formValues}
                />
              ) : null}
            </>
          ),
          [CLIENT_TYPE.LEGAL_PERSON]: (
            <>
              {selectedContactReasons[QUESTION_GROUP.PREVIOUS_INSURER] ? (
                <PreviousInsurerLegalForm {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.ADDRESS] ? (
                <AddressForm {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.YEARLY_MILEAGE] ? (
                <YearlyMileageForm {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.DRIVERS_GROUP] ? (
                <DriversGroupFormLegal {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.IBAN] ? (
                <IbanFormLegal {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.NAME] ? (
                <NameFormLegal {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.VEHICLE_SOLD] ? (
                <VehicleSoldFormLegal {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.CONTRACT_SCOPE] ? (
                <ContractScopeForm {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.DOCUMENT_REQUEST] ? (
                <DocumentRequestForm {...commonProps} />
              ) : null}
              {selectedContactReasons[QUESTION_GROUP.DOCUMENT_UPLOAD] ? (
                <DocumentUpload
                  schema={schema}
                  onChange={onFormChange}
                  formValues={formValues}
                />
              ) : null}
            </>
          ),
        }[clientType]
      }

      <ButtonsPanel
        className={classes.buttons}
        labelLeftButton={schema.buttons.back}
        labelRightButton={schema.buttons.next}
        handleLeftButtonClick={onBackButtonClick}
        handleRightButtonClick={handleNextButtonClick}
        hideRightButton={isSendButtonHidden}
        disableRightButton={isSendButtonDisabled}
      />
    </div>
  );
};

ContactFormStep4.propTypes = {
  schema: PropTypes.object.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  clientType: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  selectedContactReasons: PropTypes.object.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

export default ContactFormStep4;
