const styles = (theme) => {
  const colorSwitcher = ({ hasGreyColor }) => {
    if (hasGreyColor) return theme.palette.textColorMid;
    return theme.palette.primary1Color;
  };
  const underlineSwitcher = ({ underline }) => underline;

  return {
    button: {
      height: theme.units.spacing * 8,
      padding: '0px',
      color: colorSwitcher,
      fontWeight: 600,
      fontFamily: theme.fonts.fontFamily,
      fontSize: '16px',
      width: 'auto',
      background: 'none',
      border: 'none',
      textAlign: 'left',
      textDecoration: underlineSwitcher,
      '&:hover': {
        color: theme.palette.primary1Color,
        cursor: 'pointer',
      },
      '&:focus': {
        outline: 'none',
      },
      '&:disabled': {
        color: theme.palette.secondaryTextColor,
        cursor: 'default',
      },
    },
  };
};

export default styles;
