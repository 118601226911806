import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Grid } from '@material-ui/core';
import CustomTextField, { formikTextFieldProps } from './CustomTextField';
import { QUESTION_GROUP } from '../../../constants/';
import { getText, getQuestions, getMandatoryFields } from '../ContactForm';
import TitleLabel from './TitleLabel';
import DescriptionLabel from './DescriptionLabel';
import useFormSubmitTrigger from './useFormSubmitTrigger';
import useFormOnChange from './useFormOnChange';
import useFormErrorRender from './useFormErrorRender';

const currentFormQuestionGroup = QUESTION_GROUP.ADDRESS;

const AddressForm = (props) => {
  const {
    schema,
    submitTrigger,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    onChange,
    getPhoneNumberByOEM,
    onErrorMarked,
    isSubmitting,
  } = props;

  const fields = getQuestions(schema.questions, currentFormQuestionGroup);
  const formRef = useFormSubmitTrigger(submitTrigger);
  useFormOnChange(onChange, values, currentFormQuestionGroup);
  useFormErrorRender(onErrorMarked, isSubmitting, errors);

  const formikProps = {
    values, handleChange, handleBlur, errors, touched,
  };

  return (
    <form autoComplete="off" ref={formRef} onSubmit={handleSubmit}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <TitleLabel>
            {getText(schema, currentFormQuestionGroup, 'text_1')}
          </TitleLabel>
        </Grid>


        <Grid item xs={12}>
          <DescriptionLabel>
            {getText(schema, currentFormQuestionGroup, 'text_2')} {getPhoneNumberByOEM()}
          </DescriptionLabel>
        </Grid>


        <Grid item xs={7}>
          <CustomTextField
            {...formikTextFieldProps(fields.Street, formikProps)}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            {...formikTextFieldProps(fields.HouseNumber, formikProps)}
          />
        </Grid>


        <Grid item xs={4}>
          <CustomTextField
            {...formikTextFieldProps(fields.zipCode, formikProps)}
          />
        </Grid>
        <Grid item xs={7}>
          <CustomTextField
            {...formikTextFieldProps(fields.City, formikProps)}
          />
        </Grid>


        <Grid item xs={4}>
          <CustomTextField
            {...formikTextFieldProps(fields.Valid, formikProps)}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={7} />
      </Grid>
    </form>
  );
};

AddressForm.propTypes = {
  schema: PropTypes.object.isRequired,
  submitTrigger: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  getPhoneNumberByOEM: PropTypes.func.isRequired,
};

export default withFormik({
  handleSubmit: (_, { setSubmitting, props }) => {
    props.onSuccessfulFormSubmit(currentFormQuestionGroup);
    setSubmitting(false);
  },
  mapPropsToValues: ({ formValues }) => ({
    Street: formValues.ADDRESS.Street || '',
    HouseNumber: formValues.ADDRESS.HouseNumber || '',
    zipCode: formValues.ADDRESS.zipCode || '',
    City: formValues.ADDRESS.City || '',
    Valid: formValues.ADDRESS.Valid,
  }),
  validate: (values, { schema, ...props }) => {
    const errors = {};

    const mandatoryFields = getMandatoryFields(
      schema.questions,
      currentFormQuestionGroup,
    );

    mandatoryFields.forEach((key) => {
      if (!values[key]) {
        errors[key] = 'Required';
      }
    });

    if (Object.keys(errors).length > 0) {
      props.onValidationError(currentFormQuestionGroup);
    }

    return errors;
  },
})(AddressForm);
