import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import * as classNames from 'classnames';
import { getCurrentProjectName } from '../../../utils/theme';
import ROUTES from '../../../constants/routes';

const useStyles = makeStyles(theme => ({
  footer: {
    minHeight: '100px',
    width: '100vw',
    backgroundColor: theme.containers.contactForm.primaryColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  block: props => ({
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '50px',
    paddingRight: props.recaptchaPlaceholder ? '100px' : '50px',
  }),
  fontBase: {
    fontFamily: theme.fonts.fontFamily,
    color: theme.palette.common.white,
    '@media (max-width: 500px)': {
      fontSize: '12px !important',
    },
  },
  rightsReserved: {
    fontSize: '14px',
  },
  link: {
    fontSize: '16px',
    textDecoration: 'none',
  },
  separator: {
    display: 'inline',
    fontSize: '16px',
  },
}));

const Footer = ({ oemSchema, recaptchaPlaceholder, printImpressum = true }) => {
  const classes = useStyles({ recaptchaPlaceholder });

  const { dataProtection, legalOwnership } = oemSchema.common.footer;

  return (
    <footer className={classes.footer}>
      <p className={classNames(classes.fontBase, classes.rightsReserved, classes.block)}>
        {oemSchema[getCurrentProjectName().toLowerCase()].rightsReservedName}
      </p>

      <div className={classes.block}>
        <span>
          <a href={dataProtection.url} className={classNames(classes.fontBase, classes.link)}>
            {dataProtection.title}
          </a>
        </span>
        { printImpressum &&
        (<>
          <p className={classNames(classes.fontBase, classes.separator)}>  |  </p>
          <span>
            <a href={`/${ROUTES.CONTACT_FORM_IMPRESSUM}`} target="_blank" className={classNames(classes.fontBase, classes.link)}>
              {legalOwnership.title}
            </a>
          </span>
        </>)
        }
      </div>

    </footer>
  );
};

Footer.propTypes = {
  oemSchema: PropTypes.object,
  recaptchaPlaceholder: PropTypes.bool,
};

Footer.defaultProps = {
  oemSchema: {
    volvo: {
      phone: '',
      rightsReservedName: '',
    },
    nrw: {
      phone: '',
      rightsReservedName: '',
    },
    bmw: {
      phone: '',
      rightsReservedName: '',
    },
    hyundai: {
      phone: '',
      rightsReservedName: '',
    },
    emp: {
      phone: '',
      rightsReservedName: '',
    },
    common: {
      footer: {
        dataProtection: {
          title: '',
          url: '',
        },
        legalOwnership: {
          title: '',
          url: '',
        },
      },
    },
  },
  recaptchaPlaceholder: false,
};

export default Footer;
