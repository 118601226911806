import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Grid } from '@material-ui/core';
import CustomTextField, { formikTextFieldProps } from './CustomTextField';
import { QUESTION_GROUP, CLIENT_TYPE } from '../../../constants';
import { getText, getQuestions, getMandatoryFields } from '../ContactForm';
import TitleLabel from './TitleLabel';
import DescriptionLabel from './DescriptionLabel';
import useFormErrorRender from './useFormErrorRender';
import useFormSubmitTrigger from './useFormSubmitTrigger';
import useFormOnChange from './useFormOnChange';

const currentFormQuestionGroup = QUESTION_GROUP.NAME;

const NameFormLegal = (props) => {
  const {
    schema,
    submitTrigger,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    onChange,
    getPhoneNumberByOEM,
    onErrorMarked,
    isSubmitting
  } = props;

  const fields = getQuestions(schema.questions, currentFormQuestionGroup);
  const formRef = useFormSubmitTrigger(submitTrigger);
  useFormOnChange(onChange, values, currentFormQuestionGroup);
  useFormErrorRender(onErrorMarked, isSubmitting, errors);

  const formikProps = {
    values, handleChange, handleBlur, errors, touched,
  };

  return (
    <form autoComplete="off" ref={formRef} onSubmit={handleSubmit}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <TitleLabel>
            {getText(schema, currentFormQuestionGroup, 'text_1')}
          </TitleLabel>
        </Grid>


        <Grid item xs={12}>
          <DescriptionLabel>
            {getText(schema, currentFormQuestionGroup, 'text_2')} {getPhoneNumberByOEM()}
          </DescriptionLabel>
        </Grid>


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.Valid, formikProps)}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} />


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.CompanyName, formikProps)}
          />
        </Grid>
        <Grid item xs={6} />

      </Grid>
    </form>
  );
};

NameFormLegal.propTypes = {
  schema: PropTypes.object.isRequired,
  submitTrigger: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  getPhoneNumberByOEM: PropTypes.func.isRequired,
};

export default withFormik({
  handleSubmit: (_, { setSubmitting, props }) => {
    props.onSuccessfulFormSubmit(currentFormQuestionGroup);
    setSubmitting(false);
  },
  mapPropsToValues: ({ formValues }) => ({
    Valid: formValues[currentFormQuestionGroup].Valid || '',
    CompanyName: formValues[currentFormQuestionGroup].CompanyName || '',
  }),
  validate: (values, { schema, ...props }) => {
    const errors = {};

    const mandatoryFields = getMandatoryFields(
      schema.questions,
      currentFormQuestionGroup,
      CLIENT_TYPE.LEGAL_PERSON,
    );

    mandatoryFields.forEach((key) => {
      if (!values[key]) {
        errors[key] = 'Required';
      }
    });

    if (Object.keys(errors).length > 0) {
      props.onValidationError(currentFormQuestionGroup);
    }

    return errors;
  },
})(NameFormLegal);
