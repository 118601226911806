import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import UnfoldLess from '@material-ui/icons/UnfoldLess';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import styles from './style';

const ToggleTariffComparison = ({
  handleTariffExpansionPanelChange,
  tariffExpanded,
  labelQuestionExpanded,
  labelQuestionNotExpanded,
  classes,
}) => {
  const icon = tariffExpanded ? (
    <UnfoldLess className={classes.icons} />
  ) : (
    <UnfoldMore className={classes.icons} />
  );

  const label = tariffExpanded ? labelQuestionExpanded : labelQuestionNotExpanded;

  return (
    <Grid container justify="center" alignContent="center" direction="column" spacing={2}>
      <Grid item xs={12}>
        {/* Question 4 */}
        <label className={`${classes.label} ${classes.link}`}>
          <span
            className={classes.primary2Color}
            onClick={() => handleTariffExpansionPanelChange()}>
            {label} {icon}
          </span>
        </label>
      </Grid>
    </Grid>
  );
};

ToggleTariffComparison.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  handleTariffExpansionPanelChange: PropTypes.func.isRequired,
  tariffExpanded: PropTypes.bool.isRequired,
  labelQuestionExpanded: PropTypes.string.isRequired,
  labelQuestionNotExpanded: PropTypes.string.isRequired,
};

ToggleTariffComparison.defaultProps = {
  classes: '',
};

export default withStyles(styles)(ToggleTariffComparison);
