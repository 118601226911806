import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { QUESTION_GROUP } from '../../../constants';
import { getText } from '../ContactForm';
import TitleLabel from './TitleLabel';
import DescriptionLabel from './DescriptionLabel';

const currentFormQuestionGroup = QUESTION_GROUP.CONTRACT_SCOPE;

const ContractScopeForm = ({ schema, getPhoneNumberByOEM }) => (
  <>
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <TitleLabel>
          {getText(schema, currentFormQuestionGroup, 'text_1')}
        </TitleLabel>
      </Grid>


      <Grid item xs={12}>
        <DescriptionLabel>
          {getText(schema, currentFormQuestionGroup, 'text_2')} {getPhoneNumberByOEM()}
        </DescriptionLabel>
      </Grid>

    </Grid>
  </>
);

ContractScopeForm.propTypes = {
  schema: PropTypes.object.isRequired,
  getPhoneNumberByOEM: PropTypes.func.isRequired,
};

export default ContractScopeForm;
