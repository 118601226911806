import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { fileUploadValidationTypes } from '../../validateValueByRuleName';

const useStyles = makeStyles(theme => ({
  label: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '98px',
    width: '272px',
    margin: '5px',
    padding: '25px 16px',
    borderRadius: '9px',
    backgroundColor: theme.containers.contactForm.whiteColor,
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  },
  labelText: {
    fontFamily: theme.fonts.fontFamily,
    color: theme.palette.textColor,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  icon: {
    color: '#000000',
    width: '36px',
    height: '36px',
    marginTop: '10px',
  },
  input: {
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    label: {
      paddingLeft: '20px',
    },
  },
}));

const UploadIcon = props => (
  <svg viewBox="0 0 36 36" {...props}>
    <path
      d="M21.5526316,6.91052632 L14.5736842,15.6684211 C14.1631579,16.1473684 13.6157895,16.4210526 13,16.4210526 C13,16.4210526 13,16.4210526 13,16.4210526 C12.3842105,16.4210526 11.8368421,16.1473684 11.4263158,15.6684211 L4.51578947,6.91052632 L8.00526316,6.91052632 L11.6315789,11.4947368 L11.6315789,0 L14.3684211,0 L14.3684211,11.4947368 L17.9947368,6.91052632 L21.5526316,6.91052632 Z M26,20.5263158 L26,15.7368421 L23.2631579,15.7368421 L23.2631579,20.5263158 L2.73684211,20.5263158 L2.73684211,15.7368421 L0,15.7368421 L0,20.5263158 C0,22.0315789 1.23157895,23.2631579 2.73684211,23.2631579 L23.2631579,23.2631579 C24.7684211,23.2631579 26,22.0315789 26,20.5263158 Z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

const Input = ({
  name, label, onChange,
}) => {
  const classes = useStyles();

  const onInputClick = (event) => {
    event.target.value = '';
  };

  return (
    <label htmlFor="file" className={classes.label}>
      <UploadIcon className={classes.icon} />
      <span className={classes.labelText}>{label}</span>
      <input
        type="file"
        name={name}
        title={label}
        accept={fileUploadValidationTypes.ACCEPTED_FILE_EXTENSIONS}
        onChange={onChange}
        className={classes.input}
        onClick={onInputClick}
      />
    </label>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  name: '',
  label: '',
  onChange: () => {},
};

export default Input;
