import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import moment from 'moment';
import { VEHICLE_NUMBER_SEARCH_CAR_DETAILS_FORM } from '../../actions/vehicleNumberSearch';
import { initOffer } from '../../actions/offer';
import NumberFormat from '../../components/NumberFormat/';
import Button from '../../components/Button';
import Radio from '../../components/Radio';
import DatePickerInput from '../../components/DatePickerInput';
import {
  FINANCING_VALIDATION,
  STATE_VALIDATION,
  MILAGE_VALIDATION,
  MILAGE_VALIDATION_WITH_DATE,
} from '../../validators/validators';
import styles from './style';
import { SPECIAL_PRICE_1_ID_VALUE, SPECIAL_PRICE_2_ID_VALUE } from '../../constants/vehicle';
import { isCallCenterUser } from '../Authorization';
import { isBmw, isEmp } from '../../utils/theme';
import { getQueryParamsFromLWLRedirect } from '../../utils/lwlQueryParams';

const getFinancingOptions = options => {
  if (isCallCenterUser() && isEmp()) {
    return options;
  } else if (isBmw()) {
    const bmwNotAllowedOptions = [SPECIAL_PRICE_1_ID_VALUE];
    return options.filter(item => !bmwNotAllowedOptions.includes(item.value));
  }
  const notAllowedOptions = [SPECIAL_PRICE_1_ID_VALUE, SPECIAL_PRICE_2_ID_VALUE];
  return options.filter(item => !notAllowedOptions.includes(item.value));
};

const hasStateWithDate = state => state === 'true';

const VehicleNumberSearchCarDetailsForm = ({
  classes,
  buttons,
  state,
  financing,
  milage,
  registrationDate,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  selectedState,
}) => (
  <form onSubmit={handleSubmit} className={classes.form}>
    <Grid container spacing={0}>
      <Grid container item spacing={2} xs={12} md={5} className={classes.stateContainer}>
        <Grid item xs={12}>
          <Field
            name={state.name}
            label={state.label}
            component={Radio}
            options={state.options}
            validate={STATE_VALIDATION}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2} xs={12} md={7}>
        {!!selectedState && (
          <div className={classes.milageContainer}>
            <Field
              id={milage.id}
              name={milage.name}
              label={milage.label}
              component={NumberFormat}
              validate={
                hasStateWithDate(selectedState) ? MILAGE_VALIDATION_WITH_DATE : MILAGE_VALIDATION
              }
              helperText={milage.description}
            />
          </div>
        )}
        {hasStateWithDate(selectedState) && (
          <div className={classes.registrationContainer}>
            <Field
              name={registrationDate.name}
              label={registrationDate.label}
              component={DatePickerInput}
              maxDate={moment()}
              reduxFormReady
            />
          </div>
        )}
      </Grid>
    </Grid>
    <Grid container spacing={0} className={classes.financingContainer}>
      <Grid container item spacing={2} xs={12} md={8}>
        <Grid item xs={12}>
          <Field
            name={financing.name}
            label={financing.label}
            component={Radio}
            options={getFinancingOptions(financing.options)}
            validate={FINANCING_VALIDATION}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid container justify="center" alignContent="center" spacing={2}>
      <Grid container item xs={4} justify="center">
        <Button
          disabled={pristine || submitting || invalid}
          {...buttons.submit}
          myWidth="170px"
        />
      </Grid>
    </Grid>
  </form>
);

const selector = formValueSelector(VEHICLE_NUMBER_SEARCH_CAR_DETAILS_FORM);
const mapStateToProps = state => ({
  selectedState: selector(state, 'state'),
  initialValues: {
    milage: 0,
  },
});


export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    null,
  ),
  reduxForm({
    form: VEHICLE_NUMBER_SEARCH_CAR_DETAILS_FORM,
    onSubmit: (data, dispatch) => {
      const queryParams = getQueryParamsFromLWLRedirect();
      const modifiedData = { ...data, ...{ milage: data.milage * 1000 }, ...queryParams };
      dispatch(initOffer(modifiedData));
    },
  }),
)(VehicleNumberSearchCarDetailsForm);

VehicleNumberSearchCarDetailsForm.propTypes = {
  buttons: PropTypes.objectOf(Object).isRequired,
  financing: PropTypes.objectOf(Object).isRequired,
  state: PropTypes.objectOf(Object).isRequired,
  milage: PropTypes.objectOf(Object).isRequired,
  registrationDate: PropTypes.objectOf(Object).isRequired,
  selectedState: PropTypes.string,
  handleSubmit: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
};

VehicleNumberSearchCarDetailsForm.defaultProps = {
  selectedState: null,
  handleSubmit: () => {},
  classes: '',
  pristine: false,
  invalid: true,
  submitting: false,
};
