import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import styles from './style';
import { clearMessage } from '../../actions/notificationCenter';

class Notification extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.warning = this.props.warning;
  }
  componentWillUnmount() {
    this.props.clearMessage();
  }
  render() {
    return (
      <Typography component="div" className={this.classes.message}>
        <p> {this.props.message}</p>
      </Typography>
    );
  }
}
const mapStateToProps = state => ({
  message: state.notificationCenter.message,
  warning: state.notificationCenter.error,
});

const mapDispatchToProps = dispatch => ({
  clearMessage: () => dispatch(clearMessage()),
});

Notification.propTypes = {
  message: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  clearMessage: PropTypes.func,
  warning: PropTypes.bool,
};

Notification.defaultProps = {
  message: 'Something went wrong',
  clearMessage: () => {},
  classes: '',
  warning: true,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(Notification);
