import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid, Typography, Paper, Modal, withStyles } from '@material-ui/core';
import { deleteUser, enableUser, unlockUser, disableUser } from '../../actions/userDetails';
import styles from './style';
import Button from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';

class ShowUserDetails extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      open: false,
    };

    this.onClick = this.onClick.bind(this);
    this.inputFields = this.inputFields.bind(this);
    this.isStructureReady = this.isStructureReady.bind(this);
    this.onDeleteUser = this.onDeleteUser.bind(this);
  }

  onClick(variant) {
    this.props.variantChange(variant);
  }

  onDeleteUser() {
    // event.preventDefault();
    this.props.deleteUser(this.props.userDetails.id);
    this.handleClose();
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleEnableUser = () => {
    // event.preventDefault();
    this.props.enableUser(this.props.userDetails.id);
  };

  handleUnlockUser = () => {
    // event.preventDefault();
    this.props.unlockUser(this.props.userDetails.id);
  };

  handleDisableUser = () => {
    // event.preventDefault();
    this.props.disableUser(this.props.userDetails.id);
  };

  inputFields(fields) {
    const mapItem = [];
    fields.map(key =>
      mapItem.push(<Typography component="div" key={key} className={this.classes.inputContainer}>
        <Typography component="p" className={this.classes.label}>
          {this.props.structure.fields[key].label}
        </Typography>
        <Typography component="p" className={this.classes.value}>
          {this.props.structure.fields[key].bool
              ? this.props.userDetails[key]
                ? 'wahr'
                : 'falsch'
              : this.props.userDetails[key]}
        </Typography>
      </Typography>));
    return mapItem;
  }

  isStructureReady() {
    return this.props.structure !== undefined && Object.keys(this.props.userDetails).length > 0;
  }

  render() {
    const { structure, classes } = this.props;
    return (
      <Fragment>
        {this.isStructureReady() && (
          <Fragment>
            <Grid item xs={12}>
              <ButtonLink
                {...structure.buttons.back}
                onClick={() => this.onClick('userSearch')}
              />
              <Typography align="center" variant="h1" className={classes.title}>
                {structure.header.mainText}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} lg={8}>
              <Paper elevation={1} className={classes.rootPaper}>
                {this.inputFields(structure.fieldsOrder)}
              </Paper>
            </Grid>
            <Grid container item xs={12} md={10} lg={8} spacing={0} justify="space-between">
              <ButtonLink
                {...this.props.structure.buttons.deleteUser}
                onClick={() => this.handleOpen()}
              />
              {this.props.userDetails.accountDisabled && (
                <Button
                  {...this.props.structure.buttons.enableUser}
                  onClick={() => this.handleEnableUser()}
                />
              )}
              {!this.props.userDetails.accountDisabled && this.props.userDetails.accountLocked && (
                <Button
                  {...this.props.structure.buttons.unlockUser}
                  onClick={() => this.handleUnlockUser()}
                />
              )}
              {!this.props.userDetails.accountDisabled && !this.props.userDetails.accountLocked && (
                <Button
                  {...this.props.structure.buttons.disableUser}
                  onClick={() => this.handleDisableUser()}
                />
              )}
              <Button
                {...this.props.structure.buttons.changeDetails}
                onClick={() => this.onClick('editDetails')}
              />
            </Grid>
          </Fragment>
        )}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={this.classes.modalPaper}>
            <div className={this.classes.modalHeader}>
              <Grid container justify="center" alignContent="center" spacing={2}>
                <h3 className={this.classes.modalTitle}>
                  {this.props.structure.deleteDialog.title}
                </h3>
              </Grid>
            </div>
            <Grid container justify="center" alignContent="center" spacing={2}>
              <div className={this.classes.modalBody}>
                <p className={this.classes.modalText}>{this.props.structure.deleteDialog.text}</p>
              </div>
            </Grid>
            <div className={this.classes.modalButtons}>
              <Grid container justify="center" alignContent="center" spacing={2}>
                <Grid item sm={5} xs={12}>
                  <Button
                    {...this.props.structure.deleteDialog.buttons.cancel}
                    onClick={() => this.handleClose()}
                  />
                </Grid>
                <Grid item sm={2} xs={12} />
                <Grid item sm={5} xs={12}>
                  <Button
                    {...this.props.structure.deleteDialog.buttons.deleteUser}
                    onClick={() => this.onDeleteUser()}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails,
  structure: state.projectStructure.showUserDetails,
  inputFields: state.projectFields,
});

const mapDispatchToProps = dispatch => ({
  deleteUser: id => dispatch(deleteUser(id)),
  enableUser: id => dispatch(enableUser(id)),
  unlockUser: id => dispatch(unlockUser(id)),
  disableUser: id => dispatch(disableUser(id)),
});

ShowUserDetails.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  deleteUser: PropTypes.func,
  enableUser: PropTypes.func,
  unlockUser: PropTypes.func,
  disableUser: PropTypes.func,
};

ShowUserDetails.defaultProps = {
  classes: '',
  deleteUser: () => {},
  enableUser: () => {},
  unlockUser: () => {},
  disableUser: () => {},
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ShowUserDetails);
