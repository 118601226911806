const styles = theme => ({
  form: {
    padding: theme.spacing.unit,
  },
  buttonContainer: {
    position: 'relative',
    '& > div': {
      left: theme.spacing.unit,
      top: '32px',
    },
  },
  carContainer: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing.unit * 3,
    },
  },
  carTitle: {
    color: '#999999',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: '600',
    lineHeight: '17px',
  },
  carDesc: {
    color: '#333333',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '600',
    lineHeight: '26px',
  },
});

export default styles;
