import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import styles from './style';

const AddressInvalid = ({
  title, message, section, classes,
}) => (
  <div className={classes.container}>
    <Typography variant="h5" align="center" className={classes.section}>
      {section}
    </Typography>

    <Typography variant="h3" align="center">
      {title}
    </Typography>

    <p className={classes.subtitle}>{message}</p>
  </div>
);

AddressInvalid.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  section: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
};

AddressInvalid.defaultProps = {
  section: '',
  title: '',
  message: '',
};

export default withStyles(styles)(AddressInvalid);
