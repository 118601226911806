const styles = theme => ({
  inputContainer: {
    position: 'relative',
  },
  inputWrapper: {
    height: `${theme.spacing.unit * 5}px`,
    display: 'inline-block',
    ...theme.fonts.paragraph,
  },
  singleRadio: {
    marginRight: `${theme.spacing.unit * 7}px`,
    fontFamily: theme.fontFamily,
  },
  labelFont: {
    fontFamily: theme.fontFamily,
    marginLeft: '-3px',
  },
  size: {
    width: 40,
    height: 40,
    paddingLeft: '2px',
    paddingRight: '0px',
    color: theme.palette.primary1Color,
  },
  sizeIcon: {
    fontSize: 20,
    color: theme.palette.primary1Color,
    paddingRight: '-20px',
  },
});

export default styles;
