const styles = theme => ({
  container: {
    padding: '20px',
  },
  innerBorderContainer: {
    margin: '20px',
    border: `2px solid ${theme.palette.border}`,
  },
  paperWidthMd: {
    width: 'fit-content',
  },
  section: {
    marginTop: '14px',
    marginBottom: '8px',
    fontSize: '18px',
  },
  subtitle: {
    fontFamily: theme.fonts.fontFamily,
    fontSize: '18px',
    letterSpacing: 0,
    lineHeight: '27px',
    textAlign: 'center',
  },
  button: {
    margin: '0 20px 40px 20px',
    height: '40px',
    width: '234px',
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 0,
    fontSize: '16px',
    textTransform: 'none',
  },
  buttonWider: {
    width: '400px',
  },
  buttonCancel: {
    background: 'linear-gradient(180deg, #E5E5E5 0%, #F7F7F7 100%)',
    color: theme.palette.textColor,
  },
  buttonNext: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary1Color,
    '&:hover': {
      backgroundColor: theme.palette.primary1Color,
    },
  },
  radioLabel: {
    height: '24px',
    color: `${theme.palette.textColor}`,
    fontFamily: theme.fonts.fontFamily,
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '24px',
  },
  radio: {
    colorPrimary: `${theme.palette.primary1Color}`,
  },
});

export default styles;

