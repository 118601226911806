const styles = theme => ({
  wrapper: {
    ...theme.containers.wrapper,
  },
  title: {
    whiteSpace: 'normal',
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 4,
  },
  subtitle: {
    fontWeight: 300,
    whiteSpace: 'normal',
    paddingBottom: theme.spacing.unit * 4,
  },
});

export default styles;
