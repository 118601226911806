const styles = theme => ({
  section: {
    marginBottom: theme.spacing.unit * 16,
  },
  container: theme.containers.container,
  paper: theme.containers.paper,
  title: theme.fonts.headline_2,
  questionLabel: {
    ...theme.fonts.headline_3,
    textTransform: 'none',
  },
  dateInput: {
    marginBottom: theme.units.spacing * 5,
  },
  datePickerInput: {
    '& svg': {
      marginRight: '20px',
    },
  },
  question0: {
    marginTop: theme.units.spacing * 4,
  },
  '@media only screen and (min-width: 601px)': {
    customInput: {
      '& label': {
        position: 'absolute',
        left: '-100%',
        textAlign: 'right',
        lineHeight: `${theme.units.spacing * 5}px`,
        paddingRight: `${theme.units.spacing * 2}px`,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: '400',
      },
      '& [class*="DatePickerInput"]': {
        '& label': {
          top: 0,
        },
      },
    },
  },
});

export default styles;
