import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Dialog, Typography } from '@material-ui/core';
import ReactToPrint from '../../utils/ReactToPrint';
import PrintOfferForm from '../../components/PrintOfferForm';
import Button from '../../components/Button';
import PrintOffer from '../../containers/PrintOffer';
import styles from './style';
import { COMPANY } from '../../actions/offer';

class ModalPrintOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: null,
      lastName: null,
      postalCode: this.props.offer.postalCode,
      companyName: null,
      selectTitle: null,
      password: null,
      registration: false,
      street: null,
      houseNr: null,
      city: null,
      isPrinting: false,
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.printOffer = this.printOffer.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.offer.postalCode !== this.props.offer.postalCode) {
      this.setState({
        postalCode: this.props.offer.postalCode,
      });
    }
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
  }

  printButton() {
    return (
      <Typography component="div" align="right" className={this.props.classes.buttonContainer}>
        <Button {...this.props.structure.printDialog.buttons.printButton} />
      </Typography>
    );
  }

  async printOffer(event) {
    event.preventDefault();
    const { offerId: uuid } = this.props.offer;
    const {
      firstName, lastName, companyName, selectTitle, password: secretWord, registration,
    } = this.state;

    const name = selectTitle === COMPANY ? { companyName } : { firstName, lastName };

    let userData = {
      uuid,
      ...name,
      title: selectTitle,
      secretWord,
    };

    if (registration) {
      const { street, houseNr, city, postalCode } = this.state;
      userData = {
        ...userData,
        postalCode,
        street,
        houseNr,
        city,
      };
    }

    await this.props.setCustomerLogin(userData);
    this.setState({ isPrinting: true });
  }

  render() {
    const {
      structure,
      openPrintOfferModal,
      togglePrintOfferModal,
      evbNumber,
      inputFields,
      offer,
      printNow,
    } = this.props;

    const { id } = offer;

    const { isPrinting, registration } = this.state;

    const component = (printNow || isPrinting) ? (
      <Fragment>
        <ReactToPrint
          trigger={() => this.printButton()}
          content={() => this.componentRef}
          closeAfterPrint="true"
          bodyClass="print"
        />
        <PrintOffer id={id} withEVB={registration} hideLink={printNow} ref={el => (this.componentRef = el)} />
      </Fragment>
    ) : (
      <PrintOfferForm
        structure={structure}
        togglePrintOfferModal={togglePrintOfferModal}
        evbNumber={evbNumber}
        inputFields={inputFields}
        onValueChange={this.onValueChange}
        defaultValues={this.state}
        printOffer={this.printOffer}
      />
    );
    return (
      <Fragment>
        {structure && (
          <Dialog
            open={openPrintOfferModal}
            keepMounted
            onClose={togglePrintOfferModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth="lg"
          >
            {component}
          </Dialog>
        )}
      </Fragment>
    );
  }
}

ModalPrintOffer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  structure: PropTypes.objectOf(Object),
  setCustomerLogin: PropTypes.func,
  inputFields: PropTypes.objectOf(Object),
  offer: PropTypes.objectOf(Object).isRequired,
  openPrintOfferModal: PropTypes.bool,
  togglePrintOfferModal: PropTypes.func,
  evbNumber: PropTypes.string,
  printNow: PropTypes.bool,
};

ModalPrintOffer.defaultProps = {
  classes: '',
  structure: undefined,
  inputFields: {},
  setCustomerLogin: () => {},
  openPrintOfferModal: false,
  togglePrintOfferModal: () => {},
  evbNumber: '',
  printNow: false,
};

export default withStyles(styles)(ModalPrintOffer);
