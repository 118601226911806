import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'date-input-polyfill-react';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import history from './history';
import store from './store';
import App from './containers/App/index';
import NotFound from './components/NotFound/index';
import * as serviceWorker from './serviceWorker';
import { getTheme } from './utils/theme';
import './index.css';

const RootComponent = () => (
  <Provider store={store}>
    <ThemeProvider theme={getTheme()}>
      <Router history={history}>
        <Switch>
          <Route exact path="/not-found" component={NotFound} />
          <Route path="/" component={App} />
          <Redirect to="/not-found" />
        </Switch>
      </Router>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(<RootComponent />, document.getElementById('root'));
serviceWorker.unregister();
