import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import CreateUser from '../CreateUser';
import UserSearch from '../UserSearch';
import ShowUserDetails from '../ShowUserDetails';
import EditUserDetails from '../EditUserDetails';
import { requireAuth, allowedToAccessAdminPanel } from '../Authorization';

class UserAdmin extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      screenVariant: 'userSearch',
    };

    this.screenSwitch = this.screenSwitch.bind(this);
    this.onVariantChange = this.onVariantChange.bind(this);
  }

  componentDidUpdate() {
    if (this.state.screenVariant !== this.props.location.state.variant) {
      this.onVariantChange(this.props.location.state.variant);
    }
  }

  onVariantChange(variant) {
    this.props.location.state.variant = variant;
    this.setState({ screenVariant: variant });
  }

  screenSwitch(variant) {
    switch (variant) {
      case 'createUser':
        return <CreateUser variantChange={this.onVariantChange} />;
      case 'showUser':
        return <ShowUserDetails variantChange={this.onVariantChange} />;
      case 'editDetails':
        return <EditUserDetails variantChange={this.onVariantChange} />;
      default:
        return <UserSearch variantChange={this.onVariantChange} />;
    }
  }

  render() {
    return (
      <div className={this.classes.wrapper}>
        <Grid container justify="center" alignContent="center" spacing={0}>
          {this.screenSwitch(this.state.screenVariant)}
        </Grid>
      </div>
    );
  }
}

UserAdmin.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

UserAdmin.defaultProps = {
  classes: '',
};

export default requireAuth(compose(withStyles(styles))(UserAdmin), allowedToAccessAdminPanel, true);
