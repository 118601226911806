import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './style';
import Label from '../Label';

function SelectField({
  name, classes, defaultValue, onChange, options, label, id, setRequired,
}) {
  return (
    <div>
      <Label htmlFor={id} label={label} />
      <select
        name={name}
        className={classes.select}
        defaultValue={defaultValue}
        onChange={evn => onChange(evn.target.value, name)}
        {...(setRequired ? { required: true } : '')}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.key}
          </option>
        ))}
      </select>
    </div>
  );
}

SelectField.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  label: PropTypes.string,
  setRequired: PropTypes.bool,
};

SelectField.defaultProps = {
  name: '',
  label: '',
  classes: '',
  defaultValue: '',
  onChange: null,
  options: [],
  setRequired: undefined,
};

export default withStyles(styles)(SelectField);
