const styles = theme => ({
  head: {
    backgroundColor: theme.palette.background.white,
    fontSize: 24,
    fontWeight: 'unset',
    color: '#000',
    border: '0',
    textAlign: 'center',
  },
  body: {
    fontSize: 12,
    textAlign: 'center',
    borderLeft: '1px solid rgba(204, 204, 204, 1)',
    borderRight: '1px solid rgba(204, 204, 204, 1)',
    borderTop: '0',
    borderBottom: '0',
  },
  root: {
    padding: '4px 16px 4px 16px',
  },
});

export default styles;
