import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { searchUser, getUserDetails } from '../../actions/userDetails';
import styles from './style';
import InputText from '../../components/InputText';
import Notification from '../Notification';

class UserSearch extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      search: '',
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.userList = this.userList.bind(this);
    this.onUserSelect = this.onUserSelect.bind(this);
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
  }

  submitHandler(event) {
    event.preventDefault();
    this.props.searchUser(this.state.search);
  }

  onClick(variant) {
    this.props.variantChange(variant);
  }

  onUserSelect(user) {
    this.props.getUserDetails(user.id);
    this.props.variantChange('showUser');
  }

  userList(items) {
    const mapItem = [];
    Object.keys(items).forEach(key => {
      mapItem.push(<div
        className={this.classes.userListItem}
        key={items[key].id}
        onClick={() => this.onUserSelect(items[key])}
      >
        <div>{`${items[key].firstName} ${items[key].lastName}`}</div>
        <div>{items[key].email}</div>
        <div>{`${items[key].dealership} ${items[key].dealershipName}`}</div>
                   </div>);
    });

    if (mapItem.length) {
      return <div className={this.classes.userList}>{mapItem}</div>;
    }
    return <Typography component="h2">{this.props.structure.noUserFound}</Typography>;
  }

  render() {
    const { structure, classes } = this.props;
    return (
      <Fragment>
        {structure && (
          <Fragment>
            <Grid item xs={12}>
              <Typography align="center" variant="h1" className={classes.title}>
                {structure.header.maintext}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Notification />
              <form onSubmit={event => this.submitHandler(event)}>
                <InputText
                  {...this.props.inputFields.search}
                  {...this.props.structure.fields.search}
                  defaultValue={this.state.search}
                  onChange={this.onValueChange}
                  onLinkClick={() => this.onClick('createUser')}
                />
              </form>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              {this.userList(this.props.searchResult)}
            </Grid>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  notificationCenter: state.notificationCenter,
  searchResult: state.userSearch,
  structure: state.projectStructure.userSearch,
  inputFields: state.projectFields,
});

const mapDispatchToProps = dispatch => ({
  searchUser: search => dispatch(searchUser(search)),
  getUserDetails: id => dispatch(getUserDetails(id)),
});

UserSearch.propTypes = {
  message: PropTypes.string,
  error: PropTypes.bool,
  searchUser: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
  getUserDetails: PropTypes.func,
  structure: PropTypes.objectOf(Object),
};

UserSearch.defaultProps = {
  searchUser: () => {},
  getUserDetails: () => {},
  classes: '',
  message: '',
  error: false,
  structure: undefined,
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(UserSearch);
