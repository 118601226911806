import queryString from 'querystring';
import pick from 'lodash/pick';
import history from '../history';

export const pickNewOfferParams = queryData => pick(
  queryData, 'primaryEmployeeNumber', 'dealership',
  'salesChannel', 'organization',
);

export const getQueryParamsFromLWLRedirect = () => {
  const queryData = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(queryString.parse(history.location.search))) {
    queryData[`${key.replace('?', '')}`] = value;
  }

  if (queryData.dealerEmployeeNumber) {
    queryData.primaryEmployeeNumber = queryData.dealerEmployeeNumber;
  }

  return pickNewOfferParams(queryData);
};
