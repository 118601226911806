import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  withStyles,
  Typography,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core';
import min from 'lodash/min';
import { COMPANY } from '../../actions/offer';
import DatePickerInput from '../../components/DatePickerInput';
import SelectField from '../../components/SelectField';
import InputText from '../../components/InputText';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';
import { getCurrentProjectName } from '../../utils/theme';
import styles from './style';

const CALL_DELAY = 2;

class ModalContactCallCenter extends Component {
  state = {
    selectTitle: '',
    firstName: null,
    lastName: null,
    companyName: null,
    phone: null,
    description: null,
    callCenterTermsAndConditions: false,
    callCenterTimeSlotsCount: 1,
    currentDate: null,
    isSaturday: {
      0: false,
      1: false,
      2: false,
      3: false,
    },
    timeSlotsToSend: [],
  };

  componentDidMount = () => {
    this.setState({
      timeSlotsToSend: [this.getDefaultDate()],
      currentDate: this.getDefaultDate(),
    });
  };

  onValueChange = (value, field) => {
    this.setState({ [field]: value });
  };

  getLastSlot = (currentDate) => {
    if (this.isWeekday(currentDate)) {
      return this.getLastIndexSlot('timeSlotHours');
    }
    return this.getLastIndexSlot('timeSlotHoursSaturday');
  };

  getLastIndexSlot = (fieldName) => {
    const lastItemIndex =
      this.props.structure.fields[fieldName].options.length - 1;
    return this.props.structure.fields[fieldName].options[lastItemIndex];
  };

  getFirstSlot = (currentDate) => {
    if (this.isWeekday(currentDate)) {
      return this.props.structure.fields.timeSlotHours.options[0];
    }
    return this.props.structure.fields.timeSlotHoursSaturday.options[0];
  };

  getCurrentDateTime = () => new Date();

  getFirstPossibleCallDate = (date) => {
    const now = this.getCurrentDateTime();

    if (this.isToday(date)) {
      const twoFullHoursAhead =
        now.getHours() + CALL_DELAY + Math.ceil(now.getMinutes() / 60);
      const latestSlot = this.getLastSlot(now).value;

      if (latestSlot - twoFullHoursAhead > 0) {
        const resultDate = this.getCurrentDateTime();
        resultDate.setHours(twoFullHoursAhead);
        resultDate.setMinutes(0, 0, 0);

        return resultDate;
      }
    }

    const resultDate = new Date(date);
    const nextBusinessDay = date.getDate() + (this.isSaturday(date) ? 2 : 1);
    resultDate.setDate(nextBusinessDay);

    const firstSlotNextBusinessDay = this.getFirstSlot(resultDate).value;
    resultDate.setHours(firstSlotNextBusinessDay);

    return resultDate;
  };

  getDefaultDate = () => {
    const now = this.getCurrentDateTime();

    const defaultDate = this.getFirstPossibleCallDate(now);
    const defaultSlot = this.props.structure.fields.timeSlotHours.defaultValue;

    if (defaultDate.getHours() < defaultSlot) {
      defaultDate.setHours(defaultSlot);
    }

    return defaultDate;
  };

  setTimeSlots = (value, field) => {
    const date = new Date(this.state.timeSlotsToSend[field]);
    const timeSlotsToSendCopy = [...this.state.timeSlotsToSend];

    const currentDate = new Date(value);
    currentDate.setHours(date.getHours());
    currentDate.setMinutes(0, 0, 0);
    timeSlotsToSendCopy[field] = currentDate;
    this.setState({ timeSlotsToSend: timeSlotsToSendCopy });
    this.checkIfSaturday(value, field);
  };

  setHour = (value, field) => {
    const date = new Date(this.state.timeSlotsToSend[field]);
    date.setHours(parseInt(value, 0));
    date.setMinutes(0, 0, 0);
    const timeSlotsToSendCopy = [...this.state.timeSlotsToSend];
    timeSlotsToSendCopy[field] = date;
    this.setState({ timeSlotsToSend: timeSlotsToSendCopy });
  };

  isToday = (date) => {
    const today = this.getCurrentDateTime();
    const result =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    return result;
  };

  isSaturday = (date) => {
    const day = new Date(date).getDay();
    return day === 6;
  };

  isWeekday = (date) => {
    const day = new Date(date).getDay();
    return day !== 0 && day !== 7;
  };

  checkIfSaturday = (day, field) => {
    const weekDay = new Date(day).getDay();
    if (weekDay === 6) {
      this.setState({
        isSaturday: {
          ...this.state.isSaturday,
          [field]: true,
        },
      });
    } else {
      this.setState({
        isSaturday: {
          ...this.state.isSaturday,
          [field]: false,
        },
      });
    }
  };

  addTimeSlot = () => {
    if (this.state.callCenterTimeSlotsCount < 4) {
      this.setState(
        { callCenterTimeSlotsCount: this.state.callCenterTimeSlotsCount + 1 },
        () => {
          const timeSlotsToSendCopy = [...this.state.timeSlotsToSend];
          timeSlotsToSendCopy[
            this.state.callCenterTimeSlotsCount - 1
          ] = this.getDefaultDate();

          this.setState({ timeSlotsToSend: timeSlotsToSendCopy });
        },
      );
    }
  };

  callCenterContact = async (event) => {
    event.preventDefault();

    if (
      this.state.callCenterTermsAndConditions === true &&
      this.state.selectTitle !== ''
    ) {
      let name = {};
      if (this.state.selectTitle === COMPANY) {
        name = {
          companyName: this.state.companyName,
        };
      } else {
        name = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
        };
      }

      const slots = [];
      this.state.timeSlotsToSend.map(slot =>
        slots.push(`${slot.toLocaleDateString()} ${slot.getHours()}:00 - ${slot.getHours() +
            1}:00`));

      const description = this.state.description ? `${this.state.description} | ${slots.toString()}` : slots.toString();
      const minDate = min(this.state.timeSlotsToSend);

      const userData = {
        title: this.state.selectTitle,
        ...name,
        phoneNumber: this.state.phone,
        description,
        contactDate: minDate.toJSON(),
        acceptedTermsAndConditions: this.state.callCenterTermsAndConditions,
      };
      if (this.props.offerId) {
        userData.id = this.props.offerId;
      }

      await this.props.contactLeadManagement(userData);
      this.reset();
      this.props.toggleContactLeadManagementModal();
    }
  };

  cancel = async () => {
    this.reset();
    this.props.toggleContactLeadManagementModal();
  };

  reset = () => {
    this.setState({
      callCenterTermsAndConditions: false,
    });
  };

  options(fieldNumber) {
    let result = this.state.isSaturday[fieldNumber]
      ? this.props.structure.fields.timeSlotHoursSaturday.options
      : this.props.structure.fields.timeSlotHours.options;
    const selectedDate = this.state.timeSlotsToSend[fieldNumber];
    if (selectedDate) {
      const firstPossibleSlot = this.getFirstPossibleCallDate(selectedDate).getHours();
      result = result.filter(slot => slot.value >= firstPossibleSlot);
    }
    return result;
  }

  defaultSlot(fieldNumber) {
    const selectedDate = this.state.timeSlotsToSend[fieldNumber];
    const defaultSlot = this.props.structure.fields.timeSlotHours.defaultValue;
    const selectedSlot = selectedDate ? selectedDate.getHours() : defaultSlot;

    return selectedSlot.toString();
  }

  selectedDate(fieldNumber) {
    return this.state.timeSlotsToSend[fieldNumber] || this.getDefaultDate();
  }

  timeslots() {
    const result = [];
    /* eslint-disable no-plusplus */
    for (let i = 0; i < this.state.callCenterTimeSlotsCount; i++) {
      result.push(<Grid
        container
        justify="center"
        alignContent="center"
        spacing={2}
        key={`${i}`}
      >
        <Grid item md={4} sm={6} xs={12} align="center">
          <DatePickerInput
            key={i}
            {...this.props.structure.fields.timeslot}
            filterDate={this.isWeekday}
            onChange={value => this.setTimeSlots(value, i)}
            defaultValue={this.selectedDate(i)}
            minDate={this.state.currentDate}
            yearDropdownItemNumber={8}
            isOnModal
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <SelectField
            key={`s${i}`}
            {...this.props.structure.fields.timeSlotHours}
            onChange={value => this.setHour(value, i)}
            options={this.options(i)}
            required
            defaultValue={this.defaultSlot(i)}
          />
        </Grid>
                  </Grid>);
    }
    return result;
  }

  render() {
    const {
      structure,
      inputFields,
      classes,
      openContactLeadManagementModal,
      toggleContactLeadManagementModal,
    } = this.props;

    return (
      <Fragment>
        {structure && (
          <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openContactLeadManagementModal}
            onClose={toggleContactLeadManagementModal}
            maxWidth="lg"
          >
            <DialogTitle align="center">{structure.title}</DialogTitle>
            <form onSubmit={this.callCenterContact}>
              <DialogContent>
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  spacing={0}
                  className={classes.dialogContentTitleContainer}
                >
                  <Typography variant="h4" align="center">
                    {structure.text}
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  spacing={0}
                >
                  <Grid item sm={10} xs={12}>
                    <Grid
                      container
                      justify="flex-start"
                      alignContent="center"
                      spacing={2}
                    >
                      <Grid item sm={4} xs={12}>
                        <SelectField
                          {...inputFields.selectTitle}
                          onChange={this.onValueChange}
                          defaultValue={this.state.selectTitle}
                          setRequired
                        />
                      </Grid>
                      {this.state.selectTitle === COMPANY && (
                        <Grid item sm={8} xs={12}>
                          <InputText
                            {...inputFields.companyName}
                            onChange={this.onValueChange}
                            defaultValue={this.state.companyName}
                          />
                        </Grid>
                      )}
                      {this.state.selectTitle !== COMPANY && (
                        <Fragment>
                          <Grid item sm={4} xs={12}>
                            <InputText
                              {...inputFields.firstName}
                              onChange={this.onValueChange}
                              defaultValue={this.state.firstName}
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <InputText
                              {...inputFields.lastName}
                              onChange={this.onValueChange}
                              defaultValue={this.state.lastName}
                            />
                          </Grid>
                        </Fragment>
                      )}
                      <Grid
                        container
                        justify="center"
                        alignContent="center"
                        spacing={2}
                      >
                        <Grid item xs={4}>
                          <InputText
                            {...inputFields.phone}
                            onChange={this.onValueChange}
                            defaultValue={this.state.phone}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justify="center"
                        alignContent="center"
                        spacing={2}
                      >
                        <Grid item xs={4}>
                          <TextArea
                            {...inputFields.description}
                            classname={classes.inputContainer}
                            multiline
                            rows={2}
                            rowsMax={2}
                            inputLimit={200}
                            helperText={inputFields.description.helperText}
                            defaultValue={this.state.description}
                            onChange={this.onValueChange}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid
                          container
                          justify="center"
                          alignContent="center"
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            <p className={classes.modalText}>
                              {inputFields.timeSlotHoursTitle}
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            {this.timeslots()}
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              {...structure.buttons.timeSlots}
                              onClick={this.addTimeSlot}
                              myWidth="100%"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.termsAndConditions}
                              onChange={evn =>
                                this.onValueChange(
                                  evn.target.checked,
                                  structure.fields.termsAndConditions.name,
                                )
                              }
                              value={this.state.callCenterTermsAndConditions}
                              classes={{
                                switchBase: classes.colorSwitchBase,
                                checked: classes.colorChecked,
                                bar: classes.colorBar,
                              }}
                            />
                          }
                          label={structure.fields.termsAndConditions.label}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          item
                          xs={12}
                          className={classes.termsAndConditionsTextContainer}
                        >
                          {structure.fields.termsAndConditionsText[
                            getCurrentProjectName()
                          ].map(text => (
                            <Typography key={text} component="p" paragraph>
                              {text}
                            </Typography>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  justify="space-around"
                  alignContent="center"
                  spacing={0}
                  className={classes.dialogActionsContainer}
                >
                  <Button {...structure.buttons.cancel} onClick={this.cancel} />
                  <Button
                    {...structure.buttons.sendCallRequest}
                    disabled={!this.state.callCenterTermsAndConditions}
                  />
                </Grid>
              </DialogActions>
            </form>
          </Dialog>
        )}
      </Fragment>
    );
  }
}

ModalContactCallCenter.propTypes = {
  message: PropTypes.string,
  error: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.any),
  state: PropTypes.objectOf(PropTypes.any),
  offerId: PropTypes.string,
  contactLeadManagement: PropTypes.func.isRequired,
  openContactLeadManagementModal: PropTypes.bool.isRequired,
  toggleContactLeadManagementModal: PropTypes.func.isRequired,
  structure: PropTypes.objectOf(PropTypes.any).isRequired,
  inputFields: PropTypes.objectOf(PropTypes.any).isRequired,
  uppercase: PropTypes.string,
};

ModalContactCallCenter.defaultProps = {
  classes: '',
  message: '',
  error: false,
  state: {},
  offerId: '',
  uppercase: '',
};

export default withStyles(styles)(ModalContactCallCenter);
