import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid, Typography, Paper, withStyles, DialogContent, Dialog, CircularProgress, Box } from '@material-ui/core';
import moment from 'moment';
import { basicTariffData, basicTariffDraft, setSFEstimated, setIsCompany, setPostalCode, setSubmitStep1DialogOpen, resetOfferToInitialStep, handlePersonalDataSnackbarError } from '../../../actions/offer';
import { setCorrectedZipCode } from '../../../actions/settings';
import RadioInputBlock from '../../../components/RadioInputBlock';
import DatePickerInput from '../../../components/DatePickerInput';
import InputText from '../../../components/InputText';
import Button from '../../../components/Button';
import SwitchInput from '../../../components/SwitchInput';
import styles from './style';
import { openSnackbar, SnackbarKind } from '../../../actions/snackbar';
import { saveOfferDataInFrontendStorage, setOfferDataInFrontendStorage } from '../../../actions/offerStorage';
import NumberFormat from '../../../components/NumberFormat';
import {
  MILAGE_VALIDATION,
  MILAGE_VALIDATION_WITH_DATE,
  checkValidations,
} from '../../../validators/validators';

const isMileageValid = (milage, isVehiclePreOwned) => (isVehiclePreOwned ?
  checkValidations(MILAGE_VALIDATION_WITH_DATE, milage) :
  checkValidations(MILAGE_VALIDATION, milage));

const isMileageInOfferMissing = offerProps => (!offerProps.vehicleMileage && offerProps.vehicleMileage !== 0);
const isVehicleInOfferPreOwned = offerProps => !!offerProps.vehiclePreOwned;

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.allowedMaxBirthday = moment().subtract(18, 'years');
    this.state = {
      isCompany:
        this.props.offer.customerIsCompany !== undefined
          ? this.props.offer.customerIsCompany
          : false,
      vehicleUserConditions: this.props.offer.contractVehicleUserConditions
        ? this.props.offer.contractVehicleUserConditions + ""
        : null,
      spouseBirthdate:
        this.props.offer.contractVehicleUsersSpouseBirthdate ||
        this.allowedMaxBirthday,
      youngestDriverAge: this.props.offer.contractVehicleUsersYoungestDriverAge
        ? this.props.offer.contractVehicleUsersYoungestDriverAge
        : null,
      postalCode:
        this.props.correctedZipCode ||
        this.props.offer.customerPostalCode ||
        null,
      birthday: this.props.offer.customerBirthday || this.allowedMaxBirthday,
      differingOwner:
        this.props.offer.contractDifferingOwner !== undefined
          ? this.props.offer.contractDifferingOwner + ""
          : null,
      firstTimeInsuresThisCar:
        this.props.offer.customerFirstTimeInsuresThisCar !== undefined
          ? this.props.offer.customerFirstTimeInsuresThisCar + ""
          : null,
      /* eslint-disable */
      ["firstTimeInsuresThisCar.hadPreviousInsurer"]:
        this.props.offer.customerHadPreviousInsurer !== undefined
          ? this.props.offer.customerHadPreviousInsurer + ""
          : null,
      ["firstTimeInsuresThisCar.hadPreviousInsurer.coverBonus"]:
        this.props.offer.customerCoverBonusFromPreviousInsurance !== undefined
          ? this.props.offer.customerCoverBonusFromPreviousInsurance + ""
          : null,
      ["firstTimeInsuresThisCar.hadPreviousInsurer.inheritBonus"]:
        this.props.offer.contractSfrInheritFromOther !== undefined
          ? this.props.offer.contractSfrInheritFromOther + ""
          : null,
      /* eslint-enable */
      liability: this.props.offer.contractSfClassLiability + "",
      vollkasko: this.props.offer.contractSfClassVollkasko + "",
      contractVehicleUse: this.props.offer.contractVehicleTypeOfUsage || null,
      vehicleMileage: this.props.offer.vehicleMileage || 0,
    };

    this.isStructureReady = this.isStructureReady.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.storeBasicTariffData = this.storeBasicTariffData.bind(this);
    this.storeBasicTariffDraft = this.storeBasicTariffDraft.bind(this);
    this.calculateSF = this.calculateSF.bind(this);
    this.otherDrivers = this.otherDrivers.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.isCompany && this.state.isCompany) {
      this.setState({
        birthday: '1900-01-01',
        vehicleUserConditions: '3',
        youngestDriverAge: null,
      });
    }
    this.onIsCompanyChange(prevState.isCompany);

    if (!prevProps.offer.personalDataUpdated && this.props.offer.personalDataUpdated) {
      this.storeBasicTariffDraft();
    }
  };

  componentWillUnmount() {
    this.props.setCorrectedZipCode(null);
  }

  onIsCompanyChange = (wasCompany) => {
    if (wasCompany && !this.state.isCompany && this.props.offerAnswers.setSFKlasse === 'input') {
      this.onFieldValueChangeSetInFrontendStorage('', 'setSFKlasse');
    }
    if (this.state.isCompany && this.props.offerAnswers.setSFKlasse !== 'input') {
      this.onFieldValueChangeSetInFrontendStorage('input', 'setSFKlasse');
    }
  }

  onValueChange = async (value, field) => {
    Object.keys(this.state).forEach((key) => {
      if (key !== field && key.startsWith(field)) {
        this.setState({ [key]: undefined });
      }
    });
    this.setState({ [field]: value });
    if (field === 'postalCode') {
      this.props.setPostalCode(value);
    }
    if (field === 'isCompany') {
      this.props.setIsCompany(value);
    }
  }

  onFieldValueChangeSetInFrontendStorage = (value, field) => {
    this.props.setOfferDataInFrontendStorage({ [field]: value });
  }

  isStructureReady() {
    return this.props.structure !== undefined;
  }

  checkValueSFClass = () => {
    const maxSFValue = this.props.values.maxValueSFClass;
    if (this.state.liability > maxSFValue) {
      this.setState({ liability: maxSFValue });
    }
    if (this.state.vollkasko > maxSFValue) {
      this.setState({ vollkasko: maxSFValue });
    }
  }

  getValuesForSF(liability, vollkasko) {
    return {
      liability,
      vollkasko,
    };
  }

  parseToPositive = value => (value < 0 ? Math.abs(value) : value);

  isStrNumber = (str) => {
    const value = str ? str.toString().replace(',', '.') : str;
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  calculateSF() {
    const {
      vollkasko,
      liability,
      birthday,
      firstTimeInsuresThisCar } = this.state;

    const { setSFKlasse, hadAccidents } = this.props.offerAnswers;
    const hadPreviousInsurer = this.state['firstTimeInsuresThisCar.hadPreviousInsurer'];
    const coverBonus = this.state['firstTimeInsuresThisCar.hadPreviousInsurer.coverBonus'];
    const inheritBonus = this.state['firstTimeInsuresThisCar.hadPreviousInsurer.inheritBonus'];

    if(firstTimeInsuresThisCar === 'true' && (
      (hadPreviousInsurer === 'true' && coverBonus === 'false') ||
       (hadPreviousInsurer === 'false' && inheritBonus === 'false'))) {
      return this.getValuesForSF(0.5, 0.5);
    }


    if(inheritBonus === 'true') {
      const liabilityToNumber = this.isStrNumber(liability) ? Number(liability.toString().replace(',', '.')) : 0;
      const vollkaskoToNumber = this.isStrNumber(vollkasko) ? Number(vollkasko.toString().replace(',', '.')) : liabilityToNumber;
      return this.getValuesForSF(liabilityToNumber, vollkaskoToNumber);
    }

    if(coverBonus === 'true' || firstTimeInsuresThisCar === 'false') {
      if (setSFKlasse === 'input') {
        const liabilityToNumber = this.isStrNumber(liability) ? Number(liability.toString().replace(',', '.')) : 0;
        const vollkaskoToNumber = this.isStrNumber(vollkasko) ? Number(vollkasko.toString().replace(',', '.')) : liabilityToNumber;
        return this.getValuesForSF(liabilityToNumber, vollkaskoToNumber);
      }
      if (setSFKlasse === 'calculate') {
        const yearMaxVal = this.props.values.maxValueSFClass;
        const now = moment();
        const years = now.diff(birthday, 'years');

        if (hadAccidents === 'no') {
          const yearVal = years - 23;
          if (yearVal > yearMaxVal) {
            return this.getValuesForSF(yearMaxVal, yearMaxVal);
          } else if (yearVal > 0) {
            return this.getValuesForSF(yearVal, yearVal);
          }
        }

        if (hadAccidents === 'yes') {
          const yearVal = years - 30;
          if (yearVal > yearMaxVal) {
            return this.getValuesForSF(yearMaxVal, yearMaxVal);
          } else if (yearVal > 0) {
            return this.getValuesForSF(yearVal, yearVal);
          }
        }
      }
    }
    return {
      liability: 0,
      vollkasko: 0,
    };
  }

  otherDrivers() {
    if (this.state.vehicleUserConditions === '2') {
      return {
        spouseBirthdate: this.state.spouseBirthdate,
      };
    }
    if (this.state.vehicleUserConditions === '3' && !this.state.isCompany) {
      return {
        youngestDriverAge: this.state.youngestDriverAge,
      };
    }
  }

  onSubmitUserAnswersToOfferQuestions = () => {
    const offerAnswersToSave = {
      hadAccidents: this.props.offerAnswers.setSFKlasse !== 'input' ? this.props.offerAnswers.hadAccidents : '',
    };
    this.props.saveAnswersToOfferQuestionsInFrontendStorage(offerAnswersToSave);
  }

  submitHandler(event) {
    event.preventDefault();
    this.storeBasicTariffData()
  }

  storeBasicTariffDraft() {
    this.storeBasicTariffData(true)
  }

  storeBasicTariffData(isStoreDraftOffer = false) {
    this.onSubmitUserAnswersToOfferQuestions();

    if (isStoreDraftOffer || this.validate()) {
      if (this.props.offerAnswers.setSFKlasse === 'calculate') {
        this.props.setSFEstimated();
      }

      const differingOwnerValue = this.state.differingOwner;
      const inheritFromOtherValue = this.state['firstTimeInsuresThisCar.hadPreviousInsurer.inheritBonus'];
      const firstTimeInsuresThisCarValue = this.state.firstTimeInsuresThisCar;
      const hadPreviousInsurerValue = this.state['firstTimeInsuresThisCar.hadPreviousInsurer'];
      const hadPreviousInsurerCoverBonusValue = this.state['firstTimeInsuresThisCar.hadPreviousInsurer.coverBonus'];

      const handleNull = (value) => value === "null" ? null : value;
      const handleBooleanDraftValue = (value) => isStoreDraftOffer ? handleNull(value) : value === 'true';

      const contract = {
        differingOwner: handleBooleanDraftValue(differingOwnerValue),
        contractVehicleUse: this.state.contractVehicleUse,
        ...this.otherDrivers(),
        vehicleUserConditions: parseInt(this.state.vehicleUserConditions, 10),
        inheritFromOther: handleBooleanDraftValue(inheritFromOtherValue),
        sfClass: {
          inheritFromOther: handleBooleanDraftValue(inheritFromOtherValue),
          ...this.calculateSF(),
        },
        hadAccidents: this.props.offerAnswers.hadAccidents,
        setSFKlasse: this.props.offerAnswers.setSFKlasse,
      };

      if (isMileageInOfferMissing(this.props.offer)) {
        const adaptedMileage = this.state.vehicleMileage * 1000;
        contract.vehicleMileage = adaptedMileage;
      }

      const basicStoreDataFunc = isStoreDraftOffer ? this.props.basicTariffDraft : this.props.basicTariffData;
      basicStoreDataFunc(
        this.props.offer.id,
        {
          birthday: moment(this.state.birthday).format('YYYY-MM-DD'),
          driverLicenseDate: '1989-02-03',
          hadPreviousInsurer: handleBooleanDraftValue(hadPreviousInsurerValue),
          postalCode: this.state.postalCode,
          isCompany: this.state.isCompany,
          firstTimeInsuresThisCar: handleBooleanDraftValue(firstTimeInsuresThisCarValue),
          coverBonusFromPreviousInsurance: handleBooleanDraftValue(hadPreviousInsurerCoverBonusValue),
        },
        contract
      );
      if (!isStoreDraftOffer) {
        this.props.setSubmitStep1DialogOpen(true);
      }
    }
  }

  validate() {
    if (this.submitButtonDisabled()) {
      this.props.setErrorHandling(this.props.structure.header.validationFieldsReqired);
      return false;
    }
    if (this.state.vehicleUserConditions === '3' && !this.state.isCompany) {
      const bd = moment(this.state.birthday);
      if (moment().diff(moment(bd, 'YYYYMMDD'), 'years') < this.state.youngestDriverAge) {
        this.props.setErrorHandling(this.props.structure.header.validationAge);
        return false;
      }
    }

    if (isMileageInOfferMissing(this.props.offer)) {
      if (!isMileageValid(this.state.vehicleMileage, isVehicleInOfferPreOwned(this.props.offer))) {
        return false;
      }
    }
    return true;
  }

  submitButtonDisabled = () => {
    if (isMileageInOfferMissing(this.props.offer)) {
      if (!isMileageValid(this.state.vehicleMileage, isVehicleInOfferPreOwned(this.props.offer))) {
        return true;
      }
    }

    const {
      vehicleUserConditions,
      postalCode,
      differingOwner,
      liability,
      firstTimeInsuresThisCar,
      contractVehicleUse,
    } = this.state;

    const { setSFKlasse, hadAccidents } = this.props.offerAnswers;

    const coverBonus = this.state['firstTimeInsuresThisCar.hadPreviousInsurer.coverBonus'];
    const inheritBonus = this.state['firstTimeInsuresThisCar.hadPreviousInsurer.inheritBonus'];

    const valuesRequired = [
      vehicleUserConditions,
      postalCode,
      differingOwner,
      firstTimeInsuresThisCar,
    ];

    if (!contractVehicleUse) return true;
    if (valuesRequired.some(el => !el || el === 'null')) return true;

    const bonusValuesRequired = [
      coverBonus,
      inheritBonus,
    ];

    if (firstTimeInsuresThisCar === 'true') {
      if (bonusValuesRequired.every(el => !el)) return true;
    }

    const shouldDetermineClass = coverBonus === 'true' || firstTimeInsuresThisCar === 'false';

    if (shouldDetermineClass) {
      if (!setSFKlasse) return true;
      if (setSFKlasse === 'calculate' && !hadAccidents) return true;
      if (setSFKlasse === 'input' && !liability) return true;
    }

    return false;
  }

  render() {
    const vehicleUserConditionsOptions = this.props.structure.structure.question1.fields
      .vehicleUserConditions;
    const optionNoRestrictionArr = vehicleUserConditionsOptions.options.filter(
      item => item.value === '3',
    );
    let optionNoRestriction = { ...vehicleUserConditionsOptions };
    optionNoRestriction.options = optionNoRestrictionArr;


    const collectBonus = (this.state['firstTimeInsuresThisCar.hadPreviousInsurer.coverBonus'] ===
      this.props.structure.structure.question3_3_1.fields.coverBonus.options[0].value) ||
      (this.state.firstTimeInsuresThisCar ===
      this.props.structure.structure.question3_1.fields.firstTimeInsuresThisCar.options[1].value);

    const showHadPreviousInsurer =
      this.state.firstTimeInsuresThisCar ===
      this.props.structure.structure.question3_1.fields.firstTimeInsuresThisCar.options[0].value;

    const showCoverBonus = showHadPreviousInsurer &&
      this.state['firstTimeInsuresThisCar.hadPreviousInsurer'] ===
      this.props.structure.structure.question3_2.fields.hadPreviousInsurer.options[0].value;

    const showInheritBonus = showHadPreviousInsurer &&
      this.state['firstTimeInsuresThisCar.hadPreviousInsurer'] ===
      this.props.structure.structure.question3_2.fields.hadPreviousInsurer.options[1].value;

    const setCustomValidityForInputText = (inputText, errorMessage) => {
      if (inputText instanceof InputText) {
        const htmlInput = ReactDOM.findDOMNode(inputText).querySelector('input');
        htmlInput.setAttribute('oninvalid', `this.setCustomValidity('${errorMessage}')`);
        htmlInput.setAttribute('oninput', "this.setCustomValidity('')");
      }
    };

    return (
      <Fragment>
        {/* <Notification /> */}
        {this.isStructureReady() && (
          <form onSubmit={event => this.submitHandler(event)}>
            <Grid container justify="center" alignContent="center" spacing={0}>
              <Paper
                className={`${this.props.classes.paper} ${this.props.classes.customInput}`}
              >
                {isMileageInOfferMissing(this.props.offer) ? (
                  <section className={this.props.classes.section}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <p>
                          {
                            this.props.structure.structure.migration_question
                              .fields.vehicleMileage.label
                          }
                        </p>
                        <NumberFormat
                          {...this.props.structure.structure.migration_question
                            .fields.vehicleMileage}
                          label=""
                          meta={{
                            error: !isMileageValid(
                              this.state.vehicleMileage,
                              isVehicleInOfferPreOwned(this.props.offer)
                            )
                          }}
                          input={{
                            value: this.state.vehicleMileage,
                            onChange: value =>
                              this.onValueChange(
                                value,
                                this.props.structure.structure
                                  .migration_question.fields.vehicleMileage.name
                              )
                          }}
                        />
                      </Grid>
                    </Grid>
                  </section>
                ) : null}

                {/* Question 0 */}
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  spacing={2}
                  className={this.classes.question0}
                >
                  <Grid item xs={12}>
                    <SwitchInput
                      defaultValue={this.state.isCompany}
                      {...this.props.structure.structure.question0.fields
                        .isCompany}
                      onChange={(value, field) =>
                        this.onValueChange(value, field)
                      }
                    />
                  </Grid>
                </Grid>
                {/* Question 1 */}
                <section className={this.props.classes.section}>
                  <Typography align="center" variant="h5">
                    {this.props.structure.structure.question1.title}
                  </Typography>
                  <Typography align="center" variant="h2">
                    {this.props.structure.structure.question1.text}
                  </Typography>
                  <RadioInputBlock
                    {...(this.state.isCompany
                      ? optionNoRestriction
                      : vehicleUserConditionsOptions)}
                    defaultValue={this.state.vehicleUserConditions}
                    onChange={(value, field) =>
                      this.onValueChange(value, field)
                    }
                    myWidth="224px"
                    disabled={this.state.isCompany}
                  />
                  <Grid
                    container
                    justify="flex-start"
                    alignContent="center"
                    spacing={2}
                  >
                    <Grid item sm={5} xs={false} />
                    <Grid item sm={3} xs={9}>
                      {this.state.vehicleUserConditions ===
                        this.props.structure.structure.question1.fields
                          .vehicleUserConditions.options[1].value && (
                        <div className={this.classes.datePickerInput}>
                          <DatePickerInput
                            {...this.props.structure.structure.question1.fields
                              .spouseBirthdate}
                            onChange={(value, field) =>
                              this.onValueChange(value, field)
                            }
                            defaultValue={this.state.spouseBirthdate}
                            maxDate={this.allowedMaxBirthday}
                            yearDropdownItemNumber={100}
                            myWidth="186px"
                          />
                        </div>
                      )}
                      {this.state.vehicleUserConditions ===
                        this.props.structure.structure.question1.fields
                          .vehicleUserConditions.options[2].value &&
                        !this.state.isCompany && (
                          <InputText
                            {...this.props.structure.structure.question1.fields
                              .youngestDriverAge}
                            value={this.state.youngestDriverAge}
                            onChange={(value, field) =>
                              this.onValueChange(
                                this.parseToPositive(value),
                                field
                              )
                            }
                          />
                        )}
                    </Grid>
                  </Grid>
                </section>
                {/* Question 1a */}

                <section className={this.props.classes.section}>
                  <Typography align="center" variant="h2">
                    {this.props.structure.structure.question1a.text}
                  </Typography>
                  <RadioInputBlock
                    {...this.props.structure.structure.question1a.fields
                      .contractVehicleUse}
                    defaultValue={this.state.contractVehicleUse}
                    onChange={(value, field) =>
                      this.onValueChange(value, field)
                    }
                    myWidth="245px"
                  />
                </section>

                {/* Question 2 */}
                <section className={this.props.classes.section}>
                  <Typography align="center" variant="h5">
                    {this.props.structure.structure.question2.title}
                  </Typography>
                  <Typography align="center" variant="h2">
                    {this.props.structure.structure.question2.text}
                  </Typography>
                  <Grid container direction="column" alignItems="center">
                    <Grid item sm={5} xs={false} />
                    <Grid item>
                      <InputText
                        {...this.props.structure.structure.question2.fields
                          .postalCode}
                        onChange={(value, field) =>
                          this.onValueChange(value, field)
                        }
                        defaultValue={this.state.postalCode}
                      />
                    </Grid>
                    {!this.state.isCompany && (
                      <Grid item className={this.classes.dateInput}>
                        <div className={this.classes.datePickerInput}>
                          <DatePickerInput
                            {...this.props.structure.structure.question2.fields
                              .birthday}
                            onChange={(value, field) =>
                              this.onValueChange(value, field)
                            }
                            defaultValue={this.state.birthday}
                            maxDate={this.allowedMaxBirthday}
                            yearDropdownItemNumber={100}
                            myWidth="186px"
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                  <div className={this.props.classes.radioInputBlock}>
                    <RadioInputBlock
                      {...this.props.structure.structure.question2.fields
                        .differingOwner}
                      defaultValue={this.state.differingOwner}
                      onChange={(value, field) =>
                        this.onValueChange(value, field)
                      }
                      myPadding="150px"
                    />
                  </div>
                </section>
                {/* Question 3.1 */}
                <section className={this.props.classes.section}>
                  <Typography align="center" variant="h5">
                    {this.props.structure.structure.question3_1.title}
                  </Typography>
                  <Typography align="center" variant="h2">
                    {this.props.structure.structure.question3_1.text}
                  </Typography>
                  <RadioInputBlock
                    {...this.props.structure.structure.question3_1.fields
                      .firstTimeInsuresThisCar}
                    defaultValue={this.state.firstTimeInsuresThisCar}
                    onChange={(value, field) =>
                      this.onValueChange(value, field)
                    }
                  />
                </section>
                {/* Question 3.2 */}
                {showHadPreviousInsurer && (
                  <section className={this.props.classes.section}>
                    <Typography align="center" variant="h2">
                      {this.props.structure.structure.question3_2.text}
                    </Typography>
                    <RadioInputBlock
                      {...this.props.structure.structure.question3_2.fields
                        .hadPreviousInsurer}
                      defaultValue={
                        this.state["firstTimeInsuresThisCar.hadPreviousInsurer"]
                      }
                      onChange={(value, field) =>
                        this.onValueChange(value, field)
                      }
                    />
                  </section>
                )}
                {/* Question 3.3.1 */}
                {showCoverBonus && (
                  <section className={this.props.classes.section}>
                    <Typography align="center" variant="h2">
                      {this.props.structure.structure.question3_3_1.text}
                    </Typography>
                    <RadioInputBlock
                      {...this.props.structure.structure.question3_3_1.fields
                        .coverBonus}
                      defaultValue={
                        this.state[
                          "firstTimeInsuresThisCar.hadPreviousInsurer.coverBonus"
                        ]
                      }
                      onChange={(value, field) =>
                        this.onValueChange(value, field)
                      }
                    />
                  </section>
                )}
                {/* Question 3.3.2 */}
                {showInheritBonus && (
                  <section className={this.props.classes.section}>
                    <Typography align="center" variant="h2">
                      {this.props.structure.structure.question3_3_2.text}
                    </Typography>
                    <RadioInputBlock
                      {...this.props.structure.structure.question3_3_2.fields
                        .inheritBonus}
                      defaultValue={
                        this.state[
                          "firstTimeInsuresThisCar.hadPreviousInsurer.inheritBonus"
                        ]
                      }
                      onChange={(value, field) =>
                        this.onValueChange(value, field)
                      }
                    />
                  </section>
                )}
                {/* Question 4 */}
                {collectBonus && (
                  <section className={this.props.classes.section}>
                    <section className={this.props.classes.section}>
                      <Typography align="center" variant="h5">
                        {this.props.structure.structure.question4.title}
                      </Typography>
                      <Typography align="center" variant="h2">
                        {this.props.structure.structure.question4.text}
                      </Typography>
                      {this.state.isCompany ? (
                        <RadioInputBlock
                          {...this.props.structure.structure
                            .question4_only_input.fields.setSFKlasse}
                          defaultValue={this.props.offerAnswers.setSFKlasse}
                          onChange={(value, field) => {}}
                        />
                      ) : (
                        <RadioInputBlock
                          {...this.props.structure.structure.question4.fields
                            .setSFKlasse}
                          defaultValue={this.props.offerAnswers.setSFKlasse}
                          onChange={(value, field) =>
                            this.onFieldValueChangeSetInFrontendStorage(
                              value,
                              field
                            )
                          }
                        />
                      )}
                    </section>
                    {/* Question 5 */}
                    {this.props.offerAnswers.setSFKlasse ===
                      this.props.structure.structure.question4.fields
                        .setSFKlasse.options[0].value && (
                      <section className={this.props.classes.section}>
                        <Typography align="center" variant="h5">
                          {this.props.structure.structure.question5.title}
                        </Typography>
                        <Typography align="center" variant="h2">
                          {this.props.structure.structure.question5.text}
                        </Typography>
                        <RadioInputBlock
                          {...this.props.structure.structure.question5.fields
                            .hadAccidents}
                          defaultValue={this.props.offerAnswers.hadAccidents}
                          onChange={(value, field) =>
                            this.onFieldValueChangeSetInFrontendStorage(
                              value,
                              field
                            )
                          }
                        />
                      </section>
                    )}
                    {this.props.offerAnswers.setSFKlasse ===
                      this.props.structure.structure.question4.fields
                        .setSFKlasse.options[1].value && (
                      <section className={this.props.classes.section}>
                        <Typography align="center" variant="h5">
                          {this.props.structure.structure.question6.title}
                        </Typography>
                        <Typography align="center" variant="h2">
                          {this.props.structure.structure.question6.text}
                        </Typography>
                        <Grid container direction="column" alignItems="center">
                          <Grid item sm={5} xs={false} />
                          <Grid item>
                            <InputText
                              {...this.props.structure.structure.question6
                                .fields.liability}
                              onChange={(value, field) =>
                                this.onValueChange(
                                  this.parseToPositive(value),
                                  field
                                )
                              }
                              value={this.state.liability}
                              onBlur={this.checkValueSFClass}
                              ref={component =>
                                setCustomValidityForInputText(
                                  component,
                                  this.props.structure.structure.question6
                                    .fields.liability.validationError
                                )
                              }
                            />
                          </Grid>
                          <Grid item>
                            <InputText
                              {...this.props.structure.structure.question6
                                .fields.vollkasko}
                              onChange={(value, field) =>
                                this.onValueChange(
                                  this.parseToPositive(value),
                                  field
                                )
                              }
                              value={this.state.vollkasko}
                              onBlur={this.checkValueSFClass}
                            />
                          </Grid>
                        </Grid>
                      </section>
                    )}
                  </section>
                )}
                {/* Question 61 */}
                {this.state[
                  "firstTimeInsuresThisCar.hadPreviousInsurer.inheritBonus"
                ] ===
                  this.props.structure.structure.question3_3_2.fields
                    .inheritBonus.options[0].value && (
                  <section className={this.props.classes.section}>
                    <Typography align="center" variant="h5">
                      {this.props.structure.structure.question6_1title}
                    </Typography>
                    <Typography align="center" variant="h2">
                      {this.props.structure.structure.question6_1.text}
                    </Typography>
                    <Grid container direction="column" alignItems="center">
                      <Grid item sm={5} xs={false} />
                      <Grid item>
                        <InputText
                          {...this.props.structure.structure.question6_1.fields
                            .liability}
                          onChange={(value, field) =>
                            this.onValueChange(
                              this.parseToPositive(value),
                              field
                            )
                          }
                          value={this.state.liability}
                          onBlur={this.checkValueSFClass}
                          ref={component =>
                            setCustomValidityForInputText(
                              component,
                              this.props.structure.structure.question6_1.fields
                                .liability.validationError
                            )
                          }
                        />
                      </Grid>
                      <Grid item>
                        <InputText
                          {...this.props.structure.structure.question6_1.fields
                            .vollkasko}
                          onChange={(value, field) =>
                            this.onValueChange(
                              this.parseToPositive(value),
                              field
                            )
                          }
                          value={this.state.vollkasko}
                          onBlur={this.checkValueSFClass}
                        />
                      </Grid>
                    </Grid>
                  </section>
                )}
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  spacing={2}
                >
                  <Button
                    {...this.props.structure.buttons.submit}
                    disabled={this.submitButtonDisabled()}
                  />
                </Grid>
              </Paper>
            </Grid>

            <Dialog
              aria-labelledby="submit-dialog-loading"
              open={this.props.isSubmitStep1DialogOpen}
              maxWidth="md"
            >
              <DialogContent>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction='column'
                  spacing={0}
                >
                  <Box mt={4}>
                    <CircularProgress />
                  </Box>
                  <Box mt={4} mb={2}>
                    <Typography variant="h4" align="center">
                      Das Angebot wird berechnet, bitte haben Sie einen kurzen Moment Geduld.
                    </Typography>
                  </Box>
                </Grid>
              </DialogContent>
            </Dialog>

          </form>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.insuranceStep1,
  offer: state.offerDetails.offer,
  offerAnswers: state.offerDetails.offerAnswers,
  values: state.projectStructure.insuranceOffer.values,
  carModelList: state.carModelList,
  correctedZipCode: state.settings.correctedZipCode,
  isSubmitStep1DialogOpen: state.offerDetails.isSubmitStep1DialogOpen,
});

const mapDispatchToProps = dispatch => ({
  setErrorHandling: message => dispatch(openSnackbar({
    message,
    kind: SnackbarKind.ERROR,
  })),
  setSFEstimated: () => dispatch(setSFEstimated()),
  setIsCompany: isCompany => dispatch(setIsCompany(isCompany)),
  basicTariffData: (id, customer, contract) =>
    dispatch(basicTariffData(id, customer, contract)),
  basicTariffDraft: (id, customer, contract) =>
    dispatch(basicTariffDraft(id, customer, contract)),
  setPostalCode: postCode => dispatch(setPostalCode(postCode)),
  saveAnswersToOfferQuestionsInFrontendStorage: params => dispatch(saveOfferDataInFrontendStorage(params)),
  setOfferDataInFrontendStorage: params => dispatch(setOfferDataInFrontendStorage(params)),
  setCorrectedZipCode: zipCode => dispatch(setCorrectedZipCode(zipCode)),
  setSubmitStep1DialogOpen: params => dispatch(setSubmitStep1DialogOpen(params)),
  resetOfferToInitialStep: () => dispatch(resetOfferToInitialStep()),
  handlePersonalDataSnackbarError: () => dispatch(handlePersonalDataSnackbarError()),
});

Step1.propTypes = {
  message: PropTypes.string,
  setSFEstimated: PropTypes.func,
  basicTariffData: PropTypes.func,
  basicTariffDraft: PropTypes.func,
  setErrorHandling: PropTypes.func,
  setPostalCode: PropTypes.func.isRequired,
  error: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  offer: PropTypes.objectOf(Object).isRequired,
  offerAnswers: PropTypes.objectOf(Object).isRequired,
  saveAnswersToOfferQuestionsInFrontendStorage: PropTypes.func.isRequired,
  setOfferDataInFrontendStorage: PropTypes.func.isRequired,
  setCorrectedZipCode: PropTypes.func.isRequired,
  correctedZipCode: PropTypes.string,
  invokeUpdateStepSpecificData: PropTypes.bool,
  resetOfferToInitialStep: PropTypes.func,
  handlePersonalDataSnackbarError: PropTypes.func,
};

Step1.defaultProps = {
  classes: '',
  setErrorHandling: () => {},
  basicTariffData: () => {},
  basicTariffDraft: () => {},
  setSFEstimated: () => {},
  message: '',
  error: false,
  correctedZipCode: null,
  invokeUpdateStepSpecificData: false,
  resetOfferToInitialStep: () => {},
  handlePersonalDataSnackbarError: () => {},
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Step1);
