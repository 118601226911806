import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import styles from './style';
import Label from '../Label';
import ButtonIcon from '../ButtonIcon';

const TextArea = ({
  id,
  name,
  label,
  classes,
  linkLabel,
  onLinkClick,
  onButtonClick,
  onChange,
  defaultValue,
  value,
  rows,
  rowsMax,
  inputLimit,
  helperText,
}) => {
  const valueProps = value !== undefined ? { value } : { defaultValue };
  return (
    <div className={classes.inputContainer}>
      <Label
        label={label}
        htmlFor={name}
        linkLabel={linkLabel}
        onLinkClick={onLinkClick}
      />
      <TextField
        field={name}
        name={name}
        id={id}
        className={classes.input}
        multiline
        rows={rows}
        rowsMax={rowsMax}
        inputProps={{ maxLength: inputLimit }}
        helperText={helperText}
        onChange={evn => onChange(evn.target.value, evn.target.name)}
        {...valueProps}
      />
      { onButtonClick !== null &&
      <ButtonIcon
        onClick={onButtonClick}
        version="combined"
      />
      }
    </div>
  );
};

TextArea.propTypes = {
  helperText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  name: PropTypes.string,
  onButtonClick: PropTypes.func,
  label: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  linkLabel: PropTypes.string,
  onLinkClick: PropTypes.func,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  inputLimit: PropTypes.number,
};

TextArea.defaultProps = {
  helperText: '',
  defaultValue: '',
  value: undefined,
  id: '',
  name: '',
  label: '',
  classes: '',
  linkLabel: '',
  onLinkClick: null,
  onButtonClick: null,
  onChange: () => {},
  rows: undefined,
  rowsMax: undefined,
  inputLimit: undefined,
  // TODO find better way to exclude pattern matching
};

export default withStyles(styles)(TextArea);
