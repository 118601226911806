import React from 'react';
import {
  withStyles,
  FormLabel,
  FormControl,
  FormGroup,
  Radio as MaterialRadio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import styles from './style';

const Radio = (props) => {
  const {
    classes, label, options, name, input,
  } = props;

  const renderRadioButtons = radio => (
    <FormControlLabel
      value={radio.value}
      className={classes.formControlLabel}
      labelPlacement="end"
      control={
        <MaterialRadio
          className={classes.materialRadio}
          icon={<RadioButtonUncheckedIcon className={classes.materialRadioChecked} />}
          checkedIcon={<RadioButtonCheckedIcon className={classes.materialRadioChecked} />}
        />
      }
      label={<span className={classes.labelFont}>{radio.key}</span>}
      key={radio.key}
    />
  );

  return (
    <FormControl component="fieldset">
      <FormLabel
        component="legend"
        className={classes.formLabel}
        classes={{
          focused: classes.formLabelFocused,
        }}
      >
        {label}
      </FormLabel>
      <FormGroup>
        <RadioGroup aria-label={label} name={name} {...input} row>
          {options.map(renderRadioButtons)}
        </RadioGroup>
      </FormGroup>
    </FormControl>
  );
};

export default withStyles(styles)(Radio);
