const styles = theme => ({
  wrapper: {
    ...theme.containers.wrapper,
  },
  title: {
    ...theme.containers.title,
    paddingTop: 0,
  },
});

export default styles;
