import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { isIE } from 'react-device-detect';
import ButtonsPanel from './ButtonsPanel';
import AddressInvalid from './AddressInvalid';
import AddressCorrected from './AddressCorrected';
import validateAddress from '../../helpers/network/validateAddress';
import styles from './style';
import ZipCodeChanged from './ZipCodeChanged';

const ADDRESS_VALIDATION_STATUS = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  CORRECTED: 'CORRECTED',
  CORRECTED_WITH_CHANGED_ZIPCODE: 'CORRECTED_WITH_CHANGED_ZIPCODE',
};

const ADDRESS_NAMES = {
  CUSTOMER_ADDRESS: 'customerAddress',
  DIFFERING_OWNER_ADDRESS: 'differingOwnerAddress',
};

const isZipCodeChanged = status =>
  status === ADDRESS_VALIDATION_STATUS.CORRECTED_WITH_CHANGED_ZIPCODE;
const isCorrected = status => status === ADDRESS_VALIDATION_STATUS.CORRECTED;
const isInvalid = status => status === ADDRESS_VALIDATION_STATUS.INVALID;
const isValid = status => status === ADDRESS_VALIDATION_STATUS.VALID;

const AddressValidation = ({
  classes,
  schema,
  customerAddress,
  differingOwnerAddress,
  handleCancelButtonClick,
  handleAddressValidationNext,
  onSetCorrectedZipCode,
}) => {
  const [customerStatus, setCustomerStatus] = useState(null);
  const [differingOwnerStatus, setDifferingOwnerStatus] = useState(null);

  const [customerCorrectedAddresses, setCustomerCorrectedAddresses] = useState([]);
  const [differingOwnerCorrectedAddresses, setDifferingOwnerCorrectedAddresses] = useState([]);

  const [customerSelectedAddress, setCustomerSelectedAddress] = useState(null);
  const [differingOwnerSelectedAddress, setDifferingOwnerSelectedAddress] = useState(null);

  const [isZipCodeChangedPanelVisible, setIsZipCodeChangedPanelVisible] = useState(false);

  useEffect(() => {
    if (Object.values(customerAddress).every(el => el)) {
      validateAddress(customerAddress).then(({ status, address }) => {
        setCustomerStatus(status);
        setCustomerCorrectedAddresses(address);
        if (address && address[0]) setCustomerSelectedAddress(address[0]);
      }).catch(() => {
        handleCancelButtonClick();
      });
    }

    if (Object.values(differingOwnerAddress).every(el => el)) {
      validateAddress(differingOwnerAddress).then(({ status, address }) => {
        setDifferingOwnerStatus(status);
        setDifferingOwnerCorrectedAddresses(address);
        if (address && address[0]) setDifferingOwnerSelectedAddress(address[0]);
      }).catch(() => {
        handleCancelButtonClick();
      });
    }

    // eslint-disable-next-line
  }, []);

  const handleNextButtonClick = useCallback(() => {
    if (isZipCodeChanged(customerStatus)) {
      setIsZipCodeChangedPanelVisible(true);
    } else {
      handleAddressValidationNext(customerSelectedAddress, differingOwnerSelectedAddress);
    }
  }, [customerSelectedAddress,
    differingOwnerSelectedAddress,
    handleAddressValidationNext,
    customerStatus]);

  useEffect(() => {
    // close modal early for valid edge cases
    if (isValid(customerStatus) &&
     (isValid(differingOwnerStatus) || !Object.values(differingOwnerAddress).every(el => el))
    ) {
      handleNextButtonClick();
    }
  }, [customerStatus, differingOwnerStatus, differingOwnerAddress, handleNextButtonClick]);

  const handleCorrectedSelect = (name, address) => {
    switch (name) {
      case ADDRESS_NAMES.CUSTOMER_ADDRESS:
        setCustomerSelectedAddress(address);
        break;
      case ADDRESS_NAMES.DIFFERING_OWNER_ADDRESS:
        setDifferingOwnerSelectedAddress(address);
        break;
      default:
        // do nothing
    }
  };

  if (!customerStatus && !differingOwnerStatus) return null;

  let dialogButtons; let leftButton; let rightButton;
  if (isZipCodeChangedPanelVisible) {
    dialogButtons = schema.buttonsZipCodeChangedPanel;
    rightButton = () => {
      onSetCorrectedZipCode(customerSelectedAddress.zipCode);
    };
    leftButton = () => {
      handleAddressValidationNext(
        { ...customerSelectedAddress, ...{ zipCode: customerAddress.zipCode } },
        differingOwnerSelectedAddress,
      );
    };
  } else {
    dialogButtons = schema.buttonsPanel;
    leftButton = handleCancelButtonClick;
    rightButton = handleNextButtonClick;
  }

  return (
    <Dialog
      aria-labelledby="address-validation-dialog"
      open
      maxWidth="md"
      scroll={isIE ? 'body' : 'paper'}
      classes={{ paperWidthMd: classes.paperWidthMd }}
    >
      <div className={classes.innerBorderContainer}>
        { isZipCodeChangedPanelVisible ?
          <ZipCodeChanged {...schema.zipCodeChanged} /> : null }

        {!isZipCodeChangedPanelVisible &&
         (isCorrected(customerStatus) || isZipCodeChanged(customerStatus)) ?
           <AddressCorrected
             {...schema.corrected.customer}
             currentAddress={customerAddress}
             correctedAddresses={customerCorrectedAddresses}
             name={ADDRESS_NAMES.CUSTOMER_ADDRESS}
             handleSelect={handleCorrectedSelect}
           /> : null }

        { isInvalid(customerStatus) && !isInvalid(differingOwnerStatus) ?
          <AddressInvalid {...schema.invalid.customer} /> : null }

        { isCorrected(differingOwnerStatus) || isZipCodeChanged(differingOwnerStatus) ?
          <AddressCorrected
            {...schema.corrected.differingOwner}
            currentAddress={differingOwnerAddress}
            correctedAddresses={differingOwnerCorrectedAddresses}
            name={ADDRESS_NAMES.DIFFERING_OWNER_ADDRESS}
            handleSelect={handleCorrectedSelect}
          /> : null }

        { isInvalid(differingOwnerStatus) && !isInvalid(customerStatus) ?
          <AddressInvalid {...schema.invalid.differingOwner} /> : null }

        { isInvalid(customerStatus) && isInvalid(differingOwnerStatus) ?
          <AddressInvalid {...schema.invalid.both} /> : null}

      </div>

      {customerStatus || differingOwnerStatus ?
        <ButtonsPanel
          {...dialogButtons}
          handleLeftButtonClick={leftButton}
          handleRightButtonClick={rightButton}
        /> : null}
    </Dialog>
  );
};

AddressValidation.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  schema: PropTypes.objectOf(PropTypes.any).isRequired,
  customerAddress: PropTypes.shape({
    city: PropTypes.string,
    houseNumber: PropTypes.string,
    street: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  differingOwnerAddress: PropTypes.shape({
    city: PropTypes.string,
    houseNumber: PropTypes.string,
    street: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  handleCancelButtonClick: PropTypes.func,
  handleAddressValidationNext: PropTypes.func,
  zipCodeChanged: PropTypes.bool,
  zipCodeRevert: PropTypes.func,
  onSetCorrectedZipCode: PropTypes.func,
};

AddressValidation.defaultProps = {
  customerAddress: {},
  differingOwnerAddress: {},
  handleCancelButtonClick: () => {},
  handleAddressValidationNext: () => {},
  zipCodeChanged: false,
  zipCodeRevert: () => {},
  onSetCorrectedZipCode: () => {},
};

export default withStyles(styles)(AddressValidation);
