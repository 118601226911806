import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Typography, withStyles } from '@material-ui/core';
import { compose } from 'recompose';

import styles from './style';
import Label from '../Label';

const SwitchInput = ({
  name, onChange, defaultValue, label, classes, readOnly,
}) => (
  <Typography component="div" className={classes.root}>
    <Typography component="div" className={classes.labelContainer}>
      <Label label={label} htmlFor={name} />
    </Typography>
    <Typography component="div" className={classes.switchContainer}>
      <Switch
        className={classes.input}
        checked={defaultValue}
        onChange={evn => onChange(evn.target.checked, name)}
        disabled={readOnly}
        classes={{
          switchBase: classes.colorSwitchBase,
          checked: classes.colorChecked,
          track: classes.colorBar,
        }}
      />
    </Typography>
  </Typography>
);

SwitchInput.propTypes = {
  defaultValue: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  readOnly: PropTypes.bool,
};

SwitchInput.defaultProps = {
  defaultValue: false,
  name: '',
  onChange: () => {},
  label: '',
  classes: '',
  readOnly: false,
};

export default compose(withStyles(styles))(SwitchInput);

