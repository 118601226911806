import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import spinner from './spinner';
import notificationCenter from './notificationCenter';
import authentication from './authentication';
import personalDetails from './personalDetails';
import userDetails from './userDetails';
import userSearch from './userSearch';
import fields from './fields';
import structure from './structure';
import carModelList from './carModelList';
import offerDetails from './offer';
import vehicleNumberSearch from './vehicleNumberSearch';
import vehicleModelSearch from './vehicleModelSearch';
import snackbar from './snackbar';
import evbList from './evbList';
import settings from './settings';

export default combineReducers({
  spinner,
  form: formReducer,
  notificationCenter,
  auth: authentication,
  personalDetails,
  userDetails,
  userSearch,
  carModelList,
  offerDetails,
  projectFields: fields,
  projectStructure: structure,
  vehicleNumberSearch,
  vehicleModelSearch,
  snackbar,
  evbList,
  settings,
});
