import React from 'react';
import {
  withStyles, FormControlLabel, Checkbox as MaterializeCheckbox,
} from '@material-ui/core';
import styles from './style';

const Checkbox = (props) => {
  const {
    classes, label, checked, onChange, name,
  } = props;
  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={
        <MaterializeCheckbox
          checked={checked}
          onChange={evn => onChange(evn.target.checked, name)}
          color="primary"
          classes={{
            root: classes.root,
            checked: classes.checked,
          }}
        />
      }
      label={label}
    />
  );
};

export default withStyles(styles)(Checkbox);
