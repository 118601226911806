import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  Typography,
  withStyles,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import styles from './style';
import * as Sorting from '../../utils/sorting';
import { requireAuth,
  allowedToAccessEvbList,
  allowedToSeeSalesChannelInEvbList } from '../Authorization';
import { getEvbListUncompleted } from '../../actions/evbList';
import { clearOffer, getOfferDetails } from '../../actions/offer';
import { displayClientName } from './index';
import TablePaginationActions from './TablePaginationActions';

class UncompletedOffers extends Component {
  state = {
    order: Sorting.orderBy.DESC,
    orderBy: Sorting.orderBy.CREATED,
    rowsPerPage: 5,
    page: 0,
  }

  componentDidMount = () => {
    this.props.getEvbListUncompleted();
    this.props.clearOffer();
  };

  createSortHandler = property => (event) => {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = Sorting.orderBy.DESC;

    if (this.state.orderBy === property && this.state.order === Sorting.orderBy.DESC) {
      order = Sorting.orderBy.ASC;
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  calculateDays = (date) => {
    const createdAt = new Date(date);
    const monthsAdded = 6;
    const dateWithMonthsAdded = createdAt.setMonth(createdAt.getMonth() + monthsAdded);
    const formattedDateWithMonthsAdded = new Date(dateWithMonthsAdded);
    const now = new Date();

    const remainingDays = moment(formattedDateWithMonthsAdded).diff(moment(now), 'days');

    return `${remainingDays < 0 ? 0 : remainingDays} ${this.props.structure.fields.days}`;
  };

  render() {
    const {
      structure, classes, offers,
    } = this.props;
    const {
      orderBy, order, rowsPerPage, page,
    } = this.state;

    return (
      <Typography component="div" className={classes.wrapper}>
        <Typography align="center" variant="h2">
          {structure.header.openOffers}
        </Typography>
        <Paper className={classes.wrapperTable}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.firstColumn}>
                  <Tooltip title={structure.fields.sort} enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === Sorting.orderBy.CODE}
                      direction={order}
                      onClick={this.createSortHandler(Sorting.orderBy.CODE)}
                    >
                      {structure.fields.code}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={structure.fields.sort} enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === Sorting.orderBy.CREATED}
                      direction={order}
                      onClick={this.createSortHandler(Sorting.orderBy.CREATED)}
                      classes={{
                        root: classes.tableSortLabelRemainDays,
                      }}
                    >
                      {structure.fields.remainDays}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={structure.fields.sort} enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === Sorting.orderBy.LAST_NAME}
                      direction={order}
                      onClick={this.createSortHandler(Sorting.orderBy.LAST_NAME)}
                    >
                      {structure.fields.name}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={structure.fields.sort} enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === Sorting.orderBy.EVB_NUMBER}
                      direction={order}
                      onClick={this.createSortHandler(Sorting.orderBy.EVB_NUMBER)}
                    >
                      {structure.fields.evbNumber}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={structure.fields.sort} enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === Sorting.orderBy.CAR_NUMBER}
                      direction={order}
                      onClick={this.createSortHandler(Sorting.orderBy.CAR_NUMBER)}
                    >
                      {structure.fields.car}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                {
                 allowedToSeeSalesChannelInEvbList() ?
                   <TableCell>
                     <Tooltip title={structure.fields.sort} enterDelay={300}>
                       <TableSortLabel
                         active={orderBy === Sorting.orderBy.SALE_CHANNEL}
                         direction={order}
                         onClick={this.createSortHandler(Sorting.orderBy.SALE_CHANNEL)}
                       >
                         {structure.fields.salesChannels}
                       </TableSortLabel>
                     </Tooltip>
                   </TableCell>
                 : null
                }
                <TableCell align="center">{structure.fields.goToOffer}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Sorting.stableSort(offers, Sorting.getSorting(order, orderBy))
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map(row => (
                  <TableRow key={row.id} className={classes.tableRow}>
                    <TableCell className={classes.firstColumn}>{row.code}</TableCell>
                    <TableCell>{this.calculateDays(row.createdAt)}</TableCell>
                    <TableCell>
                      {displayClientName(row)}
                    </TableCell>
                    <TableCell>{row.vehicleEvbNumber}</TableCell>
                    <TableCell>
                      {row.vehicleHSN} / {row.vehicleTSN}
                    </TableCell>
                    {
                     allowedToSeeSalesChannelInEvbList() ?
                       <TableCell> {this.props.displaySalesChannel(row)} </TableCell>
                       : null
                    }
                    <TableCell align="center">
                      <Tooltip title={structure.fields.goToOffer}>
                        <IconButton aria-label="Complete" onClick={() => this.props.handleOpenOffer(row)}>
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={offers.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={structure.fields.labelRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} ${structure.fields.of} ${count}`
            }
            page={page}
            backIconButtonProps={{
              'aria-label': structure.fields.prevPage,
            }}
            nextIconButtonProps={{
              'aria-label': structure.fields.nextPage,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Typography>
    );
  }
}

const mapStateToProps = state => ({
  structure: state.projectStructure.evbList,
  offers: state.evbList.uncompletedOffers,
});

const mapDispatchToProps = dispatch => ({
  getEvbListUncompleted: () => dispatch(getEvbListUncompleted()),
  clearOffer: () => dispatch(clearOffer()),
  getOfferDetails: id => dispatch(getOfferDetails(id)),
});

UncompletedOffers.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  structure: PropTypes.objectOf(PropTypes.any).isRequired,
  getEvbListUncompleted: PropTypes.func.isRequired,
  clearOffer: PropTypes.func,
  offers: PropTypes.instanceOf(Array),
  handleOpenOffer: PropTypes.func.isRequired,
  displaySalesChannel: PropTypes.func,
};

UncompletedOffers.defaultProps = {
  classes: '',
  clearOffer: () => {},
  offers: [],
  displaySalesChannel: () => {},
};

export default requireAuth(
  compose(
    withStyles(styles),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(UncompletedOffers),
  allowedToAccessEvbList,
  true,
);
