const styles = theme => ({
  table: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    '& th,td': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  carRadio: {
    padding: theme.spacing.unit / 2,
    cursor: 'pointer',
    color: theme.palette.darkBlue,
    '&$carRadioChecked': {
      color: theme.palette.darkBlue,
    },
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  info: {
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit,
    border: `2px solid ${theme.palette.darkBlue}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  infoIcon: {
    verticalAlign: 'text-bottom',
    marginRight: theme.spacing.unit,
    color: theme.palette.darkBlue,
  },
  carRadioChecked: {},
  container: theme.containers.container,
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 9,
  },
  buttonContainer: {
    position: 'relative',
    '& > div': {
      left: theme.spacing.unit,
      top: '32px',
    },
  },
  carDetails: {
    height: '100%',
  },
  carDetailsLabel: {
    height: '50%',
    lineHeight: `${theme.units.spacing * 4}px`,
    position: 'relative',
    '& p': theme.fonts.tableLabel,
  },
  carDetailsText: {
    height: '50%',
    lineHeight: '100%',
  },
  modelTable: {
    backgroundColor: 'white',
    border: '1px gray solid',
    borderRadius: theme.units.borderRadius,
    marginBottom: `${theme.units.spacing * 7}px`,
  },
  modelTableRow: {
    boxSizing: 'content-box',
    borderBottom: '1px solid gray',
    display: 'block',
    '&:last-child': {
      border: 'none',
    },
  },
  modelTableHeader: {
    height: `${theme.units.spacing * 4}px`,
  },
  title: theme.fonts.headline_2,
  label: {
    ...theme.fonts.headline_1,
    lineHeight: `${theme.units.spacing * 4}px`,
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  textWrapping: {
    display: 'block',
    margin: '0 auto 1em',
    height: `${theme.units.spacing * 5}px`,
    position: 'relative',
    '& p': {
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
      '& input': {
        position: 'relative',
        left: '50%',
      },
    },
  },
  radios: {
    marginLeft: theme.spacing.unit / 2,
    marginBottom: theme.spacing.unit * 3,
  },
  radioGroup: {
    paddingLeft: theme.spacing.unit * 3,
  },
  moreOptions: {
    width: '100%',
  },
  registrationContainer: {
    width: '190px',
    marginBottom: theme.spacing.unit,
  },
  milageContainer: {
    width: '200px',
    marginBottom: theme.spacing.unit,
    marginRight: theme.spacing.unit * 5,
    '& label': {
      lineHeight: '24px',
      position: 'relative',
      width: '130%',
    },
    '& > div > div': {
      marginTop: 0,
    },
  },
});

export default styles;
