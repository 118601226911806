const styles = theme => ({
  datePicker: {
    ...theme.inputs.inputText,
    fontFamily: theme.fontfamily,
  },
  inputContainer: {
    position: 'relative',
    width: '190px',
    backgroundColor: '#FFFFFF',
  },
  datePickerCalendar: {
    display: 'flex',
    position: 'absolute',
    borderRadius: '4px',
    boxShadow: '0 0 1em grey',
    zIndex: '100',
    '& .react-datepicker__header': {
      textAlign: 'center',
      backgroundColor: 'white',
      position: 'relative',
    },
    '& .react-datepicker__current-month': {
      padding: '8px 0',
      backgroundColor: theme.palette.accent1Color,
      width: '60%',
      color: 'white',
      height: '37px',
    },
    '& .react-datepicker__header__dropdown': {
      width: '40%',
      backgroundColor: theme.palette.accent1Color,
      position: 'absolute',
      top: '0',
      right: '0',
      height: '37px',
      paddingTop: '8px',
      boxSizing: 'border-box',
      cursor: 'pointer',
    },
    '& .react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown': {
      backgroundColor: '#f0f0f0',
      position: 'absolute',
      width: '50%',
      left: '25%',
      top: '30px',
      zIndex: '1',
      textAlign: 'center',
      borderRadius: '4px',
      border: '1px solid #aeaeae',
    },
    '& .react-datepicker__year-read-view--down-arrow': {
      borderTopColor: 'white',
      right: '8px',
      marginLeft: '20px',
      top: '8px',
      borderWidth: '0.45rem',
      boxSizing: 'content-box',
      position: 'absolute',
      border: '8px solid transparent',
      height: '0',
      width: '1px',
    },
    '& .react-datepicker__year-dropdown-container--scroll': {
      display: 'inline-block;',
      margin: '0 2px',
      width: '100px',
    },
    '& .react-datepicker__year-read-view--selected-year': {
      color: 'white',
    },
    '& .react-datepicker__year-dropdown--scrollable': {
      height: '150px',
      overflowY: 'scroll',
      width: '100px',
    },
    '& .react-datepicker__navigation': {
      background: 'none',
      lineHeight: '1.7rem',
      textAlign: 'center',
      cursor: 'pointer',
      position: 'absolute',
      top: '10px',
      border: '0.45rem solid transparent',
      zIndex: '1',
      padding: '0',
      height: '10px',
      width: '10px',
      textIndent: '-999em',
      overflow: 'hidden',
    },
    '& .react-datepicker__navigation--previous': {
      left: '10px',
      borderRightColor: 'white',
    },
    '& .react-datepicker__navigation--next': {
      left: '120px',
      borderLeftColor: 'white',
    },
    '& .react-datepicker__day': {
      cursor: 'pointer',
    },
    '& .react-datepicker__day:hover': {
      borderRadius: '0.3rem',
      backgroundColor: '#f0f0f0',
    },
    '& .react-datepicker__day:focus': {
      outline: 'none',
    },
    '& .react-datepicker__day-names': {
      borderBottom: '2px solid grey',
      fontWeight: '800',
    },
    '& .react-datepicker__day-names, .react-datepicker__week': {
      whiteSpace: 'nowrap',
      margin: '0 8px',
    },
    '& .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name': {
      color: '#000',
      display: 'inline-block',
      width: '1.7rem',
      lineHeight: '1.7rem',
      textAlign: 'center',
      margin: '0.166rem',
    },
    '& .react-datepicker__month-container': {
      backgroundColor: 'white',
    },
    '& .react-datepicker__day--disabled': {
      color: '#A5ABB6',
    },
  },
  datePickerPopper: {
    zIndex: 100,
  },
  formLabel: {
    fontSize: '0.8125rem',
    fontWeight: 'bolder',
    color: theme.palette.textColor,
    paddingLeft: theme.spacing.unit,
    lineHeight: '24px',
  },
});

export default styles;
