import React from 'react';
import {
  withStyles, InputBase, InputLabel, FormControl, FormHelperText,
} from '@material-ui/core';
import styles from './style';

const TextField = (props) => {
  const {
    classes, label, placeholder, required, autoFocus, meta, input, id, type, helperText,
  } = props;
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        shrink
        htmlFor={id}
        classes={{
          focused: classes.inputLabelFocused,
        }}
        className={classes.inputLabel}
      >
        {label}
      </InputLabel>
      <InputBase
        placeholder={placeholder}
        id={id}
        classes={{
          root: classes.inputBaseRoot,
          input: classes.inputBaseInput,
          error: classes.inputBaseError,
        }}
        type={type}
        fullWidth
        required={required}
        autoFocus={autoFocus}
        error={meta.touched && !!meta.error}
        {...input}
      />
      {helperText && (
        <FormHelperText
          id={helperTextId}
          classes={{
            root: classes.formHelperTextRoot,
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(TextField);
