import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import styles from './style';
import StyledFormControlLabel from './StyledFormControlLabel';

const stringifyAddress = address => (`${address.street} ${address.houseNumber}, ${address.zipCode} ${address.city}`);
const removeSpaces = str => str.replace(/\s+/g, '');

const CURRENT = 'current';

const AddressCorrected = ({
  classes,
  section,
  title,
  labelRadioCurrentAddress,
  currentAddress,
  correctedAddresses,
  name,
  handleSelect,
}) => {
  const [value, setValue] = useState('0');

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue === CURRENT) {
      handleSelect(name, currentAddress);
    } else {
      handleSelect(name, correctedAddresses[newValue]);
    }

    setValue(newValue);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5" align="center" className={classes.section}>
        {section}
      </Typography>

      <Typography variant="h3" align="center">
        {title}
      </Typography>

      <p className={classes.subtitle}>
        {stringifyAddress(currentAddress)}
      </p>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup
          aria-label="Address choose"
          name={name}
          value={value}
          onChange={handleChange}
        >
          {correctedAddresses.map((item, index) => (
            <StyledFormControlLabel key={removeSpaces(stringifyAddress(item))} value={`${index}`} label={stringifyAddress(item)} />
          ))}
          <StyledFormControlLabel key={removeSpaces(stringifyAddress(currentAddress))} value="current" label={labelRadioCurrentAddress} />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

AddressCorrected.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  labelRadioCurrentAddress: PropTypes.string,
  currentAddress: PropTypes.shape({
    city: PropTypes.string,
    houseNumber: PropTypes.string,
    street: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  correctedAddresses: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  handleSelect: PropTypes.func,
  section: PropTypes.string,
};

AddressCorrected.defaultProps = {
  title: '',
  currentAddress: {},
  labelRadioCurrentAddress: '',
  correctedAddresses: [],
  name: '',
  handleSelect: '',
  section: '',
};

export default withStyles(styles)(AddressCorrected);
