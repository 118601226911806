import types from '../actions/types';

export const vehicleTypeKeys = {
  CAR: 'car',
  MOTORCYCLE: 'motorcycle',
  NON_SET: 'nonSet',
};

const initialState = {
  vehicleType: vehicleTypeKeys.NON_SET,
  correctedZipCode: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_VEHICLE_TYPE:
      return {
        ...state,
        vehicleType: action.payload,
      };
    case types.SET_CORRECTED_ZIP_CODE:
      return {
        ...state,
        correctedZipCode: action.payload,
      };
    default:
      return state;
  }
};

export const isMotorcycleSelected = settings =>
  settings.vehicleType === vehicleTypeKeys.MOTORCYCLE;
