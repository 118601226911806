import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './style';

const Label = ({
  label,
  htmlFor,
  classes,
  customRadioLabel,
  linkLabel,
  onLinkClick,
}) => (
  <label
    className={customRadioLabel || classes.label}
    htmlFor={htmlFor}
    label={label}
  >
    {label}
    <span className={classes.link} onClick={onLinkClick}>{linkLabel}</span>
  </label>
);

Label.propTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  linkLabel: PropTypes.string,
  onLinkClick: PropTypes.func,
  customRadioLabel: PropTypes.string,
};

Label.defaultProps = {
  htmlFor: '',
  label: '',
  classes: '',
  linkLabel: '',
  onLinkClick: null,
};

export default withStyles(styles)(Label);
