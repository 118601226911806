import { isValidIBAN, sanitizeIBAN } from '../../../utils/iban';
import { isTodayOrFuture } from '../../../utils/date';

export const fileUploadValidationTypes = {
  UPLOADED_FILES_NUMBER_LIMIT: 10,
  ACCEPTED_FILE_SIZE: 5,
  MAXIMUM_FILENAME_LENGTH: 255,
  // checks for (& / \ | ' * ; " > < % ?)
  DISALLOWED_FILENAME_CHARS: /(\(|\)|&|\/|\\|\||'|\*|;|"|>|<|%|\?)/miu,
  ACCEPTED_FILE_EXTENSIONS: [
    '.heic',
    '.pdf',
    '.jpeg',
    '.jpg',
    '.tif',
    '.tiff',
    '.png',
    '.bmp',
    '.HEIC',
    '.PNG',
    '.BMP',
    '.TIFF',
    '.TIF',
    '.JPG',
    '.JPEG',
    '.PDF',
  ].join(','),
};

const isValidFileSize = (size) => {
  const fileSize = (size / 1024 / 1024).toFixed(fileUploadValidationTypes.ACCEPTED_FILE_SIZE);
  return fileSize <= fileUploadValidationTypes.ACCEPTED_FILE_SIZE;
};

const isValidFileName = fileName => (
  !fileUploadValidationTypes.DISALLOWED_FILENAME_CHARS.test(fileName)
    && fileName.length < fileUploadValidationTypes.MAXIMUM_FILENAME_LENGTH
);

const isValidMaxFilesLimit = numberOfFiles =>
  numberOfFiles < fileUploadValidationTypes.UPLOADED_FILES_NUMBER_LIMIT;

const validators = {
  iban: iban => isValidIBAN(sanitizeIBAN(iban)),
  fileSize: size => isValidFileSize(size),
  fileName: fileName => isValidFileName(fileName),
  filesLimit: numberOfFiles => isValidMaxFilesLimit(numberOfFiles),
  dateOfBirth: date => !isTodayOrFuture(date),
};

export default (value, validatorName) => {
  const validate = validators[validatorName];
  if (!validate) {
    console.warn(`Validator '${validatorName}' does not exist.`);
    return true;
  }
  return validate(value);
};
