import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import styles from './style';

const Price = ({
  price, unit, classes, customClass,
}) => {
  const customCSS = customClass || classes.default;
  return (
    <Grid container justifyContent="center" alignContent="center" direction="column" spacing={2}>
      <Grid item>
        <Paper className={`${classes.paper}`}>
          <Typography className={`${classes.paper} ${customCSS}`}>
            <span className={classes.price}>{price.toLocaleString('de-DE', { minimumFractionDigits: 2 })}</span>
            <span className={classes.unit}>{unit}</span>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

Price.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  price: PropTypes.number.isRequired,
  unit: PropTypes.string,
  customClass: PropTypes.string,
};

Price.defaultProps = {
  classes: '',
  customClass: null,
  unit: '',
};
export default withStyles(styles)(Price);
