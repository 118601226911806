import types from './types';

export const SnackbarKind = {
  SUCCESS: 1,
  ERROR: 2,
  INFO: 3,
  WARNING: 4,
};

export const openSnackbar = data => ({
  type: types.OPEN_SNACKBAR,
  payload: data,
});

export const closeSnackbar = () => ({
  type: types.CLOSE_SNACKBAR,
});

export const shiftMessages = () => ({
  type: types.SHIFT_MESSAGES,
});
