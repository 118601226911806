import { useEffect, useRef } from 'react';

const useFormSubmitTrigger = (submitTrigger) => {
  const formRef = useRef(null);

  useEffect(() => {
    if (submitTrigger > 0) {
      // IE11 compatible
      const event = document.createEvent('Event');
      event.initEvent('submit', false, true);
      formRef.current.dispatchEvent(event);
    }
  }, [submitTrigger]);

  return formRef;
};

export default useFormSubmitTrigger;
