import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles, Dialog } from '@material-ui/core';
import * as classNames from 'classnames';
import ButtonsPanel from './ButtonsPanel';
import TitleLabel from './TitleLabel';
import DescriptionLabel from './DescriptionLabel';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  innerBorderContainer: {
    margin: '20px 20px 0 20px',
    border: `1px solid ${theme.palette.border}`,
  },
  paperWidthMd: {
    maxWidth: '600px',
  },
  buttons: {
    padding: '30px 85px 40px',
    '@media (max-width: 500px)': {
      padding: '5px',
    },
  },
  icon: {
    height: '40px',
    width: '40px',
    marginBottom: '10px',
  },
  successIconColor: {
    color: '#4BAF50',
  },
  errorIconColor: {
    color: '#B00020',
  },
}));

const SenderDialog = ({
  title,
  subtitle,
  successIcon,
  failIcon,
  onLeftButtonClick,
  onRightButtonClick,
  labelLeftButton,
  labelRightButton,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      aria-labelledby="contact-form-sender-dialog"
      open
      maxWidth="md"
      classes={{ paperWidthMd: classes.paperWidthMd }}
    >
      <div className={classes.innerBorderContainer}>
        <div className={classes.container}>

          {successIcon ?
            <CheckCircleIcon className={classNames(classes.icon, classes.successIconColor)} />
           : null}

          {failIcon ?
            <ErrorIcon className={classNames(classes.icon, classes.errorIconColor)} />
           : null}

          <TitleLabel center>
            {title}
          </TitleLabel>

          <DescriptionLabel center>
            {subtitle}
          </DescriptionLabel>

        </div>
      </div>

      <ButtonsPanel
        className={classes.buttons}
        handleLeftButtonClick={onLeftButtonClick}
        handleRightButtonClick={onRightButtonClick}
        labelLeftButton={labelLeftButton}
        labelRightButton={labelRightButton}
      />

    </Dialog>
  );
};

SenderDialog.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  successIcon: PropTypes.bool,
  failIcon: PropTypes.bool,
  onLeftButtonClick: PropTypes.func.isRequired,
  onRightButtonClick: PropTypes.func.isRequired,
  labelLeftButton: PropTypes.string.isRequired,
  labelRightButton: PropTypes.string.isRequired,
};

SenderDialog.defaultProps = {
  successIcon: false,
  failIcon: false,
};

export default SenderDialog;
