import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const ModelLabel = ({ vehicle, isMotorcycleSelected }) => {
  if (!vehicle) return (<></>);

  const strengthLabel = (isMotorcycleSelected) ?
    `${vehicle.strengthKw} KW` :
    `${vehicle.strengthHp} PS (${vehicle.strengthKw} KW)`;

  return (<Typography align="center" variant="h3">
    {vehicle.companyName} {vehicle.salesDesignation}, {vehicle.fuel}, {strengthLabel}, {vehicle.producerId} / {vehicle.vehTypeId}
  </Typography>);
};

ModelLabel.propTypes = {
  vehicle: PropTypes.shape({
    strengthKw: PropTypes.number,
    strengthHp: PropTypes.number,
    companyName: PropTypes.string,
    salesDesignation: PropTypes.string,
    fuel: PropTypes.string,
    producerId: PropTypes.string,
    vehTypeId: PropTypes.string,
  }),
  isMotorcycleSelected: PropTypes.bool,
};

ModelLabel.defaultProps = {
  vehicle: null,
  isMotorcycleSelected: false,
};

export default ModelLabel;
