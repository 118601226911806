import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Button } from '@material-ui/core';
import * as classNames from 'classnames';
import styles from './style';

const ButtonsPanel = ({
  classes,
  handleLeftButtonClick,
  handleRightButtonClick,
  labelLeftButton,
  labelRightButton,
  widerButton,
}) => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
  >
    <Button
      className={classNames(classes.button, classes.buttonCancel, widerButton ? classes.buttonWider : null)}
      onClick={handleLeftButtonClick}
    >
      {labelLeftButton}
    </Button>
    <Button
      className={classNames(classes.button, classes.buttonNext, widerButton ? classes.buttonWider : null)}
      onClick={handleRightButtonClick}
    >
      {labelRightButton}
    </Button>
  </Grid>
);

ButtonsPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  labelLeftButton: PropTypes.string,
  labelRightButton: PropTypes.string,
  widerButton: PropTypes.bool,
  handleLeftButtonClick: PropTypes.func,
  handleRightButtonClick: PropTypes.func,
};

ButtonsPanel.defaultProps = {
  labelLeftButton: '',
  labelRightButton: '',
  widerButton: false,
  handleLeftButtonClick: () => {},
  handleRightButtonClick: () => {},
};

export default withStyles(styles)(ButtonsPanel);
