export const ERROR_MESSAGE = 'Maximum string length of this field is: ';

export const maxLength = maxLengthValue => (value) => {
  if (value) {
    return value && value.length <= maxLengthValue
      ? undefined
      : `${ERROR_MESSAGE} ${maxLengthValue}`;
  }

  return undefined;
};
