const getTomorrowDate = () => {
  const date = new Date();
  date.setHours(24, 0, 0, 0);
  return date;
};

const parseDate = value => new Date(value);

const getTodayString = () => new Date().toJSON().slice(0, 10);

const isTodayOrFuture = (dateInput) => {
  if (!dateInput) {
    return false;
  }
  const date = parseDate(dateInput);
  if (!date) {
    return false;
  }
  return date >= getTomorrowDate();
};

export { getTodayString, isTodayOrFuture };
