const styles = theme => ({
  container: theme.containers.container,
  paper: theme.containers.paper,
  headlineTwo: theme.fonts.headline_2,
  form: {
    overflow: 'hidden',
  },
  link: {
    cursor: 'pointer',
    '-moz-user-select': 'none',
    '-webkit-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none',
  },
  expansionPanelHeadline: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: '22px',
    paddingRight: theme.spacing.unit,
  },
  expansionPanelSelection: {
    color: theme.palette.darkBlue,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: '22px',
    '& span': {
      display: 'inline-block',
      '&:first-child': {
        marginRight: theme.spacing.unit / 2,
      },
    },
  },
  specialDisplay: {
    fontSize: '16px',
    opacity: '1',
  },
  specialPriceText: {
    fontSize: '16px',
    fontWeight: 'bolder',
    color: theme.palette.darkBlue,
  },
  specialPrice: {
    border: `1px solid ${theme.palette.darkBlue}`,
    borderRadius: '5px',
    opacity: '0.5',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 6}px`,
  },
  specialPriceInfo: {
    fontSize: '12px',
    fontWeight: 600,
    position: 'absolute',
    bottom: 0,
    right: theme.units.spacing,
    letterSpacing: 0,
    lineHeight: '17px',
    color: theme.palette.primary1Color,
  },
  radioInputContainer: {
    paddingBottom: theme.spacing.unit,
    '& > div > label': {
      float: 'unset',
      paddingTop: 0,
      display: 'inline-block',
      width: '100px',
    },
  },
  customInputTextContainer: {
    '& > div': {
      marginBottom: theme.spacing.unit,
    },
  },
  customRadioInputContainer: {
    '& > div': {
      display: 'block',
      textAlign: 'left',
    },
    '& > div > label': {
      float: 'unset',
      display: 'inline-block',
      width: '100px',
    },
  },
  customRadioLabel: {
    ...theme.fonts.label,
    paddingLeft: 'unset',
    width: 'unset',
    paddingTop: `${theme.units.spacing}px`,
    paddingRight: `${theme.units.spacing * 5}px`,
  },
});

export default styles;
