// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authentication';
import { clearOffer } from '../../actions/offer';
import history from '../../history';

class Logout extends Component {
  componentDidMount() {
    this.props.clearOffer();
    this.props.logoutUser();
    history.push('/');
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
  clearOffer: () => dispatch(clearOffer()),
});

Logout.propTypes = {
  logoutUser: PropTypes.func,
  clearOffer: PropTypes.func,
};

Logout.defaultProps = {
  logoutUser: () => {},
  clearOffer: () => {},
};

export default compose(connect(null, mapDispatchToProps))(Logout);

