const styles = theme => ({
  userIcon: {
    margin: `${theme.units.spacing * 3}px ${theme.units.spacing * 4}px`,
    color: theme.palette.userIconColor,
    float: 'right',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  popupMenu: {
    position: 'relative',
    float: 'right',
    display: 'inline-block',
  },
  menu: {
    alignSelf: 'center',
    position: 'absolute',
    right: theme.units.spacing * 3,
    top: '82px',
    width: 'auto',
    padding: '0',
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'inline-block',
      width: '0px',
      height: '0px',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: `10px solid ${theme.palette.primaryButtonTextColor}`,
      top: '-10px',
      right: theme.spacing.unit,
    },
  },
  linkMenu: {
    width: '100%',
    borderRadius: '0 0 4px 4px ',
    backgroundColor: 'white',
    '& li': {
      padding: theme.spacing.unit * 2,
      width: '100%',
      display: 'inline-block',
      textAlign: 'center',
      borderBottom: '1px grey solid',
      boxSizing: 'border-box',
      '& button': {
        float: 'none',
        margin: '0',
      },
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  linkItem: {
    '& button': {
      height: theme.spacing.unit * 3,
    },
    '& button:disabled': {
      color: '#A5ABB6',
      cursor: 'not-allowed',
    },
  },
  username: {
    wordWrap: 'break-word',
    textAlign: 'center',
    backgroundColor: theme.palette.primaryButtonTextColor,
    fontSize: theme.typography.pxToRem(16),
    borderRadius: '4px 4px 0 0',
    padding: theme.spacing.unit,
  },
  dim: {
    backgroundColor: 'grey',
    opacity: '.6',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
  },
});

export default styles;
