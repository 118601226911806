const styles = () => ({
  paper: {
    marginBottom: '30px',
    overflow: 'hidden',
  },
  hoveringPaper: {
    position: 'fixed',
    bottom: '0px',
    left: '0',
    width: '100%',
    'margin-bottom': '0',
    'z-index': '1000',
    'padding-bottom': '25px',
  },
  '.submitButton': {
    'margin-top': '0',
  },
  backButtonContainer: {
    alignSelf: 'flex-end',
  },
  nextButtonContainer: {
    alignSelf: 'flex-end',
    marginBottom: '12px',
    '& button': {
      float: 'none',
      width: 'auto',
      minWidth: '170px',
    },
  },
});

export default styles;
