/* eslint-disable react/prop-types,max-len */
/* eslint-disable no-fallthrough */
import React, { Component, Fragment } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid, Typography, Paper, withStyles } from '@material-ui/core';
import moment from 'moment';
import {
  setOfferUserData,
  setPersonalDetails,
  setPersonalDraft,
  setStep,
  DEFAULT_PREVIOUS_INSURANCE_NUMBER,
  COMPANY,
  resetOfferToInitialStep,
  updateOfferStep,
  handlePersonalDataSnackbarError
} from '../../../actions/offer';
import DatePickerInput from '../../../components/DatePickerInput';
import InputText from '../../../components/InputText';
import SelectField from '../../../components/SelectField';
import SwitchInput from '../../../components/SwitchInput';
import PreviousInsurer from '../../PreviousInsurer';
import styles from './style';
import Footer from '../../../components/Footer';
import { getCurrentProjectName } from '../../../utils/theme';
import { openSnackbar, SnackbarKind } from '../../../actions/snackbar';
import AddressValidation from '../../../components/AddressValidation/';
import { isValidIBAN, sanitizeIBAN } from '../../../utils/iban';
import { setCorrectedZipCode } from '../../../actions/settings';
import validationRegex from '../../../constants/validationRegex';
import { ButtonIcons } from '../../../components/ButtonIcon';

const preparePersonalData = (state, title) => {
  const personalData = {
    startDate: state.contractStartDate,
    title,
    street: state.customerStreet,
    houseNr: state.customerHouseNr,
    postalCode: state.customerPostalCode,
    city: state.customerCity,
    phoneNr: state.customerPhone,
    bank: state.customerBankName,
    iban: state.customerBankIBAN,
    sepa: state.customerBankAuthorizedSEPA
  };

  const mappingsForOptionals = [
    { personalDataKey: 'companyName', stateKey: 'customerCompanyName' },
    { personalDataKey: 'firstName', stateKey: 'customerFirstName' },
    { personalDataKey: 'lastName', stateKey: 'customerLastName' },
    { personalDataKey: 'email', stateKey: 'customerEmail' },
    { personalDataKey: 'faxNr', stateKey: 'customerFax' },
    {
      personalDataKey: 'previousInsurerName',
      stateKey: 'customerPreviousInsurerName'
    },
    {
      personalDataKey: 'previousInsurerId',
      stateKey: 'customerPreviousInsurerId'
    },
    {
      personalDataKey: 'previousInsuranceNumber',
      stateKey: 'previousInsuranceNumber'
    }
  ];

  mappingsForOptionals.forEach(({ personalDataKey, stateKey }) => {
    const value = state[stateKey];
    if (value && !!String(value).replace(/\s/g, '').length) {
      personalData[personalDataKey] = value;
    }
  });

  return personalData;
};

const prepareDifferingOwner = state => {
  let differingOwnerCompanyOrPersonName = {};

  if (state.differingOwnerTitle === COMPANY) {
    differingOwnerCompanyOrPersonName = {
      companyName: state.differingOwnerCompanyName
    };
  } else {
    differingOwnerCompanyOrPersonName = {
      firstName: state.differingOwnerFirstName,
      lastName: state.differingOwnerLastName
    };
  }

  return {
    title: state.differingOwnerTitle,
    ...differingOwnerCompanyOrPersonName,
    street: state.differingOwnerStreet,
    houseNr: state.differingOwnerHouseNr,
    postalCode: state.differingOwnerPostalCode,
    city: state.differingOwnerCity
  };
};

class Step4 extends Component {
  constructor(props) {
    super(props);
    this.classes = {
      ...this.props.classes,
      hoveringPaper: 'hoveringPaper',
      totalPrice: 'totalPrice',
      separator: 'separator',
      unit: 'unit'
    };

    this.state = {
      contractStartDate: this.props.offer.contractStartDate || moment(),
      customerIsCompany: this.props.offer.customerIsCompany || false,
      customerCompanyName: this.props.offer.customerCompanyName || '',
      customerTitle: this.props.offer.customerTitle || '',
      customerFirstName: this.props.offer.customerFirstName || '',
      customerLastName: this.props.offer.customerLastName || '',
      customerStreet: this.props.offer.customerStreet || '',
      customerHouseNr: this.props.offer.customerHouseNr || '',
      customerPostalCode: this.props.offer.customerPostalCode || '',
      customerCity: this.props.offer.customerCity || '',
      customerEmail: this.props.offer.customerEmail || '',
      customerPhone: this.props.offer.customerPhone || '',
      customerFax: this.props.offer.customerFax || '',
      differingOwnerTitle: this.props.offer.differingOwnerTitle || '',
      differingOwnerCompanyName:
        this.props.offer.differingOwnerCompanyName || '',
      differingOwnerFirstName: this.props.offer.differingOwnerFirstName || '',
      differingOwnerLastName: this.props.offer.differingOwnerLastName || '',
      differingOwnerStreet: this.props.offer.differingOwnerStreet || '',
      differingOwnerHouseNr: this.props.offer.differingOwnerHouseNr || '',
      differingOwnerPostalCode: this.props.offer.differingOwnerPostalCode || '',
      differingOwnerCity: this.props.offer.differingOwnerCity || '',
      customerPreviousInsurerName:
        this.props.offer.customerPreviousInsurerName || '',
      customerPreviousInsurerId:
        this.props.offer.customerPreviousInsurerId || '',
      previousInsuranceNumber: this.props.offer.previousInsuranceNumber || '',
      isDefaultPreviousInsuranceNumber:
        this.props.offer.previousInsuranceNumber ===
        DEFAULT_PREVIOUS_INSURANCE_NUMBER,
      customerBankName: '',
      customerBankIBAN: this.props.offer.customerBankIBAN || '',
      customerBankAuthorizedSEPA:
        this.props.offer.customerBankAuthorizedSEPA || false,
      paymentinterval: this.props.offer.contractPaymentInterval,
      vehicleLicensePlateNumber:
        this.props.offer.vehicleLicensePlateNumber || '',
      vehicleIdentificationNumber:
        this.props.offer.vehicleIdentificationNumber || '',
      isAddressValidationOpen: false,
      isAddressValidated: false
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.isStructureReady = this.isStructureReady.bind(this);
    this.onValueChange = this.onValueChange.bind(this);

    this.regCompanyName = /^[\wäöüÄÖÜß\-&,"'() ]{2,128}$/;

    /* Flow A: Request pre-insurer information later */
    this.requestPreInsurerInformation =
      this.props.offer.customerFirstTimeInsuresThisCar &&
      this.props.offer.customerHadPreviousInsurer &&
      this.props.offer.customerCoverBonusFromPreviousInsurance;

    /* Flow C: Transfer from third Person */
    this.transferFromThirdPerson =
      !this.props.offer.customerHadPreviousInsurer &&
      this.props.offer.contractSfrInheritFromOther;

    /* Flow D: Change of insuerer */
    this.changeOfInsurer = !this.props.offer.customerFirstTimeInsuresThisCar;
  }

  componentDidMount() {
    scroll.scrollToTop();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.offer.personalDataUpdated &&
      this.props.offer.personalDataUpdated
    ) {
      this.handlePersonalDataUpdated();
    }
  }

  onValueChange(value, field) {
    if (
      field === 'customerTitle' ||
      field === 'customerFirstName' ||
      field === 'customerLastName' ||
      field === 'customerStreet' ||
      field === 'customerHouseNr' ||
      field === 'customerCity' ||
      field === 'customerEmail' ||
      field === 'customerPhone'
    ) {
      this.props.setOfferUserData({ field, value });
    }
    this.setState({ [field]: value });
    if (field === 'isDefaultPreviousInsuranceNumber') {
      if (value) {
        this.setState({
          previousInsuranceNumber: DEFAULT_PREVIOUS_INSURANCE_NUMBER
        });
      } else {
        this.setState({ previousInsuranceNumber: '' });
      }
    }
  }
  isStructureReady() {
    return this.props.structure !== undefined;
  }

  submitHandler(event) {
    if (event) event.preventDefault();
    if (this.validate()) {
      const title = this.state.customerIsCompany
        ? this.props.structure.structure.question2.fields.customerCompany
            .defaultValue
        : this.state.customerTitle;

      const personalData = preparePersonalData(this.state, title);

      const differingData = this.props.offer.contractDifferingOwner
        ? prepareDifferingOwner(this.state)
        : null;

      const vehicleData = this.changeOfInsurer
        ? {
            vehicleLicensePlateNumber: this.state.vehicleLicensePlateNumber,
            vehicleIdentificationNumber: this.state.vehicleIdentificationNumber
          }
        : {
            vehicleLicensePlateNumber: null,
            vehicleIdentificationNumber: null
          };

      if (this.changeOfInsurer) {
        this.props.setPersonalDetails(
          this.props.offer.id,
          personalData,
          differingData,
          vehicleData,
          this.state,
          'step5'
        );
      } else {
        this.setState(
          { vehicleLicensePlateNumber: '', vehicleIdentificationNumber: '' },
          () => {
            // setState is async but when callback fires, this.state is updated state
            this.props.setPersonalDetails(
              this.props.offer.id,
              personalData,
              differingData,
              vehicleData,
              this.state,
              'step5'
            );
          }
        );
      }
    }
  }

  validate(isPersonalDataUpdateError = false) {
    if (!isValidIBAN(this.state.customerBankIBAN)) {
      if (isPersonalDataUpdateError) {
        this.props.handlePersonalDataSnackbarError();
      } else {
        this.props.setErrorHandling(
          this.props.structure.structure.question5.fields.customerBankIBAN
            .errorMessage
        );
      }
      return false;
    }

    const { customerEmail } = this.state;
    if (customerEmail.replace(/\s+/g, '').length > 0) {
      if (!validationRegex.email.test(customerEmail)) {
        if (isPersonalDataUpdateError) {
          this.props.handlePersonalDataSnackbarError();
        } else {
          this.props.setErrorHandling(
            this.props.structure.structure.question2.fields.customerEmail
              .errorMessage
          );
        }
        return false;
      }
    }

    if (this.state.customerIsCompany) {
      if (!this.state.customerCompanyName.match(this.regCompanyName)) {
        if (isPersonalDataUpdateError) {
          this.props.handlePersonalDataSnackbarError();
        } else {
          this.props.setErrorHandling(
            this.props.structure.structure.question2.fields.customerCompanyName
              .errorMessage
          );
        }
        return false;
      }
    }

    if (this.state.previousInsuranceNumber.length > 20) {
      if (isPersonalDataUpdateError) {
        this.props.handlePersonalDataSnackbarError();
      } else {
        this.props.setErrorHandling(
          this.props.structure.structure.question4.fields
            .previousInsuranceNumber.errorMessage
        );
      }
      return false;
    }

    if (!isPersonalDataUpdateError && !this.state.isAddressValidated) {
      this.setState({ isAddressValidationOpen: true });
      return false;
    }

    return true;
  }

  handleAddressValidationNext = (customerAddress, differingOwnerAddress) => {
    let params = {};

    if (customerAddress) {
      params = {
        ...params,
        ...{
          customerCity: customerAddress.city,
          customerHouseNr: customerAddress.houseNumber,
          customerStreet: customerAddress.street,
          customerPostalCode: customerAddress.zipCode
        }
      };
    }

    if (differingOwnerAddress) {
      params = {
        ...params,
        ...{
          differingOwnerCity: differingOwnerAddress.city,
          differingOwnerHouseNr: differingOwnerAddress.houseNumber,
          differingOwnerStreet: differingOwnerAddress.street,
          differingOwnerPostalCode: differingOwnerAddress.zipCode
        }
      };
    }

    const stateChanges = {
      ...{ isAddressValidationOpen: false },
      ...{ ...params },
      ...{ isAddressValidated: true }
    };
    this.setState({ ...stateChanges }, () => {
      // setState is async but callback fires when state is updated
      this.submitHandler();
    });
  };

  handleStorePersonalDetails = async (isStoreDraftOffer = false) => {
    const title = this.state.customerIsCompany
      ? this.props.structure.structure.question2.fields.customerCompany
          .defaultValue
      : this.state.customerTitle;

    const personalData = preparePersonalData(this.state, title);

    const differingData = this.props.offer.contractDifferingOwner
      ? prepareDifferingOwner(this.state)
      : null;

    const vehicleData = this.changeOfInsurer
      ? {
          vehicleLicensePlateNumber: this.state.vehicleLicensePlateNumber,
          vehicleIdentificationNumber: this.state.vehicleIdentificationNumber
        }
      : {
          vehicleLicensePlateNumber: null,
          vehicleIdentificationNumber: null
        };

    const setPersonalDetailsFunc = isStoreDraftOffer ? this.props.setPersonalDraft : this.props.setPersonalDetails;
    await setPersonalDetailsFunc(
      this.props.offer.id,
      personalData,
      differingData,
      isStoreDraftOffer ? vehicleData : null,
      this.state,
      'step4'
    );
  };

  handleSetCorrectedZipCode = async zipCode => {
    await this.props.setCorrectedZipCode(zipCode);

    await this.handleStorePersonalDetails();

    this.props.variantChange('step1');
  };

  handlePersonalDataUpdated = async () => {
    await this.handleStorePersonalDetails(true);
  };

  render() {
    return (
      <div>
        {this.isStructureReady() && this.state.isAddressValidationOpen ? (
          <AddressValidation
            schema={this.props.schemaAddressValidation}
            handleAddressValidationNext={(
              customerAddress,
              differingOwnerAddress
            ) =>
              this.handleAddressValidationNext(
                customerAddress,
                differingOwnerAddress
              )
            }
            handleCancelButtonClick={() =>
              this.setState({ isAddressValidationOpen: false })
            }
            customerAddress={{
              city: this.state.customerCity,
              houseNumber: this.state.customerHouseNr,
              street: this.state.customerStreet,
              zipCode: this.state.customerPostalCode
            }}
            differingOwnerAddress={{
              city: this.state.differingOwnerCity,
              houseNumber: this.state.differingOwnerHouseNr,
              street: this.state.differingOwnerStreet,
              zipCode: this.state.differingOwnerPostalCode
            }}
            onSetCorrectedZipCode={this.handleSetCorrectedZipCode}
          />
        ) : null}
        {this.isStructureReady() && (
          <form onSubmit={event => this.submitHandler(event)}>
            <Grid
              container
              justify="flex-end"
              alignContent="center"
              spacing={2}
              className={this.classes.gridContainer}
            >
              <Paper className={this.props.classes.paper}>
                {/* Question 1 */}
                {!this.changeOfInsurer && (
                  <Fragment>
                    <Grid className={this.props.classes.quest1}>
                      <h2 className={this.classes.title}>
                        {this.props.structure.structure.question1.text}
                      </h2>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignContent="center"
                      spacing={2}
                    >
                      <Grid item sm={8} xs={12}>
                        <Grid
                          container
                          justify="center"
                          alignContent="center"
                          spacing={2}
                        >
                          <Grid item sm={4} xs={12}>
                            <div className={this.classes.datePickerInput}>
                              <DatePickerInput
                                {...this.props.structure.structure.question1
                                  .fields.startDate}
                                onChange={(value, field) =>
                                  this.onValueChange(value, field)
                                }
                                defaultValue={this.state.contractStartDate}
                                minDate={moment()}
                                yearDropdownItemNumber={30}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fragment>
                )}

                {/* Question 1a */}
                {this.changeOfInsurer && (
                  <Fragment>
                    <Grid className={this.props.classes.quest1}>
                      <h2 className={this.classes.title}>
                        {this.props.structure.structure.question1_1.text}
                      </h2>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignContent="center"
                      spacing={2}
                    >
                      <Grid item sm={8} xs={12}>
                        <Grid
                          container
                          justify="center"
                          alignContent="center"
                          spacing={2}
                        >
                          <Grid item sm={4} xs={12}>
                            <div className={this.classes.datePickerInput}>
                              <DatePickerInput
                                {...this.props.structure.structure.question1_1
                                  .fields.startDate}
                                onChange={(value, field) =>
                                  this.onValueChange(value, field)
                                }
                                defaultValue={this.state.contractStartDate}
                                minDate={moment()}
                                yearDropdownItemNumber={30}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fragment>
                )}

                {/* Question 2 */}
                <Grid className={this.props.classes.questions}>
                  <h2 className={this.classes.title}>
                    {this.props.structure.structure.question2.text}
                  </h2>
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  spacing={2}
                >
                  <Grid item sm={8} xs={12}>
                    <Grid
                      container
                      justify="flex-start"
                      alignContent="center"
                      spacing={2}
                    >
                      {this.state.customerIsCompany && (
                        <Fragment>
                          <Grid item sm={4} xs={12}>
                            <InputText
                              {...this.props.structure.structure.question2
                                .fields.customerCompany}
                              defaultValue={
                                this.props.structure.structure.question2.fields
                                  .customerCompany.defaultValue
                              }
                              readOnly
                            />
                          </Grid>
                          <Grid item sm={8} xs={false} />
                          <Grid item xs={12}>
                            <InputText
                              {...this.props.structure.structure.question2
                                .fields.customerCompanyName}
                              onChange={(value, field) =>
                                this.onValueChange(value, field)
                              }
                              defaultValue={this.state.customerCompanyName}
                              required={this.state.customerIsCompany}
                            />
                          </Grid>
                        </Fragment>
                      )}

                      {!this.state.customerIsCompany && (
                        <Fragment>
                          <Grid item sm={4} xs={12}>
                            <SelectField
                              {...this.props.structure.structure.question2
                                .fields.customerTitle}
                              defaultValue={this.state.customerTitle}
                              onChange={this.onValueChange}
                              options={
                                this.props.structure.structure.question2.fields
                                  .customerTitle.options
                              }
                              setRequired={!this.state.customerIsCompany}
                            />
                          </Grid>
                          <Grid item sm={8} xs={false} />
                          <Grid item sm={4} xs={12}>
                            <InputText
                              {...this.props.structure.structure.question2
                                .fields.customerFirstName}
                              onChange={(value, field) =>
                                this.onValueChange(value, field)
                              }
                              defaultValue={this.state.customerFirstName}
                              required={!this.state.customerIsCompany}
                              maxLength="29"
                            />
                          </Grid>
                          <Grid item sm={8} xs={12}>
                            <InputText
                              {...this.props.structure.structure.question2
                                .fields.customerLastName}
                              onChange={(value, field) =>
                                this.onValueChange(value, field)
                              }
                              defaultValue={this.state.customerLastName}
                              required={!this.state.customerIsCompany}
                              maxLength="29"
                            />
                          </Grid>
                        </Fragment>
                      )}
                      <Grid item sm={8} xs={12}>
                        <InputText
                          {...this.props.structure.structure.question2.fields
                            .customerStreet}
                          onChange={(value, field) =>
                            this.onValueChange(value, field)
                          }
                          defaultValue={this.state.customerStreet}
                          maxLength="25"
                          required
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <InputText
                          {...this.props.structure.structure.question2.fields
                            .customerHouseNr}
                          onChange={(value, field) =>
                            this.onValueChange(value, field)
                          }
                          defaultValue={this.state.customerHouseNr}
                          maxLength="4"
                          required
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <InputText
                          {...this.props.structure.structure.question2.fields
                            .customerPostalCode}
                          defaultValue={this.state.customerPostalCode}
                          maxLength="5"
                          readOnly
                          onButtonClick={() => this.props.openPostalCodeModal()}
                          buttonIcon={ButtonIcons.EDIT}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <InputText
                          {...this.props.structure.structure.question2.fields
                            .customerCity}
                          onChange={(value, field) =>
                            this.onValueChange(value, field)
                          }
                          defaultValue={this.state.customerCity}
                          maxLength="24"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputText
                          {...this.props.structure.structure.question2.fields
                            .customerEmail}
                          onChange={(value, field) =>
                            this.onValueChange(value, field)
                          }
                          defaultValue={this.state.customerEmail}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputText
                          {...this.props.structure.structure.question2.fields
                            .customerPhone}
                          onChange={(value, field) =>
                            this.onValueChange(value, field)
                          }
                          defaultValue={this.state.customerPhone}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Question 2_1 */}
                {this.changeOfInsurer && (
                  <Fragment>
                    <Grid className={this.props.classes.questions}>
                      <h2 className={this.classes.title}>
                        {this.props.structure.structure.question2_1.text}
                      </h2>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignContent="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        className={this.classes.questionDescription}
                      >
                        {this.props.structure.structure.question2_1.description}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignContent="center"
                      spacing={2}
                    >
                      <Grid item sm={8} xs={12}>
                        <Grid
                          container
                          justify="flex-start"
                          alignContent="center"
                          spacing={2}
                        >
                          <Fragment>
                            <Grid item xs={4}>
                              <InputText
                                {...this.props.structure.structure.question2_1
                                  .fields.vehicleLicensePlateNumber}
                                onChange={(value, field) =>
                                  this.onValueChange(value, field)
                                }
                                defaultValue={
                                  this.state.vehicleLicensePlateNumber
                                }
                                required={this.changeOfInsurer}
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <InputText
                                {...this.props.structure.structure.question2_1
                                  .fields.vehicleIdentificationNumber}
                                onChange={(value, field) =>
                                  this.onValueChange(value, field)
                                }
                                defaultValue={
                                  this.state.vehicleIdentificationNumber
                                }
                                required={this.changeOfInsurer}
                              />
                            </Grid>
                          </Fragment>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fragment>
                )}

                {/* Question 3 */}
                {this.props.offer.contractDifferingOwner === true && (
                  <React.Fragment>
                    <Grid className={this.props.classes.questions}>
                      <h2 className={this.classes.title}>
                        {this.props.structure.structure.question3.text}
                      </h2>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignContent="center"
                      spacing={2}
                    >
                      <Grid item sm={8} xs={12}>
                        <Grid
                          container
                          justify="flex-start"
                          alignContent="center"
                          spacing={2}
                        >
                          <Grid item sm={4} xs={12}>
                            <SelectField
                              {...this.props.structure.structure.question3
                                .fields.differingOwnerTitle}
                              defaultValue={this.state.differingOwnerTitle}
                              onChange={this.onValueChange}
                              options={
                                this.props.structure.structure.question3.fields
                                  .differingOwnerTitle.options
                              }
                              setRequired
                            />
                          </Grid>
                          <Grid item sm={8} xs={false} />

                          {this.state.differingOwnerTitle === COMPANY && (
                            <Fragment>
                              <Grid item xs={12}>
                                <InputText
                                  {...this.props.structure.structure.question3
                                    .fields.differingOwnerCompanyName}
                                  onChange={(value, field) =>
                                    this.onValueChange(value, field)
                                  }
                                  defaultValue={
                                    this.state.differingOwnerCompanyName
                                  }
                                  required={
                                    this.state.differingOwnerTitle === COMPANY
                                  }
                                />
                              </Grid>
                            </Fragment>
                          )}

                          {this.state.differingOwnerTitle !== COMPANY && (
                            <Fragment>
                              <Grid item sm={4} xs={12}>
                                <InputText
                                  {...this.props.structure.structure.question3
                                    .fields.differingOwnerFirstName}
                                  onChange={(value, field) =>
                                    this.onValueChange(value, field)
                                  }
                                  defaultValue={
                                    this.state.differingOwnerFirstName
                                  }
                                  required={
                                    this.state.differingOwnerTitle !== COMPANY
                                  }
                                  maxLength="29"
                                />
                              </Grid>
                              <Grid item sm={8} xs={12}>
                                <InputText
                                  {...this.props.structure.structure.question3
                                    .fields.differingOwnerLastName}
                                  onChange={(value, field) =>
                                    this.onValueChange(value, field)
                                  }
                                  defaultValue={
                                    this.state.differingOwnerLastName
                                  }
                                  maxLength="29"
                                  required
                                />
                              </Grid>
                            </Fragment>
                          )}

                          <Grid item sm={8} xs={12}>
                            <InputText
                              {...this.props.structure.structure.question3
                                .fields.differingOwnerStreet}
                              onChange={(value, field) =>
                                this.onValueChange(value, field)
                              }
                              defaultValue={this.state.differingOwnerStreet}
                              maxLength="25"
                              required
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <InputText
                              {...this.props.structure.structure.question3
                                .fields.differingOwnerHouseNr}
                              onChange={(value, field) =>
                                this.onValueChange(value, field)
                              }
                              defaultValue={this.state.differingOwnerHouseNr}
                              maxLength="4"
                              required
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <InputText
                              {...this.props.structure.structure.question3
                                .fields.differingOwnerPostalCode}
                              onChange={(value, field) =>
                                this.onValueChange(value, field)
                              }
                              defaultValue={this.state.differingOwnerPostalCode}
                              maxLength="5"
                              required
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <InputText
                              {...this.props.structure.structure.question3
                                .fields.differingOwnerCity}
                              onChange={(value, field) =>
                                this.onValueChange(value, field)
                              }
                              defaultValue={this.state.differingOwnerCity}
                              maxLength="24"
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                {/* Question 4 */}
                {(this.requestPreInsurerInformation ||
                  this.transferFromThirdPerson ||
                  this.changeOfInsurer) && (
                  <React.Fragment>
                    <Grid className={this.props.classes.questions}>
                      <h2 className={this.classes.title}>
                        {this.props.structure.structure.question4.text}
                      </h2>
                    </Grid>
                    <Grid
                      container
                      justify="center"
                      alignContent="center"
                      spacing={2}
                    >
                      <Grid item sm={8} xs={12}>
                        <PreviousInsurer
                          {...this.props.structure.structure.question4.fields
                            .customerPreviousInsurerName}
                          onChange={(value, field) =>
                            this.onValueChange(value, field)
                          }
                          previousInsurerName={
                            this.state.customerPreviousInsurerName
                          }
                        />
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <SwitchInput
                          {...this.props.structure.structure.question4.fields
                            .isDefaultPreviousInsuranceNumber}
                          onChange={(value, field) =>
                            this.onValueChange(value, field)
                          }
                          defaultValue={
                            this.state.isDefaultPreviousInsuranceNumber
                          }
                        />
                        {!this.state.isDefaultPreviousInsuranceNumber ? (
                          <InputText
                            {...this.props.structure.structure.question4.fields
                              .previousInsuranceNumber}
                            onChange={(value, field) =>
                              this.onValueChange(value, field)
                            }
                            defaultValue={this.state.previousInsuranceNumber}
                            required
                          />
                        ) : (
                          <React.Fragment>
                            {this.props.structure.structure.question4.previousInsuranceNumberInfo[
                              getCurrentProjectName()
                            ].map(info => (
                              <Typography
                                key={info}
                                component="p"
                                className={
                                  this.classes.previousInsuranceNumberInfo
                                }
                              >
                                {info}
                              </Typography>
                            ))}
                          </React.Fragment>
                        )}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                {/* Question 5 */}
                <Grid className={this.props.classes.questions}>
                  <h2 className={this.classes.title}>
                    {this.props.structure.structure.question5.text}
                  </h2>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item sm={8} xs={12}>
                    <InputText
                      {...this.props.structure.structure.question5.fields
                        .customerBankIBAN}
                      uppercase
                      onChange={(value, field) =>
                        this.onValueChange(sanitizeIBAN(value), field)
                      }
                      defaultValue={this.state.customerBankIBAN}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SwitchInput
                      {...this.props.structure.structure.question5.fields
                        .customerBankAuthorizedSEPA}
                      onChange={(value, field) =>
                        this.onValueChange(value, field)
                      }
                      defaultValue={this.state.customerBankAuthorizedSEPA}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              container
              justify="center"
              alignContent="center"
              className={this.classes.separator}
              spacing={2}
            />
            <Footer
              buttonSubmit={{
                props: this.props.structure.buttons.submit,
                onClick: () => {},
                disabled: !this.state.customerBankAuthorizedSEPA
              }}
              buttonLink={{
                props: this.props.structure.buttons.back,
                onClick: () => this.props.setStep('step3')
              }}
            />
          </form>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.insuranceStep4,
  offer: state.offerDetails.offer,
  carModelList: state.carModelList,
  schemaAddressValidation: state.projectStructure.addressValidation
});

const mapDispatchToProps = dispatch => ({
  setErrorHandling: message =>
    dispatch(
      openSnackbar({
        message,
        kind: SnackbarKind.ERROR
      })
    ),
  setPersonalDetails: (
    id,
    personalData,
    differingOwner,
    vehicleData,
    state,
    offerStep
  ) =>
    dispatch(
      setPersonalDetails(
        id,
        personalData,
        differingOwner,
        vehicleData,
        state,
        offerStep
      )
    ),
  setPersonalDraft: (
    id,
    personalData,
    differingOwner,
    vehicleData,
    state,
    offerStep
  ) =>
    dispatch(
      setPersonalDraft(
        id,
        personalData,
        differingOwner,
        vehicleData,
        state,
        offerStep
      )
    ),
  setStep: offerStep => dispatch(setStep(offerStep)),
  setOfferUserData: userData => dispatch(setOfferUserData(userData)),
  setCorrectedZipCode: zipCode => dispatch(setCorrectedZipCode(zipCode)),
  resetOfferToInitialStep: () => dispatch(resetOfferToInitialStep()),
  updateOfferStep: (id, offerStep) => dispatch(updateOfferStep(id, offerStep)),
  handlePersonalDataSnackbarError: () =>
    dispatch(handlePersonalDataSnackbarError())
});

Step4.propTypes = {
  message: PropTypes.string,
  setPersonalDetails: PropTypes.func,
  setPersonalDraft: PropTypes.func,
  setOfferUserData: PropTypes.func,
  error: PropTypes.bool,
  setStep: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
  schemaAddressValidation: PropTypes.objectOf(PropTypes.object),
  setCorrectedZipCode: PropTypes.func,
  resetOfferToInitialStep: PropTypes.func,
  updateOfferStep: PropTypes.func,
  handlePersonalDataSnackbarError: PropTypes.func
};

Step4.defaultProps = {
  classes: '',
  setPersonalDetails: () => '',
  setPersonalDraft: () => '',
  setOfferUserData: () => {},
  setStep: () => {},
  message: '',
  error: false,
  schemaAddressValidation: {},
  setCorrectedZipCode: () => {},
  updateOfferStep: () => {},
  handlePersonalDataSnackbarError: () => {}
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Step4);
