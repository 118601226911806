import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './Alert.style';

const Alert = ({
  label,
  handleButtonClick,
  style,
  hideButton,
  ...props
}) => {
  const classes = useStyles(props);
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      wrap="nowrap"
      className={classes.root}
      style={style}
    >
      <ErrorOutlineIcon className={classes.infoIcon} />
      <p className={classes.label}>{label}</p>

      {hideButton ? null
      : <button className={classes.button} onClick={handleButtonClick}><CloseIcon /></button>}

    </Grid>
  );
};

Alert.propTypes = {
  label: PropTypes.string,
  handleButtonClick: PropTypes.func,
  style: PropTypes.object, // temporary
  hideButton: PropTypes.bool,
  outlined: PropTypes.bool,
};

Alert.defaultProps = {
  label: '',
  handleButtonClick: () => {},
  style: {},
  hideButton: false,
  outlined: false,
};

export default Alert;
