import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Grid, Button, withStyles } from '@material-ui/core';
import { setMotorcycleAsVehicleType, setCarAsVehicleType } from '../../actions/settings';
import { VEHICLE_SEARCH as VEHICLE_SEARCH_URL } from '../../utils/redirects';
import history from '../../history';
import styles from './style';

const ChooseVehicleType = ({ classes, ...props }) => (
  <>
    {props.structure &&
    <Grid
      className={`${classes.container}`}
      container
      direction="row"
      justify="space-around"
      alignItems="center"
    >
      <Button
        onClick={() => {
          props.setCarAsVehicleType() &&
          history.push(`${VEHICLE_SEARCH_URL}${props.location.search}`);
        }}
        variant="outlined"
        className={classes.button}
      >
        {props.structure.buttons.car.label}
      </Button>

      <Button
        onClick={() => {
          props.setMotorcycleAsVehicleType() &&
          history.push(`${VEHICLE_SEARCH_URL}${props.location.search}`);
        }}
        variant="outlined"
        className={classes.button}
      >
        {props.structure.buttons.motorcycle.label}
      </Button>
    </Grid>
    }
  </>
);

ChooseVehicleType.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setMotorcycleAsVehicleType: PropTypes.func.isRequired,
  setCarAsVehicleType: PropTypes.func.isRequired,
  structure: PropTypes.objectOf(Object).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }),
};

ChooseVehicleType.defaultProps = {
  location: { search: '' },
};

const mapStateToProps = state => ({
  structure: state.projectStructure.chooseVehicleType,
});

const mapDispatchToProps = dispatch => ({
  setMotorcycleAsVehicleType: () => dispatch(setMotorcycleAsVehicleType()),
  setCarAsVehicleType: () => dispatch(setCarAsVehicleType()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ChooseVehicleType);
