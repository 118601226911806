import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { setUserPassword } from '../../actions/authentication';
import styles from './style';
import InputText from '../../components/InputText';
import Button from '../../components/Button';
import RuleCheck from '../../components/RuleCheck';

class CreatePassword extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      password: '',
      email: '',
      buttonDisabled: true,
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.ruleTest = this.ruleTest.bind(this);
    this.RuleCheckers = this.RuleCheckers.bind(this);
    this.isStructureReady = this.isStructureReady.bind(this);
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
    if (field === 'password') {
      const { rule } = this.props.structure;
      const buttonDisabled = Object.keys(rule).every(key => value.match(new RegExp(rule[key].reg)));
      this.setState({ buttonDisabled: !buttonDisabled });
    }
  }

  submitHandler(event) {
    event.preventDefault();
    if (this.validate()) {
      this.props.setUserPassword(
        this.props.match.params.token,
        this.state.email,
        this.state.password,
      );
    }
  }

  validate = () => {
    const formEl = this.formEl;
    const formLength = this.formEl.length;

    if (formEl.checkValidity() === false) {
      for (let i = 0; i < formLength; i++) {
        const elem = formEl[i];
        const errorLabel = elem.parentNode.querySelector('.invalid-feedback');
        if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
          const errorMessage = elem.attributes.errormessage.value;
          if (!elem.validity.valid) {
            errorLabel.textContent = errorMessage;
          } else {
            errorLabel.textContent = '';
          }
        }
      }
      return false;
    }
    for (let i = 0; i < formLength; i++) {
      const elem = formEl[i];
      const errorLabel = elem.parentNode.querySelector('.invalid-feedback');
      if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
        errorLabel.textContent = '';
      }
    }
    return true;
  };

  RuleCheckers(rules) {
    const mapItem = [];
    Object.keys(rules).map(key =>
      mapItem.push(<RuleCheck
        text={rules[key].text}
        valid={this.ruleTest(rules[key].reg)}
          // TODO change when decided on KEY naming convention
        key={`passwordCreationRule-${key}`}
      />));

    return mapItem;
  }

  ruleTest(rule) {
    return !!this.state.password.match(new RegExp(rule));
  }

  getHeader() {
    const { mainTextCreate, mainTextRestore } = this.props.structure.header;
    const { restore } = this.props;
    return restore ? mainTextRestore : mainTextCreate;
  }

  isStructureReady() {
    return this.props.structure !== undefined;
  }

  render() {
    const { restore } = this.props;
    return (
      <Typography component="div" className={this.classes.wrapper}>
        {this.isStructureReady() && (
          <Grid container justify="center" alignContent="center" spacing={0}>
            <Grid item xs={12}>
              <Typography align="center" variant="h1" className={this.classes.title}>
                {this.getHeader()}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <form
                onSubmit={event => this.submitHandler(event)}
                ref={form => (this.formEl = form)}
                noValidate
              >
                <InputText
                  {...this.props.inputFields.email}
                  {...this.props.structure.fields.email}
                  defaultValue={this.state.email}
                  onChange={this.onValueChange}
                />
                <InputText
                  {...this.props.inputFields.password}
                  {...this.props.structure.fields.password}
                  defaultValue={this.state.password}
                  onChange={this.onValueChange}
                  restore={restore}
                  validationRules={this.RuleCheckers(this.props.structure.rule)}
                />
                <Button
                  {...this.props.structure.buttons.submit}
                  disabled={this.state.buttonDisabled}
                />
              </form>
            </Grid>
          </Grid>
        )}
      </Typography>
    );
  }
}

const mapStateToProps = state => ({
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.passwordManagement,
  inputFields: state.projectFields,
});

const mapDispatchToProps = dispatch => ({
  setUserPassword: (token, email, password) => dispatch(setUserPassword(token, email, password)),
});

CreatePassword.propTypes = {
  message: PropTypes.string,
  setUserPassword: PropTypes.func,
  error: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  restore: PropTypes.bool,
};

CreatePassword.defaultProps = {
  setUserPassword: () => {},
  classes: '',
  message: '',
  error: false,
  restore: false,
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CreatePassword);
