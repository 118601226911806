import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { createUser } from '../../actions/userDetails';
import { getDealerships, getRoles, getSupervisors } from '../../actions';
import styles from './style';
import Button from '../../components/Button';
import SelectField from '../../components/SelectField';
import ButtonLink from '../../components/ButtonLink';
import InputText from '../../components/InputText';
import Notification from '../Notification';

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      firstName: '',
      lastName: '',
      employeeNumber: '',
      email: '',
      role: '',
      dealership: this.props.personalDetails.dealership,
      supervisor: '',
    };

    this.onClick = this.onClick.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onSelectBoxValueChange = this.onSelectBoxValueChange.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.inputFieldsRepeater = this.inputFieldsRepeater.bind(this);
  }

  componentDidMount() {
    this.props.getRoles('add');
    this.props.getDealerships('add');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.role !== this.state.role || prevState.dealership !== this.state.dealership) {
      this.getSelectBoxValues();
    }
  }

  onClick(variant) {
    this.props.variantChange(variant);
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
  }

  onSelectBoxValueChange(value, field) {
    this.setState({ [field]: value });
    this.getSelectBoxValues();
  }

  getSelectBoxValues() {
    this.props.getDealerships('add');
    this.props.getSupervisors('add', this.state.dealership, this.state.role);
    this.props.getRoles('add');
  }

  saveHandler() {
    this.props.createUser(
      this.state.firstName,
      this.state.lastName,
      this.state.employeeNumber,
      this.state.email,
      this.state.role,
      this.state.dealership,
      this.state.supervisor,
    );
  }

  isStructureReady() {
    if (this.props.structure === undefined && !this.props.structure.fields) {
      return null;
    }
    const { role, dealership } = this.props.structure.fields;
    return [role, dealership].every(item => item.options && item.options.length > 1);
  }

  inputFieldsRepeater(fields) {
    const mapItem = [];
    fields.forEach((key) => {
      switch (this.props.inputFields[key].type) {
        case 'select':
          mapItem.push(<SelectField
            {...this.props.inputFields[key]}
            {...this.props.structure.fields[key]}
            defaultValue={this.state[key]}
            onChange={this.onValueChange}
            key={`inputfields-${key}`}
          />);
          break;
        default:
          mapItem.push(<InputText
            {...this.props.inputFields[key]}
            {...this.props.structure.fields[key]}
            defaultValue={this.state[key]}
            onChange={this.onValueChange}
            key={`inputfields-${key}`}
          />);
      }
    });
    return mapItem;
  }

  render() {
    return (
      <Grid item xs={12}>
        {this.isStructureReady() && (
          <div>
            <ButtonLink
              {...this.props.structure.buttons.back}
              onClick={() => this.onClick('userSearch')}
            />
            <h2 className={this.classes.title}>{this.props.structure.header.maintext}</h2>
            <Grid container justify="center" alignContent="center" spacing={2}>
              <Grid item md={6} sm={8} xs={12}>
                <Notification />
                <Grid container justify="center" alignContent="center" spacing={2}>
                  <Grid item md={6} sm={8} xs={12}>
                    <InputText
                      {...this.props.inputFields.firstName}
                      defaultValue={this.state.firstName}
                      onChange={this.onValueChange}
                    />
                  </Grid>
                  <Grid item md={6} sm={8} xs={12}>
                    <InputText
                      {...this.props.inputFields.lastName}
                      defaultValue={this.state.lastName}
                      onChange={this.onValueChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {this.inputFieldsRepeater(this.props.structure.fieldsOrder)}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      {...this.props.structure.buttons.save}
                      onClick={() => this.saveHandler()}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails,
  personalDetails: state.personalDetails,
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.createUser,
  inputFields: state.projectFields,
});

const mapDispatchToProps = dispatch => ({
  createUser: (firstName, lastName, employeeNumber, email, role, dealership, upervisor) =>
    dispatch(createUser(firstName, lastName, employeeNumber, email, role, dealership, upervisor)),
  getDealerships: option => dispatch(getDealerships(option)),
  getRoles: option => dispatch(getRoles(option)),
  getSupervisors: (option, dealership, role) => dispatch(getSupervisors(option, dealership, role)),
});

CreateUser.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  createUser: PropTypes.func,
  getDealerships: PropTypes.func,
  getRoles: PropTypes.func,
  getSupervisors: PropTypes.func,
};

CreateUser.defaultProps = {
  classes: '',
  createUser: () => {},
  getDealerships: () => {},
  getRoles: () => {},
  getSupervisors: () => {},
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(CreateUser);
