/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import { Grid, Typography, Hidden, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { extendedTariffData, setStep } from '../../../actions/offer';
import RadioInput from '../../../components/RadioInput';
import OptionBlock from '../../../components/OptionBlock';
import OptionSlider from '../../../components/OptionSlider';
import CustomExpansionPanelDetails from '../../../components/CustomExpansionPanelDetails';
import CustomExpansionPanel from '../../../components/CustomExpansionPanel';
import CustomExpansionPanelSummary from '../../../components/CustomExpansionPanelSummary';
import CustomInputText from '../../../components/CustomInputText';
import Footer from '../../../components/Footer';
import DisplayInfo from '../../../components/DisplayInfo';
import styles from './style';
import RiskTypes from '../../../utils/riskTypes';
import { isMotorcycleSelected } from '../../../reducers/settings';
import { isBmw } from '../../../utils/theme';

class Step3 extends Component {
  constructor(props) {
    super(props);

    this.classes = {
      ...this.props.classes,
      hoveringPaper: 'hoveringPaper',
      totalPrice: 'totalPrice',
      separator: 'separator',
      unit: 'unit',
    };

    this.state = {
      expanded: false,
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.isStructureReady = this.isStructureReady.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.renderQuestion = this.renderQuestion.bind(this);
  }

  componentDidMount() {
    this.matchTime(this.props.offer.contractPaymentInterval);
    this.init();
    scroll.scrollToTop();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tariffs.length < 1 && this.props.tariffs.length > 0) {
      this.init();
    }
  }

  init = () => {
    if (this.props.tariffs.length > 0) {
      this.tariffTranslate = this.props.tariffs[0].special ? 'comfort' : this.props.offer.tariff;
      this.tariff =
        this.tariffTranslate && this.props.tariffs
          ? this.props.tariffs.findIndex(el => el.type === this.tariffTranslate)
          : 0;
      this.setState({
        paymentInterval: this.props.offer.contractPaymentInterval,
        roadsideAssistance: this.props.offer.contractOfferValuesRoadsideAssistance,
        mobility: this.props.offer.contractOfferValuesMobility || false,
        electroPlus: this.props.offer.contractOfferValuesElectroPlus || false,
        discountProtection: this.props.offer.contractOfferValuesDiscountProtection || false,
        driveProtection: this.props.offer.contractOfferValuesDriveProtection || false,
        driveGap: this.props.offer.contractOfferValuesDriveGAP || false,
        coverageAbroad: this.props.offer.contractOfferValuesCoverageAbroad || false,
        liability: this.props.offer.contractSfClassLiability || 0,
        vollkasko: this.props.offer.contractSfClassVollkasko || 0,
        isSpecial: this.props.tariffs[0].special,
        tariff: this.tariff,
        yearlyMileageInKM: this.props.offer.contractYearlyMileageInKM || 3,
        coverage: this.props.offer.riskType,
      });
    }
  };

  checkValueSFClass = () => {
    const maxSFValue = this.props.insuranceOffer.values.maxValueSFClass;
    if (Number(this.state.liability.toString().replace(',', '.')) > maxSFValue) {
      this.setState({ liability: maxSFValue }, this.recalculate);
    }
    if (Number(this.state.vollkasko.toString().replace(',', '.')) > maxSFValue) {
      this.setState({ vollkasko: maxSFValue }, this.recalculate);
    }
  };

  handleExpansionPanelChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  matchTime(value) {
    this.setState({
      time: this.props.structure.structure.question1.fields.paymentInterval.options.find(item => item.text === value),
    });
  }

  onValueChange(value, field) {
    this.matchTime(value);
    this.setState({ [field]: value }, this.recalculate);
  }

  onSliderChange(value, field = 'yearlyMileageInKM') {
    this.setState({ [field]: value }, this.recalculate);
  }

  isValidConditionsForDiscountProtection = () => {
    const now = moment();
    let driverAge = now.diff(moment(this.props.offer.customerBirthday), 'years');
    switch (this.props.offer.contractVehicleUserConditions) {
      default:
      case 1:
        break;
      case 2:
        driverAge = now.diff(moment(this.props.offer.contractVehicleUsersSpouseBirthdate), 'years');
        break;
      case 3:
        driverAge = this.props.offer.contractVehicleUsersYoungestDriverAge;
        break;
    }
    return (
      driverAge >= 23 &&
      this.props.offer.riskType === 'ComprehensiveCoverage' &&
      Number(this.state.liability.toString().replace(',', '.')) > 3 &&
      Number(this.state.vollkasko.toString().replace(',', '.')) > 3
    );
  };

  displaydDiscountProtection() {
    if (this.props.offer.customerIsCompany) {
      return null;
    }
    return this.isValidConditionsForDiscountProtection();
  }

  // Barkauf
  isFinancingInCash = () => this.props.offer.vehiclePaymentType === 1

  isFinancingBankBMW = () => isBmw() && this.props.offer.vehiclePaymentType === 5

  getPackage(selectedPackage) {
    if (!this.props.tariffs && !this.state.tariff) {
      return null;
    }

    return this.props.tariffs[this.state.tariff] &&
      this.props.tariffs[this.state.tariff].extras[selectedPackage]
      ? this.props.tariffs[this.state.tariff].extras[selectedPackage]
      : 0;
  }

  isStructureReady() {
    return (
      this.props.structure !== undefined &&
      this.props.tariffs.length > 0 &&
      this.state.yearlyMileageInKM
    );
  }

  recalculate() {
    if (!this.displaydDiscountProtection()) {
      this.setState({
        discountProtection: false,
      });
    }
    const data = {};
    if (!this.state.isSpecial) {
      Object.assign(data, {
        paymentInterval: this.state.paymentInterval,
        yearlyMileageInKM: this.state.yearlyMileageInKM,
        coverage: this.state.coverage,
        deductibleType: this.props.offer.contractDeductibleType,
        offerValues: {
          roadsideAssistance: this.state.roadsideAssistance,
          mobility: this.state.mobility,
          electroPlus: this.state.electroPlus,
          discountProtection: this.state.discountProtection,
          driveProtection: this.state.driveProtection,
          driveGap: this.state.driveGap,
          coverageAbroad: this.state.coverageAbroad,
        },
      });
    }
    data.sfClass = {
      liability: Number(this.state.liability.toString().replace(',', '.')),
      vollkasko: Number(this.state.vollkasko.toString().replace(',', '.')),
    };

    this.props.extendedTariffData(this.props.offer.id, data, 'step3');
  }

  submitHandler(event) {
    event.preventDefault();
    this.props.setStep('step4');
  }

  renderQuestion() {
    return (
      <Grid container justify="center">
        <Grid item>
          <Typography component="div" className={this.classes.containerPanelSummary}>
            <Hidden smDown>
              <Typography className={this.classes.expansionPanelHeading}>
                {`${this.props.structure.structure.question7.text}:`}&nbsp;
              </Typography>
            </Hidden>
            <Typography className={this.classes.expansionPanelSelection}>
              {`${this.props.structure.structure.question7.fields.liability.label}
                ${this.state.liability}`}
              {this.state.coverage !== RiskTypes.LIABILITY &&
                this.state.coverage !== RiskTypes.PARTIALLY_COMPREHENSIVE_COVERAGE &&
                `/ ${this.props.structure.structure.question7.fields.vollkasko.label}
                ${this.state.vollkasko}`}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <Fragment>
        {this.props.isSFEstimated && (
          <DisplayInfo information={this.props.insuranceOffer.information.valuesEstimated} />
        )}
        {this.isStructureReady() && (
          <form onSubmit={event => this.submitHandler(event)}>
            <Grid container justify="center" alignContent="center" direction="column" spacing={2}>
              {this.props.offer.customerHadPreviousInsurer ? (
                <CustomExpansionPanel
                  className={this.classes.paperExpansionPanel}
                  expanded={this.state.expanded === 'panel1'}
                  onChange={this.handleExpansionPanelChange('panel1')}
                >
                  <CustomExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Hidden smDown>
                      <Typography className={this.classes.expansionPanelHeading}>
                        {`${this.props.structure.structure.question7.text}:`}&nbsp;
                      </Typography>
                    </Hidden>
                    <Typography className={this.classes.expansionPanelSelection}>
                      {`${this.props.structure.structure.question7.fields.liability.label}
                        ${this.state.liability}`}
                      {this.state.coverage !== RiskTypes.LIABILITY &&
                        this.state.coverage !== RiskTypes.PARTIALLY_COMPREHENSIVE_COVERAGE &&
                        `/ ${this.props.structure.structure.question7.fields.vollkasko.label}
                        ${this.state.vollkasko}`}
                    </Typography>
                  </CustomExpansionPanelSummary>
                  <CustomExpansionPanelDetails>
                    <Grid container justify="center" alignContent="center" spacing={2}>
                      {this.state.isSpecial && (
                        <Grid container item justify="center" alignContent="center">
                          {this.props.structure.structure.specialWarning}
                        </Grid>
                      )}
                      <Grid container item justify="center" alignContent="center" className={this.classes.customInputTextContainer}>
                        <CustomInputText
                          {...this.props.structure.structure.question7.fields.liability}
                          onChange={(value, field) => this.onValueChange(value, field)}
                          value={this.state.liability}
                          onBlur={this.checkValueSFClass}
                          readOnly={!this.props.offer.customerHadPreviousInsurer}
                        />
                        {this.state.coverage !== RiskTypes.LIABILITY &&
                          this.state.coverage !==
                            RiskTypes.PARTIALLY_COMPREHENSIVE_COVERAGE && (
                            <CustomInputText
                              {...this.props.structure.structure.question7.fields.vollkasko}
                              onChange={(value, field) => this.onValueChange(value, field)}
                              value={this.state.vollkasko}
                              onBlur={this.checkValueSFClass}
                              readOnly={!this.props.offer.customerHadPreviousInsurer}
                            />
                          )}
                      </Grid>
                    </Grid>
                  </CustomExpansionPanelDetails>
                </CustomExpansionPanel>
              ) : (
                this.renderQuestion()
              )}
            </Grid>
            <Grid
              container
              justify="center"
              alignContent="center"
              spacing={2}
              className={this.classes.gridContainer}
            >
              {/* Question 1 */}
              <Grid container item xs={12} justify="center" alignContent="center">
                <h2 className={this.classes.title}>
                  {this.props.structure.structure.question1.title}
                </h2>
              </Grid>
              <Grid item className={this.state.isSpecial ? this.classes.specialPriceContainer : ''}>
                <RadioInput
                  {...this.props.structure.structure.question1.fields.paymentInterval}
                  onChange={(value, field) => this.onValueChange(value, field)}
                  defaultValue={this.state.paymentInterval}
                  readOnly={this.state.isSpecial}
                />
              </Grid>

              {/* Question 2 */}
              <OptionSlider
                startIndexAtZero={false}
                defaultValue={this.state.yearlyMileageInKM}
                title={this.props.structure.structure.question2.title}
                specialPriceLabel={this.props.structure.structure.question2.specialPriceLabel}
                options={
                  this.props.isMotorcycleSelected ?
                  this.props.structure.structure.question2.fields.yearlyMileageInKM.motorcycle.options :
                  this.props.structure.structure.question2.fields.yearlyMileageInKM.car.options}
                onChange={(value, field) => this.onSliderChange(value, field)}
                readOnly={this.state.isSpecial}
              />

              {/* Question 3 */}
              { !this.isFinancingBankBMW() && (
                <>
                  <Grid container item xs={12} justify="center" alignContent="center">
                    <h2 className={this.classes.title}>
                      {this.props.structure.structure.questionsLabel}
                    </h2>
                  </Grid>

                  {this.displaydDiscountProtection() && (
                    <OptionBlock
                      defaultValue={this.state.discountProtection}
                      {...this.props.structure.structure.question3}
                      unit={this.props.structure.structure.unit}
                      cost={this.getPackage('discountProtection')}
                      chosen={this.state.discountProtection ? 'black' : ''}
                      time={this.state.time}
                      onChange={(value, field) => this.onValueChange(value, field)}
                      readOnly={this.state.isSpecial}
                      specialPriceLabel={this.props.structure.structure.specialPriceLabel}
                    />
                  )}

                  <OptionBlock
                    defaultValue={this.state.roadsideAssistance}
                    {...this.props.structure.structure.question4}
                    unit={this.props.structure.structure.unit}
                    cost={this.getPackage('roadsideAssistance')}
                    chosen={this.state.roadsideAssistance ? 'black' : ''}
                    time={this.state.time}
                    onChange={(value, field) => this.onValueChange(value, field)}
                    readOnly={this.state.isSpecial}
                    specialPriceLabel={this.props.structure.structure.specialPriceLabel}
                  />

                  <OptionBlock
                    defaultValue={this.state.coverageAbroad}
                    {...this.props.structure.structure.question8}
                    unit={this.props.structure.structure.unit}
                        // cost={this.getPackage('coverageAbroad')}
                    chosen={this.state.coverageAbroad ? 'black' : ''}
                    time={this.state.time}
                    onChange={(value, field) => this.onValueChange(value, field)}
                    readOnly={this.state.isSpecial}
                    specialPriceLabel={this.props.structure.structure.specialPriceLabel}
                  />

                    { !this.isFinancingInCash() && (
                    <OptionBlock
                      defaultValue={this.state.driveGap}
                      {...this.props.structure.structure.question9}
                      unit={this.props.structure.structure.unit}
                      chosen={this.state.driveGap ? 'black' : ''}
                      time={this.state.time}
                      onChange={(value, field) => this.onValueChange(value, field)}
                      readOnly={this.state.isSpecial}
                      specialPriceLabel={this.props.structure.structure.specialPriceLabel}
                    />
                      )}

                  <OptionBlock
                    defaultValue={this.state.driveProtection}
                    {...this.props.structure.structure.question10}
                    unit={this.props.structure.structure.unit}
                        // cost={this.getPackage('driveProtection')}
                    chosen={this.state.driveProtection ? 'black' : ''}
                    time={this.state.time}
                    onChange={(value, field) => this.onValueChange(value, field)}
                    readOnly={this.state.isSpecial}
                    specialPriceLabel={this.props.structure.structure.specialPriceLabel}
                  />

                </>
              )}
            </Grid>
            <Grid
              container
              justify="center"
              alignContent="center"
              className={this.classes.separator}
              spacing={2}
            />
            <Footer
              buttonSubmit={{
                props: this.props.structure.structure.buttons.submit,
                onClick: () => {},
              }}
              buttonLink={{
                props: this.props.structure.structure.buttons.back,
                onClick: () => this.props.setStep('step2'),
              }}
            />
          </form>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.insuranceStep3,
  offer: state.offerDetails.offer,
  tariffs: state.offerDetails.tariffs,
  isSFEstimated: state.offerDetails.isSFEstimated,
  insuranceOffer: state.projectStructure.insuranceOffer,
  carModelList: state.carModelList,
  isMotorcycleSelected: isMotorcycleSelected(state.settings),
});

const mapDispatchToProps = dispatch => ({
  extendedTariffData: (id, contractData, offerStep, hideMessage) =>
    dispatch(extendedTariffData(id, contractData, offerStep, null)),
  setStep: offerStep => dispatch(setStep(offerStep)),
});

Step3.propTypes = {
  message: PropTypes.string,
  extendedTariffData: PropTypes.func,
  setStep: PropTypes.func,
  error: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  isMotorcycleSelected: PropTypes.bool.isRequired,
};

Step3.defaultProps = {
  extendedTariffData: () => {},
  setStep: () => {},
  classes: '',
  message: '',
  error: false,
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Step3);
