import _ from 'lodash';
import request from '../requestAgent';
import types from './types';
import handleErrors from './errorHandling';
import { carModelList } from './vehicleSearch';
import { findEndpoint, methodName } from '../helpers/endpoints/vehicles';
import { vehicleTypeKeys } from '../reducers/settings';

export const setVehicleModelBrands = brands => ({
  type: types.SET_VEHICLE_MODEL_BRANDS,
  payload: brands,
});

export const clearVehicleModelForm = clearForm => ({
  type: types.CLEAR_VEHICLE_MODEL_FORM,
  payload: clearForm,
});

const REMOVE_COUNTRY_CODE = / *\([^)]*\) */g;
const CAR_REDUNDANT_BRANDS = ['AUDI', 'BMW', 'TATA'];
const MOTORCYCLES_REDUNDANT_BRANDS = [
  'BMW',
  'BIKEBOARD',
  'CHANGZHOU',
  'CHONGQING',
  'CLAEYS',
  'DUCATI',
  'GUANGDONG',
  'HEIDEMANN',
  'HONDA',
  'HOREX',
  'JIANGSU',
  'MOTOBECANE',
  'NINGBO',
  'PEUGEOT',
  'PIAGGIO',
  'POVAZSKE',
  'SIMSON',
  'SUZUKI',
  'TAIZHOU',
  'YAMAHA',
  'ZHEJIANG'];

// TODO move processing to the backend and remove from here in future
export const getFormattedData = (vehicleType, data, value) =>
  data.map((vehicle) => {
    const newVehicle = { ...vehicle, [value]: vehicle[value].replace(REMOVE_COUNTRY_CODE, '') };

    const brandsChangeKeys = vehicleType === vehicleTypeKeys.CAR ?
      CAR_REDUNDANT_BRANDS
      : MOTORCYCLES_REDUNDANT_BRANDS;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < brandsChangeKeys.length; i++) {
      const upperCaseValue = newVehicle[value].toUpperCase();
      if (upperCaseValue.includes(brandsChangeKeys[i])) {
        newVehicle[value] = brandsChangeKeys[i];
        break;
      }
    }
    return newVehicle;
  });


export const getBrands = () => async (dispatch, getState) => {
  try {
    const { vehicleType } = getState().settings;
    if (vehicleType === vehicleTypeKeys.NON_SET) return;
    const url = findEndpoint(vehicleType, methodName.GET_BRANDS);
    const res = await request.get(url);

    const brands = getFormattedData(vehicleType, res.body, 'key');
    let preparedBrands = brands;

    if (vehicleType === vehicleTypeKeys.MOTORCYCLE) {
      // KEY equals VALUE due to motorcycles specific data, need to search by key
      preparedBrands = _.uniqBy(brands, 'key').map(brand => ({ key: brand.key, value: brand.key }));
    }

    dispatch(setVehicleModelBrands(preparedBrands));
  } catch (err) {
    dispatch(handleErrors(err.response.body));
  }
};

export const searchVehicles = (brand, model) => async (dispatch, getState) => {
  try {
    const { vehicleType } = getState().settings;
    if (vehicleType === vehicleTypeKeys.NON_SET) return;
    const url = findEndpoint(vehicleType, methodName.SEARCH_VEHICLES);

    const res = await request.post(url).send({ brand, model });
    const vehicles = getFormattedData(vehicleType, res.body, 'companyName');
    dispatch(carModelList(vehicles));
  } catch (err) {
    dispatch(handleErrors(err.response.body));
  }
};
