/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles, Typography } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import styles from './style';
import Button from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';
import history from '../../history';
import {
  requireAuth,
  allowedToAccessAdminPanel,
  allowedToAccessDropDown,
  allowedToAccessEvbList,
} from '../Authorization';

class UserMenu extends Component {
  state = {
    isShownMenu: false,
  };

  handleRedirect = (pathname, state) => {
    this.setState({
      isShownMenu: false,
    });

    const goToPage = {
      pathname,
      ...state,
    };
    history.push(goToPage);
  }

  handleToggleMenu = () => {
    this.setState({
      isShownMenu: !this.state.isShownMenu,
    });
  }

  handleLogout = () => {
    this.setState({ isShownMenu: false });
    history.push('/logout');
  }

  isStructureReady = () => this.props.structure !== undefined

  render() {
    const { classes } = this.props;
    return (
      <span>
        {this.state.isShownMenu &&
        <div
          className={classes.dim}
          onClick={() => this.handleToggleMenu()}
        />}
        <div className={classes.popupMenu}>
          <AccountCircle
            fontSize="large"
            className={classes.userIcon}
            onClick={this.handleToggleMenu}
            aria-haspopup="true"
            aria-controls="buttonYo"
          />
          <div id="buttonYo" hidden={!this.state.isShownMenu}>
            <div className={classes.menu}>
              <Typography component="p" className={classes.username}>
                {this.props.personalDetails.email}
              </Typography>
              {this.isStructureReady() && (
                <ul className={classes.linkMenu}>
                  <li className={classes.linkItem}>
                    <ButtonLink
                      label={this.props.structure.copy.myData}
                      onClick={() => this.handleRedirect('/accountdetails')}
                      position="right"
                    />
                  </li>
                  {allowedToAccessAdminPanel(this.props.personalDetails.role) && (
                    <li className={classes.linkItem}>
                      <ButtonLink
                        label={this.props.structure.copy.admin}
                        onClick={() => this.handleRedirect('/useradmin', { state: { variant: 'userSearch' } })}
                        position="right"
                      />
                    </li>
                  )}
                  {allowedToAccessEvbList(this.props.personalDetails.role) && (
                    <li className={classes.linkItem}>
                      <ButtonLink
                        label={this.props.structure.copy.evbList}
                        onClick={() => this.handleRedirect('/evblist')}
                        position="right"
                      />
                    </li>
                  )}
                  <li>
                    <Button
                      label={this.props.structure.buttons.logout.label}
                      onClick={this.handleLogout}
                      position="right"
                    />
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </span>
    );
  }
}

const mapStateToProps = state => ({
  personalDetails: state.personalDetails,
  structure: state.projectStructure.navbar,
  auth: state.auth,
});

UserMenu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

UserMenu.defaultProps = {
  classes: '',
};

export default requireAuth(
  compose(
    withStyles(styles),
    connect(mapStateToProps, null),
  )(UserMenu),
  allowedToAccessDropDown,
);
