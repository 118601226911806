import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';

const Error = ({ text, classes }) => <div className={classes.message}>{text}</div>;

export default withStyles(styles)(Error);

Error.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
};

Error.defaultProps = {
  text: '',
  classes: '',
};
