import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Email from '@material-ui/icons/Email';
import Print from '@material-ui/icons/Print';
import SaveIcon from '@material-ui/icons/Save';
import PrintNow from '@material-ui/icons/PrintOutlined';
import Phone from '@material-ui/icons/Phone';
import ButtonLink from '../../components/ButtonLink';
import ModalContactCallCenter from '../../components/ModalContactCallCenter';
import ModalSendOffer from '../../components/ModalSendOffer';
import ModalPrintOffer from '../../components/ModalPrintOffer';
import ModalSaveUserDetail from '../../components/ModalSaveUserDetail';
import ModalEditPostalCode from '../../components/ModalEditPostalCode';
import ModelLabel from '../../components/ModelLabel';
import { isMotorcycleSelected } from '../../reducers/settings';
import { getOfferDataFromFrontendStorage } from '../../actions/offerStorage';
import OFFER_STATUSES from '../../constants/offer';
import {
  isCallCenterUser,
  allowedToContactLeadManagementModal,
} from '../Authorization/index';

import styles from './style';
import { clearMessage } from '../../actions/notificationCenter';
import {
  getOfferDetails,
  setOfferStep,
  contactLeadManagement,
  setCustomerLogin,
} from '../../actions/offer';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

const STEP_1 = 'step1';

const isPrintSideIconToShow = (offer) => {
  const { offerStep, status } = offer;
  if (offerStep === STEP_1) return false;
  return [OFFER_STATUSES.INITIAL, OFFER_STATUSES.ONGOING].includes(status);
};

class InsuranceOffer extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      openSaveUserDetailModal: false,
      openEditPostalCodeModal: false,
      openSendOfferModal: false,
      openContactLeadManagementModal: false,
      openPrintOfferModal: false,
      screenVariant: this.props.offer.offerStep,
    };
  }

  componentDidMount() {
    this.fetchOffer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.offer.offerStep !== this.props.offer.offerStep) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ screenVariant: this.props.offer.offerStep });
    }
  }

  fetchOffer = () => {
    this.props.getOfferDetails(this.props.match.params.id, this.props.match.params.step)
      .finally(() => {
        this.props.getOfferDataFromFrontendStorage();
      });
  }

  handleVariantChange = (variant) => {
    this.props.clearMessage();
    this.setState({ screenVariant: variant });
    this.props.setOfferStep({ step: variant });
  }

  screenSwitch = (variant) => {
    switch (variant) {
      case 'step2':
        return <Step2 variantChange={this.handleVariantChange} />;
      case 'step3':
        return <Step3 variantChange={this.handleVariantChange} />;
      case 'step4':
        return <Step4 variantChange={this.handleVariantChange} openPostalCodeModal={this.toggleEditPostalCodeModal} />;
      case 'step5':
        return <Step5 variantChange={this.handleVariantChange} />;
      default:
        return <Step1 variantChange={this.handleVariantChange} />;
    }
  }

  toggleSendOfferModal = () => {
    this.setState({ openSendOfferModal: !this.state.openSendOfferModal });
  };

  toggleSaveUserDetailModal = () => {
    this.setState({ openSaveUserDetailModal: !this.state.openSaveUserDetailModal });
  };

  toggleEditPostalCodeModal = () => {
    this.setState({ openEditPostalCodeModal: !this.state.openEditPostalCodeModal });
  };

  togglePrintNowOfferModal = () => {
    this.setState({ printNow: true });
    this.setState({ openPrintOfferModal: !this.state.openPrintOfferModal });
  };

  togglePrintOfferModal = () => {
    this.setState({ printNow: false });
    this.setState({ openPrintOfferModal: !this.state.openPrintOfferModal });
  };

  toggleContactLeadManagementModal = () => {
    const isOpen = !this.state.openContactLeadManagementModal;
    this.setState({ openContactLeadManagementModal: isOpen });
  };

  stepsRender = (steps) => {
    const mapItem = [];
    Object.keys(steps).forEach((key) => {
      const keyNumber = parseInt(key.slice(-1), 10);
      const stepNumber = parseInt(this.state.screenVariant.slice(-1), 10);
      const lineSelection = () => {
        if (keyNumber === 5) {
          return this.classes.circleLine;
        } else if (stepNumber === keyNumber) {
          return `${this.classes.circleLine} ${this.classes.circleLineBorder} ${
            this.classes.selectedLine
          }`;
        } else if (stepNumber > keyNumber) {
          return `${this.classes.circleLine} ${this.classes.circleLineBorder} ${
            this.classes.prevLines
          }`;
        }
        return `${this.classes.circleLine} ${this.classes.circleLineBorder}`;
      };
      const circleSelection = () => {
        if (stepNumber === keyNumber) {
          return `${this.classes.circle} ${this.classes.selectedCircle}`;
        } else if (stepNumber > keyNumber) {
          return `${this.classes.circle} ${this.classes.prevCircles}`;
        }
        return this.classes.circle;
      };

      const textColorSelection = () => {
        if (stepNumber === keyNumber || stepNumber > keyNumber) {
          return this.classes.navTextSelected;
        }
        return this.classes.navText;
      };
      if (key === STEP_1) {
        mapItem.push(<Grid item xs={2} className={lineSelection()} key={key}>
          <FiberManualRecord className={circleSelection()} />
          <p className={textColorSelection()}>{steps[key]}</p>
        </Grid>);
      } else {
        mapItem.push(<Grid
          item
          xs={2}
          className={lineSelection()}
          key={key}
        >
          <FiberManualRecord className={circleSelection()} />
          <p className={textColorSelection()}>{steps[key]}</p>
        </Grid>);
      }
    });
    return mapItem;
  }

  isStructureAndOfferReady() {
    return this.props.structure && this.props.offer.offerStep !== null && this.props.offer.code;
  }

  render() {
    const { offer, structure } = this.props;
    return (
      <Fragment>
        {this.isStructureAndOfferReady() && (
          <Typography component="div">
            <Typography component="div" className={this.classes.wrapper}>
              <Grid
                container
                direction="column"
                justify="center"
                alignContent="center"
                spacing={0}
                className={this.classes.navBar}
              >
                <Typography align="center" variant="h4">
                  {structure.header.mainText}
                </Typography>
                <ModelLabel
                  vehicle={this.props.carModelList[0]}
                  isMotorcycleSelected={this.props.isMotorcycleSelected}
                />
              </Grid>
              <Grid
                container
                justify="center"
                alignContent="center"
                spacing={0}
                className={this.classes.navBar}
              >
                {this.stepsRender(structure.navbar)}
              </Grid>
            </Typography>
            <Typography component="div" className={this.classes.hr} />
            <Typography component="div" className={this.classes.content}>
              {this.state.screenVariant !== 'step5' &&
                this.state.screenVariant !== STEP_1 &&
                isCallCenterUser() && (
                  <Grid item xs={12} className={this.classes.buttonContainer}>
                    <Grid item xs={3} className={this.classes.buttonLeft}>
                      <ButtonLink
                        {...structure.openOverview}
                        onClick={() => this.handleVariantChange('step5')}
                      />
                    </Grid>
                  </Grid>
                )}

              <Grid
                container
                justify="flex-end"
                alignContent="center"
                spacing={0}
              >
                <span>
                  <SaveIcon
                    className={this.classes.sideIcons}
                    onClick={() => this.toggleSaveUserDetailModal()}
                  />
                </span>
                {allowedToContactLeadManagementModal() && (
                  <span>
                    <Phone
                      className={this.classes.sideIcons}
                      onClick={() => this.toggleContactLeadManagementModal()}
                    />
                  </span>
                )}
                <span>
                  <Email
                    className={this.classes.sideIcons}
                    onClick={() => this.toggleSendOfferModal()}
                  />
                </span>
                {isPrintSideIconToShow(this.props.offer) && (
                  <span>
                    <Print
                      className={this.classes.sideIcons}
                      onClick={() => this.togglePrintOfferModal()}
                    />
                  </span>
                )}
                {offer.offerStep === 'step5' && (
                  <span>
                    <PrintNow
                      className={this.classes.sideIcons}
                      onClick={() => this.togglePrintNowOfferModal()}
                    />
                  </span>
                )}
              </Grid>

              <Grid
                container
                justify="center"
                alignContent="center"
                spacing={0}
              >
                <Grid item xs={12}>
                  {this.screenSwitch(this.state.screenVariant)}
                </Grid>
              </Grid>

              <ModalSaveUserDetail
                offer={{
                  offerId: offer.id || this.props.match.params.step,
                  postalCode: offer.customerPostalCode || '',
                  isCompany: offer.customerIsCompany || '',
                  selectTitle: offer.customerTitle || '',
                  firstName: offer.customerFirstName || '',
                  lastName: offer.customerLastName || '',
                  street: offer.customerStreet || '',
                  houseNr: offer.customerHouseNr || '',
                  city: offer.customerCity || '',
                  email: offer.customerEmail || '',
                  phone: offer.customerPhone || ''
                }}
                isOpen={this.state.openSaveUserDetailModal}
                toggleSaveUserDetailModal={this.toggleSaveUserDetailModal}
                inputFields={this.props.inputFields}
                currentStep={offer.offerStep}
              />

              <ModalEditPostalCode
                isOpen={this.state.openEditPostalCodeModal}
                toggleEditPostalCodeModal={this.toggleEditPostalCodeModal}
                toggleVariantChange={this.handleVariantChange}
                structure={structure}
              />

              <ModalSendOffer
                offer={{
                  offerId: offer.id || this.props.match.params.step,
                  firstName: offer.firstName || '',
                  lastName: offer.lastName || '',
                  email: offer.email || '',
                  postalCode: offer.customerPostalCode || ''
                }}
                structure={structure}
                inputFields={this.props.inputFields}
                toggleSendOfferModal={this.toggleSendOfferModal}
                openSendOfferModal={this.state.openSendOfferModal}
                setCustomerLogin={this.props.setCustomerLogin}
                evbNumber={offer.evbNumber}
                currentStep={offer.offerStep}
              />

              <ModalContactCallCenter
                offerId={offer.id}
                structure={this.props.callCenterDialog.modal}
                inputFields={this.props.inputFields}
                toggleContactLeadManagementModal={
                  this.toggleContactLeadManagementModal
                }
                openContactLeadManagementModal={
                  this.state.openContactLeadManagementModal
                }
                contactLeadManagement={this.props.contactLeadManagement}
              />

              <ModalPrintOffer
                offer={{
                  offerId: offer.id || this.props.match.params.step,
                  firstName: offer.firstName || '',
                  lastName: offer.lastName || '',
                  email: offer.email || '',
                  postalCode: offer.customerPostalCode || ''
                }}
                structure={structure}
                inputFields={this.props.inputFields}
                togglePrintOfferModal={this.togglePrintOfferModal}
                openPrintOfferModal={this.state.openPrintOfferModal}
                setCustomerLogin={this.props.setCustomerLogin}
                evbNumber={offer.evbNumber}
                printNow={this.state.printNow}
              />
            </Typography>
          </Typography>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  personalDetails: state.personalDetails,
  offer: state.offerDetails.offer,
  tariffs: state.offerDetails.tariffs,
  inputFields: state.projectFields,
  structure: state.projectStructure.insuranceOffer,
  callCenterDialog: state.projectStructure.callCenterDialog,
  carModelList: state.carModelList,
  isMotorcycleSelected: isMotorcycleSelected(state.settings),
});

const mapDispatchToProps = dispatch => ({
  clearMessage: () => dispatch(clearMessage()),
  setOfferStep: step => dispatch(setOfferStep(step)),
  getOfferDetails: (id, step) => dispatch(getOfferDetails(id, step)),
  setCustomerLogin: userData => dispatch(setCustomerLogin(userData)),
  contactLeadManagement: userData => dispatch(contactLeadManagement(userData)),
  getOfferDataFromFrontendStorage: () => dispatch(getOfferDataFromFrontendStorage()),
});

InsuranceOffer.propTypes = {
  classes: PropTypes.object,
  structure: PropTypes.object,
  clearMessage: PropTypes.func,
  setOfferStep: PropTypes.func,
  getOfferDetails: PropTypes.func,
  setCustomerLogin: PropTypes.func,
  contactLeadManagement: PropTypes.func,
  offer: PropTypes.object,
  isMotorcycleSelected: PropTypes.bool.isRequired,
  getOfferDataFromFrontendStorage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  callCenterDialog: PropTypes.object,
  inputFields: PropTypes.object.isRequired,
  carModelList: PropTypes.arrayOf(PropTypes.object),
};

InsuranceOffer.defaultProps = {
  classes: {},
  structure: undefined,
  clearMessage: () => {},
  setOfferStep: () => {},
  getOfferDetails: () => {},
  setCustomerLogin: () => {},
  contactLeadManagement: () => {},
  offer: {},
  carModelList: [],
  callCenterDialog: {},
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(InsuranceOffer);
