import React, { Component } from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Label from '../../components/Label';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { getPreviousInsurer } from '../../actions/offer';

import styles from './style';

class PreviousInsurer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.previousInsurerName,
      suggestions: [],
    };

    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
  }

  handleSuggestionsFetchRequested = async ({ value }) => {
    this.setState({
      suggestions: await this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue,
    });
  };

  renderSuggestion(suggestion, { query, isHighlighted }) {
    const { classes } = this.props;
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) => part.highlight ? (
              <span key={String(index)} className={classes.menuItemHighlighted}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} className={classes.menuItem}>
                {part.text}
              </strong>
            )
          )}
        </div>
      </MenuItem>
    );
  }

  async getSuggestions(value) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = value.length;
    if (inputLength < 3) {
      return [];
    }

    const suggestions = await this.props.getPreviousInsurer(inputValue);
    let count = 0;
    return suggestions.filter(suggestion => {
      const keep = count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === value.trim().toLowerCase();

      if (keep) {
        count += 1;
      }

      return keep;
    });
  }

  getSuggestionValue(suggestion) {
    this.props.onChange(suggestion.name, 'customerPreviousInsurerName');
    this.props.onChange(suggestion.reference, 'customerPreviousInsurerId');
    return suggestion.name;
  }

  renderInputComponent = inputProps => (
    <div>
      <input {...inputProps} />
      <div className="invalid-feedback" />
    </div>
  );

  render() {
    const { classes } = this.props;
    const autosuggestProps = {
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
      renderInputComponent: this.renderInputComponent,
    };

    return (
      <div className={classes.container}>
        <Label label={this.props.label} />
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            placeholder: this.props.placeholder || '',
            classes,
            value: this.state.value,
            onChange: this.handleChange('value'),
          }}
          theme={{
            container: classes.container,
            input: classes.input,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPreviousInsurer: inputValue => dispatch(getPreviousInsurer(inputValue)),
});

PreviousInsurer.propTypes = {
  classes: PropTypes.object.isRequired,
  getPreviousInsurer: PropTypes.func,
};

PreviousInsurer.defaultProps = {
  classes: '',
  getPreviousInsurer: () => {},
};

export default compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps,
  ),
)(PreviousInsurer);
