const styles = theme => ({
  wrapper: theme.containers.wrapper,
  title: {
    marginTop: '48px',
    marginBottom: '62px',
  },
  slide: {
    overflow: 'hidden !important',
  },
  hr: {
    height: theme.spacing.unit,
    marginTop: theme.spacing.unit * 4,
    boxShadow: 'inset 0 12px 12px -12px rgba(0, 0, 0, 0.5)',
  },
  sideIcons: {
    '&:hover': {
      cursor: 'pointer',
    },
    fill: theme.palette.primary1Color,
    margin: 'auto',
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.textColorMid,
    fontFamily: theme.fontFamily,
    fontSize: '14px',
    fontWeight: 'bold',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.darkBlue,
    },
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spaceMid: {
    flexGrow: '0.8',
  },
  spaceSide: {
    flexGrow: '0.1',
  },
  arrowIcon: {
    height: '12px',
    width: '7.4px',
  },
});

export default styles;
