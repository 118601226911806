const styles = theme => ({
  text: {
    padding: theme.units.spacing * 3,
    color: theme.color,
    border: `2px solid ${theme.palette.primary1Color}`,
    margin: theme.units.spacing * 3,
    width: 'auto',
  },
});

export default styles;
