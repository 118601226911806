const styles = theme => ({
  root: {
    display: 'inline-flex',
  },
  expandIcon: {
    color: theme.palette.accent1Color,
  },
});

export default styles;
