import superagent from 'superagent';

import Cookie from 'js-cookie';

const agent = superagent.agent();

agent.on('end', () => {
  agent.set('X-CSRF-TOKEN', Cookie.get('DCIL-csrf-token'));
});

export default agent;
