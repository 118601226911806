import { bindActionCreators } from 'redux';
import request from '../../requestAgent';
import store from '../../store';
import handleErrors from '../../actions/errorHandling';

const fetchSalesChannels = async () => {
  try {
    const endpoint = '/api/request-manager/salesChannels';
    const { body } = await request.get(endpoint);
    return body.salesChannels ? body.salesChannels : {};
  } catch (err) {
    const boundActions = bindActionCreators({ handleErrors }, store.dispatch);
    boundActions.handleErrors(err);
  }

  return {};
};

export default fetchSalesChannels;
