import moment from 'moment';
import store from '../store';
import request from '../requestAgent';
import types from './types';
import { clearMessage } from './notificationCenter';
import history from '../history';
import handleErrors from './errorHandling';
import { openSnackbar, SnackbarKind } from './snackbar';
import { pickNewOfferParams } from '../utils/lwlQueryParams';
import { setMotorcycleAsVehicleType, setCarAsVehicleType } from './settings';
import { vehicleTypeKeys } from '../reducers/settings';
import { searchVehicleNumber } from './vehicleNumberSearch';
import { VEHICLE_SEARCH } from '../utils/redirects';

export const setOfferCode = offerDetails => ({
  type: types.SET_OFFER_CODE,
  payload: offerDetails,
});

export const setPersonalDataUpdated = value => ({
  type: types.SET_PERSONAL_DATA_UPDATED,
  payload: value,
});

export const setOfferStep = offerStep => ({
  type: types.SET_OFFER_STEP,
  payload: offerStep,
});

export const setOfferDetails = offerDetails => ({
  type: types.SET_OFFER_DETAILS,
  payload: offerDetails,
});
export const resetOfferPackages = () => ({
  type: types.RESET_OFFER_PACKAGES,
});

export const setEvbNumber = evbNumber => ({
  type: types.SET_EVB_NUMBER,
  payload: evbNumber,
});
export const setTariffs = tariffs => ({
  type: types.SET_TARIFFS,
  payload: tariffs,
});

export const setBasicTariffData = basicTariffData => ({
  type: types.SET_BASIC_TARIFF_DATA,
  payload: basicTariffData,
});

export const setCustomerIsCompany = isCompany => ({
  type: types.SET_IS_COMPANY,
  payload: isCompany,
});

export const setExtendedTariffData = extendedTariffData => ({
  type: types.SET_EXTENDED_TARIFF_DATA,
  payload: extendedTariffData,
});

export const setInitalOfferTariff = tariff => ({
  type: types.SET_INITIAL_TARIFF,
  payload: tariff,
});

export const changeStatus = status => ({
  type: types.CHANGE_STATUS,
  payload: status,
});

export const setMetaData = metaData => ({
  type: types.SET_META_DATA,
  payload: metaData,
});

export const addPersonalDetails = personalData => ({
  type: types.ADD_PERSONAL_DETAILS,
  payload: personalData,
});

export const setOfferUserData = userData => ({
  type: types.SET_OFFER_USER_DATA,
  payload: userData
});


export const setStep = step => (dispatch) => {
  dispatch(setOfferStep({ step }));
};

export const clearMessages = () => (dispatch) => {
  dispatch(clearMessage());
};

export const setSFEstimated = () => ({
  type: types.SET_SF_ESTIMATED,
});

export const setSubmitStep1DialogOpen = payload => ({
  type: types.SET_SUBMIT_STEP1_DIALOG_OPEN,
  payload,
});

export const clearOffer = () => ({
  type: types.CLEAR_OFFER,
});

const chooseStep = (data, step) => (dispatch) => {
  if (step === 'step5') {
    dispatch(setOfferStep({ step }));
    return;
  }

  if (!data.customerBirthday || (data.customerBirthday && step === 'step1')) {
    dispatch(setOfferStep({ step: 'step1' }));
    return;
  }
  if ((data.customerBirthday && !data.tariff > 0) || (data.tariff && step === 'step2')) {
    dispatch(setOfferStep({ step: 'step2' }));
    return;
  }
  if ((!data.customerStreet && data.tariff) || (data.tariff && step === 'step3')) {
    dispatch(setOfferStep({ step: 'step3' }));
    return;
  }
  if ((data.customerStreet && data.tariff) || (data.tariff && step === 'step4')) {
    dispatch(setOfferStep({ step: 'step4' }));
  }
};

export const DEFAULT_PREVIOUS_INSURANCE_NUMBER = 'XX9999999999';

export const COMPANY = 'Firma';

export const notModifiableOffers = {
  FINISHED: 'FINISHED',
  OVERDUE: 'OVERDUE',
  SENT: 'SENT',
  ADR_FAILED: 'ADR_FAILED',
  ADR_FAILED_FALLBACK_SENT: 'ADR_FAILED_FALLBACK_SENT',
};

export const initOffer = data => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer';
    const { personalDetails, vehicleNumberSearch, settings } = store.getState();
    const offer = {
      primaryEmployeeNumber: personalDetails.employeeNumber,
      vehicle: {
        hsn: vehicleNumberSearch.producerId,
        tsn: vehicleNumberSearch.vehTypeId.toUpperCase(),
        preOwned: JSON.parse(data.state),
        paymentType: parseInt(data.financing, 10),
        isElectric: vehicleNumberSearch.fuel.includes('Elektro') || vehicleNumberSearch.fuel.includes('Hybr'),
        registrationDate: data.registrationDate || moment(),
        type: settings.vehicleType,
        milage: data.milage,
      },
    };
    const queryParamsFromLwlRedirect = pickNewOfferParams(data);
    const res = await request.post(endpoint).send({ ...offer, ...queryParamsFromLwlRedirect });
    dispatch(setOfferDetails({
      ...res.body.offer,
    }));
    dispatch(setOfferStep({ step: 'step1' }));
    history.push(`insuranceoffer/${res.body.id}/`);
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const createOffer = (
  primaryEmployeeNumber,
  hsn,
  tsn,
  preOwned,
  paymentType,
  registrationDate,
  isElectric = false,
  milage,
  paramsFromLwlRedirect,
) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer';
    const { vehicleType } = store.getState().settings;
    const data = {
      primaryEmployeeNumber,
      vehicle: {
        hsn,
        tsn,
        preOwned,
        paymentType: parseInt(paymentType, 10),
        isElectric,
        type: vehicleType,
        milage,
      },
    };
    if (preOwned !== 'false') {
      if (!registrationDate) {
        data.vehicle.registrationDate = moment().format('MM/DD/YYYY');
      } else {
        data.vehicle.registrationDate = registrationDate;
      }
    }
    const res = await request.post(endpoint).send({ ...data, ...paramsFromLwlRedirect });

    dispatch(setOfferDetails({
      ...res.body.offer,
    }));
    dispatch(setOfferStep({ step: 'step1' }));

    history.push(`insuranceoffer/${res.body.id}/`);
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const confirmInitialOffer = (id, tariff, step, data) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/confirmInitialOffer';
    await request.post(endpoint).send({
      id,
      tariff,
      offerStep: step,
    });

    dispatch(changeStatus('ONGOING'));
    dispatch(resetOfferPackages());
    dispatch(setInitalOfferTariff(tariff));
    dispatch(setOfferStep({ step }));
    // eslint-disable-next-line no-use-before-define
    dispatch(extendedTariffData(id, data, step, true));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const basicTariffData = (id, customer, contract) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/basicTariffData';
    const res = await request.put(endpoint).send({
      id,
      customer,
      contract,
      offerStep: 'step2'
    });

    dispatch(setBasicTariffData({ customer, contract }));
    dispatch(setTariffs(res.body.tariffs));
    dispatch(setMetaData(res.body.meta));
    dispatch(setOfferDetails({
      ...res.body.offer,
    }));
    dispatch(setSubmitStep1DialogOpen(false));
    dispatch(setOfferStep({ step: 'step2' }));
  } catch (err) {
    dispatch(setSubmitStep1DialogOpen(false));
    dispatch(handleErrors(err.response.body));
  }
};

export const basicTariffDraft = (id, customer, contract) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/basicTariffDraft';
    const res = await request.put(endpoint).send({
      id,
      customer,
      contract,
      offerStep: 'step1'
    });

    dispatch(setPersonalDataUpdated(false));
    dispatch(resetOfferToInitialStep());
    dispatch(handlePersonalDataSnackbar());
  } catch (err) {
    dispatch(setPersonalDataUpdated(false));
    dispatch(handlePersonalDataSnackbar(false));
    dispatch(resetOfferToInitialStep());
  }
};

export const extendedTariffData =
(id, contractData, offerStep, hideMessage) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/extendedTariffData';
    const res = await request.patch(endpoint).send({
      id,
      contract: contractData,
      offerStep,
    });

    if (!hideMessage) {
      dispatch(openSnackbar({
        message: res.statusText,
        kind: SnackbarKind.SUCCESS,
      }));
    }
    dispatch(setExtendedTariffData(contractData));
    dispatch(setTariffs(res.body.tariffs));
    dispatch(setMetaData(res.body.meta));
    delete res.body.offer.offerStep;
    dispatch(setOfferDetails({
      ...res.body.offer,
    }));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const getPreviousInsurer = (term = '') => async (dispatch) => {
  let res;
  try {
    const endpoint = `/api/request-manager/getPreviousInsurer/${term}`;
    res = await request.get(endpoint);
    dispatch(openSnackbar({
      message: res.statusText,
      kind: SnackbarKind.SUCCESS,
    }));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
  return res.body || null;
};

export const contactLeadManagement = data => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/contactLeadManagement';
    const res = await request.post(endpoint).send(data);
    dispatch(openSnackbar({
      message: res.statusText,
      kind: SnackbarKind.SUCCESS,
    }));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const getOfferDetails = (id, step, isClient = false) => async (dispatch) => {
  try {
    const endpoint = `/api/request-manager/offer/${id}`;
    const res = await request.get(endpoint);

    dispatch(setOfferDetails(res.body.offer));
    dispatch(setTariffs(res.body.tariffs));

    const { vehicleType } = res.body.offer;
    if (vehicleType) {
      switch (vehicleType) {
        case vehicleTypeKeys.CAR:
          dispatch(setCarAsVehicleType());
          break;
        case vehicleTypeKeys.MOTORCYCLE:
          dispatch(setMotorcycleAsVehicleType());
          break;
        default:
        // eslint-disable-next-line no-console
          console.error('Unsupported vehicleType in offer');
      }
      const { vehicleHSN, vehicleTSN } = res.body.offer;
      dispatch(searchVehicleNumber({
        hsnNumber: vehicleHSN,
        tsnNumber: vehicleTSN,
      }));
    }

    if (res.body.offer && res.body.offer.id) {
      if (isClient) {
        dispatch(setOfferStep({ step: res.body.offer.offerStep }));
        history.push(`/insuranceoffer/${res.body.offer.id}/${res.body.offer.offerStep}`);
      } else {
        dispatch(chooseStep(res.body.offer, step || res.body.offer.offerStep));
      }
    } else {
      dispatch(setOfferStep({ step: 'step2' }));
    }
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const getOfferDetailsWithoutStepChange = id => async (dispatch) => {
  try {
    const endpoint = `/api/request-manager/offer/${id}`;
    const res = await request.get(endpoint);

    dispatch(setOfferDetails(res.body.offer));
    dispatch(setTariffs(res.body.tariffs));

    const { vehicleType } = res.body.offer;
    if (vehicleType) {
      (vehicleType === vehicleTypeKeys.CAR) ? dispatch(setCarAsVehicleType())
        : dispatch(setMotorcycleAsVehicleType());
    }

    const { vehicleHSN, vehicleTSN } = res.body.offer;
    dispatch(searchVehicleNumber({
      hsnNumber: vehicleHSN,
      tsnNumber: vehicleTSN,
    }));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const resetOfferToInitialStep = () => async (dispatch) => {
  dispatch(setPersonalDataUpdated(false));
  history.push(VEHICLE_SEARCH);
}

export const setPersonalDetails = (
  id,
  personalData,
  differingOwner,
  vehicleData,
  state,
  offerStep
) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/addPersonalData';
    const data = {
      id,
      personalData,
      differingOwner,
      vehicleData,
      offerStep,
    };

    if (!differingOwner) {
      delete data.differingOwner;
    }

    if (!vehicleData) {
      delete data.vehicleData;
    }

    const res = await request.post(endpoint).send(data);
    dispatch(openSnackbar({
      message: res.statusText,
      kind: SnackbarKind.SUCCESS,
    }));
    dispatch(addPersonalDetails({ ...state }));
    dispatch(setPersonalDataUpdated(false));
    dispatch(setOfferStep({ step: offerStep }));
  } catch (err) {
    dispatch(handleErrors(err.response.body));
  }
};

export const setPersonalDraft = (
  id,
  personalData,
  differingOwner,
  vehicleData,
  state,
  offerStep
) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/addPersonalDraft';
    const data = {
      id,
      personalData,
      differingOwner,
      vehicleData,
      offerStep,
    };

    if (!differingOwner) {
      delete data.differingOwner;
    }

    if (!vehicleData) {
      delete data.vehicleData;
    }

    const res = await request.post(endpoint).send(data);
    dispatch(openSnackbar({
      message: res.statusText,
      kind: SnackbarKind.SUCCESS,
    }));
    dispatch(addPersonalDetails({ ...state }));
    dispatch(setPersonalDataUpdated(false));
    dispatch(resetOfferToInitialStep());
    dispatch(handlePersonalDataSnackbar());
    dispatch(updateOfferStep(id, offerStep));
  } catch (err) {
    dispatch(handlePersonalDataSnackbar(false));
    dispatch(resetOfferToInitialStep());
    dispatch(updateOfferStep(id, offerStep));
  }
};

const handlePersonalDataSnackbar = (success = true) => dispatch => {
  dispatch(openSnackbar({
    message: success
      ? 'Die Daten wurden erfolgreich in der eVB Liste gespeichert.'
      : 'Das Angebot wurde in der eVB-Liste gespeichert, die Daten des Angebots wurden jedoch aufgrund von Fehlern im Formular nicht gespeichert.',
    kind: success ? SnackbarKind.SUCCESS : SnackbarKind.WARNING,
  }));
}

export const handlePersonalDataSnackbarError = () => dispatch => {
  dispatch(handlePersonalDataSnackbar(false));
}

export const setPersonalData = (
  id,
  personalData,
  currentStep,
) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/addPersonalDetails';
    const data = {
      id,
      personalData,
    };

    await request.post(endpoint).send(data);

    if (['step1', 'step4'].includes(currentStep)) {
      dispatch(setPersonalDataUpdated(true));
    } else {
      dispatch(resetOfferToInitialStep());
      dispatch(handlePersonalDataSnackbar());
    }

    // clear saved data in modal
    window.localStorage.removeItem(id);
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const setCustomerLogin = ({
  uuid,
  email,
  firstName,
  lastName,
  postalCode,
  companyName,
  secretWord,
  title = null,
  street = null,
  houseNr = null,
  city = null,
}) => async (dispatch) => {
  try {
    const endpoint = `/api/request-manager/offer/setCustomerLogin/${uuid}`;

    const name = companyName ? { companyName } : { firstName, lastName };

    const sendObject = {
      email,
      title,
      secretWord,
      ...name,
    };
    if (street || houseNr || city || postalCode) {
      Object.assign(sendObject, {
        postalCode,
        street,
        houseNr,
        city,
      });
    }

    const res = await request.post(endpoint).send({
      ...sendObject,
    });

    const offerData = {
      customerTitle: title,
    };

    if (companyName) {
      offerData.customerCompanyName = companyName;
    }

    if (firstName && lastName) {
      offerData.customerFirstName = firstName;
      offerData.customerLastName = lastName;
    }

    if (email) {
      offerData.customerEmail = email;
    }

    dispatch(setOfferDetails(offerData));
    dispatch(setEvbNumber(res.body.evbNumber));
    dispatch(openSnackbar({
      message: res.statusText,
      kind: SnackbarKind.SUCCESS,
    }));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const finishOffer = id => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/finish';
    const res = await request.post(endpoint).send({
      id,
    });
    dispatch(setEvbNumber(res.body.evbNumber));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const updateOfferStep = (id, nextStep) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/updateStep';
    const res = await request.post(endpoint).send({
      id,
      offerStep: nextStep
    });
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const sendDocuments = (id, email) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/sendOfferDocuments';
    await request.post(endpoint).send({ id, email });
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const setPostalCode = postCode => dispatch =>
  dispatch(setOfferDetails({ customerPostalCode: postCode }));

export const setIsCompany = isCompany => dispatch =>
  dispatch(setCustomerIsCompany(isCompany));
