import types from './types';
import request from '../requestAgent';
import handleErrors from './errorHandling';

export const setFrontedStorageData = data => ({
  type: types.SET_FRONTEND_STORAGE_DATA,
  payload: data,
});

/**
 * save offer data in persistant storage dedicated to store frontend state
 * @param {Object} params params to save { setSFKlasse: 'calculate' }
*/
export const saveOfferDataInFrontendStorage = params => async (dispatch, getState) => {
  try {
    const { id } = getState().offerDetails.offer;
    const endpoint = `/api/request-manager/offer/storage/${id}`;
    const { offerAnswers } = getState().offerDetails;
    const dataToStore = { ...offerAnswers, ...params };
    await request.put(endpoint).send({ offerAnswers: dataToStore });
    dispatch(setFrontedStorageData(dataToStore));
  } catch (err) {
    dispatch(handleErrors(err.response.body));
  }
};

/**
 * set offer data in store WITHOUT sending it to the backend
 * for selecting questions without go to next step in flow
 * @param {Object} params params to save { setSFKlasse: 'calculate' }
*/
export const setOfferDataInFrontendStorage = params => async (dispatch, getState) => {
  const { offerAnswers } = getState().offerDetails;
  dispatch(setFrontedStorageData({ ...offerAnswers, ...params }));
};

export const getOfferDataFromFrontendStorage = () => async (dispatch, getState) => {
  try {
    const { id } = getState().offerDetails.offer;
    const endpoint = `/api/request-manager/offer/storage/${id}`;
    const res = await request.get(endpoint).send();
    const { offerAnswers } = res.body;
    if (offerAnswers) {
      dispatch(setFrontedStorageData(offerAnswers));
    }
  } catch (err) {
    dispatch(handleErrors(err.response.body));
  }
};
