const styles = theme => ({
  container: theme.containers.container,
  paper: theme.containers.paper,
  label: theme.fonts.label,
  headlineTwo: theme.fonts.headline_2,
  link: {
    cursor: 'pointer',
    '-moz-user-select': 'none',
    '-webkit-user-select': 'none',
    '-ms-user-select': 'none',
    userSelect: 'none',
  },
  primary2Color: {
    color: theme.palette.primary2Color,
    justifyContent: 'center',
    alignItems: 'center',
    float: 'none',
    display: 'flex',
  },
  icons: {
    marginLeft: '10px',
  },
});

export default styles;
