import React, { Component } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Phone from '@material-ui/icons/Phone';
import Pdf from '@material-ui/icons/PictureAsPdf';
import styles from './style';
import CustomExpansionPanelDetails from '../../components/CustomExpansionPanelDetails';
import CustomExpansionPanel from '../../components/CustomExpansionPanel';
import CustomExpansionPanelSummary from '../../components/CustomExpansionPanelSummary';
import ButtonLink from '../../components/ButtonLink';
import history from '../../history';
import { getCurrentProjectName } from '../../utils/theme';

class SupportPage extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      expanded: null,
    };
    this.myRef = null;
    this.handleExpansionPanelChange = this.handleExpansionPanelChange.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.isStructureReady = this.isStructureReady.bind(this);
  }

  isStructureReady() {
    return this.props.structure !== undefined;
  }

  onClick() {
    history.goBack();
  }

  scrollToTop = () => {
    scroll.scrollToTop();
  }

  handleExpansionPanelChange = panel => (event, expanded) => {
    event.stopPropagation();
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    return (
      <Typography component="div" className={this.props.classes.wrapper}>
        {this.isStructureReady() && (
          <Grid container justify="center" alignContent="center" spacing={2}>
            <ButtonLink
              {...this.props.structure.buttons.back}
              onClick={() => this.onClick('showDetails')}
            />
            <Grid item md={8} sm={8} xs={12}>
              <Grid container justify="center" alignContent="center" spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography variant="h2" align="center">
                    {this.props.structure.header.mainText}
                  </Typography>
                  <Typography variant="h4" align="center">
                    {this.props.structure.header.subtext}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={5}>
                  <Paper className={`${this.classes.paper}  ${this.classes.contact} }`}>
                    <span className={this.classes.contactTitle}>
                      {this.props.structure.contact.header}
                    </span>
                    <span className={this.classes.contactPhone}>
                      {this.props.structure.contact.phones[getCurrentProjectName()]}
                    </span>
                    <span className={this.classes.contactHours}>
                      {this.props.structure.contact.hours[getCurrentProjectName()]}
                    </span>
                  </Paper>
                  <span className={this.classes.contactCall}>
                    <span>
                      {' '}
                      <Phone className={this.classes.iconAny} />
                    </span>
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                justify="center"
                alignContent="center"
                spacing={2}
                className={this.classes.sectionQuestions}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <Typography variant="h3" align="center">
                    {this.props.structure.questions.title}
                  </Typography>
                </Grid>
                {Object.keys(this.props.structure.questions.content).map((question, index) => (
                  <CustomExpansionPanel
                    key={index}
                    className={`${this.classes.paper}  ${this.classes.questions} }`}
                    expanded={this.state.expanded === question}
                    onChange={this.handleExpansionPanelChange(question)}
                  >
                    <CustomExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      className={this.classes.expansionPanelSummary}
                    >
                      <Typography className={this.classes.expansionPanelHeadline}>
                        {`${this.props.structure.questions.content[question].title}`}
                      </Typography>
                    </CustomExpansionPanelSummary>
                    <CustomExpansionPanelDetails>
                      <Grid container justify="center" alignContent="center" spacing={2}>
                        <Grid
                          container
                          item
                          justify="flex-start"
                          alignContent="flex-start"
                        >
                          {this.props.structure.questions.content[question].response.map((el, index) => (
                            <span key={index} className={this.classes.textParagraph}>
                              {el}
                            </span>
                                ))}
                        </Grid>
                      </Grid>
                    </CustomExpansionPanelDetails>
                  </CustomExpansionPanel>
                  ))}
              </Grid>
              <Grid container justify="center" alignContent="center" spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <h2 className={this.classes.sectionTitle}>
                    {this.props.structure.documents.title}
                  </h2>
                </Grid>
                <Paper className={`${this.classes.paper}  ${this.classes.documents} }`}>
                  <ul>
                    {this.props.structure.documents.documents[getCurrentProjectName()].map((el, index) => (
                      <li key={index} className={this.classes.singleDocument}>
                        <span>
                          {' '}
                          <Pdf className={this.classes.iconAny} />
                        </span>
                        <a href={el.link} target="_blank" rel="noopener noreferrer">{el.text}</a>
                      </li>
                      ))}
                  </ul>
                </Paper>
              </Grid>
              <Grid container justify="center" alignContent="center" spacing={2}>
                <ButtonLink
                  {...this.props.structure.buttons.top}
                  onClick={this.scrollToTop}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Typography>
    );
  }
}

const mapStateToProps = state => ({
  personalDetails: state.personalDetails,
  structure: state.projectStructure.supportPage,
  inputFields: state.projectFields,
});

SupportPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

SupportPage.defaultProps = {
  classes: '',
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(SupportPage);
