const styles = theme => ({
  icon: {
    border: 'none',
    position: 'relative',
    zIndex: '20',
    top: '5px',
    right: '5px',
    fill: theme.palette.primary1Color,
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 0.5,
  },
  link: {
    display: 'block',
    color: theme.palette.primary1Color,
    fontWeight: 'semiBold',
    fontSize: theme.typography.pxToRem(17),
    margin: '15px 0',
    lineHeight: '22px',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary1Color,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
  },
  container: {
    paddingTop: theme.spacing.unit * 3,
  },
});

export default styles;
