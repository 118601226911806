import types from './types';
import handleErrors from './errorHandling';
import { findEndpoint, methodName } from '../helpers/endpoints/vehicles';
import sendRequestSearchVehicleNumber from '../helpers/network/vehicles';

export const carModelList = carList => ({
  type: types.SET_CAR_MODEL_LIST,
  payload: carList,
});

export const clearCarModelList = () => ({
  type: types.CLEAR_CAR_MODEL_LIST,
});

export const searchVehicleNumber = (hsnNumber, tsnNumber) => async (dispatch, getState) => {
  try {
    const { vehicleType } = getState().settings;
    const url = findEndpoint(vehicleType, methodName.SEARCH_VEHICLE_NUMBER);
    const { body } = await sendRequestSearchVehicleNumber(vehicleType, url, hsnNumber, tsnNumber);
    dispatch(carModelList([body]));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};
