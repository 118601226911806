import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Typography, withStyles } from '@material-ui/core';
import { getOfferDetailsWithoutStepChange } from '../../actions/offer';
import NoEvbBox from './NoEvbBox';
import EvbBox from './EvbBox';
import styles from './style';

class FinishOffer extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getOfferDetailsWithoutStepChange(id);
  }

  isStructureReady = () => (this.props.structure !== undefined && this.props.offer.id);

  isEvb = () => (this.props.offer.evbNumber || this.props.offer.vehicleEvbNumber);
  isEvbToShow = () => (this.props.car.length && this.isEvb());
  isNoEvbToShow = () => (!this.props.offer.customerFirstTimeInsuresThisCar && !this.isEvb());

  render() {
    const {
      classes, structure, offer, car,
    } = this.props;

    return (
      <Typography component="div" className={classes.root}>
        {this.isStructureReady() ? (
          <Typography component="div" className={classes.wrapper}>
            {this.isEvbToShow() ?
              <EvbBox classes={classes} structure={structure} offer={offer} car={car} /> : null }
            {this.isNoEvbToShow() ?
              <NoEvbBox classes={classes} structure={structure} /> : null}
          </Typography>)
             : null}
      </Typography>
    );
  }
}

const mapStateToProps = state => ({
  structure: state.projectStructure.finishOffer,
  offer: state.offerDetails.offer,
  car: state.carModelList,
});

const mapDispatchToProps = dispatch => ({
  getOfferDetailsWithoutStepChange: id => dispatch(getOfferDetailsWithoutStepChange(id)),
});

FinishOffer.propTypes = {
  classes: PropTypes.objectOf(Object),
  structure: PropTypes.objectOf(Object),
  offer: PropTypes.objectOf(Object).isRequired,
  car: PropTypes.arrayOf(Object),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  getOfferDetailsWithoutStepChange: PropTypes.func,
};

FinishOffer.defaultProps = {
  classes: {},
  structure: undefined,
  car: undefined,
  match: { params: { id: '' } },
  getOfferDetailsWithoutStepChange: () => {},
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(FinishOffer);
