const styles = theme => ({
  title: {
    ...theme.containers.title,
    paddingTop: 0,
  },
  rootPaper: {
    ...theme.containers.rootPaper,
  },
  inputContainer: {
    paddingBottom: theme.spacing.unit * 4,
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.textColorLight,
    textTransform: 'uppercase',
    paddingBottom: theme.spacing.unit,
  },
  value: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
  },
  ...theme.modal,
});

export default styles;
