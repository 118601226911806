import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';

import { changeUserDetails, getUserDetails } from '../../actions/userDetails';
import { getDealerships, getRoles, getSupervisors } from '../../actions';
import styles from './style';
import Button from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';
import SelectField from '../../components/SelectField';
import InputText from '../../components/InputText';
import Notification from '../Notification';

class EditUserDetails extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      firstName: this.props.userDetails.firstName,
      lastName: this.props.userDetails.lastName,
      email: this.props.userDetails.email,
      employeeNumber: this.props.userDetails.employeeNumber,
      role: this.props.userDetails.role,
      dealership: this.props.userDetails.dealership,
      supervisor: this.props.userDetails.supervisorId,
    };

    this.onClick = this.onClick.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.isStructureReady = this.isStructureReady.bind(this);
  }

  componentDidMount() {
    this.props.getUserDetails(this.props.userDetails.id);
    this.getSelectBoxValues();
  }

  componentDidUpdate(prevProps, prevState) {
    const getSelectValue = key =>
      this.props.structure.fields[key].options.some(item => item.value === this.state[key]);

    ['supervisor', 'dealership'].forEach((item) => {
      if (
        !_.isEqual(
          prevProps.structure.fields[item].options,
          this.props.structure.fields[item].options,
        )
      ) {
        if (!getSelectValue(item)) {
          /* eslint-disable react/no-did-update-set-state */
          this.setState({ [item]: '' });
        }
      }
    });

    if (prevState.role !== this.state.role || prevState.dealership !== this.state.dealership) {
      this.getSelectBoxValues();
    }
  }

  onClick(variant) {
    this.props.variantChange(variant);
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
  }

  getSelectBoxValues() {
    this.props.getDealerships('edit');
    this.props.getSupervisors('edit', this.state.dealership, this.state.role);
    this.props.getRoles('edit');
  }

  submitHandler(event) {
    event.preventDefault();
    this.props.changeUserDetails(
      this.props.userDetails.id,
      this.state.firstName,
      this.state.lastName,
      this.state.email,
      this.state.employeeNumber,
      this.state.role,
      this.state.dealership,
      this.state.supervisor,
    );
  }

  inputFieldsRepeater(fields) {
    const mapItem = [];
    fields.forEach((key) => {
      switch (this.props.inputFields[key].type) {
        case 'select':
          mapItem.push(<SelectField
            {...this.props.inputFields[key]}
            {...this.props.structure.fields[key]}
            defaultValue={this.state[key]}
            onChange={this.onValueChange}
            key={`inputfields-${key}`}
          />);
          break;

        default:
          mapItem.push(<InputText
            {...this.props.inputFields[key]}
            {...this.props.structure.fields[key]}
            defaultValue={this.state[key]}
            onChange={this.onValueChange}
            key={`inputfields-${key}`}
          />);
      }
    });
    return mapItem;
  }

  isStructureReady() {
    if (this.props.structure && !this.props.structure.fields) {
      return null;
    }
    const { role, dealership, supervisor } = this.props.structure.fields;
    return [role, dealership, supervisor].every(item => item.options && item.options.length > 1);
  }

  render() {
    return (
      <Grid item xs={12}>
        {this.isStructureReady() && (
          <div>
            <ButtonLink
              {...this.props.structure.buttons.back}
              onClick={() => this.onClick('showDetails')}
            />
            <h2 className={this.classes.title}>{this.props.structure.header.maintext}</h2>
            <Grid container justify="center" alignContent="center" spacing={2}>
              <Grid item md={6} sm={8} xs={12}>
                <Notification />
                <form onSubmit={event => this.submitHandler(event)}>
                  <Grid container justify="center" alignContent="center" spacing={2}>
                    <Grid item md={6} sm={8} xs={12}>
                      <InputText
                        {...this.props.inputFields.firstName}
                        defaultValue={this.state.firstName}
                        onChange={this.onValueChange}
                      />
                    </Grid>
                    <Grid item md={6} sm={8} xs={12}>
                      <InputText
                        {...this.props.inputFields.lastName}
                        defaultValue={this.state.lastName}
                        onChange={this.onValueChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {this.inputFieldsRepeater(this.props.structure.fieldsOrder)}
                    </Grid>
                    <Grid item xs={12}>
                      <Button {...this.props.structure.buttons.save} />
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails,
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.editUserDetails,
  inputFields: state.projectFields,
  personalDetails: state.personalDetails,
});

const mapDispatchToProps = dispatch => ({
  changeUserDetails: (
    id,
    firstName,
    lastName,
    email,
    employeeNumber,
    role,
    dealership,
    supervisor,
  ) =>
    dispatch(changeUserDetails(
      id,
      firstName,
      lastName,
      email,
      employeeNumber,
      role,
      dealership,
      supervisor,
    )),
  getDealerships: option => dispatch(getDealerships(option)),
  getRoles: option => dispatch(getRoles(option)),
  getSupervisors: (option, dealership, role) => dispatch(getSupervisors(option, dealership, role)),
  getUserDetails: id => dispatch(getUserDetails(id)),
});

EditUserDetails.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  changeUserDetails: PropTypes.func,
  getDealerships: PropTypes.func,
  getRoles: PropTypes.func,
  getSupervisors: PropTypes.func,
  getUserDetails: PropTypes.func,
  structure: PropTypes.objectOf(Object),
};

EditUserDetails.defaultProps = {
  classes: '',
  changeUserDetails: () => {},
  getDealerships: () => {},
  getRoles: () => {},
  getSupervisors: () => {},
  getUserDetails: () => {},
  structure: undefined,
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EditUserDetails);
