import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './style';

// const prepareIconColor = (iconElement, disabled) => {
//   if (iconElement.props.color) {
//     return iconElement;
//   }
//   return React.cloneElement(iconElement, { color: textColor({ disabled }) });
// };

const ButtonLink = ({
  classes,
  // iconAfter,
  // iconBefore,
  label,
  onClick,
  disabled,
  type,
  underline,
}) => (
  <button
    underline={underline}
    className={classes.button}
    onClick={onClick}
    disabled={disabled}
    type={type}
  >
    {/* {iconBefore ? prepareIconColor(iconBefore) : null} */}
    {label}
    {/* {iconAfter ? prepareIconColor(iconAfter) : null} */}
  </button>
);

ButtonLink.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  // iconBefore: PropTypes.element,
  // iconAfter: PropTypes.element,
  label: PropTypes.string,
  onClick: PropTypes.func,
  underline: PropTypes.string,
  type: PropTypes.string,
};

ButtonLink.defaultProps = {
  onClick: () => {},
  // iconBefore: null,
  // iconAfter: null,
  disabled: false,
  label: 'temp label',
  classes: '',
  type: 'button',
  underline: 'none',
};

export default withStyles(styles)(ButtonLink);
