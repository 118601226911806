const styles = theme => ({
  label: theme.fonts.label,
  link: {
    float: 'right',
    color: theme.palette.primary1Color,
  },
});

export default styles;

