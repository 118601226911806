import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IconButton, withStyles, SnackbarContent } from '@material-ui/core';
import MaterialSnackbar from '@material-ui/core/Snackbar';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import * as classNames from 'classnames';
import { closeSnackbar, SnackbarKind, shiftMessages } from '../../actions/snackbar';
import styles from './style';

const snackbarActions = (props) => {
  const { onClose, classes } = props;
  const actionsArr = [
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={e => onClose && onClose(e, '')}
    >
      <CloseIcon className={classes.icon} />
    </IconButton>,
  ];
  return actionsArr;
};

const Snackbar = (props) => {
  const { classes } = props;

  const variants = {
    [SnackbarKind.INFO]: [InfoIcon, classes.info],
    [SnackbarKind.WARNING]: [WarningIcon, classes.warning],
    [SnackbarKind.SUCCESS]: [CheckCircleIcon, classes.success],
    [SnackbarKind.ERROR]: [ErrorIcon, classes.error],
  };

  const [Icon, cssClass] = variants[props.kind];

  return (
    <MaterialSnackbar
      open={props.open}
      message={props.message}
      onClose={props.onClose}
      onExited={props.onExited}
      anchorOrigin={props.anchorOrigin}
      autoHideDuration={props.autoHideDuration}
    >
      <SnackbarContent
        className={classNames(classes.snackbarContent, cssClass)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {props.message}
          </span>
        }
        action={snackbarActions(props)}
      />
    </MaterialSnackbar>
  );
};

const mapStateToProps = (state) => {
  const { messages } = state.snackbar;
  return {
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    open: state.snackbar.open,
    message: <span>{messages.length > 0 ? messages[0].message : ''}</span>,
    autoHideDuration: messages.length > 0 ? messages[0].hideDuration : 0,
    kind: messages.length > 0 ? messages[0].kind : SnackbarKind.INFO,
  };
};

const mapDispatchToProps = dispatch => ({
  onClose: (event, reason) => {
    if (reason !== 'clickaway') {
      dispatch(closeSnackbar());
    }
  },
  onExited: () => {
    dispatch(shiftMessages());
  },
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Snackbar);
