import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QUESTION_GROUP, CLIENT_TYPE } from '../../constants/';
import validateValueByRuleName from './Components/validateValueByRuleName';

import { getParsedCustomerContactSchema } from '../../reducers/structure';

import ContactFormStep1 from './ContactFormStep1';
import ContactFormStep2 from './ContactFormStep2';
import ContactFormStep3 from './ContactFormStep3';
import ContactFormStep4 from './ContactFormStep4';
import Footer from './Components/Footer';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 80px)',
    ...theme.containers.loginBackground,
    backgroundSize: 'cover',
  },
  container: {
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      // IE 11
      height: 'auto',
      minHeight: 'calc(100vh - 80px)',
    },
  },
}));

const isSchemaReady = schema => !!schema.step1;

const parseContactReasonsForCheckboxes = contactReasons =>
  (contactReasons.map(({ key }) => key).reduce((ac, a) => ({ ...ac, [a]: false }), {}));

export const getText = (schema, questionGroup, valueName) => {
  let str = '';
  try {
    str = schema.contactReasons.filter(({ key }) => key === questionGroup)[0].frontend[valueName];
  } catch {
    // do nothing
  }
  return str;
};

const canGoToStep3 = (selectedContactReasons) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of Object.entries(selectedContactReasons)) {
    if (item.length === 2) {
      if (item[1]) {
        return true;
      }
    }
  }
  return false;
};

export const getQuestions = (questions, questionGroup) => questions
  .filter(({ contactReason }) => contactReason === questionGroup)
  .reduce((ac, a) => ({ ...ac, [a.fieldName]: a }), {});

export const getMandatoryFields = (questions, questionGroup, clientType) => questions
  .filter(({ contactReason }) => contactReason === questionGroup)
  .filter(({ mandatory }) => !!mandatory)
  .filter(({ visibility }) => (visibility === clientType || visibility === 'ALL'))
  .map(({ fieldName }) => fieldName);

export const getFieldsWithValidationRules = (
  questions,
  questionGroup,
  clientType,
  withNotMandatory = false,
) => questions
  .filter(({ contactReason }) => contactReason === questionGroup)
  .filter(({ mandatory }) => withNotMandatory || !!mandatory)
  .filter(({ visibility }) => (visibility === clientType || visibility === 'ALL'))
  .filter(({ validation }) => (validation && validation.length));


export const validateValuesByRuleNames = (values, errors) => ({ fieldName: key, validation }) => {
  const value = values[key];
  if (value) {
    validation.forEach((ruleName) => {
      if (!errors[key] && !validateValueByRuleName(value, ruleName)) {
        // eslint-disable-next-line no-param-reassign
        errors[key] = ruleName;
      }
    });
  }
};

const initialFormValues = {
  [QUESTION_GROUP.IDENTIFICATION]: {},
  [QUESTION_GROUP.ADDRESS]: {},
  [QUESTION_GROUP.IBAN]: {},
  [QUESTION_GROUP.YEARLY_MILEAGE]: {},
  [QUESTION_GROUP.DRIVERS_GROUP]: {},
  [QUESTION_GROUP.NAME]: {},
  [QUESTION_GROUP.CONTRACT_SCOPE]: {},
  [QUESTION_GROUP.VEHICLE_SOLD]: {},
  [QUESTION_GROUP.DOCUMENT_REQUEST]: {},
  [QUESTION_GROUP.DOCUMENT_UPLOAD]: {},
  [QUESTION_GROUP.PREVIOUS_INSURER]: {},
};

const ContactForm = ({ schema }) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(1);

  const [selectedContactReasons, setSelectedContactReasons] = useState({});
  const [selectedClientType, setSelectedClientType] = useState(CLIENT_TYPE.LEGAL_PERSON);

  const [formValues, setFormValues] = useState(initialFormValues);

  const handleFormChange = (key, values) => {
    setFormValues({
      ...formValues,
      [key]: values,
    });
  };

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setFormValues(initialFormValues);
        if (isSchemaReady(schema)) {
          setSelectedContactReasons(parseContactReasonsForCheckboxes(schema.contactReasons));
        }
        break;
      case 2:
        setFormValues({
          ...initialFormValues,
          ...{ [QUESTION_GROUP.IDENTIFICATION]: formValues.IDENTIFICATION },
        });
        break;
      default:
          // do nothing
    }

    // eslint-disable-next-line
  }, [currentStep]);

  useEffect(() => {
    if (isSchemaReady(schema)) {
      setSelectedContactReasons(parseContactReasonsForCheckboxes(schema.contactReasons));
      setSelectedClientType(schema.clientType.physicalPerson);
    }
  }, [schema]);

  const handleNextButtonClick = () => {
    let nextStep = currentStep + 1;
    if (nextStep > 4) nextStep = 1;

    if (nextStep === 3) {
      const passToStep3 = canGoToStep3(selectedContactReasons);
      if (!passToStep3) return;
    }

    setCurrentStep(nextStep);
  };

  const handleBackButtonClick = () => {
    let nextStep = currentStep - 1;
    if (nextStep <= 0) nextStep = 1;
    setCurrentStep(nextStep);
  };

  if (!isSchemaReady(schema)) return <div className={classes.root} />;

  return (
    <div className={classes.container}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        {
          {
            1: <ContactFormStep1
              schema={schema}
              onNextButtonClick={handleNextButtonClick}
              setSelectedClientType={setSelectedClientType}
              clientType={selectedClientType}
            />,
            2: <ContactFormStep2
              schema={schema}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              selectedContactReasons={selectedContactReasons}
              setSelectedContactReasons={setSelectedContactReasons}
            />,
            3: <ContactFormStep3
              schema={schema}
              onBackButtonClick={handleBackButtonClick}
              moveToNextStep={handleNextButtonClick}
              clientType={selectedClientType}
              onFormChange={handleFormChange}
              formValues={formValues}
            />,
            4: <ContactFormStep4
              schema={schema}
              onBackButtonClick={handleBackButtonClick}
              clientType={selectedClientType}
              selectedContactReasons={selectedContactReasons}
              onFormChange={handleFormChange}
              formValues={formValues}
              setCurrentStep={setCurrentStep}
            />,
          }[currentStep]
        }
      </GoogleReCaptchaProvider>
      <Footer oemSchema={schema.oem} recaptchaPlaceholder />
    </div>
  );
};

const mapStateToProps = state => ({
  schema: getParsedCustomerContactSchema(state.projectStructure.customerContact),
});

ContactForm.propTypes = {
  schema: PropTypes.object,
};

ContactForm.defaultProps = {
  schema: {
    clientType: '',
  },
};

export default compose(connect(mapStateToProps, null))(ContactForm);
