import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core';
import cx from "classnames";
import styles from "./style";

const Button = ({
  classes,
  label,
  onClick,
  disabled,
  type,
  loading,
  version,
  position,
  myWidth
}) => (
  <button
    className={cx({
      [`${classes.button}`]: !disabled,
      [`${classes.buttonDisabled}`]: disabled,
      [`${classes.buttonLoadingHide}`]: !loading
    })}
    onClick={onClick}
    disabled={disabled}
    type={type}
  >
    <span
      className={cx(classes.buttonLabel, {
        [`${classes.buttonLabelHide}`]: loading
      })}
    >
      {label}
    </span>
  </button>
);

Button.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  myWidth: PropTypes.string,
  type: PropTypes.string,
  version: PropTypes.string,
  position: PropTypes.string
};

Button.defaultProps = {
  onClick: () => {},
  disabled: false,
  label: "temp label",
  loading: false,
  classes: "",
  type: "button",
  version: "primary",
  position: "left",
  myWidth: "170px"
};

export default withStyles(styles)(Button);
