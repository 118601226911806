import store from '../store';
import { isBmw } from './theme';

const USER_ROOT_PATH = '/';
const CLIENT_ROOT_PATH = '/angebot';
const SECOND_CLIENT_ROOT_PATH = '/clientauth';
const CREATE_PASSWORD = '/createpassword';
const RESET_PASSWORD = '/resetpassword';
const RESTORE_PASSWORD = '/restorepassword';
const LOGGED_IN_USER = 'LoggedInUser';
const CLIENT = 'Client';
export const VEHICLE_SEARCH = '/vehiclesearch';
export const CHOOSE_VEHICLE_TYPE = '/choosevehicletype';

export const user = store.getState().auth.loggedInUser || localStorage.getItem(LOGGED_IN_USER);

export const loginRoot = (currentLocation = USER_ROOT_PATH) => {
  if (
    [USER_ROOT_PATH, CLIENT_ROOT_PATH, SECOND_CLIENT_ROOT_PATH, RESET_PASSWORD, CREATE_PASSWORD, RESTORE_PASSWORD].includes(`/${currentLocation.split(USER_ROOT_PATH)[1]}`)
  ) {
    return currentLocation;
  }
  return user === CLIENT ? CLIENT_ROOT_PATH : USER_ROOT_PATH;
};

export const afterLogin = () => (isBmw() ? CHOOSE_VEHICLE_TYPE : VEHICLE_SEARCH);

export const resetPassword = () => RESET_PASSWORD;
