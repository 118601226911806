import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Grid, RadioGroup, FormControlLabel, FormControl, Radio } from '@material-ui/core';
import CustomTextField, { formikTextFieldProps } from './CustomTextField';
import { QUESTION_GROUP, CLIENT_TYPE } from '../../../constants';
import { getText, getQuestions, getFieldsWithValidationRules, validateValuesByRuleNames } from '../ContactForm';
import TitleLabel from './TitleLabel';
import DescriptionLabel from './DescriptionLabel';
import useFormSubmitTrigger from './useFormSubmitTrigger';
import useFormOnChange from './useFormOnChange';
import useFormErrorRender from './useFormErrorRender';

const currentFormQuestionGroup = QUESTION_GROUP.DRIVERS_GROUP;

const DriversGroupFormPhysical = (props) => {
  const {
    schema,
    submitTrigger,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    onChange,
    setFieldValue,
    onErrorMarked,
    isSubmitting
  } = props;

  const fields = getQuestions(schema.questions, currentFormQuestionGroup);
  const formRef = useFormSubmitTrigger(submitTrigger);
  useFormOnChange(onChange, values, currentFormQuestionGroup);
  useFormErrorRender(onErrorMarked, isSubmitting, errors);

  const formikProps = {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    validationErrorMessages: schema.validationErrorMessages,
  };

  const handleRadioChange = (e) => {
    setFieldValue('DateOfBirth', '');
    setFieldValue('YoungestAge', '');
    handleChange(e);
  };

  return (
    <form autoComplete="off" ref={formRef} onSubmit={handleSubmit}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <TitleLabel>
            {getText(schema, currentFormQuestionGroup, 'text_1')}
          </TitleLabel>
        </Grid>


        <Grid item xs={12}>
          <DescriptionLabel>
            {getText(schema, currentFormQuestionGroup, 'text_2')}
          </DescriptionLabel>
        </Grid>


        <Grid item xs={4}>
          <CustomTextField
            {...formikTextFieldProps(fields.Valid, formikProps)}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={8} />


        <Grid item xs={12}>
          <TitleLabel>
            {getText(schema, currentFormQuestionGroup, 'text_3')}
          </TitleLabel>
        </Grid>


        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup name="WhoIsDrivingRadioResponse" value={values.WhoIsDrivingRadioResponse} onChange={handleRadioChange}>
              <FormControlLabel
                value={fields.AnyDriver.label}
                control={<Radio color="primary" />}
                label={fields.AnyDriver.label}
              />
              <FormControlLabel
                value={fields.MyselfAndPerson.label}
                control={<Radio color="primary" />}
                label={fields.MyselfAndPerson.label}
              />
              <FormControlLabel
                value={fields.MyselfOnly.label}
                control={<Radio color="primary" />}
                label={fields.MyselfOnly.label}
              />
            </RadioGroup>
          </FormControl>
        </Grid>


        {values.WhoIsDrivingRadioResponse === fields.AnyDriver.label ?
          <>
            <Grid item xs={8}>
              <CustomTextField
                {...formikTextFieldProps(fields.YoungestAge, formikProps)}
              />
            </Grid>
            <Grid item xs={4} />
          </> : null}

        {values.WhoIsDrivingRadioResponse === fields.MyselfAndPerson.label ?
          <>
            <Grid item xs={6}>
              <CustomTextField
                {...formikTextFieldProps(fields.DateOfBirth, formikProps)}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6} />
          </> : null}


      </Grid>
    </form>
  );
};

DriversGroupFormPhysical.propTypes = {
  schema: PropTypes.object.isRequired,
  submitTrigger: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default withFormik({
  handleSubmit: (_, { setSubmitting, props }) => {
    props.onSuccessfulFormSubmit(currentFormQuestionGroup);
    setSubmitting(false);
  },
  mapPropsToValues: ({ formValues }) => ({
    WhoIsDrivingRadioResponse: formValues[currentFormQuestionGroup].WhoIsDrivingRadioResponse || 'Beliebige Fahrer',
    Valid: formValues[currentFormQuestionGroup].Valid,
    DateOfBirth: formValues[currentFormQuestionGroup].DateOfBirth,
    YoungestAge: formValues[currentFormQuestionGroup].YoungestAge,
  }),
  validate: (values, { schema, ...props }) => {
    const errors = {};

    const fields = getQuestions(schema.questions, currentFormQuestionGroup);

    getFieldsWithValidationRules(
      schema.questions,
      currentFormQuestionGroup,
      CLIENT_TYPE.PHYSICAL_PERSON,
    ).filter(({ fieldName: key }) => (key !== fields.DateOfBirth.fieldName
        || (key === fields.DateOfBirth.fieldName
          && values.WhoIsDrivingRadioResponse === fields.MyselfAndPerson.label)))
      .forEach(validateValuesByRuleNames(values, errors));

    if (!values.Valid) {
      errors.Valid = 'Required';
    }

    if (values.WhoIsDrivingRadioResponse === fields.AnyDriver.label && !values.YoungestAge) {
      errors.YoungestAge = 'Required';
    }

    if (values.WhoIsDrivingRadioResponse === fields.MyselfAndPerson.label && !values.DateOfBirth) {
      errors.DateOfBirth = 'Required';
    }

    if (Object.keys(errors).length > 0) {
      props.onValidationError(currentFormQuestionGroup);
    }

    return errors;
  },
})(DriversGroupFormPhysical);
