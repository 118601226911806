import types from '../actions/types';

const initialState = {
  brands: [],
  clearSearchForm: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_VEHICLE_MODEL_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case types.CLEAR_VEHICLE_MODEL_FORM:
      return {
        ...state,
        clearSearchForm: action.payload,
      };
    default:
      return state;
  }
};
