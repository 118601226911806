import React from 'react';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography } from '@material-ui/core';
import VehicleModel from '../../models/vehicle';
import ButtonIcon from '../../components/ButtonIcon';
import TextField from '../../components/TextField';
import {
  searchVehicleNumber,
  VEHICLE_NUMBER_SEARCH_FIND_CAR_FORM,
} from '../../actions/vehicleNumberSearch';
import { isMotorcycleSelected } from '../../reducers/settings';
import { HSN_NUMBER_VALIDATION, TSN_NUMBER_VALIDATION } from '../../validators/validators';
import styles from './style';

const VehicleNumberSearchFindCarForm = ({
  classes,
  hsnNumber,
  copy,
  handleSubmit,
  tsnNumber,
  pristine,
  submitting,
  invalid,
  vehicle,
  isMotorcycle,
}) => {
  const strengthDescription = () => (
    isMotorcycle
      ? `${vehicle.strengthKw} KW`
      : `${vehicle.strengthHp} PS / ${vehicle.strengthKw} KW`
  );

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container spacing={0}>
        <Grid container item spacing={2} xs={12} md={6}>
          <Grid item xs={12} sm={5} md={4}>
            <Field
              autoFocus
              id={hsnNumber.id}
              label={hsnNumber.label}
              required={hsnNumber.required}
              name={hsnNumber.name}
              placeholder={hsnNumber.placeholder}
              component={TextField}
              validate={HSN_NUMBER_VALIDATION}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <Field
              id={tsnNumber.id}
              label={tsnNumber.label}
              required={tsnNumber.required}
              name={tsnNumber.name}
              placeholder={tsnNumber.placeholder}
              component={TextField}
              validate={TSN_NUMBER_VALIDATION}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={4} className={classes.buttonContainer}>
            <ButtonIcon
              version="combined"
              type="submit"
              disabled={pristine || submitting || invalid}
            />
          </Grid>
        </Grid>
        {vehicle && (
          <Grid container item spacing={2} xs={12} md={6} className={classes.carContainer}>
            <Grid item xs={5} md={6}>
              <Typography component="div">
                <Typography component="p" className={classes.carTitle}>
                  {copy.model}
                </Typography>
                <Typography component="p" className={classes.carDesc}>
                  {vehicle.companyName} {vehicle.salesDesignation}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="div">
                <Typography component="p" className={classes.carTitle}>
                  {copy.power}
                </Typography>
                <Typography component="p" className={classes.carDesc}>
                  {strengthDescription(vehicle)}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

const mapStateToProps = state => ({
  isMotorcycle: isMotorcycleSelected(state.settings),
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    null,
  ),
  reduxForm({
    form: VEHICLE_NUMBER_SEARCH_FIND_CAR_FORM,
    onSubmit: (data, dispatch) => {
      dispatch(searchVehicleNumber(data));
    },
  }),
)(VehicleNumberSearchFindCarForm);

VehicleNumberSearchFindCarForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  tsnNumber: PropTypes.objectOf(Object).isRequired,
  hsnNumber: PropTypes.objectOf(Object).isRequired,
  copy: PropTypes.objectOf(Object).isRequired,
  isMotorcycle: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  vehicle: VehicleModel,
};

VehicleNumberSearchFindCarForm.defaultProps = {
  classes: '',
  vehicle: null,
  handleSubmit: () => {},
  pristine: false,
  invalid: true,
  submitting: false,
};
