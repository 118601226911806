import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClientLogin from '../../containers/ClientLogin';
import ClientSecretValidation from '../../containers/ClientSecretValidation';

const ClientAuth = props => {
  if (props.showSecretWordValidation) {
    return <ClientSecretValidation />;
  }
  return <ClientLogin />;
};

const mapStateToProps = state => ({
  showSecretWordValidation: state.auth.client.showSecretWordValidation,
});

ClientAuth.propTypes = {
  showSecretWordValidation: PropTypes.bool,
};

ClientAuth.defaultProps = {
  showSecretWordValidation: false,
};

export default connect(
  mapStateToProps,
  null,
)(ClientAuth);
