import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    minHeight: '68px',
    width: '100%',
    borderRadius: '4px',
    backgroundColor: props =>
      (props.outlined ? theme.containers.contactForm.whiteColor :
        theme.containers.contactForm.darkBlueColor),
    border: props =>
      (props.outlined ? `1px solid ${theme.containers.contactForm.darkBlueColor}` :
        '0'),
  },
  label: {
    margin: '14px 0',
    color: props =>
      (props.outlined ? theme.containers.contactForm.darkBlueColor :
        theme.containers.contactForm.whiteColor),
    fontFamily: theme.fonts.fontFamily,
    fontSize: '14px',
    lineHeight: '20px',
  },
  button: {
    margin: '0 16px',
    height: '24px',
    width: '24px',
    color: props =>
      (props.outlined ? theme.containers.contactForm.darkBlueColor :
        theme.containers.contactForm.whiteColor),
    background: 'Transparent no-repeat',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      // IE 11
      padding: '0',
      minHeight: '24px',
      minWidth: '24px',
    },
  },
  infoIcon: {
    margin: '0 16px',
    color: props =>
      (props.outlined ? theme.containers.contactForm.darkBlueColor :
        theme.containers.contactForm.whiteColor),
  },
}));
