const PASSWORD_TOO_SHORT_ERROR_CODE = 60004;

const UNATHORIZED = 401;

const UNATHORIZED_STATUS = 'Unauthorized';

export {
  UNATHORIZED,
  UNATHORIZED_STATUS,
  PASSWORD_TOO_SHORT_ERROR_CODE,
};
