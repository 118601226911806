/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Check from '@material-ui/icons/Check';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import RootRef from '@material-ui/core/RootRef';
import _ from 'lodash';
import { getCurrentProjectName } from '../../utils/theme';
import Price from '../Price';
import Button from '../Button';
import styles from './style';

class NormalTariffComparison extends Component {
  constructor(props) {
    super(props);
    this.basic = React.createRef();
    this.comfort = React.createRef();
    this.state = {
      paperHeight: 'auto',
    };
  }

  componentDidMount() {
    // for trigger componentDidUpdate for runs without page reload, it's cover edge case
    this.setState({ paperHeight: 'auto' });
  }

  componentDidUpdate() {
    this.equalizeFactsListsHeight();
  }

  getTariffCards = () =>
    this.props.metaData.tariffs
      .filter((tariff) => {
        if (!this.props.prices[tariff.name]) return false;
        if (!this.props.isSpecial) return tariff.displayOn.includes(this.props.coverage);
        return this.props.prices[tariff.name].special;
      }).map(({ name: tariff }) => (
        <Grid item xs={5} key={tariff}>
          <Grid
            container
            justify="center"
            alignContent="center"
            alignItems="stretch"
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Paper>
                <Typography
                  className={`${this.props.classes.paper} ${this.props.classes.headline}`}
                >
                  {this.props.isSpecial
                    ? this.props.metaData.special.label
                    : this.props.metaData[tariff].label[getCurrentProjectName()]}
                </Typography>
                <>
                  <Hidden xsDown>
                    <ListItem>
                      <ListItemText>
                        <Typography className={this.props.classes.listItems}>
                          {this.props.metaData[tariff].text}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <RootRef rootRef={this[tariff]}>
                      <List component="nav" style={{ minHeight: `${this.state.paperHeight}` }}>
                        {this.normalDisplayFacts(tariff, this.props.isSpecial)}
                      </List>
                    </RootRef>
                  </Hidden>
                </>
                <Price
                  price={this.props.prices[tariff].price}
                  unit={this.props.footerPrice[this.props.unit].unit}
                />
              </Paper>
              <Paper>
                <Button
                  {...this.props.metaData[tariff].buttons.submit}
                  onClick={this.clickHandler(tariff)}
                  myWidth="100%"
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      ));

  normalDisplayFacts = (tariff, isSpecial) =>
    this.props.metaData[tariff].fields.facts.items[getCurrentProjectName()]
      .filter(({ priceTypeFilter }) => priceTypeFilter.includes(isSpecial ? 'special' : 'nonspecial'))
      .filter(({ key }) => key.includes(this.props.coverage))
      .map(({ value }) => (
        <ListItem key={value}>
          <ListItemIcon>
            <Check className={this.props.classes.accent1Color} />
          </ListItemIcon>
          <ListItemText>
            <Typography className={this.props.classes.listItems}>{value}</Typography>
          </ListItemText>
        </ListItem>
      ));
  clickHandler = tarr => () => {
    this.props.onClick()(this.props.prices[tarr].type);
  };

  equalizeFactsListsHeight = () => {
    if (this.basic.current && this.comfort.current &&
     this.basic.current.clientHeight > 0 && this.comfort.current.clientHeight > 0) {
      const max = _.max([this.basic.current.clientHeight, this.comfort.current.clientHeight]);
      const formattedMax = `${max}px`;
      if (this.state.paperHeight !== formattedMax) {
        // componentDidUpdate setState is in this case safe because of if statement,
        // it's not in infinite loop
        this.setState({
          paperHeight: formattedMax,
        });
      }
    }
  }

  render() {
    return (
      <Grid container justify="center" alignContent="center" direction="row" spacing={2}>
        <Grid item xs={1} />
        {this.getTariffCards()}
        <Grid item xs={1} />
      </Grid>
    );
  }
}

NormalTariffComparison.propTypes = {
  message: PropTypes.string,
  error: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  metaData: PropTypes.objectOf(Object).isRequired,
  coverage: PropTypes.string.isRequired,
  prices: PropTypes.objectOf(Object).isRequired,
  unit: PropTypes.string.isRequired,
  footerPrice: PropTypes.objectOf(Object).isRequired,
  onClick: PropTypes.func,
  isSpecial: PropTypes.bool,
};

NormalTariffComparison.defaultProps = {
  classes: '',
  message: '',
  error: false,
  onClick: () => {},
  isSpecial: false,
};

export default withStyles(styles)(NormalTariffComparison);
