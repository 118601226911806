const styles = theme => ({
  wrapper: theme.containers.wrapper,
  content: {
    ...theme.containers.wrapper,
    marginBottom: theme.spacing.unit * 6,
  },
  circleLine: {
    margin: '10px 0px',
    height: '13px',
  },
  circleLineBorder: {
    position: 'relative',
    '&::after': {
      borderBottom: `2px solid ${theme.palette.secondaryTextColor}`,
      content: '""',
      position: 'absolute',
      bottom: '0px',
      width: '100%',
      left: '50%',
      zIndex: '10',
    },
  },
  prevLines: {
    '&::after': {
      borderBottom: `2px solid ${theme.palette.primary1Color}`,
    },
  },
  selectedLine: {
    '&::after': {
      // borderBottom: `2px solid ${theme.palette.textColorVeryLight}`,
    },
  },
  line: {
    width: '100%',
  },
  circle: {
    position: 'relative',
    zIndex: '20',
    '&:hover': {
      cursor: 'pointer',
    },
    color: '#CCCCCC',
    marginLeft: '47%',
    display: 'flex',
  },
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    circle: {
      marginLeft: '47%',
    },
  },
  selectedCircle: {
    fill: theme.palette.accent1Color,
  },
  prevCircles: {
    fill: theme.palette.accent1Color,
  },
  sideIcons: {
    'margin-left': '20px',
    border: 'none',
    position: 'relative',
    zIndex: '20',
    '&:hover': {
      cursor: 'pointer',
    },
    fill: theme.palette.primary1Color,
    margin: 'auto',
    display: 'flex',
  },
  navBar: {
    height: '100px',
    position: 'relative',
  },
  navText: {
    color: '#CCCCCC',
    fontSize: '0.75rem',
    fontWeight: '600',
    lineHeight: '17px',
    textAlign: 'center',
  },
  navTextSelected: {
    color: theme.palette.accent1Color,
    fontSize: '0.75rem',
    fontWeight: '600',
    lineHeight: '17px',
    textAlign: 'center',
  },
  hr: {
    height: theme.spacing.unit,
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 2,
    boxShadow: 'inset 0 12px 12px -12px rgba(0, 0, 0, 0.5)',
  },
});

export default styles;
