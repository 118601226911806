// TODO add correct color shades from theme
const borderColorSwitcher = ({ warning }) => {
  if (warning === true) return 'red';
  return 'green';
};

const visibility = ({ message }) => {
  if (message === '') return 'none';
  return 'block';
};

const styles = theme => ({
  message: {
    color: theme.palette.textColor,
    border: 'solid 2px',
    padding: theme.spacing.unit - 2,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    borderColor: borderColorSwitcher,
    display: visibility,
    boxSizing: 'border-box',
    borderRadius: theme.units.borderRadius,
    fontFamily: theme.fontFamily,
  },
});

export default styles;
