import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import Check from '@material-ui/icons/Check';
import { getCurrentProjectName } from '../../utils/theme';
import CustomTableCell from '../CustomTableCell';
import Price from '../Price';
import Button from '../Button';
import styles from './style';

const ExtendedTariffComparison = ({
  metaData, coverage, prices, classes, onClick, isSpecial, footerPrice, unit,
}) => {
  let showBasic = true;
  let showComfort = metaData.displayComfortOn.includes(coverage);
  if (isSpecial) {
    const { basic, comfort } = prices;
    showBasic = basic && basic.special;
    showComfort = comfort && comfort.special;
  }
  const clickhandler = tarr => () => {
    onClick()(prices[tarr].type);
  };

  const getInsuranceCovers = () =>
    metaData.coverage.map(cover => (
      <TableBody key={cover}>
        <TableRow className={classes.rowSubHeads} key={metaData[cover].label}>
          <CustomTableCell className={classes.subHeads}>{metaData[cover].label}</CustomTableCell>
          {showBasic && <CustomTableCell />}
          {showComfort && showBasic && <CustomTableCell className={classes.spacing} />}
          {showComfort && <CustomTableCell />}
        </TableRow>

        {metaData[cover].rows
          .filter(({ key }) => key.includes(coverage))
          .map((row) => {
            const basisValue =
              row.basic.value === 'Ja' ? (
                <Check className={classes.accent1Color} />
              ) : (
                row.basic.value
              );
            const comfortValue =
              row.comfort.value === 'Ja' ? (
                <Check className={classes.accent1Color} />
              ) : (
                row.comfort.value
              );
            return (
              <TableRow className={classes.row} key={row.label}>
                <CustomTableCell className={classes.bodyLabel}>{row.label}</CustomTableCell>
                {showBasic && <CustomTableCell>{basisValue}</CustomTableCell>}
                {showComfort && showBasic && <CustomTableCell className={classes.spacing} />}
                {showComfort && <CustomTableCell>{comfortValue}</CustomTableCell>}
              </TableRow>
            );
          })}
      </TableBody>
    ));

  const getPrice = position => (
    <TableBody>
      <TableRow key={metaData.price.label}>
        {showBasic && (
          <CustomTableCell className={`${classes.priceLabel} ${classes.rowLabel}`}>
            {metaData.price.label}
          </CustomTableCell>
        )}
        {showBasic && (
          <CustomTableCell className={position === 'bottom' ? classes.borderEnd : ''}>
            <Price
              customClass={classes.price}
              price={prices.basic.price}
              unit={footerPrice[unit].unit}
            />
          </CustomTableCell>
        )}
        {showComfort && <CustomTableCell className={classes.spacing} />}
        {showComfort && (
          <CustomTableCell className={position === 'bottom' ? classes.borderEnd : ''}>
            <Price
              customClass={classes.price}
              price={prices.comfort ? prices.comfort.price : 0}
              unit={footerPrice[unit].unit}
            />
          </CustomTableCell>
        )}
      </TableRow>
    </TableBody>
  );

  return (
    <Grid container justify="center" alignContent="center" direction="row" spacing={2}>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead className={classes.headers}>
            <TableRow className={classes.rowHead}>
              <CustomTableCell className={`${classes.rowHeadLabel} ${classes.col}`}>
                {metaData.header.label}
              </CustomTableCell>
              {showBasic && (
                <CustomTableCell className={`${classes.borderStart} ${classes.col}`}>
                  {metaData.header.basic}
                </CustomTableCell>
              )}
              {showComfort && showBasic && <CustomTableCell className={classes.spacing} />}
              {showComfort && (
                <CustomTableCell className={`${classes.borderStart} ${classes.col}`}>
                  {isSpecial ? metaData.header.special : metaData.header.comfort[getCurrentProjectName()]}
                </CustomTableCell>
              )}
            </TableRow>
          </TableHead>
          {getPrice('top')}
          {getInsuranceCovers()}
          {getPrice('bottom')}
          <TableBody>
            <TableRow>
              <CustomTableCell className={classes.buttons}>{metaData.header.label}</CustomTableCell>
              {showBasic && (
                <CustomTableCell className={classes.buttons}>
                  <Button
                    {...metaData.buttons.submit}
                    onClick={clickhandler('basic')}
                    myWidth="100%"
                  />
                </CustomTableCell>
              )}
              {showComfort && showBasic && <CustomTableCell className={classes.spacing} />}
              {showComfort && (
                <CustomTableCell className={classes.buttons}>
                  <Button
                    {...metaData.buttons.submit}
                    onClick={clickhandler('comfort')}
                    myWidth="100%"
                  />
                </CustomTableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

ExtendedTariffComparison.propTypes = {
  message: PropTypes.string,
  error: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  metaData: PropTypes.objectOf(Object).isRequired,
  coverage: PropTypes.string.isRequired,
  prices: PropTypes.objectOf(Object).isRequired,
  unit: PropTypes.string.isRequired,
  footerPrice: PropTypes.objectOf(Object).isRequired,
};

ExtendedTariffComparison.defaultProps = {
  classes: '',
  message: '',
  error: false,
};

export default withStyles(styles)(ExtendedTariffComparison);
