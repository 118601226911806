import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { loginClient, CustomerTypes } from '../../actions/authentication';

import styles from './style';

import InputText from '../../components/InputText';
import Button from '../../components/Button';
import Notification from '../Notification';
import RadioInput from '../../components/RadioInput';

class ClientLogin extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      offerCode: '',
      lastName: '',
      companyName: '',
      customerType: '',
    };
    this.submitHandler = this.submitHandler.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.isStructureReady = this.isStructureReady.bind(this);
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
  }

  validate = () => {
    const { offerCode, customerType, lastName, companyName } = this.state;
    return offerCode && customerType && (lastName || companyName);
  }

  submitHandler(event) {
    const { offerCode, customerType, lastName, companyName } = this.state;
    const name = customerType === CustomerTypes.PERSON ? { lastname: lastName } : { companyName };
    event.preventDefault();
    this.props.loginClient(offerCode, name);
  }

  isStructureReady() {
    return this.props.structure !== undefined;
  }


  render() {
    return (
      <div className={this.classes.wrapper}>
        {this.isStructureReady() && (
          <Grid container justify="center" alignContent="center" spacing={0}>
            <Grid item xs={12}>
              <Typography align="center" variant="h1" className={this.classes.title}>
                {this.props.structure.header.mainText}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={6}>
              <Notification />
              <form onSubmit={event => this.submitHandler(event)}>
                <InputText
                  {...this.props.structure.fields.offerCode}
                  defaultValue={this.state.offerCode}
                  onChange={this.onValueChange}
                  key={this.props.structure.fields.offerCode.name}
                />
                <Typography component="div" className={this.classes.radioOptions}>
                  <Typography className={this.classes.radioTitle}>
                    {this.props.structure.customerTypeHeader}
                  </Typography>
                  <RadioInput
                    {...this.props.structure.customerType}
                    onChange={(value, field) => this.onValueChange(value, field)}
                    defaultValue={this.state.customerType}
                  />
                </Typography>
                { this.state.customerType === CustomerTypes.PERSON && (
                  <InputText
                    {...this.props.structure.fields.lastName}
                    defaultValue={this.state.lastName}
                    onChange={this.onValueChange}
                    key={this.props.structure.fields.lastName.name}
                  />
                )}
                { this.state.customerType === CustomerTypes.COMPANY && (
                  <InputText
                    {...this.props.structure.fields.companyName}
                    defaultValue={this.state.companyName}
                    onChange={this.onValueChange}
                    key={this.props.structure.fields.companyName.name}
                  />
                )}

                <Button
                  disabled={!this.validate()}
                  {...this.props.structure.buttons.submit}
                />
              </form>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.clientLogin,
  inputFields: state.projectFields,
});

const mapDispatchToProps = dispatch => ({
  loginClient: (offerCode, name) => dispatch(loginClient(offerCode, name)),
});

ClientLogin.propTypes = {
  loginClient: PropTypes.func,
  error: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  structure: PropTypes.objectOf(Object),
  inputFields: PropTypes.objectOf(Object),
};

ClientLogin.defaultProps = {
  loginClient: () => {},
  classes: '',
  error: false,
  structure: undefined,
  inputFields: undefined,
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ClientLogin);
