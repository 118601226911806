const styles = theme => ({
  formControlLabel: {
    marginBottom: theme.spacing.unit * 2,
    textAlign: 'justify',
  },
  root: {
    '&$checked': {
      color: theme.palette.darkBlue,
    },
  },
  checked: {},
});

export default styles;
