const styles = theme => ({
  buttonIconCombined: {
    width: `${theme.units.spacing * 5}px`,
    height: `${theme.units.spacing * 5}px`,
    position: 'absolute',
    backgroundColor: theme.palette.accent1Color,
    display: 'block',
    bottom: '0',
    right: '0',
    borderRadius: '0px 4px 4px 0px',
    cursor: 'pointer',
  },
  buttonIconStandalone: {
    width: `${theme.units.spacing * 5}px`,
    height: `${theme.units.spacing * 5}px`,
    backgroundColor: theme.palette.accent1Color,
    display: 'block',
    cursor: 'pointer',
    position: 'relative',
    bottom: `-${theme.units.spacing * 3}px`,
    marginTop: '12px',
  },
  button: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary1Color,
    width: '100%',
    height: '100%',
    '&:disabled': {
      backgroundColor: theme.palette.border,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  icons: {
    color: theme.palette.common.white,
    display: 'flex',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
});

export default styles;

