import types from '../actions/types';

const initialState = {
  loggedInUser: null,
  passwordResetEmail: null,
  client: {
    showSecretWordValidation: false,
    lastname: '',
    offercode: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOG_USER:
      return {
        ...state,
        loggedInUser: action.payload,
      };
    case types.CLEAR_USER:
      return {
        ...state,
      };
    case types.SET_CLIENT: {
      const { lastname, companyName, offercode } = action.payload;
      const name = lastname ? { lastname } : { companyName };
      return {
        ...state,
        client: {
          ...state.client,
          ...name,
          offercode,
        },
      };
    }
    case types.SHOW_SECRETWORD_VALIDATION:
      return {
        ...state,
        client: {
          ...state.client,
          showSecretWordValidation: action.payload,
        },
      };
    case types.SET_EMAIL_FOR_PASSWORD_RESET:
      return {
        ...state,
        passwordResetEmail: action.payload,
      };
    case types.CLEAR_EMAIL_FOR_PASSWORD_RESET:
      return {
        ...state,
        passwordResetEmail: null,
      };
    default:
      return {
        ...state,
      };
  }
};
