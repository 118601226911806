import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles,
  Typography,
  FormControlLabel,
  FormControl } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonsPanel from './Components/ButtonsPanel';
import Alert from './Components/Alert/Alert';
import { QUESTION_GROUP } from '../../constants';

const useStyles = makeStyles(theme => ({
  wrapper: theme.containers.contactForm.wrapper,
  title: {
    margin: '64px 0 22px 0',
    fontSize: '18px',
  },
  alert: {
    marginTop: '64px',
  },
  checkbox: {
    color: theme.containers.contactForm.primaryColor,
    '&$checked': {
      color: theme.containers.contactForm.primaryColor,
    },
  },
  checked: {},
  buttons: theme.containers.contactForm.buttons,
}));

const areSelectedContactReasonsInPositionForShowAlert = selectedContactReasons =>
  (selectedContactReasons.ADDRESS &&
  !selectedContactReasons.YEARLY_MILEAGE &&
  !selectedContactReasons.IBAN);

const hiddenContactReasons = [QUESTION_GROUP.IDENTIFICATION];
const getContactReasons = schema =>
  schema.contactReasons.filter(item => !hiddenContactReasons.includes(item.key));

const ContactFormStep2 = ({
  schema,
  onBackButtonClick,
  onNextButtonClick,
  selectedContactReasons,
  setSelectedContactReasons,
}) => {
  const classes = useStyles();

  const [showAlert, setShowAlert] = useState(false);
  const [isAfterSpecialCase, setIsAfterSpecialCase] = useState(false);

  useEffect(() => {
    if (
      !isAfterSpecialCase &&
      !showAlert &&
      areSelectedContactReasonsInPositionForShowAlert(selectedContactReasons)) {
      setSelectedContactReasons({ ...selectedContactReasons, YEARLY_MILEAGE: true, IBAN: true });
      setShowAlert(true);
      setIsAfterSpecialCase(true);
    }
  }, [selectedContactReasons, showAlert, isAfterSpecialCase, setSelectedContactReasons]);

  const handleChange = (event) => {
    setSelectedContactReasons({
      ...selectedContactReasons,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <div className={classes.wrapper}>
      {showAlert ? <Alert style={{ marginTop: '24px' }} label={schema.step2.labelAlert} handleButtonClick={handleAlertClose} /> : null}
      <FormControl component="fieldset">
        <Typography variant="h3" align="left" classes={{ root: classes.title }}>
          {schema.step2.title}
        </Typography>
        <FormGroup>
          {getContactReasons(schema).map(item => (
            <FormControlLabel
              key={item.key}
              control={
                <Checkbox
                  checked={selectedContactReasons[item.key]}
                  onChange={handleChange}
                  name={item.key}
                  color="default"
                  classes={{ root: classes.checkbox, checked: classes.checked }}
                />
              }
              label={item.label}
            />))}
        </FormGroup>
      </FormControl>

      <ButtonsPanel
        className={classes.buttons}
        labelLeftButton={schema.buttons.back}
        labelRightButton={schema.buttons.next}
        handleLeftButtonClick={onBackButtonClick}
        handleRightButtonClick={onNextButtonClick}
      />
    </div>
  );
};

ContactFormStep2.propTypes = {
  schema: PropTypes.object.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  selectedContactReasons: PropTypes.object.isRequired,
  setSelectedContactReasons: PropTypes.func.isRequired,
};

export default ContactFormStep2;
