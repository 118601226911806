const styles = theme => ({
  wrapper: {
    ...theme.containers.wrapper,
    marginTop: '30px',
  },
  container: theme.containers.container,
  paperExpansionPanel: theme.containers.paper,
  paper: {
    marginBottom: '30px',
    overflow: 'hidden',
  },
  title: theme.fonts.headline_2,
  centerText: {
    'text-align': 'center',
    margin: '0',
  },
  contact: {
    margin: '50px 0 0 0',
    padding: '10px',
    'margin-bottom': '0',
    '& span': {
      width: '100%',
      display: 'inline-block',
      'text-align': 'center',
    },
  },
  contactPhone: {
    'font-size': '20px',
    color: theme.palette.primary1Color,
    margin: '10px 0',
  },
  contactCall: {
    'text-align': 'center',
    display: 'inline-block',
    width: '100%',
    padding: '5px',
    color: theme.palette.primary1Color,
    'line-height': '20px',
  },
  iconAny: {
    border: 'none',
    position: 'relative',
    zIndex: '20',
    top: '5px',
    right: '5px',
    fill: theme.palette.primary1Color,
  },
  textParagraph: {
    padding: '3px 0 3px 0',
    width: '100%',
  },
  sectionQuestions: {
    'margin-top': '40px',
  },
  questions: {
    width: '100%',
  },
  expansionPanelSummary: {
    width: '90%',
  },
  sectionTitle: {
    'text-align': 'center',
  },
  documents: {
    padding: '10px 20px 15px 20px',
  },
  singleDocument: {
    color: theme.palette.primary1Color,
    fontWeight: 'semiBold',
    fontFamily: theme.fonts.fontFamily,
    fontSize: '17px',
    margin: '5px 0',
    'line-height': '22px',
    '& a': {
      'text-decoration': 'none',
      color: theme.palette.primary1Color,
    },
  },
});

export default styles;
