import { vehicleTypeKeys } from '../../reducers/settings';
import request from '../../requestAgent';

const sendRequestSearchVehicleNumber = async (vehicleType, url, hsnNumber, tsnNumber) => {
  let res;
  switch (vehicleType) {
    case vehicleTypeKeys.CAR: {
      res = await request
        .post(url)
        .send({ producerId: hsnNumber, vehTypeId: tsnNumber });
      break;
    }
    case vehicleTypeKeys.MOTORCYCLE: {
      res = await request
        .get(`${url}/producer/${hsnNumber}/vehicle-type/${tsnNumber}`)
        .send();
      break;
    }
    default: {
      throw Error('invalid vehicleType');
    }
  }
  return res;
};

export default sendRequestSearchVehicleNumber;
