import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SwitchInput from '../SwitchInput';
import styles from './style';

function OptionBlock({
  classes,
  defaultValue,
  onChange,
  title,
  text,
  fields,
  chosen,
  unit,
  time,
  cost,
  readOnly,
}) {
  const listItems = text.map(singleText => <li key={singleText}>{singleText}</li>);
  return (
    <Grid item xs={10}>
      <Paper className={classes.paper}>
        {/* Question 3 */}
        <Grid container justify="center" alignContent="center" spacing={2} className={readOnly ? classes.specialPrice : ''}>
          <Grid item xs={2}>
            <SwitchInput
              defaultValue={defaultValue}
              {...fields[Object.keys({ ...fields })[0]]}
              onChange={(value, field) => onChange(value, field)}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={cost > 0 ? 8 : 10}>
            <h3 className={`${classes.questionLabel} ${chosen}`}>{title}</h3>
            <div className={classes.questionParagraph}>
              <ul>{listItems}</ul>
            </div>
          </Grid>
          { cost > 0 &&
            <Grid item xs={2}>
              <h3 className={`${classes.questionLabel} ${chosen}`}>
                <div>
                  {cost} {unit}
                </div>
              </h3>
              <span className={`${classes.timeLabel}`}>{time}</span>
            </Grid>
          }
        </Grid>
      </Paper>
    </Grid>
  );
}

OptionBlock.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  fields: PropTypes.objectOf(PropTypes.object),
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.string),
  price: PropTypes.string,
  cost: PropTypes.number,
  unit: PropTypes.string,
  chosen: PropTypes.string,
  time: PropTypes.string,
  readOnly: PropTypes.bool,
  specialPriceLabel: PropTypes.string,
};

OptionBlock.defaultProps = {
  name: '',
  classes: '',
  defaultValue: false,
  onChange: null,
  title: '',
  text: '',
  fields: '',
  price: 'Price',
  cost: 0,
  chosen: '',
  unit: '',
  time: '',
  readOnly: false,
  specialPriceLabel: '',
};

export default withStyles(styles)(OptionBlock);
