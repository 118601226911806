const styles = theme => ({
  wrapper: {
    ...theme.containers.wrapper,
    marginTop: theme.spacing.unit * 8,
  },
  title: {
    paddingTop: theme.spacing.unit * 6,
    marginBottom: theme.spacing.unit * 8,
    textAlign: 'center',
  },
  wrapperTable: {
    paddingTop: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 8,
    flexGrow: 1,
    overflowX: 'auto',
    minWidth: 960,

  },
  table: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 3,
  },
  firstColumn: {
    paddingLeft: theme.spacing.unit * 8,
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
  },
  tableSortLabelRemainDays: {
    minWidth: theme.spacing.unit * 11,
  },
  tableRow: {
    '& th, td': {
      padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit * 3}px`,
    },
  },
  tablePaginationActions: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

export default styles;
