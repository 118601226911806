import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  Typography,
  withStyles,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import styles from './style';
import * as Sorting from '../../utils/sorting';
import { requireAuth,
  allowedToAccessEvbList,
  allowedToSeeSalesChannelInEvbList } from '../Authorization';
import { getEvbListCompleted } from '../../actions/evbList';
import { clearOffer } from '../../actions/offer';
import TablePaginationActions from './TablePaginationActions';
import { displayClientName } from './index';

class CompletedOffers extends Component {
  state = {
    order: Sorting.orderBy.DESC,
    orderBy: Sorting.orderBy.FINISHED,
    rowsPerPage: 5,
    page: 0,
  }

  componentDidMount() {
    this.props.getEvbListCompleted();
    this.props.clearOffer();
  }

  createSortHandler = property => (event) => {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = Sorting.orderBy.DESC;

    if (this.state.orderBy === property && this.state.order === Sorting.orderBy.DESC) {
      order = Sorting.orderBy.ASC;
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const {
      structure, classes, offers,
    } = this.props;
    const {
      orderBy, order, rowsPerPage, page,
    } = this.state;

    return (
      <Typography component="div" className={classes.wrapper}>
        <Typography align="center" variant="h2">
          {structure.header.completedOffers}
        </Typography>
        <Paper className={classes.wrapperTable}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell>
                  <Tooltip title={structure.fields.sort} enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === Sorting.orderBy.FINISHED}
                      direction={order}
                      onClick={this.createSortHandler(Sorting.orderBy.FINISHED)}
                    >
                      {structure.fields.finished}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={structure.fields.sort} enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === Sorting.orderBy.LAST_NAME}
                      direction={order}
                      onClick={this.createSortHandler(Sorting.orderBy.LAST_NAME)}
                    >
                      {structure.fields.name}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={structure.fields.sort} enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === Sorting.orderBy.EVB_NUMBER}
                      direction={order}
                      onClick={this.createSortHandler(Sorting.orderBy.EVB_NUMBER)}
                    >
                      {structure.fields.evbNumber}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={structure.fields.sort} enterDelay={300}>
                    <TableSortLabel
                      active={orderBy === Sorting.orderBy.CAR_NUMBER}
                      direction={order}
                      onClick={this.createSortHandler(Sorting.orderBy.CAR_NUMBER)}
                    >
                      {structure.fields.car}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                {
                 allowedToSeeSalesChannelInEvbList() ?
                   <TableCell>
                     <Tooltip title={structure.fields.sort} enterDelay={300}>
                       <TableSortLabel
                         active={orderBy === Sorting.orderBy.SALE_CHANNEL}
                         direction={order}
                         onClick={this.createSortHandler(Sorting.orderBy.SALE_CHANNEL)}
                       >
                         {structure.fields.salesChannels}
                       </TableSortLabel>
                     </Tooltip>
                   </TableCell>
                 : null
                }
                <TableCell align="center">{structure.fields.seeDetails}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Sorting.stableSort(offers, Sorting.getSorting(order, orderBy))
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map(row => (
                  <TableRow key={row.id} className={classes.tableRow}>
                    <TableCell>{new Date(row.finishedAt).toLocaleDateString()}</TableCell>
                    <TableCell>
                      {displayClientName(row)}
                    </TableCell>
                    <TableCell>{row.vehicleEvbNumber}</TableCell>
                    <TableCell>
                      {row.vehicleHSN} / {row.vehicleTSN}
                    </TableCell>
                    {
                     allowedToSeeSalesChannelInEvbList() ?
                       <TableCell> {this.props.displaySalesChannel(row)} </TableCell>
                       : null
                    }
                    <TableCell align="center">
                      <Tooltip title={structure.fields.seeDetails}>
                        <IconButton aria-label="Details" onClick={() => this.props.handleOpenOffer(row)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={offers.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={structure.fields.labelRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} ${structure.fields.of} ${count}`
            }
            page={page}
            backIconButtonProps={{
              'aria-label': structure.fields.prevPage,
            }}
            nextIconButtonProps={{
              'aria-label': structure.fields.nextPage,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Typography>
    );
  }
}

const mapStateToProps = state => ({
  structure: state.projectStructure.evbList,
  offers: state.evbList.completedOffers,
  currentUserRole: state.personalDetails.role,
});

const mapDispatchToProps = dispatch => ({
  getEvbListCompleted: () => dispatch(getEvbListCompleted()),
  clearOffer: () => dispatch(clearOffer()),
});

CompletedOffers.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  structure: PropTypes.objectOf(PropTypes.any).isRequired,
  getEvbListCompleted: PropTypes.func.isRequired,
  offers: PropTypes.instanceOf(Array),
  clearOffer: PropTypes.func,
  handleOpenOffer: PropTypes.func.isRequired,
  displaySalesChannel: PropTypes.func,
};

CompletedOffers.defaultProps = {
  classes: '',
  offers: [],
  clearOffer: () => {},
  displaySalesChannel: () => {},
};

export default requireAuth(
  compose(
    withStyles(styles),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(CompletedOffers),
  allowedToAccessEvbList,
  true,
);
