import { createTheme } from '@material-ui/core/styles';

const fontFamily = 'Open Sans, tahoma';
const fontFamilyLight = 'Open Sans, tahoma';

const grey500 = '#999';
const imagePath = '/assets/images/hyundai';
const blueLogo = `${imagePath}/logo.png`;
const loginBg = `${imagePath}/login_bg.jpg`;
const logo = `${imagePath}/logo.png`;
const successBg = `${imagePath}/success_bg.jpg`;

export const palette = {
  primary1Color: '#012C5E',
  primary2Color: '#174D8D',
  primaryButtonTextColor: '#F4F4F4',
  textColor: '#333',
  textColorMid: '#666',
  textColorLight: '#999',
  textColorVeryLight: '#adadad',
  secondaryTextColor: '#ccc',
  accent1Color: '#012C46',
  white: '#F7F9FC',
  border: '#A5ABB6',
  mainBackgroundColor: '#F4F4F4',
  darkBlue: '#022D60',
  navBarLinkLabelColor: '#012C5E',
  navBarVerticalLine: '#012C5E',
  userIconColor: '#012C5E',
  text: {
    primary: '#333',
    secondary: '#666',
  },
};

export const paletteObjects = {
  buttons: {
    primary: {
      textNormal: palette.primaryButtonTextColor,
      textHover: palette.primaryButtonTextColor,
      bgNormal: palette.primary1Color,
      bgHover: palette.primary2Color,
      bgHoverDisabled: palette.border,
      borderNormal: palette.primary1Color,
      borderHover: palette.primary2Color,
    },
    secondary: {
      textNormal: palette.primary1Color,
      textHover: palette.primaryButtonTextColor,
      bgNormal: palette.white,
      bgHover: palette.primary2Color,
      borderNormal: palette.primary1Color,
      borderHover: palette.primary2Color,
    },
  },
  navBar: {
    navMenu: {
      border: palette.border,
      background: palette.white,
    },
  },
};

export const organization = 'Hyundai';

export const imagesObject = {
  label: 'Hyundai',
  logo,
  blueLogo,
};

const units = {
  spacing: 8,
  borderRadius: '4px',
};

const shape = {
  borderRadius: '4px',
};

const fonts = {
  fontFamily: 'Open Sans, tahoma',
  paragraph: {
    fontFamily,
    'font-size': '18px',
    color: palette.textColor,
    'line-height': '30px',
  },
  headline_1: {
    'font-family': fontFamily,
    'font-size': '40px',
    color: palette.textColor,
    'line-height': '50px',
    'font-weight': 'SemiBold',
    whiteSpace: 'normal',
  },
  headline_2: {
    fontFamily: fontFamilyLight,
    'font-style': 'normal',
    'font-size': '32px',
    color: palette.textColor,
    fontWeight: '300',
    paddingBottom: units.spacing * 5,
    whiteSpace: 'normal',
  },
  headline_3: {
    padding: '8px',
    paddingTop: '24px',
    fontFamily,
    fontSize: '12px',
    color: palette.textColorLight,
    lineHeight: `${units.spacing * 3}px`,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  headline_3_print: {
    padding: '4px',
    paddingTop: '12px',
    fontFamily,
    fontSize: '8pt',
    color: palette.textColorLight,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  headline_4: {
    fontFamily,
    fontSize: '16px',
    padding: '8px',
    paddingBottom: '32px',
    color: palette.textColor,
  },
  headline_5: {
    fontFamily,
    fontSize: '24px',
    color: palette.textColor,
    paddingTop: `${units.spacing * 2}px`,
    whiteSpace: 'normal',
  },
  label: {
    fontFamily,
    fontSize: '0.875rem',
    color: palette.textColor,
    lineHeight: `${units.spacing * 3}px`,
    fontWeight: 'Bold',
    float: 'left',
    width: '100%',
    textAlign: 'left',
    paddingLeft: `${units.spacing}px`,
    boxSizing: 'border-box',
  },
  tableLabel: {
    fontFamily,
    position: 'absolute',
    color: palette.textColor,
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: `${units.spacing * 4}px`,
    fontWeight: 'bold',
  },
  modalTitle: {
    fontFamily,
    fontSize: '14px',
    color: palette.textColor,
    lineHeight: `${units.spacing * 3}px`,
    fontWeight: 'bold',
  },
};

const inputBase = {
  display: 'block',
  height: `${units.spacing * 5}px`,
  lineHeight: `${units.spacing * 3}px`,
  border: `1px solid ${palette.border}`,
  padding: `${units.spacing}px`,
  fontFamily,
};

const inputs = {
  inputText: {
    ...inputBase,
    width: '100%',
    fontSize: '16px',
    boxSizing: 'border-box',
    '&:valid~.invalid-feedback': {
      display: 'block',
    },
    '&::placeholder': {
      color: palette.textColorVeryLight,
      fontWeight: 300,
    },
    '& ~.invalid-feedback': {
      ...fonts.label,
      lineHeight: `${units.spacing * 2}px`,
      height: `${units.spacing * 2}px`,
      padding: `${units.spacing * 1}px 0`,
      boxSizing: 'content-box',
      color: 'red',
    },
  },
  selectField: {
    fontFamily,
    fontSize: '16px',
    display: 'block',
    width: '100%',
    padding: units.spacing,
    marginBottom: `${units.spacing * 4}px`,
    boxSizing: 'border-box',
    height: `${units.spacing * 5}px`,
    lineHeight: `${units.spacing * 3}px`,
    borderRadius: '0px',
    outline: 'none',
    border: `1px solid ${palette.border}`,
    backgroundColor: 'white',
    color: 'inherit',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
  },
};

const containers = {
  wrapper: {
    margin: '0 auto',
    maxWidth: '80%',
    paddingBottom: '24px',
  },
  title: {
    whiteSpace: 'normal',
    paddingTop: '48px',
    paddingBottom: '48px',
  },
  rootPaper: {
    padding: '0px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: units.spacing * 2,
  },
  paper: {
    textAlign: 'center',
    color: palette.textColor,
    whiteSpace: 'nowrap',
    marginBottom: units.spacing,
    background: 'none',
    boxShadow: 'none',
    fontFamily,
    padding: '2px 4px 2px 4px',
  },
  appWrapper: {
    display: 'block',
    paddingTop: '80px',
    height: '100%',
    overflow: 'auto',
    minHeight: '100vh',
  },
  loginBackground: {
    backgroundImage: `url(${loginBg})`,
    backgroundPosition: 'bottom',
  },
  successOfferBackground: {
    backgroundImage: `url(${successBg})`,
    backgroundPosition: 'bottom',
  },
  navBarBackground: {
    backgroundImage: 'linear-gradient(0deg, rgb(201, 212, 224) 1%, rgb(254, 254, 255) 46%, #FFFFFF 100%)',
  },
  contactForm: {
    primaryColor: palette.darkBlue,
    linkColor: '#333',
    whiteColor: '#FFF',
    darkBlueColor: '#022D60',
    wrapper: {
      alignSelf: 'center',
      width: '680px',
      maxWidth: '680px',
      '@media (max-width: 800px)': {
        padding: '0 6px',
        width: 'auto',
      },
    },
    buttons: {
      margin: '60px 0 64px 0',
    },
  },
};

const components = {
  navBarLogo: {
    height: '28px',
    paddingLeft: '50px',
    marginTop: '26px',
  },
  printOfferLogo: {
    width: '25%',
  },
};

const modal = {
  modalPaper: {
    position: 'absolute',
    width: `${units.spacing * 4}px 0`,
    backgroundColor: palette.white,
    paddingBottom: `${units.spacing * 4}px`,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    borderRadius: units.borderRadius,
  },
  modalHeader: {
    backgroundColor: palette.mainBackgroundColor,
    padding: `${units.spacing}px ${units.spacing * 2}px`,
    borderRadius: `${units.borderRadius} ${units.borderRadius} 0`,
  },
  modalTitle: {
    ...fonts.modalTitle,
  },
  modalBody: {
    padding: `${units.spacing * 4}px ${units.spacing * 4}px 0`,
  },
  modalText: {
    ...fonts.paragraph,
    textAlign: 'center',
    paddingBottom: `${units.spacing * 4}px`,
  },
  hintText: {
    ...fonts.paragraph,
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: '150%',
  },
  modalButtons: {
    padding: `0 ${units.spacing * 4}px`,
  },
};

const overrides = {
  MuiDialog: {
    paperScrollPaper: {
      maxHeight: 'calc(100% - 180px)',
      width: '100%',
    },
    paper: {
      backgroundColor: palette.white,
    },
  },
  MuiDialogTitle: {
    root: {
      boxShadow:
        '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
      marginBottom: '24px',
    },
  },
  MuiDialogActions: {
    root: {
      marginBottom: '24px',
    },
  },
  MuiTabs: {
    root: {
      fontSize: '1rem',
      color: palette.darkBlue,
    },
    indicator: {
      backgroundColor: palette.darkBlue,
    },
  },
  MuiTab: {
    root: {
      fontSize: '1rem !important',
    },
    textColorPrimary: {
      '&$selected': {
        fontWeight: 600,
        color: palette.darkBlue,
      },
    },
    wrapper: {
      whiteSpace: 'nowrap',
    },
  },
  MuiFormControl: {
    root: {
      width: '100%',
    },
  },

  MuiInput: {
    root: {
      position: 'relative',
      backgroundColor: 'white',
      border: '1px solid #A5ABB6',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      transition: ['border-color', 'box-shadow'],
      '&:focus': {
        borderColor: '#A5ABB6',
        boxShadow: 'none',
        outline: 'unset',
      },
      '&::placeholder': {
        color: '#A5ABB6',
        fontWeight: 300,
      },
    },
  },

  MuiTableCell: {
    root: {
      padding: '4px 8px 4px 8px',
    },
  },
};

export const hyundai = createTheme({
  typography: {
    fontFamily,
    h1: {
      fontSize: '2rem', // 32
      fontWeight: 300,
      fontFamily: fontFamilyLight,
      whiteSpace: 'pre-line',
    },
    h2: {
      fontSize: '2rem', // 32
      fontWeight: 300,
      fontFamily: fontFamilyLight,
      lineHeight: '43px',
      whiteSpace: 'pre-line',
      marginBottom: '24px',
    },
    h3: {
      fontSize: '1.5rem', // 24
      fontWeight: 600,
      lineHeight: '33px',
    },
    h4: {
      fontSize: '1rem', // 16
      fontWeight: 'normal',
      lineHeight: '17px',
      marginBottom: '8px',
    },
    h5: {
      fontSize: '0.75rem', // 12
      fontWeight: 'bold',
      color: grey500,
      lineHeight: '17px',
      textTransform: 'uppercase',
    },
    useNextVariants: true,
  },
  fontFamily,
  palette,
  paletteObjects,
  units,
  fonts,
  background: 'lightGrey',
  inputs,
  containers,
  shape,
  modal,
  overrides,
  components,
});
