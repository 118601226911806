import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Slider from 'rc-slider';
import styles from './style';

class OptionSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchValue: null,
      marks: {},
      values: [],
    };

    // Options Slider starts at index 0. Others like Step3.jsx start at index 1.
    // We need to make sure that we pass the correct index in and out
    // in order to keep this component reusable for future components
    const { startIndexAtZero, defaultValue } = this.props;
    this.addIndex = startIndexAtZero ? 0 : 1;
    this.defaultValue = defaultValue - this.addIndex;
  }

  componentDidMount() {
    const { options } = this.props;
    this.setSliderData(options);
  }

  componentDidUpdate(prevProps) {
    if (this.state.values.length === 0 || prevProps.options !== this.props.options) {
      this.setSliderData(this.props.options);
    }

    if (!this.state.matchValue && this.state.values.length > 0) {
      this.setMatchValue(this.defaultValue);
    }
  }

  setMatchValue(value) {
    this.setState({ matchValue: this.state.values[value] });
  }

  setSliderData(options) {
    const marks = {};
    const values = [];

    for (let i = 0; i < options.length; i++) {
      marks[i] = '';
      values[i] = options[i].value.toString().split('');

      if (values[i].length < 5) {
        values[i].unshift('0');
      }
    }

    this.setState({ marks, values });
  }

  render() {
    const {
      classes, title, onChange, readOnly, unit, options,
    } = this.props;
    const { matchValue = [], marks } = this.state;

    const getMatchValues = () =>
      /* eslint-disable react/no-array-index-key */
      matchValue.map((value, index) => (
        <span className={classes.number} key={index}>
          {value}{' '}
        </span>
      ));

    return (
      <Grid
        container
        justify="center"
        alignContent="center"
        spacing={2}
        className={classes.question2}
      >
        <h2 className={classes.title}>{title}</h2>
        <Grid container item xs={12} justify="flex-end" className={readOnly ? classes.specialPriceContainer : ''}>
          <Grid item xs={1} />
          <Grid item xs={8} className={classes.sliderContainer}>
            <Slider
              min={0}
              max={options ? options.length - 1 : 0}
              step={1}
              defaultValue={this.defaultValue}
              onChange={(value, field) => {
                this.setMatchValue(value);
                onChange(value + this.addIndex, field);
              }}
              marks={marks}
              disabled={readOnly}
            />
          </Grid>
          {!isNaN(parseInt(matchValue)) && (
            <Grid item xs={3} className={classes.mileage}>
              {getMatchValues()}
              <span className={classes.price}>{unit}</span>
            </Grid>
          )}
          {isNaN(parseInt(matchValue)) && (
            <Grid item xs={3}>
              <h2 className={classes.unlimited}>{matchValue}</h2>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

OptionSlider.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  startIndexAtZero: PropTypes.bool,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  title: PropTypes.string,
  unit: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  readOnly: PropTypes.bool,
  specialPriceLabel: PropTypes.string,
};

OptionSlider.defaultProps = {
  classes: '',
  startIndexAtZero: true,
  defaultValue: 2,
  onChange: null,
  title: '',
  unit: 'km',
  options: [],
  readOnly: false,
  specialPriceLabel: '',
};

export default withStyles(styles)(OptionSlider);
