import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';
import styles from './style';
import Label from '../Label';

function RadioInput({
  name,
  classes,
  defaultValue,
  onChange,
  options,
  label,
  customRadioLabel,
  customRadioGroupClass,
  readOnly,
}) {
  return (
    <div className={classes.inputContainer}>
      <Label label={label} htmlFor={name} customRadioLabel={customRadioLabel} />
      <RadioGroup
        aria-label={label}
        name={name}
        className={classNames(classes.inputWrapper, customRadioGroupClass)}
        value={defaultValue}
        onChange={event => onChange(event.target.value, name)}
      >
        {options.map(option => (
          <FormControlLabel
            value={option.value}
            className={classes.singleRadio}
            control={
              <Radio
                className={classes.size}
                icon={<RadioButtonUncheckedIcon className={classes.sizeIcon} />}
                checkedIcon={<RadioButtonCheckedIcon className={classes.sizeIcon} />}
                disabled={readOnly}
              />
            }
            label={<span className={classes.labelFont}>{option.key}</span>}
            key={option.key}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

RadioInput.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  label: PropTypes.string,
  customRadioLabel: PropTypes.string,
  customRadioGroupClass: PropTypes.string,
  readOnly: PropTypes.bool,
};

RadioInput.defaultProps = {
  name: '',
  label: '',
  classes: '',
  defaultValue: '',
  onChange: null,
  options: [],
  customRadioLabel: '',
  customRadioGroupClass: '',
  readOnly: false,
};

export default withStyles(styles)(RadioInput);
