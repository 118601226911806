import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Edit from '@material-ui/icons/Edit';
import styles from './style';

export const ButtonIcons = {
  SEARCH: 'search',
  CALENDAR: 'calendar',
  EDIT: 'edit',
};

const prepareIcon = (icon, classes) => {
  if (icon === ButtonIcons.SEARCH) {
    return <Search className={classes.icons} />;
  }
  if (icon === ButtonIcons.CALENDAR) {
    return <CalendarToday className={classes.icons} />;
  }
  if (icon === ButtonIcons.EDIT) {
    return <Edit className={classes.icons} />;
  }
};

const prepareVersion = (version, classes) => {
  if (version === 'standalone') {
    return classes.buttonIconStandalone;
  }
  if (version === 'combined') {
    return classes.buttonIconCombined;
  }
};

const Button = ({
  classes, icon, onClick, disabled, version, type,
}) => (
  <div className={prepareVersion(version, classes)}>
    <button className={classes.button} onClick={onClick} disabled={disabled} type={type}>
      {prepareIcon(icon, classes)}
    </button>
  </div>
);

Button.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  version: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => {},
  disabled: false,
  classes: '',
  version: 'standalone',
  icon: 'search',
  type: 'button',
};

export default withStyles(styles)(Button);
