import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Grid, withStyles, Typography, DialogContent } from '@material-ui/core';
import { getOfferDetails } from '../../actions/offer';
import mapOfferOverview from '../../utils/mapOfferOverview';
import { getLabel, getBlueLogo, getCurrentProjectName } from '../../utils/theme';
import styles from './style';

class PrintOffer extends Component {
  componentDidMount() {
    const { id } = this.props;
    getOfferDetails(id);
  }

  getPrice() {
    const { offerDetails } = this.props;
    const { offer, tariffs } = offerDetails;
    if(!tariffs || !tariffs[0]) {
      return;
    }
    const selectedTariff = tariffs[0].special ? tariffs[0].type : offer.tariff;
    const selectedTariffIndex = selectedTariff
      ? tariffs.findIndex(el => el.type === selectedTariff)
      : 0;

    if (tariffs[selectedTariffIndex]) {
      return `${tariffs[selectedTariffIndex].price.toLocaleString('de-DE', { minimumFractionDigits: 2 })} €`;
    }
    return '';
  }

  renderOfferOverview = () => {
    const { offerDetails, structure, carModelList, classes } = this.props;
    const { offer } = offerDetails;

    if(!offer || !offer.tariff) {
      return;
    }

    const renderItems = items =>
      items.map((item, i) => (
        <Fragment key={i}>
          {item.paperTag && (
            <Grid item xs={12} key={item.paperTag}>
              <Typography component="h2" className={classes.sectionTitle}>
                {item.paperTag}
              </Typography>
            </Grid>
          )}

          <Grid item xs={6} key={item.fieldTitle}>
            <Typography component="h3" className={classes.sectionSubTitle}>
              {item.fieldTitle}
            </Typography>

            {item.fieldContent.map((contentItem, e) => (
              <Typography component="div" className={classes.fieldContent} key={e}>
                {contentItem}
              </Typography>
            ))}
          </Grid>
        </Fragment>
      ));

    const data = mapOfferOverview(classes, structure, offer, carModelList);

    return data.map((overview, i) => (
      /* eslint-disable react/no-array-index-key */
      <Typography component="div" key={i}>
        <Grid container direction="row" justify="flex-start" spacing={0} className={classes.itemContainer}>
          {['leftItems', 'rightItems'].map(items => renderItems(overview[items]))}
        </Grid>
      </Typography>
    ));
  };

  render() {
    const { classes, offerDetails, printStructure, withEVB } = this.props;
    const { mainTitle, offerCode, login, price, evbNumber } = printStructure;
    const { offer } = offerDetails;
    return (
      <DialogContent className={classes.dialogContent}>
        <Grid container justify="flex-start" spacing={0} direction="row">
          <Grid xs={12} item>
            <Typography component="div" align="left">
              <img src={getBlueLogo()} alt={getLabel()} className={classes.logo} />
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography variant="h3" align="center" className={classes.title}>
              {mainTitle}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="flex-start" spacing={0} direction="row">
          <Grid xs={6} item>
            <Typography component="p" className={classes.sectionTitle}>
              {offerCode}
            </Typography>
            <Typography component="p" className={classes.fieldContent}>{offer.code}</Typography>
          </Grid>
          {withEVB && (
            <Grid xs={6} item>
              <Typography component="p" className={classes.sectionTitle}>
                {evbNumber}
              </Typography>
              <Typography component="p" className={classes.fieldContent}>{offer.evbNumber}</Typography>
            </Grid>
          )}
          <Grid container justify="flex-start" spacing={0} direction="row">
            <Grid item>
              {!this.props.hideLink && (
              <Fragment>
                <Typography component="p" className={classes.sectionTitle}>
                  {login.title}
                </Typography>
                <Typography component="p" className={classes.fieldContent}>{login[getCurrentProjectName()].url}</Typography>
              </Fragment>
              )}
              <Typography component="div">{this.renderOfferOverview()}</Typography>
              <Typography component="p" className={classes.sectionTitle}>
                {price}
              </Typography>
              <Typography component="p" className={classes.fieldContent}>{this.getPrice()}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    );
  }
}

const mapStateToProps = state => ({
  offerDetails: state.offerDetails,
  structure: state.projectStructure.insuranceStep5,
  printStructure: state.projectStructure.printOffer,
  carModelList: state.carModelList,
});

const mapDispatchToProps = () => dispatch => ({
  getOfferDetails: id => dispatch(getOfferDetails(id)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PrintOffer),
);

PrintOffer.propTypes = {
  classes: PropTypes.objectOf(Object),
  structure: PropTypes.objectOf(Object),
  printStructure: PropTypes.objectOf(Object),
  carModelList: PropTypes.arrayOf(Object),
  id: PropTypes.string,
  offerDetails: PropTypes.objectOf(Object),
  hideLink: PropTypes.bool,
};

PrintOffer.defaultProps = {
  classes: undefined,
  structure: undefined,
  carModelList: undefined,
  printStructure: undefined,
  id: '',
  offerDetails: undefined,
  hideLink: false,
};
