const styles = theme => ({
  container: theme.containers.container,
  gridContainer: {
    width: '100%',
    marginBottom: '80px',
  },
  paper: {
    ...theme.containers.paper,
    width: '100%',
  },
  title: theme.fonts.headline_5,
  datePickerInput: {
    display: 'inline-block',
  },
  quest1: {
    marginBottom: '32px',
    marginTop: '40px',
  },
  questions: {
    marginTop: '56px',
    marginBottom: '16px',
  },
  questionLabel: theme.fonts.headline_3,
  '@global': {
    '.submitButton': {
      'margin-top': '0',
    },
    '.separator': {
      display: 'block',
      height: '70px',
    },
    '.hoveringPaper': {
      marginBottom: '30px',
      overflow: 'hidden',
      position: 'fixed',
      bottom: '0px',
      left: '0',
      width: '100%',
      background: '#fff',
      'margin-bottom': '0',
      'z-index': '1000',
      'padding-bottom': '25px',
    },
    '.hoveringPaper .totalPrice': {
      'font-size': '32px',
      'font-style': 'normal',
      'font-weight': '650',
      'margin-top': '25px',
      display: 'inline-block',
    },
    '.hoveringPaper .unit': {
      'font-size': '16px',
      'font-style': 'normal',
      'font-weight': '650',
    },
  },
  previousInsuranceNumberInfo: {
    marginBottom: theme.spacing.unit,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  questionDescription: {
    whiteSpace: 'normal',
    display: 'block',
    height: '70px',
  },
});

export default styles;
