import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    height: 'calc(100vh - 80px)',
    display: 'flex',
    flexDirection: 'column',
  },
  fontBase: {
    color: theme.containers.contactForm.primaryColor,
    lineHeight: '1.2',
    fontFamily: theme.fonts.fontFamily,
    '@media (max-width: 500px)': {
      fontSize: '12px !important'
    }
  },
  innerContainer: {
    margin: '80px auto',
    maxWidth: '80%',
    flexGrow: 1,
  },
  header: {
    fontWeight: 'bold',
    fontSize: '28px',
    marginBottom: '32px'
  },
  block: {
    marginBottom: '32px'
  },
  list: {
    paddingRight: '10px',
    listStyleType: 'none'
  },
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      flex: '1'
    }
  },
  link: {
    color: theme.containers.contactForm.linkColor,
    textDecoration: 'none'
  }
}));
