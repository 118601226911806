import React, { Component } from 'react';
import { connect } from 'react-redux';

import Error from '../../components/Error';
import { isHyundai } from '../../utils/theme';
import USER_ROLES from '../../constants/user';
import store from '../../store';

export const requireAuth = (WrappedComponent, resource, showError = false) => {
  class WithAuthorization extends Component {
    render() {
      const { role } = this.props.personalDetails;
      if (!role || !this.props.auth.loggedInUser) {
        return null;
      }
      if (resource(role)) {
        return <WrappedComponent {...this.props} />;
      }
      return showError ? <Error text="Sie haben keine Berechtigung für diese Seite!" /> : null;
    }
  }
  const mapStateToProps = state => ({
    personalDetails: state.personalDetails,
    auth: state.auth,
  });

  return connect(
    mapStateToProps,
    null,
  )(WithAuthorization);
};

// Methods for check is a container allowed to user, role applied by requireAuth hoc

export const allowedToAccessAdminPanel = (role) => {
  switch (role) {
    case USER_ROLES.ADMIN_ORGANIZATION: {
      return true;
    }
    case USER_ROLES.ADMIN_DEALERSHIP: {
      return true;
    }
    case USER_ROLES.MANAGER_DEALERSHIP: {
      return true;
    }
    case USER_ROLES.LEAD_DEALER: {
      return false;
    }
    case USER_ROLES.DEALER: {
      return false;
    }
    case USER_ROLES.CALL_CENTER: {
      return false;
    }
    default: {
      // For example customer without a role
      return false;
    }
  }
};

export const allowedToAccessDropDown = (role) => {
  switch (role) {
    case USER_ROLES.ADMIN_ORGANIZATION: {
      return true;
    }
    case USER_ROLES.ADMIN_DEALERSHIP: {
      return true;
    }
    case USER_ROLES.MANAGER_DEALERSHIP: {
      return true;
    }
    case USER_ROLES.LEAD_DEALER: {
      return true;
    }
    case USER_ROLES.DEALER: {
      return true;
    }
    case USER_ROLES.CALL_CENTER: {
      return true;
    }
    default: {
      // For example customer without a role
      return false;
    }
  }
};

export const allowedToAccessEvbList = (role) => {
  switch (role) {
    case USER_ROLES.ADMIN_ORGANIZATION: {
      return false;
    }
    case USER_ROLES.ADMIN_DEALERSHIP: {
      return false;
    }
    case USER_ROLES.MANAGER_DEALERSHIP: {
      return true;
    }
    case USER_ROLES.LEAD_DEALER: {
      return true;
    }
    case USER_ROLES.DEALER: {
      return true;
    }
    case USER_ROLES.CALL_CENTER: {
      return true;
    }
    default: {
      // For example customer without a role
      return false;
    }
  }
};

export const allowedToAccessNewOffer = (role) => {
  switch (role) {
    case USER_ROLES.ADMIN_ORGANIZATION: {
      return false;
    }
    case USER_ROLES.ADMIN_DEALERSHIP: {
      return false;
    }
    case USER_ROLES.MANAGER_DEALERSHIP: {
      return true;
    }
    case USER_ROLES.LEAD_DEALER: {
      return true;
    }
    case USER_ROLES.DEALER: {
      return true;
    }
    case USER_ROLES.CALL_CENTER: {
      return false;
    }
    default: {
      // For example customer without a role
      return false;
    }
  }
};

// Methods for check is a component inside container allowed to show, no params needed

const getRole = () =>
  (store.getState().auth.loggedInUser ? store.getState().personalDetails.role : null);

export const allowedToContactLeadManagementModal = () => {
  const role = getRole();
  switch (true) {
    case isHyundai():
      switch (role) {
        case USER_ROLES.DEALER:
          return false;
        default:
          return true;
      }
    default: {
      return true;
    }
  }
};

// step5
export const allowedToSendDocuments = () => {
  const role = getRole();
  switch (role) {
    case USER_ROLES.DEALER: {
      return true;
    }
    case USER_ROLES.CALL_CENTER: {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const isDealerUser = () => {
  const role = getRole();
  const dealerTypes = [
    USER_ROLES.ADMIN_DEALER,
    USER_ROLES.LEAD_DEALER,
    USER_ROLES.DEALER,
  ];
  return dealerTypes.includes(role);
};

export const isCallCenterUser = () => getRole() === USER_ROLES.CALL_CENTER;

export const allowedToSeeSalesChannelInEvbList = () => (!!(isDealerUser() || isCallCenterUser()));
