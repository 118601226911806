const styles = theme => ({
  header: {
    padding: 40,
    color: theme.color,
    textAlign: 'center',
  },
  text: {
    padding: 40,
    color: theme.color,
  },
  container: {
    background: theme.background,
  },
});

export default styles;
