import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const TitleLabel = ({ children, center }) => (
  <Typography
    style={{ fontSize: '18px' }}
    variant="h3"
    {...(center ? { align: 'center' } : {})}
  >
    {children}
  </Typography>
);

TitleLabel.propTypes = {
  children: PropTypes.node.isRequired,
  center: PropTypes.bool,
};

TitleLabel.defaultProps = {
  center: false,
};

export default TitleLabel;
