import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';

import PropTypes from 'prop-types';
import styles from './style';

const DisplayInfo = ({ classes, information }) => (
  <Grid container justifyContent="center" alignContent="center" className={classes.text}>
    <Typography variant="h4" align="center">
      {information}
    </Typography>
  </Grid>
);

DisplayInfo.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  information: PropTypes.string,
};

DisplayInfo.defaultProps = {
  classes: '',
  information: '',
};

export default withStyles(styles)(DisplayInfo);
