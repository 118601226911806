import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './style';

const RuleCheck = ({
  text,
  classes,
  valid,
}) => (
  <p className={classes.ruleCheck}>
    {text}
  </p>
);

RuleCheck.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  valid: PropTypes.bool,
};

RuleCheck.defaultProps = {
  text: 'Some text',
  classes: '',
  valid: false,
};

export default withStyles(styles)(RuleCheck);
