const styles = theme => ({
  root: {
    'min-height': 'calc(100vh - 80px)',
    ...theme.containers.loginBackground,
    backgroundSize: 'cover',
  },
  wrapper: theme.containers.wrapper,
  grid: {
    paddingTop: '200px',
  },
  notification: {
    '& p': {
      color: theme.palette.common.white,
    },
  },
  form: {
    '& label': {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      color: theme.palette.common.white,
      lineHeight: '21px',
      '& span': {
        fontSize: '0.75rem',
        color: theme.palette.common.white,
        lineHeight: '21px',
      },
    },
  },
  submitButton: {
    width: '100%',
    height: '40px',
    fontSize: '1rem',
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: 'unset',
    marginTop: '10px',
    backgroundColor: `${theme.palette.primary2Color}`,
    '&:hover': {
      backgroundColor: `${theme.palette.accent1Color}`,
    },
  },
  title: {
    color: theme.palette.common.white,
    marginBottom: '30px',
    lineHeight: '60px',
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(28),
  },
});

export default styles;
