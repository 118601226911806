import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { QUESTION_GROUP } from '../../../constants';
import { getText } from '../ContactForm';
import TitleLabel from './TitleLabel';
import DescriptionLabel from './DescriptionLabel';

const currentFormQuestionGroup = QUESTION_GROUP.DRIVERS_GROUP;

const DriversGroupFormLegal = ({ schema }) => (
  <>
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <TitleLabel>
          {getText(schema, currentFormQuestionGroup, 'text_1_legal')}
        </TitleLabel>
      </Grid>


      <Grid item xs={12}>
        <DescriptionLabel>
          {getText(schema, currentFormQuestionGroup, 'text_2_legal')}
        </DescriptionLabel>
      </Grid>

    </Grid>
  </>
);

DriversGroupFormLegal.propTypes = {
  schema: PropTypes.object.isRequired,
};

export default DriversGroupFormLegal;
