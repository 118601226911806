const styles = () => ({
  dialogActionsContainer: {
    '& button': {
      marginTop: 0,
    },
  },
  titleContainer: {
    boxShadow: 'none',
  },
});
export default styles;
