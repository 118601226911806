import { vehicleTypeKeys } from '../../reducers/settings';

const methodName = {
  GET_BRANDS: 'getBrands',
  SEARCH_VEHICLES: 'searchVehicles',
  SEARCH_VEHICLE_NUMBER: 'searchVehicleNumber',
};

const apiUrl = {
  CAR: '/api/car-information-service',
  MOTORCYCLE: '/api/motorcycle-information-service',
};

const endpoints = {
  [vehicleTypeKeys.CAR]: {
    [methodName.GET_BRANDS]: `${apiUrl.CAR}/carBrands`,
    [methodName.SEARCH_VEHICLES]: `${apiUrl.CAR}/carModel/find`,
    [methodName.SEARCH_VEHICLE_NUMBER]: `${apiUrl.CAR}/carModel`,
  },
  [vehicleTypeKeys.MOTORCYCLE]: {
    [methodName.GET_BRANDS]: `${apiUrl.MOTORCYCLE}/brands`,
    [methodName.SEARCH_VEHICLES]: `${apiUrl.MOTORCYCLE}/motorcycles/search`,
    [methodName.SEARCH_VEHICLE_NUMBER]: `${apiUrl.MOTORCYCLE}/motorcycle`,
  },
};

const findEndpoint = (vehicleType, name) => endpoints[vehicleType][name];

export { findEndpoint, methodName };
