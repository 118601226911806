import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import Button from '../../components/Button';
import { getLabel, getBlueLogo } from '../../utils/theme';

const getCustomerName = offer => (offer.customerIsCompany
  ? `${offer.customerTitle} ${offer.customerCompanyName}`
  : `${offer.customerTitle} ${offer.customerFirstName} ${offer.customerLastName}`);

const getEvbNumber = (offer) => {
  switch (true) {
    case !!offer.evbNumber:
      return offer.evbNumber;
    case !!offer.vehicleEvbNumber:
      return offer.vehicleEvbNumber;
    default:
      return '';
  }
};

const EvbBox = ({
  classes, structure, offer, car,
}) => {
  const [selectedCar] = car || [];
  return (
    <>
      <Typography component="div" align="center">
        <img src={getBlueLogo()} alt={getLabel()} className={classes.logo} />
      </Typography>
      <Grid container justify="flex-start" alignContent="flex-start" spacing={0}>
        <Grid
          item
          container
          alignItems="flex-start"
          justify="flex-start"
          spacing={0}
          xs={12}
          md={8}
          className={classes.grid}
        >
          <Grid container item xs={12} spacing={0}>
            <Grid item xs={12}>
              <Typography align="left" variant="h1" className={classes.title}>
                {structure.copy.title}
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={0}>
            <Grid item xs={12}>
              <Typography component="p" className={classes.description}>
                {structure.copy.descriptionP1}
              </Typography>
              <Typography component="p" className={classes.description}>
                {structure.copy.descriptionP2}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={0}
            className={`${classes.gridNumber} ${classes.printOnly}`}
          >
            <Grid item xs={12}>
              <Typography component="p" className={classes.description}>
                {structure.copy.personalData}
              </Typography>
              <Typography component="p" className={classes.description}>
                {getCustomerName(offer)}
              </Typography>
              <Typography component="p" className={classes.description}>
                {offer.customerStreet} {offer.customerHouseNr}
              </Typography>
              <Typography component="p" className={classes.description}>
                {offer.customerPostalCode} {offer.customerCity}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={0}
            className={`${classes.gridNumber} ${classes.printOnly}`}
          >
            <Grid item xs={12}>
              <Typography component="p" className={classes.description}>
                {structure.copy.yourCar}
              </Typography>
              <Typography component="p" className={classes.description}>
                {selectedCar.companyName} {selectedCar.salesDesignation}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={0} className={classes.gridNumber}>
            <Grid item xs={12}>
              <Typography component="p" className={classes.description}>
                {structure.copy.evbTitle}
              </Typography>
              <Typography align="left" variant="h2" className={classes.number}>
                {structure.copy.evbName}: {getEvbNumber(offer)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={0} className={classes.screenOnly}>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                {...structure.buttons.new}
                onClick={() => window.print()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>);
};

EvbBox.propTypes = {
  classes: PropTypes.objectOf(Object),
  structure: PropTypes.objectOf(Object),
  offer: PropTypes.objectOf(Object).isRequired,
  car: PropTypes.arrayOf(Object),
};

EvbBox.defaultProps = {
  classes: '',
  structure: undefined,
  car: undefined,
};


export default EvbBox;
