import types from '../actions/types';
import { SnackbarKind } from '../actions/snackbar';

const DEFAULT_HIDE_DURATION = 3000;

const initialState = {
  messages: [],
  open: false,
};

const snackbarMessagesToSkip = ['ok', 'done'];
const isSkipSnackbarMessage = message => !message || typeof message !== 'string' || snackbarMessagesToSkip.includes(message.toLowerCase());

export default (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_SNACKBAR:
      if (isSkipSnackbarMessage(action.payload.message)) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        open: true,
        messages: state.messages.concat({
          message: action.payload.message,
          hideDuration: action.payload.hideDuration
            ? action.payload.hideDuration
            : DEFAULT_HIDE_DURATION,
          kind: action.payload.kind ? action.payload.kind : SnackbarKind.INFO,
        }),
      };
    case types.CLOSE_SNACKBAR:
      return {
        ...state,
        open: false,
      };
    case types.SHIFT_MESSAGES: {
      const messages = state.messages.filter((m, idx) => idx > 0);
      return {
        ...state,
        open: messages.length > 0,
        messages,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
