/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, Hidden } from '@material-ui/core';

import Home from '@material-ui/icons/Home';
import styles from './style';
import history from '../../history';
import {
  requireAuth,
  allowedToAccessNewOffer,
} from '../Authorization';
import { clearOffer } from '../../actions/offer';
import { clearCarModelList } from '../../actions/vehicleSearch';
import { clearVehicleNumberSearch } from '../../actions/vehicleNumberSearch';
import { clearVehicleModelForm } from '../../actions/vehicleModelSearch';
import { setNonSetAsVehicleType } from '../../actions/settings';
import { isBmw } from '../../utils/theme';

class NewOfferButton extends Component {
  state = {
    open: false,
  }

  handleRedirect = (link) => {
    if (history.location.pathname !== link) {
      history.push(link);
    }
    this.setState({ open: false });
    this.props.clearOffer();
    this.props.clearCarModelList();
    this.props.clearVehicleNumberSearch();
    this.props.clearVehicleModelForm(true);
    if (isBmw()) {
      this.props.setNonSetAsVehicleType();
    }
  }

  openConfirmation = () => this.setState({ open: true });

  handleClose = () => this.setState({ open: false });

  render() {
    const { classes, structure } = this.props;
    return (
      <Fragment>
        {structure && (
          <Fragment>
            <span className={classes.link}>
              <Hidden smDown>
                <label
                  className={classes.linkText}
                  onClick={this.openConfirmation}
                >
                  {structure.buttons.newOffer.label}
                </label>
              </Hidden>
              <Hidden mdUp>
                <Home
                  fontSize="large"
                  className={classes.userIcon}
                  onClick={this.openConfirmation}
                  aria-haspopup="true"
                  aria-controls="buttonNewOffer"
                />
              </Hidden>
              <span className={classes.line} />
            </span>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="dialog-conformation"
            >
              <DialogContent>
                <DialogContentText>
                  {structure.buttons.newOffer.confirmationText}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={this.handleClose}>
                  {structure.buttons.newOffer.confirmationCancel}
                </Button>
                <Button variant="outlined" onClick={() => this.handleRedirect(structure.buttons.newOffer.link)} className={classes.buttonOk}>
                  {structure.buttons.newOffer.confirmationOk}
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  structure: state.projectStructure.navbar,
  personalDetails: state.personalDetails,
});

const mapDispatchToProps = dispatch => ({
  clearOffer: () => dispatch(clearOffer()),
  clearCarModelList: () => dispatch(clearCarModelList()),
  clearVehicleNumberSearch: () => dispatch(clearVehicleNumberSearch()),
  clearVehicleModelForm: clearForm => dispatch(clearVehicleModelForm(clearForm)),
  setNonSetAsVehicleType: () => dispatch(setNonSetAsVehicleType()),
});

NewOfferButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  structure: PropTypes.objectOf(Object),
  personalDetails: PropTypes.shape({ role: PropTypes.string }),
  clearOffer: PropTypes.func,
  clearCarModelList: PropTypes.func,
  clearVehicleNumberSearch: PropTypes.func,
  clearVehicleModelForm: PropTypes.func,
  setNonSetAsVehicleType: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

NewOfferButton.defaultProps = {
  classes: '',
  structure: undefined,
  personalDetails: {},
  clearOffer: () => {},
  clearCarModelList: () => {},
  clearVehicleNumberSearch: () => {},
  clearVehicleModelForm: () => {},
  setNonSetAsVehicleType: () => {},
  location: { pathname: '' },
};

export default requireAuth(
  compose(
    withStyles(styles),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(NewOfferButton),
  allowedToAccessNewOffer,
);
