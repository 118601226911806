import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  withStyles,
} from "@material-ui/core";
import InputText from "../../components/InputText";
import Button from "../../components/Button";
import SelectField from "../../components/SelectField";
import styles from "./style";
import { setPersonalData, COMPANY } from "../../actions/offer";
import { compose } from 'recompose';
import { connect } from 'react-redux';
import _ from 'lodash';

const filterSelect = (select, isCompany) => {
  const options = select.options.filter(option => {
    if (!isCompany && option.key !== COMPANY) {
      return true
    }
    if ((isCompany && option.key === COMPANY) || option.key === "Auswählen") {
      return true
    }
    return false
  })

  return {
    "name": "selectTitle",
    "id": "selectTitle",
    "label": "Anrede",
    "defaultValue": "",
    "type": "select",
    "required": true,
    options
  }
}

class ModalSaveUserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectTitle: this.props.offer.selectTitle,
      firstName: this.props.offer.firstName,
      lastName: this.props.offer.lastName,
      companyName: null,
      postalCode: this.props.offer.postalCode,
      street: this.props.offer.street,
      houseNr: this.props.offer.houseNr,
      city: this.props.offer.city,
      email: this.props.offer.email.length > 0 ? this.props.offer.email : null,
      phone: this.props.offer.phone
    };
    this.sendOffer = this.sendOffer.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.saveToStorage = this.saveToStorage.bind(this);
  }

  componentWillMount() {
    const storageFields = JSON.parse(window.localStorage.getItem(this.props.offer.offerId));

    if (storageFields) {
      Object.keys(storageFields).forEach(key => {
        if (storageFields[key] && storageFields[key].length) {
          this.setState({
            [key]: storageFields[key]
          })
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.offer.selectTitle !== this.props.offer.selectTitle) {
      this.setState({
        selectTitle: this.props.offer.selectTitle
      });
    }
    if (prevProps.offer.firstName !== this.props.offer.firstName) {
      this.setState({
        firstName: this.props.offer.firstName
      });
    }
    if (prevProps.offer.lastName !== this.props.offer.lastName) {
      this.setState({
        lastName: this.props.offer.lastName
      });
    }
    if (prevProps.offer.street !== this.props.offer.street) {
      this.setState({
        street: this.props.offer.street
      });
    }
    if (prevProps.offer.houseNr !== this.props.offer.houseNr) {
      this.setState({
        houseNr: this.props.offer.houseNr
      });
    }
    if (prevProps.offer.city !== this.props.offer.city) {
      this.setState({
        city: this.props.offer.city
      });
    }
    if (prevProps.offer.email !== this.props.offer.email) {
      this.setState({
        email: this.props.offer.email
      });
    }
    if (prevProps.offer.phone !== this.props.offer.phone) {
      this.setState({
        phone: this.props.offer.phone
      });
    }
    if (prevProps.offer.postalCode !== this.props.offer.postalCode) {
      this.setState({
        postalCode: this.props.offer.postalCode,
      });
    }
  }

  saveToStorage() {
    window.localStorage.setItem(this.props.offer.offerId, JSON.stringify(this.state));
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });

    _.debounce(this.saveToStorage, 500)()
  }

  async sendOffer(event) {
    event.preventDefault();

    const preparePersonalData = (state) => {
      const names = (state) => {
        if (state.selectTitle === COMPANY) {
          return {
            companyName: state.companyName
          }
        }
        return {
          firstName: state.firstName,
          lastName: state.lastName,
        }
      }

      const email = (state) => {
        if(state.email && state.email.length) {
          return { email: state.email }
        }
        return {}
      }

      const personalData = {
        ...names(state),
        ...email(state),
        title: state.selectTitle,
        street: state.street,
        houseNr: state.houseNr,
        postalCode: state.postalCode,
        city: state.city,
        phoneNr: state.phone,
      };

      return personalData;
    };

    const personalData = preparePersonalData(this.state);

    this.props.setPersonalData(
      this.props.offer.offerId,
      personalData,
      this.props.currentStep,
    )
  }

  render() {
    const {
      isOpen,
      toggleSaveUserDetailModal,
      classes,
      currentStep,
      inputFields,
    } = this.props;

    return (
      <Fragment>
        <Dialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpen}
          onClose={toggleSaveUserDetailModal}
          maxWidth="lg"
        >
          <DialogTitle align="center">Angebot speichern</DialogTitle>
          <form onSubmit={this.sendOffer}>
            <Fragment>
              <DialogContent>
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  spacing={0}
                >
                  <Grid item sm={10} xs={12}>
                    <Grid
                      container
                      justify="flex-start"
                      alignContent="center"
                      spacing={2}
                    >
                      <Grid item sm={4} xs={12}>
                        <SelectField
                          {...filterSelect(
                            inputFields.selectTitle,
                            this.props.offer.isCompany
                          )}
                          defaultValue={this.state.selectTitle}
                          onChange={this.onValueChange}
                          setRequired
                        />
                      </Grid>
                      <Grid item sm={8} xs={12} />
                      {this.props.offer.isCompany && (
                        <Grid item xs={12}>
                          <InputText
                            {...inputFields.companyName}
                            value={this.state.companyName}
                            onChange={this.onValueChange}
                            disableAutocomplete
                          />
                        </Grid>
                      )}

                      {!this.props.offer.isCompany && (
                        <Fragment>
                          <Grid item sm={6} xs={12}>
                            <InputText
                              {...inputFields.firstName}
                              onChange={this.onValueChange}
                              placeholder=""
                              value={
                                this.state.firstName ? this.state.firstName : ''
                              }
                              disableAutocomplete
                              maxLength="29"
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <InputText
                              {...inputFields.lastName}
                              onChange={this.onValueChange}
                              placeholder=""
                              value={
                                this.state.lastName ? this.state.lastName : ''
                              }
                              disableAutocomplete
                              maxLength="29"
                            />
                          </Grid>
                        </Fragment>
                      )}

                      <Grid item xs={12}>
                        <Grid
                          container
                          justify="flex-start"
                          alignContent="center"
                          spacing={2}
                        >
                          <Grid item sm={6} xs={12}>
                            <InputText
                              {...inputFields.street}
                              onChange={this.onValueChange}
                              value={this.state.street ? this.state.street : ''}
                              disableAutocomplete
                              maxLength="25"
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <InputText
                              {...inputFields.houseNr}
                              onChange={this.onValueChange}
                              value={
                                this.state.houseNr ? this.state.houseNr : ''
                              }
                              disableAutocomplete
                              maxLength="4"
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <InputText
                              {...inputFields.postalCode}
                              defaultValue={this.state.postalCode}
                              onChange={this.onValueChange}
                              value={
                                this.state.postalCode
                                  ? this.state.postalCode
                                  : ''
                              }
                              readOnly={
                                !!this.state.postalCode &&
                                currentStep !== 'step1'
                              }
                              disableAutocomplete
                              maxLength="5"
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <InputText
                              {...inputFields.city}
                              onChange={this.onValueChange}
                              value={this.state.city ? this.state.city : ''}
                              disableAutocomplete
                              maxLength="24"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <InputText
                              {...inputFields.email}
                              onChange={this.onValueChange}
                              value={this.state.email ? this.state.email : ''}
                              label={'E-Mail Adresse (optional)'}
                              required={false}
                              disableAutocomplete
                              placeholder=""
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <InputText
                              {...inputFields.phone}
                              onChange={this.onValueChange}
                              value={this.state.phone ? this.state.phone : ''}
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Grid
                  container
                  justify="space-around"
                  alignContent="center"
                  spacing={0}
                  className={classes.dialogActionsContainer}
                >
                  <Button
                    label="Abbrechen"
                    version="secondary"
                    onClick={() => toggleSaveUserDetailModal()}
                  />
                  <Button label="Angebot speichern" type="submit" />
                </Grid>
              </DialogActions>
            </Fragment>
          </form>
        </Dialog>
      </Fragment>
    );
  }
}

ModalSaveUserDetail.propTypes = {
  offer: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleSaveUserDetailModal: PropTypes.func.isRequired,
  inputFields: PropTypes.objectOf(PropTypes.any).isRequired,
  currentStep: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setPersonalData: (id, personalData, currentStep) =>
    dispatch(setPersonalData(id, personalData, currentStep)),
});

export default compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps,
  )
)(ModalSaveUserDetail);
