import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const DescriptionLabel = ({ children, blue, center }) => (
  <Typography
    style={{ lineHeight: '24px', width: '100%' }}
    variant="h4"
    {...(blue ? { color: 'primary' } : {})}
    {...(center ? { align: 'center' } : {})}
  >
    {children}
  </Typography>
);

DescriptionLabel.propTypes = {
  children: PropTypes.node.isRequired,
  blue: PropTypes.bool,
  center: PropTypes.bool,
};

DescriptionLabel.defaultProps = {
  blue: false,
  center: false,
};

export default DescriptionLabel;
