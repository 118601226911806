const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 3,
  },
  labelContainer: {
    position: 'relative',
    height: 'auto',
    display: 'inline-block',
    '& label': {
      lineHeight: `${theme.spacing.unit * 5}px`,
      position: 'relative !important',
      left: '0 !important',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: '400',
      paddingRight: theme.spacing.unit,
    },
  },
  switchContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
  },
  colorSwitchBase: {
    '&$colorChecked': {
      color: theme.palette.primary1Color,
      '& + $colorBar': {
        backgroundColor: theme.palette.primary1Color,
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

export default styles;
