const styles = theme => ({
  paper: {
    marginBottom: '30px',
    padding: '20px 15px 15px 10px',
    overflow: 'hidden',
    fontFamily: theme.fontFamily,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  title: theme.fonts.headline_2,
  questionLabel: {
    'font-style': 'normal',
    'font-size': '24px',
    fontFamily: theme.fontFamily,
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: `${theme.units.spacing * 1}px`,
  },
  timeLabel: {
    'font-style': 'normal',
    'font-size': '12px',
    fontFamily: theme.fontFamily,
  },
  '@global': {
    '.black': {
      'font-weight': '700',
    },
  },
  questionParagraph: {
    'font-weight': '300',
    'font-style': 'normal',
    'font-size': '16px',
    'line-height': '24px',
    fontFamily: theme.fontFamily,
    paddingBottom: theme.units.spacing * 2,
  },
  specialPrice: {
    position: 'relative',
    border: `1px solid ${theme.palette.darkBlue}`,
    borderRadius: '5px',
    opacity: '0.5',
    padding: `${theme.units.spacing * 2} 0`,
  },
  specialPriceInfo: {
    fontSize: '12px',
    fontWeight: 600,
    position: 'absolute',
    bottom: 0,
    right: theme.units.spacing,
    letterSpacing: 0,
    lineHeight: '17px',
    color: theme.palette.primary1Color,
  },
});

export default styles;
