export const ERROR_MESSAGE = 'You must enter an amount less than or qual: ';

export const lessThanEqual = lessThanEqualValue => (value) => {
  if (value) {
    return value && value <= lessThanEqualValue
      ? undefined
      : `${ERROR_MESSAGE} ${lessThanEqualValue}`;
  }

  return undefined;
};
