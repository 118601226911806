import request from '../requestAgent';
import types from './types';
import handleErrors from './errorHandling';

const setDealershipsAddUser = payload => ({
  type: types.SET_DEALERSHIPS_ADD_USER,
  payload,
});

const setDealershipsEditUser = payload => ({
  type: types.SET_DEALERSHIPS_EDIT_USER,
  payload,
});

export const getDealerships = option => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user/dealerships';
    const res = await request.get(endpoint);
    dispatch(option === 'add' ? setDealershipsAddUser(res.body) : setDealershipsEditUser(res.body));
  } catch (err) {
    dispatch(handleErrors(err.response.body));
  }
};

export default getDealerships;
