import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import ShowAccountDetails from '../ShowAccountDetails';
import EditAccountDetails from '../EditAccountDetails';
import PasswordChange from '../PasswordChange';
import { getPersonalDetails } from '../../actions/userDetails';
import { clearMessage } from '../../actions/notificationCenter';

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      screenVariant: 'showDetails',
    };

    this.screenSwitch = this.screenSwitch.bind(this);
    this.onVariantChange = this.onVariantChange.bind(this);
  }

  componentDidMount() {
    this.props.getPersonalDetails();
    if (this.props.match.params.ps === 'changePassword') {
      this.onVariantChange('changePassword');
    }
  }

  onVariantChange(variant) {
    this.props.clearMessage();
    this.setState({ screenVariant: variant });
  }

  screenSwitch(variant) {
    switch (variant) {
      case 'changePassword':
        return <PasswordChange variantChange={this.onVariantChange} />;
      case 'changeDetails':
        return <EditAccountDetails variantChange={this.onVariantChange} />;
      default:
        return <ShowAccountDetails variantChange={this.onVariantChange} />;
    }
  }

  render() {
    return this.screenSwitch(this.state.screenVariant);
  }
}

const mapDispatchToProps = dispatch => ({
  getPersonalDetails: () => dispatch(getPersonalDetails()),
  clearMessage: () => dispatch(clearMessage()),
});

AccountDetails.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  getPersonalDetails: PropTypes.func,
  clearMessage: PropTypes.func,
};

AccountDetails.defaultProps = {
  classes: '',
  getPersonalDetails: () => {},
  clearMessage: () => {},
};

export default compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps,
  ),
)(AccountDetails);
