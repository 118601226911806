import React, { Component, Fragment } from 'react';
import { Element, animateScroll as scroll, scroller } from 'react-scroll';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { openSnackbar, SnackbarKind } from '../../../actions/snackbar';
import ButtonLink from '../../../components/ButtonLink';
import {
  setOfferStep,
  finishOffer,
  notModifiableOffers,
  sendDocuments,
} from '../../../actions/offer';
import history from '../../../history';
import mapOfferOverview from '../../../utils/mapOfferOverview';
import {
  isCallCenterUser,
  allowedToSendDocuments,
  isDealerUser,
} from '../../Authorization';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import CustomSwitch from '../../../components/CustomSwitch';
import DialogWithDocuments from '../../../components/DialogWithDocuments';
import Footer from '../../../components/Footer';
import InputText from '../../../components/InputText';
import fetchSalesChannels from '../../../helpers/network/fetchSalesChannels';
import validationRegex from '../../../constants/validationRegex';
import styles from './style';
import { isBmw } from '../../../utils/theme';

class Step5 extends Component {
  constructor(props) {
    super(props);
    this.classes = {
      ...this.props.classes,
      hoveringPaper: 'hoveringPaper',
      totalPrice: 'totalPrice',
      separator: 'separator',
      unit: 'unit',
    };
    this.state = {
      isAvbDelivered: false,
      sendDocuments: false,
      email: '',
      emailSent: false,
      isOpen: false,
      legal1: false,
      legal2: false,
      legal3: false,
      legal4: false,
      legal5: false,
      legal6: false,
      legal7: false,
      legal8: false,
      legal9: false,
      legal10: false,
      legal11: false,
      legal12: false,
      salesChannels: {},
    };
    this.isStructureReady = this.isStructureReady.bind(this);
    this.valid = this.valid.bind(this);
    this.checkLegalForCallCenterUser = this.checkLegalForCallCenterUser.bind(this);
    this.sendDocuments = this.sendDocuments.bind(this);
    this.isEmailValid = this.isEmailValid.bind(this);
  }

  async componentDidMount() {
    scroll.scrollToTop();

    try {
      await this.prepareSalesChannels();
    } catch {
      // do nothing
    }
  }

  onClick(variant) {
    this.props.setOfferStep({ step: variant });
  }

  onHandleConfirmationModal = () =>
    this.setState({ isOpen: !this.state.isOpen });

  onBack() {
    history.goBack();
  }

  onValueChange = (value, field) => {
    this.setState({ [field]: value });
  };

  scrollToEmail = () => {
    scroller.scrollTo('scroll-email-input', {
      duration: 500,
      delay: 100,
      smooth: 'easeInOutQuart'
    })
  }

  valid() {
    return (
      this.state.isAvbDelivered &&
      ((this.state.sendDocuments && this.state.emailSent) || !this.state.sendDocuments) &&
      this.checkLegalForCallCenterUser() &&
      !this.isCompletedOffer()
    );
  }

  checkLegalForCallCenterUser() {
    if (isCallCenterUser()) {
      return !this.props.structure.legalFields
        .map(legal => this.state[legal.name])
        .includes(false);
    }
    return true;
  }

  finishOffer() {
    const offerId = this.props.offer.id;
    if (this.valid() && offerId) {
      this.props.finishOffer(offerId);
      history.push(`/finish/${offerId}`);
    }
  }

  redirect(variant) {
    history.push(`/${variant}`);
  }

  isStructureReady() {
    return (
      this.props.structure !== undefined && this.props.carModelList.length > 0
    );
  }

  isCompletedOffer = () => {
    const { offer } = this.props;
    return Object.keys(notModifiableOffers).some(item => item === offer.status);
  };

  sendDocuments = () => {
    if (this.isEmailValid()) {
      this.props.sendDocuments(this.props.offer.id, this.state.email);
      this.props.openSnackbarOnSendDocument('Die Dokumente wurden erfolgreich per E-Mail geschickt.')
      this.setState({ emailSent: true });
    }
  };

  isEmailValid = () => {
    const { email } = this.state;
    return validationRegex.email.test(email);
  };

  getPaymentType() {
    return this.props.offer.vehiclePaymentType === 5 && isBmw() ? 'bmwBank' : 'other';
  }

  prepareSalesChannels = async () => {
    const salesChannels = await fetchSalesChannels();
    this.setState({ salesChannels });
  };

  renderOverview = () => {
    const renderItems = items =>
      items.map((item) => {
        const hasItem = item.fieldTitle !== '';
        const uniqKey = item.fieldTitle.split(' ').join('');

        return (
          hasItem && (
            <Grid item key={uniqKey}>
              {item.paperTag && (
                <span className={this.classes.paperTag}>{item.paperTag}</span>
              )}

              <div className={this.classes.fieldTitle}>{item.fieldTitle}</div>

              {item.fieldContent.map((contentItem, i) => {
                  const fieldContentUniqKey = `${i}-${uniqKey}`.split(' ').join('');
               return (
                 <div className={this.classes.fieldContent} key={fieldContentUniqKey}>
                   {contentItem}
                 </div>
              );
})}
            </Grid>
          )
        );
      });

    const generateCards = data =>
      data.map((overview) => {
        let outerUniqKey = overview.buttonStructure.label;
        if (overview.leftItems.length > 0) {
          outerUniqKey = `${outerUniqKey}${overview.leftItems[0].fieldTitle}`.split(' ').join('');
        }

        return (
          <div key={outerUniqKey}>
            {!this.isCompletedOffer() && (
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <ButtonLink
                  {...overview.buttonStructure}
                  onClick={() => this.onClick(overview.backStep)}
                />
              </Grid>
            </Grid>
          )}

            <Paper className={this.classes.paperBox}>
              <Grid container direction="row" justify="flex-start" spacing={5}>
                {['leftItems', 'rightItems'].map((items) => {
                  const uniqKey = `${items}-${overview.buttonStructure.label}`.split(' ').join('');
                  return (
                    <Grid item xs={6} key={uniqKey}>
                      <Grid container direction="column" spacing={4}>
                        {renderItems(overview[items])}
                      </Grid>
                    </Grid>
              );
})}
              </Grid>
            </Paper>
          </div>
        );
      });

    const price = this.props.tariffs.find(item => item.type === this.props.offer.tariff);

    const data = mapOfferOverview(
      this.classes,
      this.props.structure,
      this.props.offer,
      this.props.carModelList,
      price ? price.price : '',
    );

    // it's not inside mapOfferOverview because of requirements that this logic should
    // apply only for dealer and not for print
    if (
      isDealerUser() &&
      data[1] &&
      data[1].rightItems.length > 0
    ) {
      const fieldTitle = this.props.structure.structure.insuranceDetails.salesChannels.toUpperCase();
      let saleChannel = '-';
      const dbSaleChannel = this.props.offer.saleChannel;
      if (dbSaleChannel) {
        saleChannel = this.state.salesChannels[dbSaleChannel] || dbSaleChannel;
      }
      data[1].rightItems.push({ fieldTitle, fieldContent: [saleChannel] });
    }

    return generateCards(data);
  };

  render() {
    return (
      <div>
        {this.isStructureReady() && (
          <Grid container direction="column">
            {isCallCenterUser() && (
              <Grid item xs={12} className={this.classes.buttonContainer}>
                <Grid item xs={3} className={this.classes.buttonLeft}>
                  <ButtonLink
                    {...this.props.structure.buttons.back}
                    onClick={() => this.onBack()}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              container
              justify="center"
              alignItems="stretch"
              direction="column"
            >
              <Grid item>
                <h2 className={this.classes.title}>
                  {this.isCompletedOffer()
                    ? this.props.structure.header.mainText
                    : this.props.structure.header.mainTextForOpenOffer}
                </h2>
              </Grid>
              <Grid item className={this.classes.gridContainer}>
                {this.renderOverview()}
              </Grid>

              {isCallCenterUser() && (
                <Grid item container direction="column">
                  {this.props.structure.legalFields.map(legal => (
                    <Grid item key={legal.id}>
                      <Checkbox
                        name={legal.name}
                        label={legal.label}
                        checked={this.state[legal.name]}
                        onChange={this.onValueChange}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}

              <Grid item className={this.classes.avbDelivered}>
                <CustomSwitch
                  {...this.props.structure.structure.isAvbDelivered}
                  onChange={this.onValueChange}
                  defaultValue={
                    this.state.isAvbDelivered || this.isCompletedOffer()
                  }
                  readOnly={this.isCompletedOffer()}
                  onHandleConfirmationModal={this.onHandleConfirmationModal}
                />
              </Grid>
              <DialogWithDocuments
                isOpen={this.state.isOpen}
                onHandleConfirmationModal={this.onHandleConfirmationModal}
                structure={this.props.structure.structure.isAvbDelivered}
                paymentType={this.getPaymentType()}
              />

              {allowedToSendDocuments() && (
                <Fragment>
                  <Grid item className={this.classes.sendDocuments}>
                    <CustomSwitch
                      {...this.props.structure.structure.sendDocuments}
                      onChange={(value, field) => {
                        this.onValueChange(value, field);
                        if(!this.state.sendDocuments) {
                          this.scrollToEmail();
                        }
                      }}
                      defaultValue={
                        this.state.sendDocuments || this.isCompletedOffer()
                      }
                      readOnly={this.isCompletedOffer()}
                      onHandleConfirmationModal={() => {}}
                    />
                  </Grid>
                  <Element name='scroll-email-input'>
                  {this.state.sendDocuments && (
                    <Grid item container spacing={3}>
                      <Grid item xs={12} sm={8}>
                        <InputText
                          {...this.props.emailField}
                          onChange={this.onValueChange}
                          defaultValue={this.state.email}
                          readOnly={this.state.emailSent}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} align="center">
                        <Button
                          {...this.props.structure.buttons.sendDocuments}
                          disabled={
                            this.state.emailSent || !this.isEmailValid()
                          }
                          onClick={this.sendDocuments}
                        />
                      </Grid>
                    </Grid>
                  )}
                  </Element>
                </Fragment>
              )}
            </Grid>

            <Grid
              container
              direction="column"
              justify="center"
              alignContent="center"
              className={this.classes.separator}
              spacing={2}
            />
            <Footer
              buttonSubmit={{
                props: this.props.structure.buttons.continue,
                onClick: () => this.finishOffer(),
                disabled: !this.valid(),
              }}
              buttonLink={{
                props: this.props.structure.buttons.backBottom,
                onClick: () => this.onClick('step4'),
                disabled: this.isCompletedOffer(),
              }}
            />
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  personalDetails: state.personalDetails,
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.insuranceStep5,
  offer: state.offerDetails.offer,
  tariffs: state.offerDetails.tariffs,
  carModelList: state.carModelList,
  emailField: state.projectFields.email,
});

const mapDispatchToProps = dispatch => ({
  setOfferStep: step => dispatch(setOfferStep(step)),
  finishOffer: id => dispatch(finishOffer(id)),
  sendDocuments: (id, email) => dispatch(sendDocuments(id, email)),
  openSnackbarOnSendDocument: message =>
    dispatch(openSnackbar({
      message,
      kind: SnackbarKind.SUCCESS,
    })),
});

Step5.propTypes = {
  message: PropTypes.string,
  setOfferStep: PropTypes.func,
  finishOffer: PropTypes.func,
  sendDocuments: PropTypes.func,
  error: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
};

Step5.defaultProps = {
  classes: '',
  setOfferStep: () => {},
  finishOffer: () => {},
  sendDocuments: () => {},
  message: '',
  error: false,
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Step5);
