import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import ButtonsPanel from './Components/ButtonsPanel';
import IdentificationFormPhysical from './Components/IdentificationFormPhysical';
import IdentificationFormLegal from './Components/IdentificationFormLegal';

const useStyles = makeStyles(theme => ({
  wrapper: {
    ...theme.containers.contactForm.wrapper,
    paddingTop: '64px',
  },
  buttons: theme.containers.contactForm.buttons,
}));

const ContactFormStep3 = ({
  schema,
  onBackButtonClick,
  moveToNextStep,
  onFormChange,
  clientType,
  formValues,
}) => {
  const classes = useStyles();

  const [submitTrigger, setSubmitTrigger] = useState(0);

  const handleNextButtonClick = () => {
    setSubmitTrigger(submitTrigger + 1);
  };

  return (
    <div className={classes.wrapper}>

      {clientType === schema.clientType.physicalPerson ?
        <IdentificationFormPhysical
          moveToNextStep={moveToNextStep}
          submitTrigger={submitTrigger}
          schema={schema}
          onChange={onFormChange}
          formValues={formValues}
        />
         : null}

      {clientType === schema.clientType.legalPerson ?
        <IdentificationFormLegal
          moveToNextStep={moveToNextStep}
          submitTrigger={submitTrigger}
          schema={schema}
          onChange={onFormChange}
          formValues={formValues}
        />
        : null}

      <ButtonsPanel
        className={classes.buttons}
        labelLeftButton={schema.buttons.back}
        labelRightButton={schema.buttons.next}
        handleLeftButtonClick={onBackButtonClick}
        handleRightButtonClick={handleNextButtonClick}
      />
    </div>
  );
};

ContactFormStep3.propTypes = {
  schema: PropTypes.object.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  moveToNextStep: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  clientType: PropTypes.string.isRequired,
};

export default ContactFormStep3;
