
const OFFER_STATUSES = {
  INITIAL: 'INITIAL',
  ONGOING: 'ONGOING',
  ADR_FAILED_FALLBACK_SENT: 'ADR_FAILED_FALLBACK_SENT',
  ADR_FAILED: 'ADR_FAILED',
  SENT: 'SENT',
  FINISHED: 'FINISHED',
};

export default OFFER_STATUSES;
