const styles = theme => ({
  navbar: {
    ...theme.containers.navBarBackground,
    height: '80px',
    top: '0px',
    display: 'block',
    width: '100%',
    position: 'fixed',
    zIndex: '10000',
  },
  link: {
    position: 'relative',
    float: 'right',
    display: 'flex',
    color: theme.palette.navBarLinkLabelColor,
    fontWeight: 'semiBold',
    fontFamily: theme.fonts.fontFamily,
  },
  linkText: {
    cursor: 'pointer',
    height: '78px',
    lineHeight: '78px',
    fontSize: 16,
    paddingRight: theme.spacing.unit * 3.5,
    paddingLeft: theme.spacing.unit * 3,
  },
  linkLabel: {
    color: 'currentColor',
    textDecoration: 'none',
  },
  userIcon: {
    margin: '24px 32px',
  },
  logo: {
    ...theme.components.navBarLogo,
  },
  line: {
    borderLeft: `2px solid ${theme.palette.navBarVerticalLine}`,
    height: '32px',
    marginTop: '24px',
    marginRight: -theme.spacing.unit * 0.5,
  },
  buttonOk: {
    color: theme.palette.primaryButtonTextColor,
    backgroundColor: theme.palette.primary1Color,
    '&:hover': {
      backgroundColor: theme.palette.primary2Color,
    },
  },
  '@media print': {
    navbar: {
      display: 'none',
    },
  },
});

export default styles;
