const styles = theme => ({
  root: {
    ...theme.containers.successOfferBackground,
    backgroundSize: 'cover',
    height: '100%',
    minHeight: 'calc(100vh - 80px)',
  },
  wrapper: theme.containers.wrapper,
  grid: {
    marginTop: '200px',
    padding: theme.spacing.unit * 4,
    backgroundColor: theme.palette.common.white,
    boxShadow: '1px 1px 5px 1px rgba(51,51,51,0.5)',
  },
  gridNumber: {
    marginTop: theme.spacing.unit * 4,
  },
  title: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing.unit * 3,
    lineHeight: '43px',
  },
  description: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '16px',
    marginBottom: theme.spacing.unit,
    '&:first-child': {
      fontWeight: 'bold',
    },
  },
  number: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(48),
    fontWeight: 'bold',
    lineHeight: '48px',
  },
  buttonContainer: {
    '& button': {
      width: '100%',
      marginTop: 0,
    },
  },
  printOnly: {
    display: 'none',
  },
  logo: {
    display: 'none',
  },
  '@media print': {
    root: {
      backgroundImage: 'unset',
    },
    screenOnly: {
      display: 'none',
    },
    printOnly: {
      display: 'flex',
    },
    grid: {
      boxShadow: 'unset',
      marginTop: '20px',
    },
    logo: {
      display: 'flex',
      width: '40%',
      maxWidth: '300px',
      maxHeight: '70px',
    },
    number: {
      fontSize: theme.typography.pxToRem(30),
      lineHeight: '30px',
    },
  },
});

export default styles;
