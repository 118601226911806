const styles = theme => ({
  paper: theme.containers.paper,
  default: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  price: {
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '650',
    marginTop: '25px',
    display: 'inline-block',
    paddingRight: '5px',
  },
  unit: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '650',
  },
});

export default styles;
