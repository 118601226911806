import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Price from '../../components/Price';

import styles from './style';

class FooterPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: 0,
      tariffs: [],
    };
  }

  componentDidMount() {
    if (this.tariffsReady()) {
      this.calculatePrice();
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.tariffs !== this.state.tariffs && this.tariffsReady()) {
      this.calculatePrice();
    }
  }

  tariffsReady = () => this.props.tariffs.length > 0;

  calculatePrice() {
    const selectedTariff = this.props.offer.tariff;
    const selectedTariffIndex = this.props.tariffs
      .findIndex(el => el.type === selectedTariff);

    if (this.props.tariffs[selectedTariffIndex]) {
      this.setState({
        price: this.props.tariffs[selectedTariffIndex].price,
        tariffs: this.props.tariffs,
      });
    }
  }

  render() {
    if (!this.tariffsReady()) {
      return null;
    }
    const { offer, footerPrice } = this.props;
    return (
      <Price price={this.state.price} unit={footerPrice[offer.contractPaymentInterval].unit} />
    );
  }
}

const mapStateToProps = state => ({
  footerPrice: state.projectStructure.footerPrice,
  offer: state.offerDetails.offer,
  tariffs: state.offerDetails.tariffs,
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(FooterPrice));

FooterPrice.propTypes = {
  offer: PropTypes.objectOf(PropTypes.any),
  tariffs: PropTypes.arrayOf(PropTypes.any),
  footerPrice: PropTypes.objectOf(PropTypes.any),
};

FooterPrice.defaultProps = {
  offer: {},
  tariffs: [],
  footerPrice: {},
};
