import types from '../actions/types';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CAR_MODEL_LIST:
      return [
        ...action.payload,
      ];
    case types.CLEAR_CAR_MODEL_LIST:
      return [];
    default:
      return [
        ...state,
      ];
  }
};
