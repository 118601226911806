import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { getLabel, getBlueLogo } from '../../utils/theme';

const NoEvbBox = ({ classes, structure }) => (
  <>
    <Typography component="div" align="center">
      <img src={getBlueLogo()} alt={getLabel()} className={classes.logo} />
    </Typography>
    <Grid container justify="flex-start" alignContent="flex-start" spacing={0}>
      <Grid
        item
        container
        alignItems="flex-start"
        justify="flex-start"
        spacing={0}
        xs={12}
        md={8}
        className={classes.grid}
      >
        <Grid container item xs={12} spacing={0}>
          <Grid item xs={12}>
            <Typography align="left" variant="h1" className={classes.title}>
              {structure.copy.title}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={0}>
          <Grid item xs={12}>
            <Typography component="p" className={classes.description}>
              {structure.copy.descriptionP3}
            </Typography>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  </>);

NoEvbBox.propTypes = {
  classes: PropTypes.objectOf(Object),
  structure: PropTypes.objectOf(Object),
};

NoEvbBox.defaultProps = {
  classes: '',
  structure: undefined,
};


export default NoEvbBox;
