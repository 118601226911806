import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  withStyles,
} from '@material-ui/core';
import Button from '../../components/Button';
import styles from './style';

const ModalEditPostalCode = ({
  isOpen,
  toggleEditPostalCodeModal,
  toggleVariantChange,
  structure,
  classes,
}) => (
  <Fragment>
    <Dialog
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={toggleEditPostalCodeModal}
      maxWidth="md"
    >
      <DialogTitle align="center" className={classes.titleContainer}>
        {structure.editPostalCodeDialog.title}
      </DialogTitle>
      <Fragment>
        <DialogContent>
          <Grid
            container
            justify="center"
            alignContent="center"
            spacing={0}
          />
        </DialogContent>

        <DialogActions>
          <Grid
            container
            justify="space-around"
            alignContent="center"
            spacing={0}
            className={classes.dialogActionsContainer}
          >
            <Button
              label={structure.editPostalCodeDialog.yes}
              type="submit"
              onClick={() => {
                toggleEditPostalCodeModal();
                toggleVariantChange('step1');
              }}
            />
            <Button
              label={structure.editPostalCodeDialog.no}
              version="secondary"
              onClick={() => toggleEditPostalCodeModal()}
            />
          </Grid>
        </DialogActions>
      </Fragment>
    </Dialog>
  </Fragment>
);

ModalEditPostalCode.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleEditPostalCodeModal: PropTypes.func.isRequired,
  toggleVariantChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any),
  structure: PropTypes.objectOf(Object),
};

ModalEditPostalCode.defaultProps = {
  classes: '',
  structure: undefined,
};

export default withStyles(styles)(ModalEditPostalCode);
