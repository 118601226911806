import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';

const StyledFormControlLabel = ({ classes, ...props }) => (
  <FormControlLabel
    {...props}
    control={<Radio className={classes.radio} color="primary" />}
    label={<p className={classes.radioLabel}> {props.label} </p>}
  />
);

StyledFormControlLabel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string,
};

StyledFormControlLabel.defaultProps = {
  label: '',
};

export default withStyles(styles)(StyledFormControlLabel);
