const desc = (a, b, orderBy) => {
  // TODO - please refactor
  if (!b[orderBy]) return 1;
  if (!a[orderBy]) return -1;
  if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
    return 1;
  }
  return 0;
};

export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const getSorting = (order, orderBy) =>
  (order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy));

export const orderBy = {
  ASC: 'asc',
  DESC: 'desc',
  FINISHED: 'finishedAt',
  CREATED: 'createdAt',
  LAST_NAME: 'customerLastName',
  EVB_NUMBER: 'vehicleEvbNumber',
  CAR_NUMBER: 'vehicleHSN',
  CODE: 'code',
  SALE_CHANNEL: 'saleChannel',
};
