const SHOW_SPINNER = 'SHOW_SPINNER';
const SET_NOTIFICATION = 'SET_NOTIFICATION';
const SET_ERROR = 'SET_ERROR';
const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
const LOG_OUT_USER = 'LOG_OUT_USER';
const LOG_USER = 'LOG_USER';
const CLEAR_USER = 'CLEAR_USER';
const LOG_CLIENT = 'LOG_CLIENT';
const SET_CLIENT = 'SET_CLIENT';
const SET_USER_DETAILS = 'SET_USER_DETAILS';
const SET_OFFER_USER_DATA = 'SET_OFFER_USER_DATA';
const SET_PERSONAL_DETAILS = 'SET_PERSONAL_DETAILS';
const SET_SEARCHED_USERS = 'SET_SEARCHED_USERS';
const SET_STRUCTURE = 'SET_STRUCTURE';
const SET_FIELDS = 'SET_FIELDS';
const SET_CAR_MODEL_LIST = 'SET_CAR_MODEL_LIST';
const SET_DEALERSHIPS_ADD_USER = 'SET_DEALERSHIPS_ADD_USER';
const SET_SUPERVISORS_ADD_USER = 'SET_SUPERVISORS_ADD_USER';
const SET_ROLES_ADD_USER = 'SET_ROLES_ADD_USER';
const SET_DEALERSHIPS_EDIT_USER = 'SET_DEALERSHIPS_EDIT_USER';
const SET_SUPERVISORS_EDIT_USER = 'SET_SUPERVISORS_EDIT_USER';
const SET_ROLES_EDIT_USER = 'SET_ROLES_EDIT_USER';
const SET_VEHICLE_NUMBER_SEARCH = 'SET VEHICLE_NUMBER SEARCH';
const CLEAR_CAR_MODEL_LIST = 'CLEAR_CAR_MODEL_LIST';
const CLEAR_VEHICLE_NUMBER_SEARCH = 'CLEAR VEHICLE NUMBER SEARCH';
const SET_OFFER_CODE = 'SET_OFFER_CODE';
const SET_PERSONAL_DATA_UPDATED = 'SET_PERSONAL_DATA_UPDATED';
const SET_OFFER_STEP = 'SET_OFFER_STEP';
const SET_OFFER_DETAILS = 'SET_OFFER_DETAILS';
const SET_EVB_NUMBER = 'SET_EVB_NUMBER';
const SET_TARIFFS = 'SET_TARIFFS';
const SET_BASIC_TARIFF_DATA = 'SET_BASIC_TARIFF_DATA';
const SET_META_DATA = 'SET_META_DATA';
const SET_INITIAL_TARIFF = 'SET_INITIAL_TARIFF';
const CHANGE_STATUS = 'CHANGE_STATUS';
const SET_EXTENDED_TARIFF_DATA = 'SET_EXTENDED_TARIFF_DATA';
const ADD_PERSONAL_DETAILS = 'ADD_PERSONAL_DETAILS';
const RESET_OFFER_PACKAGES = 'RESET_OFFER_PACKAGES';
const SHOW_SECRETWORD_VALIDATION = 'SHOW_SECRETWORD_VALIDATION';
const SET_SF_ESTIMATED = 'SET_SF_ESTIMATED';
const OPEN_SNACKBAR = 'OPEN SNACKBAR';
const CLOSE_SNACKBAR = 'CLOSE SNACKBAR';
const SHIFT_MESSAGES = 'SHIFT MESSAGES';
const CLEAR_OFFER = 'CLEAR OFFER';
const SET_BUILD_INFO = 'SET_BUILD_INFO';
const SET_VEHICLE_MODEL_BRANDS = 'SET_VEHICLE_MODEL_BRANDS';
const CLEAR_VEHICLE_MODEL_FORM = 'CLEAR_VEHICLE_MODEL_FORM';
const SET_EVB_LIST_COMPLETED = 'SET_EVB_LIST_COMPLETED';
const SET_EVB_LIST_UNCOMPLETED = 'SET_EVB_LIST_UNCOMPLETED';
const SET_EVB_OFFER_TAB = 'SET_EVB_OFFER_TAB';
const SET_VEHICLE_TYPE = 'SET_VEHICLE_TYPE';
const SET_FRONTEND_STORAGE_DATA = 'SET_FRONTEND_STORAGE_DATA';
const SET_CORRECTED_ZIP_CODE = 'SET_CORRECTED_ZIP_CODE';
const SET_EMAIL_FOR_PASSWORD_RESET = 'SET_EMAIL_FOR_PASSWORD_RESET';
const CLEAR_EMAIL_FOR_PASSWORD_RESET = 'CLEAR_EMAIL_FOR_PASSWORD_RESET';
const SET_SUBMIT_STEP1_DIALOG_OPEN = 'SET_SUBMIT_STEP1_DIALOG_OPEN';
const SET_IS_COMPANY = 'SET_IS_COMPANY';

export default {
  SHOW_SPINNER,
  SET_NOTIFICATION,
  SET_ERROR,
  CLEAR_MESSAGE,
  LOG_OUT_USER,
  LOG_USER,
  CLEAR_USER,
  LOG_CLIENT,
  SET_IS_COMPANY,
  SET_CLIENT,
  SET_USER_DETAILS,
  SET_OFFER_USER_DATA,
  SET_PERSONAL_DETAILS,
  SET_SEARCHED_USERS,
  SET_STRUCTURE,
  SET_FIELDS,
  SET_CAR_MODEL_LIST,
  SET_DEALERSHIPS_ADD_USER,
  SET_SUPERVISORS_ADD_USER,
  SET_ROLES_ADD_USER,
  SET_DEALERSHIPS_EDIT_USER,
  SET_SUPERVISORS_EDIT_USER,
  SET_ROLES_EDIT_USER,
  SET_VEHICLE_NUMBER_SEARCH,
  CLEAR_CAR_MODEL_LIST,
  CLEAR_VEHICLE_NUMBER_SEARCH,
  SET_OFFER_CODE,
  SET_PERSONAL_DATA_UPDATED,
  SET_OFFER_STEP,
  SET_OFFER_DETAILS,
  SET_EVB_NUMBER,
  SET_TARIFFS,
  SET_BASIC_TARIFF_DATA,
  SET_META_DATA,
  SET_INITIAL_TARIFF,
  CHANGE_STATUS,
  SET_EXTENDED_TARIFF_DATA,
  ADD_PERSONAL_DETAILS,
  RESET_OFFER_PACKAGES,
  SHOW_SECRETWORD_VALIDATION,
  SET_SF_ESTIMATED,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  SHIFT_MESSAGES,
  CLEAR_OFFER,
  SET_BUILD_INFO,
  SET_VEHICLE_MODEL_BRANDS,
  CLEAR_VEHICLE_MODEL_FORM,
  SET_EVB_LIST_COMPLETED,
  SET_EVB_LIST_UNCOMPLETED,
  SET_EVB_OFFER_TAB,
  SET_VEHICLE_TYPE,
  SET_FRONTEND_STORAGE_DATA,
  SET_CORRECTED_ZIP_CODE,
  SET_EMAIL_FOR_PASSWORD_RESET,
  CLEAR_EMAIL_FOR_PASSWORD_RESET,
  SET_SUBMIT_STEP1_DIALOG_OPEN
};
