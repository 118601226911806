import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './style';

const ZipCodeChanged = ({
  message, classes,
}) => (
  <div className={classes.container}>
    <p className={classes.subtitle}>{message}</p>
  </div>
);

ZipCodeChanged.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  message: PropTypes.string,
};

ZipCodeChanged.defaultProps = {
  message: '',
};

export default withStyles(styles)(ZipCodeChanged);
