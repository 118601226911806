const paletteSwitcher = (palette, property) => props => palette.buttons[props.version][property];

const positionSwitcher = ({ position }) => position;

const widthSwitcher = ({ myWidth }) => myWidth;

const styles = (theme) => {
  const buttonStyle = {
    position: 'relative',
    cursor: 'pointer',
    marginTop: theme.units.spacing * 3,
    minHeight: theme.units.spacing * 5,
    border: props =>
      `2px solid ${paletteSwitcher(
        theme.paletteObjects,
        'borderNormal',
      )(props)}`,
    color: paletteSwitcher(theme.paletteObjects, 'textNormal'),
    fontWeight: 'semiBold',
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.fonts.fontFamily,
    backgroundColor: paletteSwitcher(theme.paletteObjects, 'bgNormal'),
    float: positionSwitcher,
    width: widthSwitcher,
    padding: `0 ${theme.units.spacing}px`,
    transition: 'all .3s ease-in',

    '&:hover': {
      color: paletteSwitcher(theme.paletteObjects, 'textHover'),
      backgroundColor: paletteSwitcher(theme.paletteObjects, 'bgHover'),
      border: props =>
        `2px solid ${paletteSwitcher(theme.paletteObjects, 'bgHover')(props)}`,
    },
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      backgroundColor: theme.palette.border,
      borderColor: theme.palette.border,
    },
    '&::before': {
      position: 'absolute',
      left: 'calc(50% - 12px)',
      width: '24px',
      height: '24px',
      content: '""',
      transform: 'translateX(-50%)',
      border: '3px solid #f3f3f3',
      borderTop: '3px solid #012C46',
      borderRadius: '50%',
      animation: '$spin 1s linear infinite',
      transition: 'opacity .2s ease-in',
    },
  };

  return {
    button: buttonStyle,
    buttonDisabled: {
      ...buttonStyle,
      '&:hover': {
        backgroundColor: paletteSwitcher(
          theme.paletteObjects,
          'bgHoverDisabled',
        ),
        cursor: 'not-allowed',
      },
    },
    buttonLoadingHide: {
      '&::before': {
        content: 'none',
      },
    },
    buttonLabel: {
      transition: 'opacity .2s ease-in',
    },
    buttonLabelHide: {
      opacity: '0',
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
  };
};

export default styles;
