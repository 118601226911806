const styles = theme => ({
  paper: theme.containers.paper,
  headline: {
    fontSize: '24px',
  },
  accent1Color: {
    color: theme.palette.accent1Color,
  },
  listItems: {
    fontSize: '12px',
    fontFamily: theme.fontFamily,
    whiteSpace: 'pre-wrap',
  },
});

export default styles;
