export const NEXT_BUTTON_ACTION = 'NEXT_BUTTON_ACTION';
export const SUB_FORM_ERROR = 'SUB_FORM_ERROR';
export const AFTER_NEXT_BUTTON_WITH_ERROR_ACTION = 'AFTER_NEXT_BUTTON_WITH_ERROR_ACTION';
export const SHOW_SUCCESS_DIALOG = 'SHOW_SUCCESS_DIALOG';
export const SHOW_ERROR_DIALOG = 'SHOW_ERROR_DIALOG';
export const HIDE_SUCCESS_DIALOG = 'SHOW_SUCCESS_DIALOG';
export const HIDE_ERROR_DIALOG = 'SHOW_ERROR_DIALOG';
export const SET_SEND_BUTTON_HIDDEN = 'SET_SEND_BUTTON_HIDDEN';
export const SET_SEND_BUTTON_DISABLED = 'SET_SEND_BUTTON_DISABLED';

export const initialState = {
  submitTrigger: 0,
  nextButtonClicked: false,
  subFormErrorReady: false,
  showSuccessDialog: false,
  showErrorDialog: false,
  isSendButtonHidden: true,
  isSendButtonDisabled: false,
};

export const contactFormStep4Reducer = (currState, action) => {
  switch (action.type) {
    case NEXT_BUTTON_ACTION:
      return { ...currState, nextButtonClicked: true, subFormErrorReady: false, submitTrigger: currState.submitTrigger + 1 };
    case SUB_FORM_ERROR:
      return { ...currState, subFormErrorReady: true };
    case AFTER_NEXT_BUTTON_WITH_ERROR_ACTION:
      return { ...currState, nextButtonClicked: false, subFormErrorReady: false };
    case SHOW_ERROR_DIALOG:
      return { ...currState, showErrorDialog: true};
    case HIDE_ERROR_DIALOG:
      return { ...currState, showErrorDialog: false};
    case SHOW_SUCCESS_DIALOG:
      return { ...currState, showSuccessDialog: true};
    case HIDE_SUCCESS_DIALOG:
      return { ...currState, showSuccessDialog: false};
    case SET_SEND_BUTTON_HIDDEN:
      return { ...currState, isSendButtonHidden: action.data };
    case SET_SEND_BUTTON_DISABLED:
      return { ...currState, isSendButtonDisabled: action.data };
    default:
      throw new Error("Should not be reached! " + action.type);
  }
};
