import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography } from '@material-ui/core';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { changeAccountPassword } from '../../actions/userDetails';
import styles from './style';
import Button from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';
import InputText from '../../components/InputText';
import RuleCheck from '../../components/RuleCheck';
import Notification from '../Notification';

class PasswordChange extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      oldPassword: '',
      newPassword: '',
      retypePassword: '',
    };

    this.onClick = this.onClick.bind(this);
    this.inputFieldsRepeater = this.inputFieldsRepeater.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.isStructureReady = this.isStructureReady.bind(this);
    this.ruleTest = this.ruleTest.bind(this);
    this.RuleCheckers = this.RuleCheckers.bind(this);
  }

  onClick(variant) {
    this.props.variantChange(variant);
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
  }

  saveHandler() {
    this.props.changeAccountPassword(
      this.state.oldPassword,
      this.state.newPassword,
      this.state.retypePassword,
    );
  }

  isStructureReady() {
    return this.props.structure !== undefined;
  }

  inputFieldsRepeater(fields) {
    const mapItem = [];
    fields.map(key =>
      mapItem.push(<InputText
        {...this.props.inputFields.password}
        {...this.props.structure.fields[key]}
        defaultValue={this.state[key]}
        onChange={this.onValueChange}
        key={`inputfields-${key}`}
        validationRules={this.RuleCheckers(key, this.props.structure.rule)}
      />));
    return mapItem;
  }

  ruleTest(rule) {
    return !!this.state.newPassword.match(new RegExp(rule));
  }

  RuleCheckers(field, rules) {
    const mapItem = [];
    if (field === 'newPassword') {
      Object.keys(rules).map(key =>
        mapItem.push(<RuleCheck
          text={rules[key].text}
          valid={this.ruleTest(rules[key].reg)}
          key={`passwordCreationRule-${key}`}
        />));
    }
    return mapItem;
  }


  render() {
    const { classes, structure } = this.props;
    return (
      <Typography component="div" className={this.classes.wrapper}>
        <Grid container justify="center" alignContent="center" spacing={0}>
          <Grid item xs={12} className={classes.wrapper}>
            {this.isStructureReady() && (
              <Grid container justify="center" alignContent="center" spacing={0}>
                <Grid item xs={10}>
                  <Typography align="left" component="div" >
                    <ButtonLink
                      {...structure.buttons.back}
                      onClick={() => this.onClick('showDetails')}
                    />
                  </Typography>
                  <Typography align="center" variant="h1" className={classes.title}>
                    {structure.header.mainTextChange}
                  </Typography>
                  <Notification />
                </Grid>
                <Grid item xs={12} md={8} lg={6}>
                  <form onSubmit={event => this.submitHandler(event)}>
                    {this.inputFieldsRepeater(structure.fieldsOrder)}
                    <Button {...structure.buttons.save} onClick={() => this.saveHandler()} />
                  </form>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Typography>
    );
  }
}

const mapStateToProps = state => ({
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.passwordManagement,
  inputFields: state.projectFields,
});

const mapDispatchToProps = dispatch => ({
  changeAccountPassword: (password, newPassword, retypePassword) =>
    dispatch(changeAccountPassword(password, newPassword, retypePassword)),
});

PasswordChange.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  changeAccountPassword: PropTypes.func,
};

PasswordChange.defaultProps = {
  changeAccountPassword: () => {},
  classes: '',
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PasswordChange);
