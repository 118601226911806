import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import VehicleNumberSearchFindCarForm from '../VehicleNumberSearchFindCarForm';
import VehicleNumberSearchCarDetailsForm from '../VehicleNumberSearchCarDetailsForm';
import VehicleModel from '../../models/vehicle';

const VehicleNumberSearch = (props) => {
  const { structure, inputFields, vehicle } = props;
  return (
    <Fragment>
      <VehicleNumberSearchFindCarForm
        copy={structure.copy}
        vehicle={vehicle}
        hsnNumber={{ ...structure.fields.hsnNumber, ...inputFields.hsnNumber }}
        tsnNumber={{ ...structure.fields.tsnNumber, ...inputFields.tsnNumber }}
      />
      {vehicle && (
        <VehicleNumberSearchCarDetailsForm
          buttons={structure.buttons}
          financing={{ ...inputFields.financing, ...structure.fields.financing }}
          state={{ ...inputFields.state, ...structure.fields.state }}
          milage={structure.fields.milage}
          registrationDate={structure.fields.registrationDate}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  vehicle: state.vehicleNumberSearch,
  inputFields: state.projectFields,
  structure: state.projectStructure.vehicleNumberSearch,
});

VehicleNumberSearch.propTypes = {
  structure: PropTypes.objectOf(Object).isRequired,
  inputFields: PropTypes.objectOf(Object).isRequired,
  vehicle: VehicleModel,
};

VehicleNumberSearch.defaultProps = {
  vehicle: null,
};

export default connect(
  mapStateToProps,
  null,
)(VehicleNumberSearch);
