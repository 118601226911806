const styles = theme => ({
  container: theme.containers.container,
  paper: theme.containers.paper,
  divider: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
  title: theme.fonts.headline_2,
});

export default styles;
