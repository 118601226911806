import history from '../history';
import store from '../store';
import { clearUser, logUser, setPasswordResetEmail, clearPasswordResetEmail } from './authentication';
import { loginRoot, resetPassword } from '../utils/redirects';
import { openSnackbar, SnackbarKind } from './snackbar';
import { UNATHORIZED, UNATHORIZED_STATUS, PASSWORD_TOO_SHORT_ERROR_CODE } from '../constants/errors';

const getMessageByValidationKey = (errorHandling, validationkey) => {
  switch (validationkey) {
    case 'contract.youngestDriverAge':
      return errorHandling.youngestDriverAge;
    case 'customer.postalCode':
    case 'differingOwner.postalCode':
      return errorHandling.postcode;
    case 'personalData.iban':
      return errorHandling.iBan;
    case 'email':
      return errorHandling.eMail;
    case 'personalData.email':
      return errorHandling.eMail;
    default:
      return errorHandling.defaultMessage;
  }
};

const handleUnauthorized = (code) => {
  switch (code) {
    case PASSWORD_TOO_SHORT_ERROR_CODE:
      history.push(resetPassword());
      break;
    default:
      history.push(loginRoot());
      break;
  }
};

const skipErrorMessage = (statusCode, code) => statusCode === UNATHORIZED
  && code === PASSWORD_TOO_SHORT_ERROR_CODE;

const getMessageByCode = (errorHandling, code) => {
  switch (code) {
    case 301026:
      return errorHandling.postcode;
    case 10002:
      return errorHandling.unauthorized;
    case 301031:
      return errorHandling.sFClassHigherThan;
    case 301017:
      return errorHandling.insurerData;
    case 301013:
      return errorHandling.changingOfferForbiden;
    case 50009:
      return errorHandling.passwordJustUsed;
    case 301034:
      return errorHandling.notEmptyField;
    default:
      return errorHandling.defaultMessage;
  }
};

export const handleUserResetPassword = (email, error) => (dispatch) => {
  const {
    statusCode, code, status,
  } = error;
  if (statusCode === undefined
    && status === 'Unauthorized'
    && code === PASSWORD_TOO_SHORT_ERROR_CODE) {
    dispatch(setPasswordResetEmail(email));
  } else {
    dispatch(clearPasswordResetEmail());
  }
};

const handleErrors = error => (dispatch) => {
  const {
    statusCode, message, validation, code, status,
  } = error;
  const { errorHandling } = store.getState().projectStructure;
  let newMessage;
  const updatedStatusCode = statusCode === undefined && status === UNATHORIZED_STATUS
    ? UNATHORIZED
    : statusCode;

  switch (updatedStatusCode) {
    case UNATHORIZED:
      dispatch(clearUser());
      dispatch(logUser(null));
      handleUnauthorized(code);
      newMessage = errorHandling.unauthorized;
      break;
    case 400:
      newMessage = message && validation
        ? getMessageByValidationKey(errorHandling, validation.keys[0])
        : errorHandling.defaultMessage;
      break;
    default:
      newMessage = code ? getMessageByCode(errorHandling, code) : errorHandling.defaultMessage;
  }

  if (!skipErrorMessage(updatedStatusCode, code)) {
    dispatch(openSnackbar({
      message: newMessage,
      kind: SnackbarKind.ERROR,
    }));
  }
};

export default handleErrors;
