import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import DatePicker, { registerLocale } from 'react-datepicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import de from 'date-fns/locale/de'; // react-datepicker dependency
import moment from 'moment';
import { FormLabel, withStyles } from '@material-ui/core';

import ButtonIcon from '../ButtonIcon';
import styles from './style';

const DatePickerInput = (props) => {
  const {
    classes,
    minDate,
    maxDate,
    onChange,
    name,
    label,
    defaultValue,
    filterDate,
    yearDropdownItemNumber,
    reduxFormReady,
  } = props;

  const [startDate, setStartDate] = useState(moment(defaultValue).isValid() ?
    moment(defaultValue).toDate() : new Date());

  const [open, setIsOpen] = useState(false);
  registerLocale('de', de);

  const handleChange = (date) => {
    if (date) {
      setStartDate(date);
      const isoDate = moment(date).hours(12).toISOString();
      // eslint-disable-next-line react/prop-types
      reduxFormReady ? props.input.onChange(isoDate) : onChange(isoDate, name);
    }
  };

  const convertDate = date => (date ? moment(date).toDate() : null);

  return (
    <div className={classes.inputContainer}>
      <FormLabel
        component="legend"
        className={classes.formLabel}
      >
        {label}
      </FormLabel>
      <ButtonIcon onClick={() => setIsOpen(true)} version="combined" icon="calendar" />
      <DatePicker
        name={name}
        open={open}
        onClickOutside={() => setIsOpen(false)}
        onInputClick={() => setIsOpen(true)}
        minDate={convertDate(minDate)}
        maxDate={convertDate(maxDate)}
        selected={startDate}
        filterDate={filterDate}
        onChange={handleChange}
        className={classes.datePicker}
        calendarClassName={classes.datePickerCalendar}
        popperClassName={classes.datePickerPopper}
        showYearDropdown
        dateFormatCalendar="MMMM"
        scrollableYearDropdown
        dateFormat="dd.MM.yyyy"
        locale="de"
        showDisabledMonthNavigation
        yearDropdownItemNumber={yearDropdownItemNumber}
      />
    </div>
  );
};

DatePickerInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  minDate: PropTypes.objectOf(Object),
  maxDate: PropTypes.objectOf(Object),
  onChange: PropTypes.func,
  label: PropTypes.string,
  yearDropdownItemNumber: PropTypes.number,
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any,
  filterDate: PropTypes.func,
  reduxFormReady: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  classes: '',
  minDate: moment().subtract(100, 'years'),
  maxDate: undefined,
  yearDropdownItemNumber: 100,
  onChange: () => {},
  label: '',
  name: '',
  defaultValue: moment(),
  filterDate: undefined,
  reduxFormReady: false,
};

export default compose(withStyles(styles))(DatePickerInput);
