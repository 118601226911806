import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid, Button } from '@material-ui/core';
import * as classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  button: {
    height: '40px',
    width: '204px',
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 0,
    fontSize: '16px',
    textTransform: 'none',
    '@media (max-width: 500px)': {
      width: '36vw',
    },
  },
  buttonCancel: {
    background: 'linear-gradient(180deg, #E5E5E5 0%, #F7F7F7 100%)',
    color: theme.palette.textColor,
  },
  buttonNext: {
    color: theme.containers.contactForm.whiteColor,
    backgroundColor: theme.palette.primary1Color,
    '&:hover': {
      backgroundColor: theme.palette.primary1Color,
    },
    '&:disabled': {
      backgroundColor: theme.containers.contactForm.whiteColor,
    },
  },
}));

const ButtonsPanel = ({
  handleLeftButtonClick,
  handleRightButtonClick,
  labelLeftButton,
  labelRightButton,
  className,
  hideRightButton,
  disableRightButton,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={className}
    >
      <Button
        className={classNames(classes.button, classes.buttonCancel)}
        onClick={handleLeftButtonClick}
      >
        {labelLeftButton}
      </Button>

      { !hideRightButton ?
        <Button
          className={classNames(classes.button, classes.buttonNext)}
          disabled={disableRightButton}
          onClick={handleRightButtonClick}
        >
          {labelRightButton}
        </Button> : null}

    </Grid>
  );
};

ButtonsPanel.propTypes = {
  labelLeftButton: PropTypes.string,
  labelRightButton: PropTypes.string,
  handleLeftButtonClick: PropTypes.func,
  handleRightButtonClick: PropTypes.func,
  hideRightButton: PropTypes.bool,
  className: PropTypes.string,
  disableRightButton: PropTypes.bool,
};

ButtonsPanel.defaultProps = {
  labelLeftButton: '',
  labelRightButton: '',
  handleLeftButtonClick: () => {},
  handleRightButtonClick: () => {},
  hideRightButton: false,
  className: null,
  disableRightButton: false,
};

export default ButtonsPanel;
