import request from '../requestAgent';
import types from './types';
import handleErrors from './errorHandling';

export const setInputfields = payload => ({
  type: types.SET_FIELDS,
  payload,
});

export const setStructure = payload => ({
  type: types.SET_STRUCTURE,
  payload,
});

export const getSchema = () => async (dispatch) => {
  try {
    const endpoint = '/api/meta-service/schema';
    const res = await request.get(endpoint);
    dispatch(setInputfields(res.body.inputField));
    dispatch(setStructure(res.body.structure));
  } catch (err) {
    dispatch(handleErrors(err.response.body));
  }
};
