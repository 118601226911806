import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import styles from './style';
import Label from '../Label';
import ButtonIcon, { ButtonIcons } from '../ButtonIcon';


const InputText = ({
  id,
  name,
  onBlur,
  onInvalid,
  onChange,
  placeholder,
  required,
  type,
  defaultValue = '',
  value,
  label,
  classes,
  uppercase,
  linkLabel,
  onLinkClick,
  pattern,
  maxLength,
  onKeyDown,
  onButtonClick,
  buttonIcon,
  errorMessage,
  readOnly,
  title,
  helperText,
  helperTextArray,
  helperTextRestore,
  restore,
  validationRules,
  disableAutocomplete,
}) => {
  const valueProps = value !== undefined ? { value } : { defaultValue };
  const printHelper = (text, className) => <Typography component="p" key={`helperText-'${text}`} className={className}>{text}</Typography>;

  return (
    <div className={classes.inputContainer}>
      <Label label={label} htmlFor={name} linkLabel={linkLabel} onLinkClick={onLinkClick} />
      <input
        className={uppercase ? classes.inputUppercase : classes.input}
        id={id || (name || '')}
        name={name}
        onBlur={onBlur}
        onChange={evn => onChange(evn.target.value, evn.target.name)}
        onKeyDown={evn => onKeyDown(evn)}
        onInvalid={evn => onInvalid(evn)}
        placeholder={placeholder}
        required={required}
        type={type}
        pattern={pattern}
        errormessage={errorMessage}
        readOnly={readOnly}
        title={title}
        maxLength={maxLength}
        {...valueProps}
        {...(disableAutocomplete && { autoComplete: 'new-password' })}
      />
      { validationRules }
      { helperTextArray.map(el => (
        printHelper(el, classes.ruleCheck)
      )) }
      { restore && printHelper(helperTextRestore, classes.ruleCheck) }
      { helperText && printHelper(helperText, classes.helperText) }
      { onButtonClick !== null && <ButtonIcon onClick={onButtonClick} version="combined" icon={buttonIcon} />}
      <div className="invalid-feedback" />
    </div>
  );
};

InputText.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onInvalid: PropTypes.func,
  onChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  buttonIcon: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.string,
  valid: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'number']),
  classes: PropTypes.objectOf(PropTypes.string),
  uppercase: PropTypes.bool,
  linkLabel: PropTypes.string,
  onLinkClick: PropTypes.func,
  pattern: PropTypes.string,
  errorMessage: PropTypes.string,
  title: PropTypes.string,
  readOnly: PropTypes.bool,
  marginTop: PropTypes.string,
  helperText: PropTypes.string,
  helperTextArray: PropTypes.arrayOf(PropTypes.string),
  helperTextRestore: PropTypes.string,
  restore: PropTypes.bool,
  validationRules: PropTypes.arrayOf(PropTypes.object),
  disableAutocomplete: PropTypes.bool,
};

InputText.defaultProps = {
  defaultValue: '',
  value: undefined,
  id: '',
  name: '',
  maxLength: '',
  valid: null,
  onChange: () => {},
  onInvalid: () => {},
  onBlur: () => {},
  onKeyDown: () => {},
  placeholder: '',
  required: false,
  type: 'text',
  label: '',
  classes: '',
  uppercase: false,
  linkLabel: '',
  onLinkClick: null,
  onButtonClick: null,
  buttonIcon: ButtonIcons.SEARCH,
  // TODO find better way to exclude pattern matching
  pattern: '.*',
  errorMessage: '',
  title: '',
  readOnly: false,
  marginTop: '0px',
  helperText: '',
  helperTextArray: [],
  helperTextRestore: '',
  restore: false,
  validationRules: null,
  disableAutocomplete: false,
};

export default withStyles(styles)(InputText);
