import types from '../actions/types';
import EvbOffersType from '../models/evbOffers';

const initialState = {
  completedOffers: [],
  uncompletedOffers: [],
  evbOfferTab: EvbOffersType.Completed,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_EVB_LIST_COMPLETED:
      return {
        ...state,
        completedOffers: action.payload,
      };
    case types.SET_EVB_LIST_UNCOMPLETED:
      return {
        ...state,
        uncompletedOffers: action.payload,
      };
    case types.SET_EVB_OFFER_TAB:
      return {
        ...state,
        evbOfferTab: action.payload,
      };
    default:
      return state;
  }
};
