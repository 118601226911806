import request from '../requestAgent';
import types from './types';
import handleErrors from './errorHandling';

export const setEvbListCompleted = offers => ({
  type: types.SET_EVB_LIST_COMPLETED,
  payload: offers,
});

export const getEvbListCompleted = () => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/evbList/finished';
    const res = await request.get(endpoint);
    dispatch(setEvbListCompleted(res.body.offers));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};


export const setEvbListUncompleted = offers => ({
  type: types.SET_EVB_LIST_UNCOMPLETED,
  payload: offers,
});

export const getEvbListUncompleted = () => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/offer/evbList/open';
    const res = await request.get(endpoint);
    dispatch(setEvbListUncompleted(res.body.offers));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const setEvbOfferTab = tab => ({
  type: types.SET_EVB_OFFER_TAB,
  payload: tab,
});
