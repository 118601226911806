const styles = theme => ({
  dialogContentTitleContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  dialogActionsContainer: {
    '& button': {
      marginTop: 0,
    },
  },
});
export default styles;
