import request from '../requestAgent';
import types from './types';
import { setPersonalDetails, getPersonalDetails, setSearchedUsers } from './userDetails';
import { getOfferDetails } from './offer';
import handleErrors, { handleUserResetPassword } from './errorHandling';
import history from '../history';
import { afterLogin, loginRoot } from '../utils/redirects';
import showSpinner from './spinner';
import { openSnackbar, SnackbarKind } from './snackbar';
import { getOrganization } from '../utils/theme';
import { ROUTES } from '../constants/';

export const CustomerTypes = {
  PERSON: 'person',
  COMPANY: 'company',
};

export const setErrorMessage = message => ({
  type: types.SET_ERROR,
  payload: message,
});

export const setMessage = message => ({
  type: types.SET_NOTIFICATION,
  payload: message,
});

export const clearUser = () => ({
  type: types.CLEAR_USER,
});

export const logUser = loggedInUser => ({
  type: types.LOG_USER,
  payload: loggedInUser,
});

export const setPasswordResetEmail = email => ({
  type: types.SET_EMAIL_FOR_PASSWORD_RESET,
  payload: email,
});

export const clearPasswordResetEmail = () => ({
  type: types.SET_EMAIL_FOR_PASSWORD_RESET,
});

export const setClient = client => ({
  type: types.SET_CLIENT,
  payload: client,
});

export const logClient = isLoggedIn => ({
  type: types.LOG_CLIENT,
  payload: isLoggedIn,
});

export const showSecretWordValidation = isShown => ({
  type: types.SHOW_SECRETWORD_VALIDATION,
  payload: isShown,
});

export const clearClient = () => ({
  type: types.CLEAR_CLIENT,
});

export const setUser = role => async (dispatch) => {
  const loggedInUser = role === 'callcenter' ? 'Callcenter' : 'User';
  localStorage.setItem('LoggedInUser', loggedInUser);
  dispatch(logUser(loggedInUser));
};

export const isUserLogged = currentLocation => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user/isLoggedIn';
    const res = await request.get(endpoint);
    const isLoggedIn = res.body.status;
    if (!isLoggedIn) {
      dispatch(clearUser());
      localStorage.removeItem('LoggedInUser');
      if (!currentLocation.includes(ROUTES.CONTACT_FORM)) {
        history.push(loginRoot(currentLocation));
      }
    }

    if (localStorage.getItem('LoggedInUser')) {
      const value = localStorage.getItem('LoggedInUser');
      dispatch(logUser(value));
    }
    if (isLoggedIn && !localStorage.getItem('LoggedInUser')) {
      // set logged in user's role if user session (cookie) was created by other supplier (e.g. lwl)
      // in that case we need to fetch authenticated user data and set missing variable
      // in localStorage
      const endpointAccount = '/api/user-manager/user/accountDetails';
      const resAccount = await request.get(endpointAccount);
      dispatch(setUser(resAccount.body.role));
    }

    dispatch(showSpinner(false));
    const { user = {} } = res.body;
    const { authenticatedCustomer = null } = user;

    if (authenticatedCustomer) {
      dispatch(logUser('Client'));
      dispatch(getOfferDetails(res.body.user.offerId, '', true));
    }

    if (isLoggedIn && !authenticatedCustomer) {
      dispatch(getPersonalDetails());
    }
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user/logout';
    await request.get(endpoint);
    dispatch(clearUser());
    localStorage.removeItem('LoggedInUser');
    dispatch(logUser(null));
    dispatch(setSearchedUsers([]));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const loginUser = (username, password) => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user/login';
    const res =
      await request.post(endpoint).send({ username, password, organization: getOrganization() });
    dispatch(setPersonalDetails(res.body));
    dispatch(setUser(res.body.role));
    dispatch(showSpinner(false));
    dispatch(clearPasswordResetEmail());
    history.push(afterLogin());
  } catch (err) {
    dispatch(handleUserResetPassword(username, err.response.body));
    dispatch(handleErrors(err.response.body));
  }
};

export const loginClient = (offercode, name) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/authenticateCustomer';
    await request.post(endpoint).send({ offercode, ...name });
    dispatch(setClient({ offercode, ...name }));
    dispatch(showSpinner(false));
    dispatch(showSecretWordValidation(true));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const validateClientSecret = (offercode, name, secretWord) => async (dispatch) => {
  try {
    const endpoint = '/api/request-manager/authenticateCustomer';
    const res = await request.post(endpoint).send({ offercode, ...name, secretWord });
    dispatch(logUser('Client'));
    localStorage.setItem('LoggedInUser', 'Client');
    dispatch(getOfferDetails(res.body.id, '', true));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const setUserPassword = (token, email, password) => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user/setUserPassword';
    const res = await request.post(endpoint).send({ token, email, password });
    dispatch(openSnackbar({
      message: res.body.message,
      kind: SnackbarKind.SUCCESS,
    }));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const resetUserPassword = email => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user/resetPassword';
    const res = await request.post(endpoint).send({ email });
    dispatch(openSnackbar({
      message: res.body.message,
      kind: SnackbarKind.SUCCESS,
    }));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};
