import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Input from './Input';
import Preview from './Preview';
import validateValueByRuleName from '../../validateValueByRuleName';
import request from '../../../../../requestAgent';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '40px 0',
  },
  errorMessage: {
    color: '#FF0000',
    width: '272px',
    padding: '5px',
    fontSize: '14px',
    fontFamily: theme.fonts.fontFamily,
  },
}));


const FilePanel = ({
  uploadInputLabel,
  onChangeDocumentUploadFormValues,
}) => {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState('');
  const [files, setFiles] = useState([]);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const convertToBase64 = file => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  const addFile = async (file) => {
    try {
      const { size, name } = file;
      if (files.some(f => f.name === name)) {
        return;
      }

      if (!validateValueByRuleName(size, 'fileSize')) {
        setErrorMessage('Die Datei muss kleiner als 5 MB sein.');
        return;
      }

      if (!validateValueByRuleName(name, 'fileName')) {
        setErrorMessage('Bitte kontrollieren Sie den Dateinamen. Leerzeichen und Sonderzeichen (& / | \' * ; " > < % ?) sind nicht erlaubt.');
        return;
      }
      let recaptchaToken = '';
      if (executeRecaptcha) {
        recaptchaToken = await executeRecaptcha('documentUpload');
      }
      const base64File = await convertToBase64(file);
      const formData = new FormData();
      formData.append('base64', base64File);
      formData.append('filename', name);
      formData.append('recaptchaToken', recaptchaToken);
      const endpoint = '/api/customer-contact-services/attachment';
      const res = await request.post(endpoint).send(formData);
      const { location } = res.body;
      const filesAfterAdd = [...files, { name, location }];
      setFiles(filesAfterAdd);
      onChangeDocumentUploadFormValues(filesAfterAdd);
      setErrorMessage('');
    } catch (e) {
      setErrorMessage('Die Operation konnte nicht durchgeführt werden. Bitte versuchen Sie es später noch einmal.');
    }
  };

  const deleteFile = async (location) => {
    try {
      const endpoint = '/api/customer-contact-services/attachment';
      await request.delete(endpoint).send({ location });
      const filesAfterDelete = files.filter(file => file.location !== location);
      setFiles(filesAfterDelete);
      onChangeDocumentUploadFormValues(filesAfterDelete);
      setErrorMessage('');
    } catch {
      // log happen in backend
    }
  };

  return (
    <div className={classes.container}>

      {files.map(file => (
        <Preview
          key={file.location}
          file={file}
          onRemove={() => deleteFile(file.location)}
        />
        ))}

      {validateValueByRuleName(files.length, 'filesLimit') ? (
        <div>
          <Input
            label={uploadInputLabel}
            onChange={e => addFile(e.target.files[0])}
          />
          <p className={classes.errorMessage}>{errorMessage}</p>
        </div>) : null}

    </div>
  );
};

FilePanel.propTypes = {
  uploadInputLabel: PropTypes.string,
  onChangeDocumentUploadFormValues: PropTypes.func,
};

FilePanel.defaultProps = {
  uploadInputLabel: '',
  onChangeDocumentUploadFormValues: () => {},
};

export default FilePanel;
