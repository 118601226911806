import types from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCHED_USERS:
      return {
        ...action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
