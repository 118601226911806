import types from './types';

export const setErrorMessage = message => ({
  type: types.SET_ERROR,
  payload: message,
});

export const setMessage = message => ({
  type: types.SET_NOTIFICATION,
  payload: message,
});

export const clearMessage = () => ({
  type: types.CLEAR_MESSAGE,
});
