const styles = theme => ({
  formLabel: {
    fontSize: '0.8125rem',
    fontWeight: 'bolder',
    color: theme.palette.textColor,
    paddingLeft: theme.spacing.unit,
    lineHeight: '17px',
  },
  formLabelFocused: {
    color: `${theme.palette.darkBlue} !important`,
  },
  formControlLabel: {
    marginRight: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit,
  },
  materialRadio: {
    width: 40,
    height: 40,
    paddingLeft: '2px',
    paddingRight: '0px',
    color: theme.palette.primary1Color,
  },
  materialRadioChecked: {
    fontSize: 20,
    color: theme.palette.primary1Color,
    paddingRight: '-20px',
  },
});

export default styles;
