import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid, Typography, withStyles, Button } from '@material-ui/core';
import { loginUser, clearPasswordResetEmail } from '../../actions/authentication';
import { clearMessage } from '../../actions/notificationCenter';
import styles from './style';
import InputText from '../../components/InputText';
import Notification from '../Notification';
import history from '../../history';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      message: '',
    };

    this.history = history;
    this.submitHandler = this.submitHandler.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.inputFieldsRepeater = this.inputFieldsRepeater.bind(this);
    this.linkHandler = this.linkHandler.bind(this);
  }

  componentWillUnmount() {
    this.props.clearMessage();
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
  }

  submitHandler(event) {
    event.preventDefault();
    this.props.loginUser(this.state.username, this.state.password);
  }

  linkHandler() {
    this.props.clearPasswordResetEmail();
    this.history.push('/resetpassword');
  }

  inputFieldsRepeater(fields) {
    const mapItem = [];
    fields.map(key =>
      mapItem.push(<InputText
        {...this.props.inputFields[key]}
        {...this.props.structure.fields[key]}
        id={key}
        defaultValue={this.state[key]}
        onLinkClick={this.linkHandler}
        onChange={this.onValueChange}
        key={`inputfields-${key}`}
      />));
    return mapItem;
  }

  render() {
    const { classes, structure } = this.props;
    return (
      <Typography component="div" className={classes.root}>
        <Typography component="div" className={classes.wrapper}>
          {structure && (
            <Grid
              container
              alignItems="flex-start"
              justify="center"
              spacing={0}
              className={classes.grid}
            >
              <Grid container item xs={12} spacing={8}>
                <Grid item xs={12} md={6}>
                  <Typography align="left" variant="h1" className={classes.title}>
                    {structure.header.mainText}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={8}>
                <Grid item xs={12} sm={8} md={5} className={classes.notification}>
                  <Notification />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={8}>
                <Grid item xs={12} sm={8} md={5}>
                  <form onSubmit={event => this.submitHandler(event)} className={classes.form}>
                    {this.inputFieldsRepeater(structure.fieldsOrder)}
                    <Button
                      variant="outlined"
                      className={classes.submitButton}
                      type={structure.buttons.submit.type}
                    >
                      {structure.buttons.submit.label}
                    </Button>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Typography>
      </Typography>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.login,
  inputFields: state.projectFields,
});

const mapDispatchToProps = dispatch => ({
  loginUser: (username, password) => dispatch(loginUser(username, password)),
  clearMessage: () => dispatch(clearMessage()),
  clearPasswordResetEmail: () => dispatch(clearPasswordResetEmail()),
});

Login.propTypes = {
  message: PropTypes.string,
  loginUser: PropTypes.func,
  clearPasswordResetEmail: PropTypes.func,
  clearMessage: PropTypes.func,
  error: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  structure: PropTypes.objectOf(Object),
  inputFields: PropTypes.objectOf(Object),
};

Login.defaultProps = {
  classes: '',
  loginUser: () => {},
  clearMessage: () => {},
  clearPasswordResetEmail: () => {},
  message: '',
  error: false,
  structure: undefined,
  inputFields: undefined,
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Login);
