import types from '../actions/types';

const initialState = {
  offer: {
    code: null,
    id: null,
    offerStep: 'step1',
    evbNumber: '',
  },
  tariffs: [],
  isSFEstimated: false,
  isSubmitStep1DialogOpen: false,
  offerAnswers: {
    setSFKlasse: '',
    hadAccidents: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FRONTEND_STORAGE_DATA:
      return {
        ...state,
        offerAnswers: action.payload
      };
    case types.SET_SF_ESTIMATED:
      return {
        ...state,
        isSFEstimated: true
      };
    case types.SET_SUBMIT_STEP1_DIALOG_OPEN:
      return {
        ...state,
        isSubmitStep1DialogOpen: action.payload
      };
    case types.CLEAR_OFFER:
      return initialState;
    case types.SET_OFFER_DETAILS:
      return {
        ...state,
        offer: {
          ...state.offer,
          ...action.payload
        }
      };
    case types.SET_OFFER_CODE:
      return {
        ...state,
        offer: {
          ...state.offer,
          offerCode: action.payload.code,
          offerId: action.payload.id
        }
      };
    case types.SET_OFFER_STEP:
      return {
        ...state,
        offer: {
          ...state.offer,
          offerStep: action.payload.step
        }
      };
    case types.SET_PERSONAL_DATA_UPDATED:
      return {
        ...state,
        offer: {
          ...state.offer,
          personalDataUpdated: action.payload
        }
      };
    case types.SET_EVB_NUMBER: {
      return {
        ...state,
        offer: {
          ...state.offer,
          evbNumber: action.payload
        }
      };
    }
    case types.SET_OFFER_USER_DATA: {
      return {
        ...state,
        offer: {
          ...state.offer,
          [action.payload.field]: action.payload.value
        }
      };
    }
    case types.SET_TARIFFS: {
      return {
        ...state,
        tariffs: action.payload
      };
    }
    case types.SET_BASIC_TARIFF_DATA: {
      const { customer, contract } = action.payload;
      return {
        ...state,
        offer: {
          ...state.offer,
          customerIsCompany: customer.isCompany,
          customerBirthday: customer.birthday,
          customerPostalCode: customer.postalCode,
          contractSfClassLiability: contract.sfClass.liability,
          contractSfClassVollkasko: contract.sfClass.vollkasko,
          customerHadPreviousInsurer: customer.hadPreviousInsurer,
          contractVehicleUserConditions: contract.vehicleUserConditions,
          contractDifferingOwner: contract.differingOwner,
          contractHadAccidents: contract.hadAccidents,
          contractSetSFKlasse: contract.setSFKlasse,
          contractSfrInheritFromOther: contract.inheritFromOther,
          contractVehicleUsersYoungestDriverAge: contract.youngestDriverAge,
          contractVehicleUsersSpouseBirthdate: contract.spouseBirthdate,
          customerFirstTimeInsuresThisCar: customer.firstTimeInsuresThisCar,
          customerCoverBonusFromPreviousInsurance:
            customer.coverBonusFromPreviousInsurance
        }
      };
    }
    case types.SET_IS_COMPANY: {
      return {
        ...state,
        offer: {
          ...state.offer,
          customerIsCompany: action.payload
        }
      };
    }
    case types.SET_META_DATA: {
      return {
        ...state,
        meta: action.payload
      };
    }
    case types.SET_INITIAL_TARIFF: {
      return {
        ...state,
        offer: {
          ...state.offer,
          tariff: action.payload
        }
      };
    }
    case types.CHANGE_STATUS: {
      return {
        ...state,
        offer: {
          ...state.offer,
          status: action.payload
        }
      };
    }
    case types.SET_EXTENDED_TARIFF_DATA: {
      const {
        sfClass,
        paymentInterval,
        yearlyMileageInKM,
        offerValues,
        deductibleType,
        riskType,
        coverage
      } = action.payload;

      /* eslint-disable prefer-const */
      let data = {};

      if (sfClass.vollkasko) {
        data.contractSfClassVollkasko = sfClass.vollkasko;
      }

      if (sfClass.liability) {
        data.contractSfClassLiability = sfClass.liability;
      }

      if (paymentInterval) {
        data.contractPaymentInterval = paymentInterval;
      }

      if (yearlyMileageInKM) {
        data.contractYearlyMileageInKM = yearlyMileageInKM;
      }

      if (offerValues) {
        data.contractOfferValuesRoadsideAssistance =
          offerValues.roadsideAssistance;
        data.contractOfferValuesMobility = offerValues.mobility;
        data.contractOfferValuesElectroPlus = offerValues.electroPlus;
        data.contractOfferValuesDiscountProtection =
          offerValues.discountProtection;
      }

      if (deductibleType) {
        data.contractDeductibleType = deductibleType;
      }

      if (riskType) {
        data.riskType = riskType;
      }

      if (coverage) {
        data.coverage = coverage;
      }

      return {
        ...state,
        offer: {
          ...state.offer,
          ...data
        }
      };
    }
    case types.DELETE_EXTENDED_TARIFF_DATA: {
      return {
        ...state,
        offer: {
          ...state.offer,
          paymentInterval: null,
          yearlyMileageInKM: null,
          deductibleType: null,
          riskType: null
        }
      };
    }

    case types.ADD_PERSONAL_DETAILS: {
      return {
        ...state,
        offer: {
          ...state.offer,
          ...action.payload
        }
      };
    }
    default:
      return {
        ...state
      };
  }
};
