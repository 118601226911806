import types from '../actions/types';

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_STRUCTURE:
      return {
        ...state,
        ...payload,
      };
    case types.SET_DEALERSHIPS_ADD_USER: {
      return {
        ...state,
        createUser: {
          ...state.createUser,
          fields: {
            ...state.createUser.fields,
            dealership: {
              ...state.createUser.fields.dealership,
              options: [
                state.createUser.fields.dealership.options[0],
                ...payload.map(({ dealership, dealershipName }) => ({
                  key: `${dealership} - ${dealershipName}`,
                  value: dealership,
                })),
              ],
            },
          },
        },
      };
    }
    case types.SET_DEALERSHIPS_EDIT_USER: {
      return {
        ...state,
        editUserDetails: {
          ...state.editUserDetails,
          fields: {
            ...state.editUserDetails.fields,
            dealership: {
              ...state.editUserDetails.fields.dealership,
              options: [
                // First item is the placeholder.
                // Can be refcatored depending on the placeholder strategy for select boxes
                state.editUserDetails.fields.dealership.options[0],
                ...payload.map(({ dealership, dealershipName }) => ({
                  key: `${dealership} - ${dealershipName}`,
                  value: dealership,
                })),
              ],
            },
          },
        },
      };
    }
    case types.SET_SUPERVISORS_ADD_USER: {
      return {
        ...state,
        createUser: {
          ...state.createUser,
          fields: {
            ...state.createUser.fields,
            supervisor: {
              ...state.createUser.fields.supervisor,
              options: [
                state.createUser.fields.supervisor.options[0],
                ...payload.map(({ id, firstName, lastName }) => ({
                  key: `${lastName}, ${firstName}`,
                  value: id,
                })),
              ],
            },
          },
        },
      };
    }
    case types.SET_SUPERVISORS_EDIT_USER: {
      return {
        ...state,
        editUserDetails: {
          ...state.editUserDetails,
          fields: {
            ...state.editUserDetails.fields,
            supervisor: {
              ...state.editUserDetails.fields.supervisor,
              options: [
                // First item is the placeholder.
                // Can be refcatored depending on the placeholder strategy for select boxes
                state.editUserDetails.fields.supervisor.options[0],
                ...payload.map(({ id, firstName, lastName }) => ({
                  key: `${lastName}, ${firstName}`,
                  value: id,
                })),
              ],
            },
          },
        },
      };
    }
    case types.SET_ROLES_ADD_USER: {
      return {
        ...state,
        createUser: {
          ...state.createUser,
          fields: {
            ...state.createUser.fields,
            role: {
              ...state.createUser.fields.role,
              options: [
                state.createUser.fields.role.options[0],
                ...payload.map(({ name, label }) => ({
                  key: label,
                  value: name,
                })),
              ],
            },
          },
        },
      };
    }
    case types.SET_ROLES_EDIT_USER: {
      return {
        ...state,
        editUserDetails: {
          ...state.editUserDetails,
          fields: {
            ...state.editUserDetails.fields,
            role: {
              ...state.editUserDetails.fields.role,
              options: [
                // First item is the placeholder.
                // Can be refcatored depending on the placeholder strategy for select boxes
                state.editUserDetails.fields.role.options[0],
                ...payload.map(({ name, label }) => ({
                  key: label,
                  value: name,
                })),
              ],
            },
          },
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export const getParsedCustomerContactSchema = customerContact => (customerContact ? {
  step1: customerContact.step1,
  step2: customerContact.step2,
  questions: customerContact.questions,
  clientType: customerContact.clientType,
  buttons: customerContact.buttons,
  contactReasons: customerContact.contactReasons,
  sentDialog: customerContact.sentDialog,
  oem: customerContact.oem,
  validationErrorMessages: customerContact.validationErrorMessages,
} : {});
