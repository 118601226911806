const styles = theme => ({
  wrapper: {
    ...theme.containers.wrapper,
  },
  title: {
    ...theme.containers.title,
  },
  radioTitle: {
    ...theme.fonts.label,
    paddingBottom: theme.units.spacing * 1.5,
  },
  radioOptions: {
    paddingBottom: theme.units.spacing * 2,
  },
});

export default styles;
