const styles = theme => ({
  title: {
    ...theme.containers.title,
  },
  userList: {
    display: 'table',
    width: '100%',
    marginTop: theme.spacing.unit * 4,
    border: '1px solid grey',
  },
  userListItem: {
    display: 'table-row',
    width: '100%',
    backgroundColor: 'white',
    '& div': {
      display: 'table-cell',
      textAlign: 'left',
      padding: 10,
      borderBottom: '1px solid grey',
    },
    '&:hover': {
      backgroundColor: 'grey',
    },
  },
});

export default styles;
