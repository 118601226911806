const styles = theme => ({
  inputContainer: {
    marginBottom: theme.spacing.unit * 4,
  },
  labelContainer: {
    position: 'relative',
    height: 'auto',
    display: 'inline-block',
    '& label': {
      lineHeight: `${theme.spacing.unit * 5}px`,
      position: 'relative',
      left: 0,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: '400',
      paddingRight: theme.spacing.unit,
    },
  },
  blockWrapper: {
    display: 'inline-block',
    '@media only screen and (max-width: 600px)': {
      display: 'grid',
      paddingBottom: `${theme.spacing.unit * 2}px`,
    },
    '& button': {
      height: theme.spacing.unit * 5,
      margin: '0px 8px',
      minWidth: '150px',
    },
    '&[data-checked="false"] button:not(:hover)': {
      backgroundColor: 'white',
      color: theme.palette.textColor,
    },
  },
});

export default styles;
