const styles = theme => ({
  paper: theme.containers.paper,
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  col: {
    width: '33.33%',
  },
  rowHead: {
    backgroundColor: theme.palette.background.default,
  },
  rowPrice: {
    backgroundColor: theme.palette.background.default,
  },
  rowSubHeads: {
    backgroundColor: theme.palette.background.default,
  },
  headline: {
    fontSize: '24px',
  },
  accent1Color: {
    color: theme.palette.accent1Color,
  },
  subHeads: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'right',
    backgroundColor: theme.palette.mainBackgroundColor,
    border: 'none',
  },
  rowHeadLabel: {
    backgroundColor: theme.palette.mainBackgroundColor,
    border: 'none',
  },
  bodyLabel: {
    textAlign: 'right',
    backgroundColor: theme.palette.mainBackgroundColor,
    border: 'none',
  },
  body: {
    textAlign: 'right',
  },
  price: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  priceLabel: {
    border: 'none',
  },
  borderEnd: {
    borderBottom: '1px solid rgba(204, 204, 204, 1)',
    borderLeft: '1px solid rgba(204, 204, 204, 1)',
    borderRight: '1px solid rgba(204, 204, 204, 1)',
  },
  borderStart: {
    borderTop: '1px solid rgba(204, 204, 204, 1)',
    borderLeft: '1px solid rgba(204, 204, 204, 1)',
    borderRight: '1px solid rgba(204, 204, 204, 1)',
  },
  buttons: {
    border: 'none',
    padding: '4px 16px',
  },
  paperTable: {
    display: 'inline-flex',
  },
  spacing: {
    border: 'none',
    backgroundColor: theme.palette.mainBackgroundColor,
  },
  table: {
    fontFamily: theme.fontFamily,
  },
});

export default styles;
