import React, { Component, Fragment } from 'react';
import { Router, Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import styles from './style';
import history from '../../history';
import Login from '../Login';
import ClientAuth from '../ClientAuth';
import CreatePassword from '../CreatePassword';
import AccountDetails from '../AccountDetails';
import EvbList from '../EvbList';
import UserAdmin from '../UserAdmin';
import VehicleSearch from '../VehicleSearch';
import ChooseVehicleType from '../ChooseVehicleType';
import ResetPassword from '../ResetPassword';
import InsuranceOffer from '../InsuranceOffer';
import FinishOffer from '../FinishOffer';
import SupportPage from '../SupportPage';
import Impressum from '../ContactForm/Components/Impressum/Impressum';
import Navbar from '../Navbar';
import Logout from '../Logout';
import ContactForm from '../ContactForm/ContactForm';
import { isUserLogged } from '../../actions/authentication';
import { getSchema } from '../../actions/schema';
import PrivateRoute from '../PrivateRoute';
import Snackbar from '../../components/Snackbar';
import { isBmw } from '../../utils/theme';
import { setCarAsVehicleType } from '../../actions/settings';
import ROUTES from '../../constants/routes';

class App extends Component {
  componentDidMount() {
    this.props.getSchema();
    this.props.isUserLogged(this.props.location.pathname);
    this.setupVehicleTypeForNonBmwFlows();
  }

  setupVehicleTypeForNonBmwFlows = () => {
    if (!isBmw()) {
      this.props.setCarAsVehicleType();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Navbar />
        <div id="main" className={classes.wrapper}>
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Redirect from="/clientauth" to="/angebot" />
              <Route exact path="/angebot" component={ClientAuth} />
              <Route exact path="/resetpassword" component={ResetPassword} />
              <Route exact path="/support" component={SupportPage} />
              <Route exact path="/createpassword/:token" component={CreatePassword} />
              <Route
                exact
                path="/restorepassword/:token"
                render={props => <CreatePassword {...props} restore />}
              />
              <Route exact path={`/${ROUTES.CONTACT_FORM}`} component={ContactForm} />
              <Route exact path={`/${ROUTES.CONTACT_FORM_IMPRESSUM}`} component={Impressum} />
              <PrivateRoute exact path="/accountdetails" component={AccountDetails} />
              <PrivateRoute path="/accountdetails/:ps/:id" component={AccountDetails} />
              <PrivateRoute path="/insuranceoffer/:id/:step" component={InsuranceOffer} />
              <PrivateRoute path="/insuranceoffer/:id" component={InsuranceOffer} />
              <PrivateRoute exact path="/vehiclesearch" component={VehicleSearch} />
              <PrivateRoute exact path="/choosevehicletype" component={ChooseVehicleType} />
              <PrivateRoute exact path="/useradmin" component={UserAdmin} />
              <PrivateRoute path="/finish/:id" component={FinishOffer} />
              <PrivateRoute exact path="/evblist" component={EvbList} />
            </Switch>
          </Router>
        </div>
        <Snackbar />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSchema: () => dispatch(getSchema()),
  isUserLogged: location => dispatch(isUserLogged(location)),
  setCarAsVehicleType: () => dispatch(setCarAsVehicleType()),
});

App.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any),
  getSchema: PropTypes.func,
  isUserLogged: PropTypes.func,
  location: PropTypes.objectOf(PropTypes.any),
  setCarAsVehicleType: PropTypes.func,
};

App.defaultProps = {
  classes: {},
  getSchema: () => {},
  isUserLogged: () => {},
  location: {},
  setCarAsVehicleType: () => {},
};

export default compose(
  withStyles(styles),
  withRouter,
)(connect(
  null,
  mapDispatchToProps,
)(App));
