import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Paper from '@material-ui/core/Paper';
import Button from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';
import styles from './style';

class ShowAccountDetails extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {};

    this.onClick = this.onClick.bind(this);
    this.inputFields = this.inputFields.bind(this);
  }

  onClick(variant) {
    this.props.variantChange(variant);
  }

  inputFields(fields) {
    const mapItem = [];
    fields.map(key =>
      mapItem.push(<Typography component="div" key={key} className={this.classes.inputContainer}>
        <Typography component="p" className={this.classes.label}>
          {this.props.structure.fields[key].label}
        </Typography>
        <Typography component="p" className={this.classes.value}>
          {this.props.personalDetails[key]}
        </Typography>
                   </Typography>));
    return mapItem;
  }

  render() {
    const { structure, classes } = this.props;
    return (
      <div className={classes.wrapper}>
        {structure && (
          <Grid container justify="center" alignContent="center" spacing={0}>
            <Grid item xs={12}>
              <Typography align="center" variant="h1" className={classes.title}>
                {structure.header.mainText}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} lg={8}>
              <Paper elevation={1} className={classes.rootPaper}>
                {this.inputFields(structure.fieldsOrder)}
              </Paper>
            </Grid>
            <Grid container item xs={12} md={10} lg={8} spacing={0} justify="space-between">
              <ButtonLink
                {...structure.buttons.changePassword}
                onClick={() => this.onClick('changePassword')}
              />
              <Button
                {...this.props.structure.buttons.changeDetails}
                onClick={() => this.onClick('changeDetails')}
              />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  personalDetails: state.personalDetails,
  structure: state.projectStructure.showAccountDetails,
  inputFields: state.projectFields,
});

ShowAccountDetails.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  structure: PropTypes.objectOf(Object),
  personalDetails: PropTypes.objectOf(Object).isRequired,
};

ShowAccountDetails.defaultProps = {
  classes: '',
  structure: undefined,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(ShowAccountDetails);
