import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles, InputLabel, FormControl, FormHelperText,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import * as classNames from 'classnames';
import styles from './styles';

const CustomNumberFormat = (props) => {
  const {
    classes, label, id, name, helperText, meta: { error }, input: { onChange, value },
  } = props;

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        shrink
        htmlFor={id}
        classes={{ focused: classes.inputLabelFocused }}
        className={classes.inputLabel}
      >
        {label}
      </InputLabel>
      <NumberFormat
        className={classNames(classes.numberFormat, {
          [classes.invalidNumberFormat]: !!error,
        })}
        name={name}
        allowNegative={false}
        decimalScale={0}
        defaultValue={0}
        suffix=" 000"
        inputMode="numeric"
        value={value}
        onValueChange={({ floatValue }) => {
          onChange(floatValue || 0);
        }}
      />
      {helperText && (
      <FormHelperText
        id={helperTextId}
        classes={{
        root: classes.formHelperTextRoot,
      }}
      >
        {helperText}
      </FormHelperText>
  )}
    </FormControl>
  );
};

CustomNumberFormat.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  helperText: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any, // string or bool
  }),
  input: PropTypes.shape({
    value: PropTypes.number,
    onChange: PropTypes.func,
  }),
};

CustomNumberFormat.defaultProps = {
  classes: '',
  label: '',
  id: '',
  name: '',
  helperText: '',
  meta: {
    error: false,
  },
  input: {},
};

export default withStyles(styles)(CustomNumberFormat);
