import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import * as classNames from 'classnames';
import useStyles from './Impressum.style';
import Footer from '../Footer';
import { isEmailValue, isValidUrl } from './utils';

const displayEmailItem = (item, classes) => (
  <>
    <span>E-Mail: </span>
    <a className={classes.link} href={`mailto:${item}`}>{item}</a>
  </>
);

const displayUrlItem = (item, classes) => (
  <>
    <span>www: </span>
    <a className={classes.link} href={item} target="_blank" rel="noopener noreferrer">{item}</a>
  </>
);

const displayText = (item, classes) => (<p>{item}</p>);

const renderKeyMap = {
  email: 'email',
  url: 'url',
  text: 'text',
};

const renderFunctionMap = {
  [renderKeyMap.email]: displayEmailItem,
  [renderKeyMap.url]: displayUrlItem,
  [renderKeyMap.text]: displayText
};

const renderItem = (item, classes) => {
  let type;
  switch (true) {
    case isEmailValue(item):
      type = renderKeyMap.email;
      break;
    case isValidUrl(item):
      type = renderKeyMap.url;
      break;
    default:
      type = renderKeyMap.text;
  }
  return renderFunctionMap[type](item, classes);
};

const displayList = (classes, list) => (
  <ul className={classNames(classes.block, classes.list)}>
    {list.map(item => (
      <li key={item.toString()}>
        {renderItem(item, classes)}
      </li>
    ))}
  </ul>
);

const Impressum = ({ schema }) => {
  const classes = useStyles();
  const { impressum } = schema;

  if (!impressum || !impressum.firstSectionHeading) {
    return null;
  }

  return (
    <div className={classes.container}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      <div className={classNames(classes.fontBase, classes.innerContainer)}>
        <h2 className={classes.header}>{impressum.firstSectionHeading}</h2>
        {displayList(classes, impressum.firstSectionList)}
        {displayList(classes, impressum.firstSectionSubList)}
        <p className={classes.block}>{impressum.secondSectionHeading}</p>
        <div className={classes.columns}>
          {displayList(classes, impressum.secondSectionListLeft)}
          {displayList(classes, impressum.secondSectionListRight)}
        </div>
        {displayList(classes, impressum.thirdSectionList)}
      </div>
      </GoogleReCaptchaProvider>
      <Footer oemSchema={schema.oem} recaptchaPlaceholder printImpressum={false} />
    </div>
  );
};

const mapStateToProps = state => ({
  schema: state.projectStructure.customerContact
    ? state.projectStructure.customerContact
    : {}
});

Impressum.propTypes = {
  schema: PropTypes.object
};

Impressum.defaultProps = {
  schema: {}
};

export default compose(connect(mapStateToProps, null))(Impressum);
