const QUESTION_GROUP = {
  ADDRESS: 'ADDRESS',
  YEARLY_MILEAGE: 'YEARLY_MILEAGE',
  DRIVERS_GROUP: 'DRIVERS_GROUP',
  IBAN: 'IBAN',
  NAME: 'NAME',
  VEHICLE_SOLD: 'VEHICLE_SOLD',
  CONTRACT_SCOPE: 'CONTRACT_SCOPE',
  DOCUMENT_REQUEST: 'DOCUMENT_REQUEST',
  DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
  IDENTIFICATION: 'IDENTIFICATION',
  PREVIOUS_INSURER: 'PREVIOUS_INSURER',
};

const CLIENT_TYPE = {
  ALL: 'ALL',
  PHYSICAL_PERSON: 'Privatkunde',
  LEGAL_PERSON: 'Firmenkunde',
};

export {
  QUESTION_GROUP,
  CLIENT_TYPE,
};
