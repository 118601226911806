import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import styles from './style';

const TabContainer = ({ children, dir, classes }) => (
  <Typography component="div" dir={dir} className={classes.tabContainer}>
    {children}
  </Typography>
);

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles))(TabContainer);
