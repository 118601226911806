import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Grid } from '@material-ui/core';
import CustomTextField, { formikTextFieldProps } from './CustomTextField';
import { QUESTION_GROUP, CLIENT_TYPE } from '../../../constants/';
import { getText, getQuestions, getMandatoryFields, getFieldsWithValidationRules, validateValuesByRuleNames } from '../ContactForm';
import TitleLabel from './TitleLabel';
import DescriptionLabel from './DescriptionLabel';
import useFormSubmitTrigger from './useFormSubmitTrigger';
import useFormOnChange from './useFormOnChange';
import honeyStyles from './honeyStyles';

const currentFormQuestionGroup = QUESTION_GROUP.IDENTIFICATION;

const IdentificationFormPhysical = (props) => {
  const {
    schema,
    submitTrigger,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    onChange
  } = props;

  const fields = getQuestions(schema.questions, currentFormQuestionGroup);
  const formRef = useFormSubmitTrigger(submitTrigger);
  useFormOnChange(onChange, values, currentFormQuestionGroup);

  const formikProps = {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    validationErrorMessages: schema.validationErrorMessages,

  };

  return (
    <form autoComplete="off" ref={formRef} onSubmit={handleSubmit}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <TitleLabel>
            {getText(schema, currentFormQuestionGroup, 'text_1')}
          </TitleLabel>
        </Grid>


        <Grid item xs={12}>
          <DescriptionLabel>
            {getText(schema, currentFormQuestionGroup, 'text_2')}
          </DescriptionLabel>
        </Grid>


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.LastName, formikProps)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.FirstName, formikProps)}
          />
        </Grid>


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.DateOfBirth, formikProps)}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.PhoneNumber, formikProps)}
          />
        </Grid>


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.Street, formikProps)}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomTextField
            {...formikTextFieldProps(fields.HouseNumber, formikProps)}
          />
        </Grid>
        <Grid item xs={3} />


        <Grid item xs={3}>
          <CustomTextField
            {...formikTextFieldProps(fields.zipCode, formikProps)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.City, formikProps)}
          />
        </Grid>
        <Grid item xs={3} />


        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.PolicyNumber, formikProps)}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            {...formikTextFieldProps(fields.LicensePlate, formikProps)}
          />
        </Grid>


        <CustomTextField
          {...formikTextFieldProps(fields.PlaceOfBirth, formikProps)}
          style={honeyStyles}
        />


      </Grid>
    </form>
  );
};

IdentificationFormPhysical.propTypes = {
  schema: PropTypes.object.isRequired,
  submitTrigger: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withFormik({
  handleSubmit: (_, { setSubmitting, props }) => {
    setSubmitting(false);
    props.moveToNextStep();
  },
  mapPropsToValues: ({ formValues }) => ({
    City: formValues.IDENTIFICATION.City || '',
    DateOfBirth: formValues.IDENTIFICATION.DateOfBirth,
    FirstName: formValues.IDENTIFICATION.FirstName || '',
    HouseNumber: formValues.IDENTIFICATION.HouseNumber || '',
    LastName: formValues.IDENTIFICATION.LastName || '',
    LicensePlate: formValues.IDENTIFICATION.LicensePlate || '',
    PhoneNumber: formValues.IDENTIFICATION.PhoneNumber || '',
    PolicyNumber: formValues.IDENTIFICATION.PolicyNumber || '',
    Street: formValues.IDENTIFICATION.Street || '',
    zipCode: formValues.IDENTIFICATION.zipCode || '',
    PlaceOfBirth: formValues.IDENTIFICATION.PlaceOfBirth || '',
  }),
  validate: (values, { schema }) => {
    const errors = {};

    getFieldsWithValidationRules(
      schema.questions,
      currentFormQuestionGroup,
      CLIENT_TYPE.PHYSICAL_PERSON,
    ).forEach(validateValuesByRuleNames(values, errors));

    const mandatoryFields = getMandatoryFields(
      schema.questions,
      currentFormQuestionGroup,
      CLIENT_TYPE.PHYSICAL_PERSON,
    );

    mandatoryFields.forEach((key) => {
      if (!values[key]) {
        errors[key] = 'Required';
      }
    });


    return errors;
  },
})(IdentificationFormPhysical);
