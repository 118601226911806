import React, { Component, Fragment } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Paper, Hidden, Typography, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomExpansionPanelDetails from '../../../components/CustomExpansionPanelDetails';
import CustomExpansionPanel from '../../../components/CustomExpansionPanel';
import RadioInput from '../../../components/RadioInput';
import CustomRadioInput from '../../../components/CustomRadioInput';
import CustomExpansionPanelSummary from '../../../components/CustomExpansionPanelSummary';
import CustomInputText from '../../../components/CustomInputText';
import { extendedTariffData, confirmInitialOffer, setStep } from '../../../actions/offer';
import { clearMessage, setErrorMessage } from '../../../actions/notificationCenter';
import TariffComparison from '../../TariffComparison';
import DisplayInfo from '../../../components/DisplayInfo';
import ButtonLink from '../../../components/ButtonLink';
import styles from './style';
import RiskTypes from '../../../utils/riskTypes';
import { getCurrentProjectName } from '../../../utils/theme';

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      expanded: null,
      isLoading: true,
      isSpecial: null,
      tariff: null,
      liability: '',
      vollkasko: '',
    };

    this.isStructureReady = this.isStructureReady.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.validate = this.validate.bind(this);
    this.handleExpansionPanelChange = this.handleExpansionPanelChange.bind(this);
    this.getCoverageLabel = this.getCoverageLabel.bind(this);
  }

  componentDidMount() {
    this.init();
    scroll.scrollToTop();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tariffs.length < 1 && this.props.tariffs.length > 0) {
      this.init();
    }
    if (prevProps.tariffs.length !== this.props.tariffs.length) {
      this.init();
    }
  }

  init() {
    if (this.props.tariffs.length > 0) {
      const { tariffs = [{ special: false }] } = this.props;
      this.setState({
        isSpecial: tariffs[0].special,
        tariff: this.props.tariffs || '',
        liability: this.props.offer.contractSfClassLiability || 0,
        vollkasko: this.props.offer.contractSfClassVollkasko || 0,
        ...this.getValuesFromDeductible(),
        isLoading: false,
      });
    }
  }

  getValuesFromDeductible() {
    let res = {};
    const { translations } = this.props.structure.structure.question3[getCurrentProjectName()];
    const riskType = this.props.offer.riskType ? this.props.offer.riskType : RiskTypes.COMPREHENSIVE_COVERAGE;

    if (riskType === RiskTypes.COMPREHENSIVE_COVERAGE && this.props.offer.contractDeductibleType) {
      const keys = Object.keys(translations[1]);
      keys.forEach((el) => {
        if (translations[1][el] === this.props.offer.contractDeductibleType) {
          res = {
            fullyComprehensiveCover: el.split('/')[0],
            partialCover: el.split('/')[1],
            coverage: '1',
            expanded: 'panel2',
          };
        }
      });
    }
    if (riskType === RiskTypes.COMPREHENSIVE_COVERAGE && !this.props.offer.contractDeductibleType) {
      res = {
        fullyComprehensiveCover: '500\u20AC',
        partialCover: '300\u20AC',
        coverage: '1',
      };
    }
    if (riskType === RiskTypes.PARTIALLY_COMPREHENSIVE_COVERAGE) {
      const keys = Object.keys(translations[2]);
      keys.forEach((el) => {
        if (translations[2][el] === this.props.offer.contractDeductibleType) {
          res = {
            fullyComprehensiveCover: '500\u20AC',
            partialCover: el,
            coverage: '2',
            expanded: 'panel2',
          };
        }
      });
    }

    if (riskType === RiskTypes.LIABILITY) {
      res = {
        fullyComprehensiveCover: '500\u20AC',
        partialCover: '300\u20AC',
        expanded: 'panel2',
        coverage: '3',
      };
    }

    if (!res.coverage) {
      console.error(`No special price displayed? Deductible type ${this.props.offer.contractDeductibleType} is not configured in schema.`);
    }

    return res;
  }

  onValueChange(value, field) {
    this.setState({ [field]: value }, this.updateOffer);
  }

  isStructureReady() {
    return (
      this.props.structure !== undefined && this.props.tariffs.length > 0 && !this.state.isLoading
    );
  }

  handleExpansionPanelChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  checkValueSFClass = () => {
    const maxSFValue = this.props.insuranceOffer.values.maxValueSFClass;
    if (Number(this.state.liability.toString().replace(',', '.')) > maxSFValue) {
      this.setState({ liability: maxSFValue }, this.updateOffer);
    }
    if (Number(this.state.vollkasko.toString().replace(',', '.')) > maxSFValue) {
      this.setState({ vollkasko: maxSFValue }, this.updateOffer);
    }
  };

  getDataForUpdateOffer = () => {
    const deductibleType = this.getDeductibleType();
    const data = {
      sfClass: {
        liability: Number(this.state.liability.toString().replace(',', '.')),
        vollkasko: Number(this.state.vollkasko.toString().replace(',', '.')),
      },
    };
    if (!this.state.isSpecial) {
      data.coverage = this.props.structure.structure.question2.translations[this.state.coverage];
    }
    if (!this.state.isSpecial && deductibleType) {
      data.deductibleType = deductibleType;
    }
    return data;
  }

  updateOffer(hideMessage = null) {
    const data = this.getDataForUpdateOffer();
    this.props.extendedTariffData(this.props.offer.id, data, 'step2', hideMessage);
  }

  getCoverageLabel = () => this.props.structure.structure.question2.fields.coverage.options
    .filter(item => item.value === this.state.coverage)
    .map(item => item.key)
    .join('');

  getDeductibleLabel = () => {
    const fullyComprehensiveCoverLabel = this.props.structure.structure.question3.fields.fullyComprehensiveCover.displayOn.includes(this.state.coverage)
      ? `${this.state.fullyComprehensiveCover} ${
        this.props.structure.structure.question3.fields.fullyComprehensiveCover.field.label
      } /`
      : '';

    const partialCoverLabel = this.props.structure.structure.question3.fields.partialCover.displayOn.includes(this.state.coverage)
      ? `${this.state.partialCover} ${
        this.props.structure.structure.question3.fields.partialCover.field.label
      } `
      : '';

    return `${fullyComprehensiveCoverLabel} ${partialCoverLabel} `;
  };

  getPartialCoverOptions = () => {
    if (
      !this.props.structure.structure.question3.fields.fullyComprehensiveCover.displayOn.includes(this.state.coverage)
    ) {
      const { options } = this.props.structure.structure.question3.fields.partialCover.field[
        getCurrentProjectName()
      ];
      return {
        ...this.props.structure.structure.question3.fields.partialCover.field,
        options,
      };
    }
    const options = this.state.fullyComprehensiveCover
      ? this.props.structure.structure.question3.fields.partialCover.field[
        getCurrentProjectName()
      ].options.filter(item =>
        this.props.structure.structure.question3.fields.partialCover[
          getCurrentProjectName()
        ].allowedCombinations[
          this.state.fullyComprehensiveCover
        ].includes(item.key))
      : [];

    const selectedOption = options.find(option => option.key === this.state.partialCover);
    if (!selectedOption) {
      this.setState({ partialCover: options[options.length - 1].key });
      // ToDo: setState doesn't work so I have to use this ;) Big mess in the code.
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.partialCover = options[options.length - 1].key;
    }

    return {
      ...this.props.structure.structure.question3.fields.partialCover.field,
      options,
    };
  };

  getDeductibleType = () => {
    const validations = this.props.structure.structure.question3[
      getCurrentProjectName()
    ].translations[this.state.coverage];
    if (validations.validate.length === 0) return null;

    if (validations.validate.length === 1) {
      return validations[this.state.partialCover];
    }

    if (validations.validate.length === 2) {
      return validations[`${this.state.fullyComprehensiveCover}/${this.state.partialCover}`];
    }
    return null;
  };

  updateAndConfirmOffer = (tariffTranslate) => {
    const data = this.getDataForUpdateOffer();
    this.props.confirmInitialOffer(this.props.offer.id, tariffTranslate, 'step3', data);
  };

  submitHandler(tariff) {
    if (this.validate()) {
      this.props.clearMessage();
      const tariffTranslate = this.state.isSpecial
        ? tariff === 'comfort'
          ? 'special'
          : tariff
        : tariff;
      this.updateAndConfirmOffer(tariffTranslate);
    } else {
      this.props.setErrorMessage('error message');
    }
  }

  validate() {
    return true;
  }

  render() {
    return (
      <Fragment>
        {this.props.isSFEstimated && (
          <DisplayInfo information={this.props.insuranceOffer.information.valuesEstimated} />
        )}
        {/* <Notification /> */}
        {this.isStructureReady() && (
          <form className={this.classes.form}>
            <Grid container direction="column" justify="center" alignContent="center" spacing={0}>
              <Paper className={this.classes.paper}>
                {/* Question 1 */}
                <CustomExpansionPanel
                  className={this.classes.paper}
                  expanded={this.state.expanded === 'panel1'}
                  onChange={this.handleExpansionPanelChange('panel1')}
                >
                  <CustomExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Hidden smDown>
                      <Typography className={this.classes.expansionPanelHeadline}>
                        {this.props.structure.structure.question1.text}:
                      </Typography>
                    </Hidden>
                    <Typography className={this.classes.expansionPanelSelection}>
                      <span>
                        {this.props.structure.structure.question1.fields.liability.label}{' '}
                        {this.state.liability}
                      </span>
                      {this.props.structure.structure.question1.displayOn.includes(this.state.coverage) && (
                        <span>
                          / {this.props.structure.structure.question1.fields.vollkasko.label}{' '}
                          {this.state.vollkasko}
                        </span>
                      )}
                    </Typography>
                  </CustomExpansionPanelSummary>
                  <CustomExpansionPanelDetails>
                    <Grid container justify="center" alignContent="center" spacing={2}>
                      {this.state.isSpecial && (
                        <Grid container item justify="center" alignContent="center">
                          {this.props.structure.structure.specialWarning}
                        </Grid>
                      )}
                      <Grid container item justify="center" alignContent="center" className={this.classes.customInputTextContainer}>
                        <CustomInputText
                          {...this.props.structure.structure.question1.fields.liability}
                          onChange={(value, field) => this.onValueChange(value, field)}
                          value={this.state.liability}
                          onBlur={this.checkValueSFClass}
                        />
                        {this.props.structure.structure.question1.displayOn.includes(this.state.coverage) && (
                          <CustomInputText
                            {...this.props.structure.structure.question1.fields.vollkasko}
                            onChange={(value, field) => this.onValueChange(value, field)}
                            value={this.state.vollkasko}
                            onBlur={this.checkValueSFClass}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </CustomExpansionPanelDetails>
                </CustomExpansionPanel>
                {/* Question 2 */}
                <CustomExpansionPanel
                  className={this.classes.paper}
                  expanded={this.state.expanded === 'panel2' && !this.state.isSpecial}
                  onChange={this.handleExpansionPanelChange('panel2')}
                >
                  <CustomExpansionPanelSummary
                    expandIcon={!this.state.isSpecial && <ExpandMoreIcon />}
                    disabled={this.state.isSpecial}
                    className={this.state.isSpecial ? this.classes.specialPrice : ''}
                  >
                    <Hidden smDown>
                      <Typography
                        className={
                          this.state.isSpecial
                            ? this.classes.specialDisplay
                            : this.classes.expansionPanelHeadline
                        }
                      >
                        {this.props.structure.structure.question2.text}:
                        &nbsp;
                      </Typography>
                    </Hidden>
                    <Typography
                      className={
                        this.state.isSpecial
                          ? this.classes.specialPriceText
                          : this.classes.expansionPanelSelection
                      }
                    >
                      {this.getCoverageLabel()}
                    </Typography>
                  </CustomExpansionPanelSummary>
                  <CustomExpansionPanelDetails>
                    <Paper className={`${this.classes.paper} ${this.classes.customInput}`}>
                      <RadioInput
                        {...this.props.structure.structure.question2.fields.coverage}
                        defaultValue={this.state.coverage}
                        onChange={(value, field) => this.onValueChange(value, field)}
                        readOnly={this.state.isSpecial}
                      />
                    </Paper>
                  </CustomExpansionPanelDetails>
                </CustomExpansionPanel>
                {/* Question 3 */}
                {this.props.structure.structure.question3.displayOn.includes(this.state.coverage) && (
                  <CustomExpansionPanel
                    className={this.classes.paper}
                    expanded={this.state.expanded === 'panel3' && !this.state.isSpecial}
                    onChange={this.handleExpansionPanelChange('panel3')}
                  >
                    <CustomExpansionPanelSummary
                      expandIcon={!this.state.isSpecial && <ExpandMoreIcon />}
                      className={this.state.isSpecial ? this.classes.specialPrice : ''}
                    >
                      <Hidden smDown>
                        <Typography
                          className={
                            this.state.isSpecial
                              ? this.classes.specialDisplay
                              : this.classes.expansionPanelHeadline
                          }
                        >
                          {this.props.structure.structure.question3.text}:
                          &nbsp;
                        </Typography>
                      </Hidden>
                      <Typography
                        className={
                          this.state.isSpecial
                            ? this.classes.specialPriceText
                            : this.classes.expansionPanelSelection
                        }
                      >
                        {this.getDeductibleLabel()}
                      </Typography>
                    </CustomExpansionPanelSummary>
                    <CustomExpansionPanelDetails>
                      <Paper className={this.classes.paper}>
                        {this.props.structure.structure.question3.fields.fullyComprehensiveCover.displayOn.includes(this.state.coverage) && (
                          <Typography component="div" className={this.classes.radioInputContainer}>
                            <RadioInput
                              {...this.props.structure.structure.question3.fields
                                .fullyComprehensiveCover.field}
                              defaultValue={this.state.fullyComprehensiveCover}
                              customRadioLabel={this.classes.customRadioLabel}
                              onChange={(value, field) => this.onValueChange(value, field)}
                              readOnly={this.state.isSpecial}
                            />
                          </Typography>
                        )}
                        {this.props.structure.structure.question3.fields.partialCover.displayOn.includes(this.state.coverage) && (
                          <Typography
                            component="div"
                            className={this.classes.customRadioInputContainer}
                          >
                            <CustomRadioInput
                              {...this.getPartialCoverOptions()}
                              defaultValue={this.state.partialCover}
                              customRadioLabel={this.classes.customRadioLabel}
                              onChange={(value, field) => this.onValueChange(value, field)}
                              readOnly={this.state.isSpecial}
                            />
                          </Typography>
                        )}
                      </Paper>
                    </CustomExpansionPanelDetails>
                  </CustomExpansionPanel>
                )}
              </Paper>
              { this.state.coverage && (
                <TariffComparison
                  coverage={this.state.coverage}
                  onClick={() => this.submitHandler}
                  price={this.props.tariffs}
                  isSpecial={this.state.isSpecial}
                />
              )}
              <div align="center">
                <ButtonLink
                  {...this.props.backButton}
                  onClick={() => this.props.setStep('step1')}
                />
              </div>
            </Grid>
          </form>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.insuranceStep2,
  backButton: state.projectStructure.insuranceStep3.structure.buttons.back,
  offer: state.offerDetails.offer,
  tariffs: state.offerDetails.tariffs,
  isSFEstimated: state.offerDetails.isSFEstimated,
  insuranceOffer: state.projectStructure.insuranceOffer,
  carModelList: state.carModelList,
});

const mapDispatchToProps = dispatch => ({
  clearMessage: () => dispatch(clearMessage()),
  setErrorMessage: message => dispatch(setErrorMessage(message)),
  extendedTariffData: (id, contractData, offerStep, hideMessage) =>
    dispatch(extendedTariffData(id, contractData, offerStep, hideMessage)),
  confirmInitialOffer: (id, tariffTranslate, offerStep, data) =>
    dispatch(confirmInitialOffer(id, tariffTranslate, offerStep, data)),
  setStep: offerStep => dispatch(setStep(offerStep)),
});

Step2.propTypes = {
  message: PropTypes.string,
  clearMessage: PropTypes.func,
  setErrorMessage: PropTypes.func,
  extendedTariffData: PropTypes.func,
  confirmInitialOffer: PropTypes.func,
  error: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  offer: PropTypes.objectOf(Object).isRequired,
  structure: PropTypes.objectOf(Object).isRequired,
  insuranceOffer: PropTypes.objectOf(Object).isRequired,
  backButton: PropTypes.objectOf(Object).isRequired,
  tariffs: PropTypes.arrayOf(Object).isRequired,
  setStep: PropTypes.func.isRequired,
  isSFEstimated: PropTypes.bool.isRequired,
};

Step2.defaultProps = {
  classes: '',
  clearMessage: () => {},
  setErrorMessage: () => {},
  extendedTariffData: () => {},
  confirmInitialOffer: () => {},
  message: '',
  error: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Step2));
