export const isEmailValue = item => /\S+@\S+\.\S+/.test(item);

export const isValidUrl = item => {
  let url;
  try {
    url = new URL(item);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}
