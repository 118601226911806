const USER_ROLES = {
  ADMIN_DEALER: 'adminDealer',
  ADMIN_DEALERSHIP: 'adminDealership',
  ADMIN_ORGANIZATION: 'adminOrganization',
  CALL_CENTER: 'callcenter',
  DEALER: 'dealer',
  LEAD_DEALER: 'leadDealer',
  MANAGER_DEALERSHIP: 'managerDealership',
};

export default USER_ROLES;
