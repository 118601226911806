const styles = theme => ({
  container: theme.containers.container,
  gridContainer: {
    width: '100%',
    marginBottom: '80px',
  },
  customInputTextContainer: {
    '& > div': {
      marginBottom: theme.spacing.unit,
    },
  },
  paperExpansionPanel: {
    ...theme.containers.paper,
    marginBottom: `${theme.units.spacing * 6}px`,
  },
  containerPanelSummary: {
    minHeight: '48px',
    margin: '12px 0 60px 0',
    display: 'flex',
    flexGrow: '1',
  },
  expansionPanelSelection: {
    fontFamily: theme.fontFamily,
  },
  expansionPanelHeading: {
    fontFamily: theme.fontFamily,
  },
  paper: {
    marginBottom: '30px',
    overflow: 'hidden',
  },
  specialPriceContainer: {
    position: 'relative',
    border: `1px solid ${theme.palette.darkBlue}`,
    borderRadius: '5px',
    opacity: '0.5',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 4}px !important`,
  },
  specialPriceInfo: {
    fontSize: '12px',
    fontWeight: 600,
    position: 'absolute',
    bottom: 0,
    right: theme.units.spacing,
    letterSpacing: 0,
    lineHeight: '17px',
    color: theme.palette.primary1Color,
  },
  title: theme.fonts.headline_5,
  questionLabel: theme.fonts.headline_3,
  '@global': {
    '.submitButton': {
      'margin-top': '0',
    },
    '.separator': {
      display: 'block',
      height: '70px',
    },
    // '.hoveringPaper': {
    //   position: 'fixed',
    //   bottom: '0px',
    //   left: '0',
    //   width: '100%',
    //   'margin-bottom': '0',
    //   'z-index': '1000',
    //   'padding-bottom': '25px',
    // },
    // '.hoveringPaper .totalPrice': {
    //   'font-size': '32px',
    //   'font-style': 'normal',
    //   'font-weight': '650',
    //   'margin-top': '25px',
    //   display: 'inline-block',
    // },
    // '.hoveringPaper .unit': {
    //   // 'font-size': '16px',
    //   'font-size': '32px',
    //   'font-style': 'normal',
    //   'font-weight': '650',
    // },
    '.rc-slider': {
      display: 'block',
      position: 'relative',
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    },
    '.rc-slider-rail': {
      position: 'absolute',
      width: '100%',
      backgroundColor: '#e9e9e9',
      height: '4px',
      borderRadius: '6px',
    },
    '.rc-slider-track': {
      position: 'absolute',
      left: '0',
      height: '4px',
      borderRadius: '6px',
      backgroundColor: theme.palette.primary1Color,
    },
    '.rc-slider-handle': {
      position: 'absolute',
      marginLeft: '-7px',
      marginTop: '-6px',
      width: '14px',
      height: '14px',
      cursor: 'grab',
      borderRadius: '50%',
      border: `solid 2px ${theme.palette.primary1Color}`,
      backgroundColor: theme.palette.primary1Color,
      MsTouchAction: 'pan-x',
      touchAction: 'pan-x',
    },
    '.rc-slider-handle-focus': {
      borderColor: '#57c5f7',
      boxShadow: '0 0 0 5px #96dbfa',
      outline: 'none',
    },
    '.rc-slider-handle-click-focused-focus': {
      borderColor: '#96dbfa',
      boxShadow: 'unset',
    },
    '.rc-slider-handle-hover': {
      borderColor: '#57c5f7',
    },
    '.rc-slider-handle-active': {
      borderColor: '#57c5f7',
      boxShadow: '0 0 5px #57c5f7',
      cursor: 'grabbing',
    },
    '.rc-slider-mark': {
      position: 'absolute',
      top: '18px',
      left: '0',
      width: '100%',
      fontSize: '12px',
    },
    '.rc-slider-mark-text': {
      position: 'absolute',
      display: 'inline-block',
      verticalAlign: 'middle',
      textAlign: 'center',
      cursor: 'pointer',
      color: '#999',
    },
    '.rc-slider-mark-text-active': {
      color: '#666',
    },
    '.rc-slider-step': {
      position: 'absolute',
      width: '100%',
      height: '4px',
      background: 'transparent',
    },
    '.rc-slider-dot': {
      position: 'absolute',
      bottom: '-1px',
      marginLeft: '-3px',
      width: '6px',
      height: '6px',
      border: '2px solid #e9e9e9',
      backgroundColor: '#fff',
      cursor: 'pointer',
      borderRadius: '50%',
      verticalAlign: 'middle',
    },
    '.rc-slider-dot-active': {
      borderColor: theme.palette.primary1Color,
      backgroundColor: theme.palette.primary1Color,
    },
    '.rc-slider-disabled': {
      backgroundColor: '#e9e9e9',
    },
    '.rc-slider-disabled--.rc-slider-track': {
      backgroundColor: '#ccc',
    },
    '.rc-slider-disabled--.rc-slider-handle': {
      borderColor: '#ccc',
      boxShadow: 'none',
      backgroundColor: '#fff',
      cursor: 'not-allowed',
    },
    '.rc-slider-disabled--.rc-slider-dot': {
      cursor: 'not-allowed !important',
    },
    '.rc-slider-disabled--.rc-slider-mark-text': {
      cursor: 'not-allowed !important',
    },
    '.rc-slider-vertical': {
      width: '14px',
      height: '100%',
      padding: '0 5px',
    },
    '.rc-slider-vertical--.rc-slider-rail': {
      height: '100%',
      width: '4px',
    },
    '.rc-slider-vertical--.rc-slider-track': {
      left: '5px',
      bottom: '0',
      width: '4px',
    },
    '.rc-slider-vertical--.rc-slider-handle': {
      marginLeft: '-5px',
      marginBottom: '-7px',
      MsTouchAction: 'pan-y',
      touchAction: 'pan-y',
    },
    '.rc-slider-vertical--.rc-slider-mark': {
      top: '0',
      left: '18px',
      height: '100%',
    },
    '.rc-slider-vertical--.rc-slider-step': {
      height: '100%',
      width: '4px',
    },
    '.rc-slider-vertical--.rc-slider-dot': {
      left: '2px',
      marginBottom: '-4px',
    },
    '.rc-slider-vertical--.rc-slider-dot-first-child': {
      marginBottom: '-4px',
    },
    '.rc-slider-vertical--.rc-slider-dot-last-child': {
      marginBottom: '-4px',
    },
    '.rc-slider-tooltip-zoom-down-enter': {
      transform: 'scale(0, 0)',
      animationTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
    },
    '.rc-slider-tooltip-zoom-down-appear': {
      transform: 'scale(0, 0)',
      animationTimingFunction: 'cubic-bezier(0.23, 1, 0.32, 1)',
    },
    '.rc-slider-tooltip-zoom-down-leave': {
      animationTimingFunction: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
    },
    '.rc-slider-tooltip-zoom-down-enter-.rc-slider-tooltip-zoom-down-enter-active': {
      animationName: 'rcSliderTooltipZoomDownIn',
      animationPlayState: 'running',
    },
    '.rc-slider-tooltip-zoom-down-appear-.rc-slider-tooltip-zoom-down-appear-active': {
      animationName: 'rcSliderTooltipZoomDownIn',
      animationPlayState: 'running',
    },
    '.rc-slider-tooltip-zoom-down-leave-.rc-slider-tooltip-zoom-down-leave-active': {
      animationName: 'rcSliderTooltipZoomDownOut',
      animationPlayState: 'running',
    },
    '.rc-slider-tooltip': {
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    },
    '.rc-slider-tooltip-hidden': {
      display: 'none',
    },
    '.rc-slider-tooltip-placement-top': {
      padding: '4px 0 8px 0',
    },
    '.rc-slider-tooltip-inner': {
      padding: '6px 2px',
      minWidth: '24px',
      height: '24px',
      fontSize: '12px',
      lineHeight: '1',
      color: '#fff',
      textAlign: 'center',
      textDecoration: 'none',
      backgroundColor: '#6c6c6c',
      borderRadius: '6px',
      boxShadow: '0 0 4px #d9d9d9',
    },
    '.rc-slider-tooltip-arrow': {
      position: 'absolute',
      width: '0',
      height: '0',
      borderColor: 'transparent',
      borderStyle: 'solid',
    },
    '.rc-slider-tooltip-placement-top--.rc-slider-tooltip-arrow': {
      bottom: '4px',
      left: '50%',
      marginLeft: '-4px',
      borderWidth: '4px 4px 0',
      borderTopColor: '#6c6c6c',
    },
  },
});

export default styles;
