const bulletSwitcher = ({ valid }) => {
  if (valid === true) return 'url("/assets/images/check_bullet.svg")';
  return 'url("/assets/images/ex_bullet.svg")';
};

const styles = theme => ({
  ruleCheck: {
    ...theme.fonts.paragraph,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px 0px 3px',
    '&::before': {
      content: '""',
      background: 'no-repeat left center',
      width: '30px',
      height: '30px',
      display: 'inline-block',
      backgroundImage: bulletSwitcher,
    },
  },
});

export default styles;
