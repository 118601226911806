import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { loginRoot } from '../../utils/redirects';
import styles from './style';

const PrivateRoute = ({
  component: Component,
  classes,
  auth,
  spinner,
  ...rest
}) => {
  const { showSpinner } = spinner;
  if (showSpinner) {
    return <CircularProgress className={classes.progress} />;
  }
  return (
    /* eslint-disable no-confusing-arrow */
    <Route
      {...rest}
      render={props =>
        (auth && auth.loggedInUser && !showSpinner) ? (
          <Component {...props} />
          )
        : (
          <Redirect
            to={{
              pathname: loginRoot(),
              state: { from: props.location },
            }}
          />
            )
        }
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  spinner: state.spinner,
});

PrivateRoute.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  component: PropTypes.func,
  props: PropTypes.objectOf(PropTypes.any),
  auth: PropTypes.objectOf(PropTypes.any),
  spinner: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
};

PrivateRoute.defaultProps = {
  classes: '',
  component: React.Component,
  props: {},
  auth: {},
  spinner: {},
  location: {},
};

export default compose(withStyles(styles), connect(mapStateToProps, null))(PrivateRoute);
