const styles = theme => ({
  toggleTariffComparisonContainer: {
    color: theme.palette.darkBlue,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    lineHeight: '21px',
    textAlign: 'center',
    paddingBottom: theme.spacing.unit,
    paddingTop: theme.spacing.unit * 4,
  },
});

export default styles;
