import { useEffect } from 'react';
import _ from 'lodash';

const useFormOnChange = (onChange, values, questionGroup) => {
  useEffect(() => {
    if (onChange) {
      _.throttle(() => onChange(questionGroup, values), 2000)();
    }
    // eslint-disable-next-line
  }, [values]);
};

export default useFormOnChange;
