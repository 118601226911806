import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  withStyles,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import InputText from '../../components/InputText';
import Button from '../../components/Button';
import SwitchInput from '../../components/SwitchInput';
import SelectField from '../../components/SelectField';
import styles from './style';
import { COMPANY } from '../../actions/offer';

const PrintOfferForm = ({
  structure,
  classes,
  togglePrintOfferModal,
  evbNumber,
  inputFields,
  onValueChange,
  defaultValues,
  printOffer,
}) => (
  <Fragment>
    <DialogTitle align="center">{structure.printDialog.title}</DialogTitle>
    <form onSubmit={event => printOffer(event)}>
      {evbNumber === '' && (
        <Fragment>
          <DialogContent>
            <Grid container justify="center" alignContent="center" spacing={0}>
              <Typography variant="h4" align="center">
                {structure.printDialog.text}
              </Typography>
            </Grid>
            <Grid container justify="center" alignContent="center" spacing={0}>
              <Grid item sm={10} xs={12}>
                <Grid container justify="flex-start" alignContent="center" spacing={2}>
                  <Grid item sm={4} xs={12}>
                    <SelectField
                      {...inputFields.selectTitle}
                      onChange={onValueChange}
                      setRequired
                    />
                  </Grid>
                  <Grid item sm={8} xs={12} />
                  {
                    defaultValues.selectTitle === COMPANY ?
                      <Grid item xs={12}>
                        <InputText
                          {...inputFields.companyName}
                          onChange={onValueChange}
                          defaultValue={defaultValues.companyName}
                          disableAutocomplete
                        />
                      </Grid> :
                      <Fragment>
                        <Grid item sm={6} xs={12}>
                          <InputText
                            {...inputFields.firstName}
                            onChange={onValueChange}
                            defaultValue={defaultValues.firstName}
                            disableAutocomplete
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <InputText
                            {...inputFields.lastName}
                            onChange={onValueChange}
                            defaultValue={defaultValues.lastName}
                            disableAutocomplete
                          />
                        </Grid>
                      </Fragment>
                  }
                  <Grid item xs={12}>
                    <InputText
                      {...inputFields.password}
                      {...structure.printDialog.fields.password}
                      onChange={onValueChange}
                      defaultValue={defaultValues.password}
                      disableAutocomplete
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SwitchInput
                      {...inputFields.registration}
                      onChange={onValueChange}
                      defaultValue={defaultValues.registration}
                    />
                  </Grid>
                  {!defaultValues.registration && (
                    <Grid item xs={12}>
                      <Typography component="p" align="center">
                        {structure.printDialog.fields.text}
                      </Typography>
                    </Grid>
                  )}
                  {defaultValues.registration && (
                    <Grid item xs={12}>
                      <Grid container justify="flex-start" alignContent="center" spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <InputText
                            {...inputFields.street}
                            onChange={onValueChange}
                            defaultValue={defaultValues.street}
                            disableAutocomplete
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <InputText
                            {...inputFields.houseNr}
                            onChange={onValueChange}
                            defaultValue={defaultValues.houseNr}
                            disableAutocomplete
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <InputText
                            {...inputFields.postalCode}
                            onChange={onValueChange}
                            defaultValue={defaultValues.postalCode}
                            readOnly
                            disableAutocomplete
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <InputText
                            {...inputFields.city}
                            onChange={onValueChange}
                            defaultValue={defaultValues.city}
                            disableAutocomplete
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              justify="space-around"
              alignContent="center"
              spacing={0}
              className={classes.dialogActionsContainer}
            >
              <Button
                {...structure.printDialog.buttons.cancel}
                onClick={togglePrintOfferModal}
              />
              <Button {...structure.printDialog.buttons.print} />
            </Grid>
          </DialogActions>
        </Fragment>
      )}
      {evbNumber !== '' && (
        <Fragment>
          <DialogContent>
            <Grid container justify="center" alignContent="center" spacing={0}>
              <Typography variant="h4" align="center">
                {structure.evbDialog.successText}
              </Typography>
            </Grid>
            <Grid container justify="center" alignContent="center" spacing={0}>
              <Grid item xs={12}>
                <Typography variant="h4" align="center">
                  {structure.evbDialog.yourEvb}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h3" align="center">
                  {structure.evbDialog.evb} {evbNumber}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              justify="space-around"
              alignContent="center"
              spacing={0}
              className={classes.dialogActionsContainer}
            >
              <Button
                {...structure.evbDialog.buttons.close}
                onClick={togglePrintOfferModal}
              />
            </Grid>
          </DialogActions>
        </Fragment>
      )}
    </form>
  </Fragment>
);

PrintOfferForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  structure: PropTypes.objectOf(Object),
  setCustomerLogin: PropTypes.func,
  inputFields: PropTypes.objectOf(Object),
  offer: PropTypes.objectOf(Object),
  openPrintOfferModal: PropTypes.bool,
  togglePrintOfferModal: PropTypes.func,
  evbNumber: PropTypes.string,
};

PrintOfferForm.defaultProps = {
  classes: '',
  offer: {},
  structure: undefined,
  inputFields: {},
  setCustomerLogin: () => {},
  openPrintOfferModal: false,
  togglePrintOfferModal: () => {},
  evbNumber: '',
};

export default withStyles(styles)(PrintOfferForm);
