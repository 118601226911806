const styles = theme => ({
  dialogContentTitleContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  dialogActionsContainer: {
    '& button': {
      marginTop: 0,
    },
  },
  link: {
    color: theme.palette.primary1Color,
  },
  colorSwitchBase: {
    '&$colorChecked': {
      color: theme.palette.primary1Color,
      '& + $colorBar': {
        backgroundColor: theme.palette.primary1Color,
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  termsAndConditionsTextContainer: {
    paddingLeft: theme.spacing.unit * 6,
  },
});

export default styles;
