import request from '../requestAgent';
import types from './types';
import handleErrors from './errorHandling';
import { setUser } from './authentication';
import { openSnackbar, SnackbarKind } from './snackbar';
import history from '../history';
import { VEHICLE_SEARCH } from '../utils/redirects';

export const setPersonalDetails = userDetails => ({
  type: types.SET_PERSONAL_DETAILS,
  payload: userDetails,
});

export const setUserDetails = userDetails => ({
  type: types.SET_USER_DETAILS,
  payload: userDetails,
});

export const setSearchedUsers = searchedUsers => ({
  type: types.SET_SEARCHED_USERS,
  payload: searchedUsers,
});

export const getUserDetails = userId => async (dispatch) => {
  try {
    const endpoint = `/api/user-manager/user/${userId}`;
    const res = await request.get(endpoint);
    dispatch(setUserDetails(res.body));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const changeUserDetails = (
  userId,
  firstName,
  lastName,
  email,
  employeeNumber,
  role,
  dealership,
  supervisorId,
) => async (dispatch) => {
  try {
    const endpoint = `api/user-manager/user/${userId}`;
    const res = await request.put(endpoint).send({
      firstName,
      lastName,
      email,
      employeeNumber,
      role,
      dealership,
      supervisorId,
    });
    dispatch(openSnackbar({
      message: res.statusText,
      kind: SnackbarKind.SUCCESS,
    }));
    dispatch(getUserDetails(userId));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const getPersonalDetails = () => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user/accountDetails';
    const res = await request.get(endpoint);
    dispatch(setPersonalDetails(res.body));
    dispatch(setUser(res.body.role));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const changePersonalDetails = (firstName, lastName, email) => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user/profile';
    const res = await request.put(endpoint).send({ firstName, lastName, email });
    dispatch(openSnackbar({
      message: res.statusText,
      kind: SnackbarKind.SUCCESS,
    }));
    dispatch(getPersonalDetails());
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const changeAccountPassword = (
  currentPassword,
  newPassword,
  repeatPassword,
) => async (dispatch) => {
  try {
    if (repeatPassword !== newPassword) {
      dispatch(openSnackbar({
        message: 'Die angegebenen Passwörter stimmen nicht überein',
        kind: SnackbarKind.ERROR,
      }));
      return;
    }
    if (currentPassword === newPassword) {
      dispatch(openSnackbar({
        message: 'You are not allowed to use the same password.',
        kind: SnackbarKind.ERROR,
      }));
      return;
    }
    const endpoint = '/api/user-manager/user/changeAccountPassword';
    const res = await request.post(endpoint).send({ currentPassword, newPassword });
    dispatch(openSnackbar({
      message: res.body.message,
      kind: SnackbarKind.SUCCESS,
    }));
    dispatch(getPersonalDetails());
    history.push(VEHICLE_SEARCH);
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const searchUser = email => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user/find';
    const res = await request.post(endpoint).send({ email });
    dispatch(setSearchedUsers(res.body));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const createUser = (
  firstName,
  lastName,
  employeeNumber,
  email,
  role,
  dealership,
  supervisorId,
) => async (dispatch) => {
  try {
    const endpoint = '/api/user-manager/user';
    const res = await request.post(endpoint).send({
      firstName,
      lastName,
      employeeNumber,
      email,
      role,
      dealership,
      supervisorId,
    });
    dispatch(openSnackbar({
      message: res.statusText,
      kind: SnackbarKind.SUCCESS,
    }));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const enableUser = id => async (dispatch) => {
  try {
    const endpoint = `/api/user-manager/user/enableUser/${id}`;
    const res = await request.put(endpoint);
    dispatch(openSnackbar({
      message: 'Done',
      kind: SnackbarKind.SUCCESS,
    }));
    dispatch(setUserDetails(res.body));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const unlockUser = id => async (dispatch) => {
  try {
    const endpoint = `/api/user-manager/user/unlockUser/${id}`;
    const res = await request.put(endpoint);
    dispatch(openSnackbar({
      message: 'Done',
      kind: SnackbarKind.SUCCESS,
    }));
    dispatch(setUserDetails(res.body));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const disableUser = id => async (dispatch) => {
  try {
    const endpoint = `/api/user-manager/user/disableUser/${id}`;
    const res = await request.put(endpoint);
    dispatch(openSnackbar({
      message: 'Done',
      kind: SnackbarKind.SUCCESS,
    }));
    dispatch(setUserDetails(res.body));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};

export const deleteUser = id => async (dispatch) => {
  try {
    const endpoint = `/api/user-manager/user/${id}`;
    const res = await request.delete(endpoint);
    dispatch(openSnackbar({
      message: res.statusText,
      kind: SnackbarKind.SUCCESS,
    }));
  } catch (err) { dispatch(handleErrors(err.response.body)); }
};
