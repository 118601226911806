import { useEffect } from 'react';

const useFormErrorRender = (onErrorRender, isSubmitting, errors) => {
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      onErrorRender();
    }
  }, [errors, isSubmitting]);
};

export default useFormErrorRender;
