import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography, withStyles, Hidden } from '@material-ui/core';
import NormalTariffComparison from '../../components/NormalTariffComparison';
import ExtendedTariffComparison from '../../components/ExtendedTariffComparison';
import ToggleTariffComparison from '../../components/ToggleTariffComparison';
import styles from './style';

class TariffComparison extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tariffExpanded: false,
    };
    this.handleTariffExpansionPanelChange = this.handleTariffExpansionPanelChange.bind(this);
  }

  handleTariffExpansionPanelChange() {
    this.setState({
      tariffExpanded: !this.state.tariffExpanded,
    });
  }

  render() {
    const prices = {};
    this.props.price.forEach((el) => {
      prices[el.type] = {
        type: el.type,
        price: el.price,
        special: el.special,
      };
    });
    const { classes } = this.props;
    return (
      <Grid container justify="center" alignContent="center" direction="column" spacing={5}>
        {/* Toggle */}
        <Hidden xsDown>
          <Typography component="div" className={classes.toggleTariffComparisonContainer}>
            <ToggleTariffComparison
              handleTariffExpansionPanelChange={this.handleTariffExpansionPanelChange}
              tariffExpanded={this.state.tariffExpanded}
              labelQuestionExpanded={this.props.structure.structure.question5.text}
              labelQuestionNotExpanded={this.props.structure.structure.question4.text}
            />
          </Typography>
        </Hidden>

        {/* Normal display offer screen */}
        <Grid item>
          {!this.state.tariffExpanded && (
            <NormalTariffComparison
              metaData={this.props.structure.structure.question4.cards}
              coverage={this.props.coverage}
              prices={prices}
              unit={this.props.offer.contractPaymentInterval}
              footerPrice={this.props.footerPrice}
              onClick={this.props.onClick}
              isSpecial={this.props.isSpecial}
            />
          )}

          {/* Extended display offer screen */}
          {this.state.tariffExpanded && (
            <ExtendedTariffComparison
              metaData={this.props.structure.structure.question5.table}
              coverage={this.props.coverage}
              prices={prices}
              unit={this.props.offer.contractPaymentInterval}
              footerPrice={this.props.footerPrice}
              onClick={this.props.onClick}
              isSpecial={this.props.isSpecial}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.insuranceStep2,
  offer: state.offerDetails.offer,
  footerPrice: state.projectStructure.footerPrice,
});

TariffComparison.propTypes = {
  coverage: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  structure: PropTypes.objectOf(Object).isRequired,
  offer: PropTypes.objectOf(Object).isRequired,
  footerPrice: PropTypes.objectOf(Object).isRequired,
  price: PropTypes.arrayOf(Object).isRequired,
  isSpecial: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

TariffComparison.defaultProps = {
  classes: '',
};

export default connect(mapStateToProps)(withStyles(styles)(TariffComparison));
