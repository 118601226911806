import PropTypes from 'prop-types';

const VehicleModel = PropTypes.shape({
  producerId: PropTypes.string,
  vehTypeId: PropTypes.string,
  companyName: PropTypes.string,
  salesDesignation: PropTypes.string,
  strengthHp: PropTypes.number,
  strengthKw: PropTypes.number,
  fuel: PropTypes.string,
});

export default VehicleModel;
