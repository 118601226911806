import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import styles from './style';
import Label from '../Label';
import Button from '../Button';

function RadioInputBlock({
  name,
  classes,
  defaultValue,
  onChange,
  options,
  label,
  myWidth,
  disabled,
}) {
  return (
    <Grid container justify="center" alignItems="center" className={classes.inputContainer}>
      <Grid item xs={12} align="center">
        <Typography component="div" className={classes.labelContainer}>
          <Label label={label} htmlFor={name} />
        </Typography>
        {options.map(option => (
          <div
            className={classes.blockWrapper}
            data-checked={option.value === defaultValue && 'true'}
            key={name + option.key}
          >
            <Button
              label={option.key}
              onClick={() => onChange(option.value, name)}
              myWidth={myWidth}
              disabled={disabled}
            />
          </div>
        ))}
      </Grid>
    </Grid>
  );
}

RadioInputBlock.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  myWidth: PropTypes.string,
};

RadioInputBlock.defaultProps = {
  name: '',
  label: '',
  classes: '',
  defaultValue: '',
  onChange: null,
  disabled: false,
};

export default withStyles(styles)(RadioInputBlock);
