import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography } from '@material-ui/core';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { changePersonalDetails } from '../../actions/userDetails';
import styles from './style';
import Button from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';
import InputText from '../../components/InputText';
import Notification from '../Notification';

class EditAccountDetails extends Component {
  constructor(props) {
    super(props);
    this.classes = this.props.classes;
    this.state = {
      firstName: this.props.personalDetails.firstName,
      lastName: this.props.personalDetails.lastName,
      email: this.props.personalDetails.email,
    };

    this.onClick = this.onClick.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.isStructureReady = this.isStructureReady.bind(this);
  }

  onClick(variant) {
    this.props.variantChange(variant);
  }

  onValueChange(value, field) {
    this.setState({ [field]: value });
  }

  submitHandler(event) {
    event.preventDefault();
    this.props.changePersonalDetails(this.state.firstName, this.state.lastName, this.state.email);
  }

  isStructureReady() {
    return this.props.structure !== undefined;
  }

  render() {
    return (
      <Grid container justify="center" alignContent="center" spacing={0}>
        <Grid item xs={12} className={this.classes.wrapper}>
          {this.isStructureReady() && (
            <div>
              <ButtonLink
                {...this.props.structure.buttons.back}
                onClick={() => this.onClick('showDetails')}
              />
              <Typography align="center" variant="h1" className={this.classes.title}>
                {this.props.structure.header.mainText}
              </Typography>
              <Grid container justify="center" alignContent="center" spacing={2}>
                <Grid item md={6} sm={8} xs={12}>
                  <Notification />
                  <form onSubmit={event => this.submitHandler(event)}>
                    <Grid container justify="center" alignContent="center" spacing={2}>
                      <Grid item md={6} sm={12}>
                        <InputText
                          {...this.props.inputFields.firstName}
                          defaultValue={this.state.firstName}
                          onChange={this.onValueChange}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <InputText
                          {...this.props.inputFields.lastName}
                          defaultValue={this.state.lastName}
                          onChange={this.onValueChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputText
                          {...this.props.inputFields.email}
                          defaultValue={this.state.email}
                          onChange={this.onValueChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button {...this.props.structure.buttons.save} />
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  personalDetails: state.personalDetails,
  notificationCenter: state.notificationCenter,
  structure: state.projectStructure.editAccountDetails,
  inputFields: state.projectFields,
});

const mapDispatchToProps = dispatch => ({
  changePersonalDetails: (firstName, lastName, email) =>
    dispatch(changePersonalDetails(firstName, lastName, email)),
});

EditAccountDetails.propTypes = {
  changePersonalDetails: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
};

EditAccountDetails.defaultProps = {
  changePersonalDetails: () => {},
  classes: '',
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EditAccountDetails);
