import PropTypes from 'prop-types';
import SupervisorModel from './supervisor';

const PersonalDetailsModel = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  id: PropTypes.string,
  email: PropTypes.string,
  organization: PropTypes.string,
  dealership: PropTypes.string,
  role: PropTypes.string,
  employeeNumber: PropTypes.string,
  token: PropTypes.string,
  expirationDate: PropTypes.string,
  accountLocked: PropTypes.bool,
  accountDisabled: PropTypes.bool,
  roleLabel: PropTypes.string,
  supervisorId: PropTypes.string,
  supervisorFullName: PropTypes.string,
  supervisor: SupervisorModel,
});

export default PersonalDetailsModel;
